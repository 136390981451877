import { useRef } from "react";

import classes from "./Modal.module.css";
import { useClickAway, useKeyPressEvent } from "react-use";

export default function Modal({
    setModalOpen,
    style = {},
    backgroundStyle = {},
    setSelectedType = null,
    className = null,
    children = null,
    backgroundClass = false,
    onClick = null,
}) {
    const modal_ref = useRef(null);

    useClickAway(modal_ref, (e) => {
        e.stopPropagation();
        setModalOpen ? setModalOpen(false) : void 0;
        setSelectedType ? setSelectedType(null) : void 0;
    });
    useKeyPressEvent("Escape", () => setModalOpen(false));
    return (
        <div
            className={[classes.ModalBackground, backgroundClass && backgroundClass, "ZoomingHeight"].join(" ")}
            style={{ ...backgroundStyle }}
            onClick={onClick}
        >
            <div className={[classes.Modal, className].join(" ")} style={{ ...style }} ref={modal_ref}>
                {children}
            </div>
        </div>
    );
}
