import { useEffect, useState } from "react";

//UI
import classes from "./CreateFillInBlanks.module.css";
import joinClasses from "../../../../../../utils/joinClasses";

//components
import LabeledSection from "../../../../../ComponentLibrary/LabeledSection/LabeledSection";
import TextInput from "../../../../../ComponentLibrary/TextInput/TextInput";
import LexicalRichTextMax_ReadOnly from "../../../../../LexicalEditors/containers/LexicalMax/LexicalMaxReadOnly";
import LexicalMax from "../../../../../LexicalEditors/containers/LexicalMax/LexicalMax";
import Switch from "../../../../../UI/Switch/Switch";
import EditButton from "../../../../../Shared/EditButton/EditButton";

//context
import { useLanguage } from "../../../../../../contexts/LanguageContext";
import { useQuizEditContext } from "../../../../../../contexts/QuizEditContext";

//functions
import { getQuestionDataNew } from "../../../../../ResourcePath/Resources/QuizResource/functions/QuizHelperFunctions";
import { sumPositives } from "../../../../../Helpers/HelperFunction";
import { spliceObject } from "../../../../../Helpers/HelperFunctions_2";
import { handleImageUploadQuizQuestion } from "../../../../../LexicalEditors/functions/LexicalImageFunctions";

export const blanksRegex = /<s><span class="LexicalMaxTheme__textStrikethrough"[^>]*>(.+?)<\/span><\/s>/g;
export const blanksRegexNoG = /<s><span class="LexicalMaxTheme__textStrikethrough"[^>]*>(.+?)<\/span><\/s>/;
export const blanksInputWidth = 148;

export default function CreateFillInBlanks({}) {
    const { translate } = useLanguage();
    const { editQuestion, setEditQuestion, handleChangeBool, storage_directory } = useQuizEditContext();

    const [qBlanksData, setQBlanksData] = useState(null);
    const [parsedQGap, setParsedQGap] = useState(null);
    const [bufferTimer, setBufferTimer] = useState(null);
    const [triggerUpdate, setTriggerUpdate] = useState(1);
    const { q_blanks, q_question, q_explanation, q_false_ans, q_false_answers, q_sggstns } = getQuestionDataNew(editQuestion);

    // console.log("CREATE_BLANK", q_question, q_explanation);
    //TODO_M: Maxpoints not taken -> always 0
    // useEffect(() => {
    //     if(q_question) setQBlanksData(qBlanksData);
    //     if(q_explanation) setEditQuestion({ ...editQuestion, cont: { ...editQuestion?.cont, ["expl"]: q_explanation } });
    // }, [q_question, q_explanation]);

    const handleChangeQuestion = (value, field) => {
        setEditQuestion({ ...editQuestion, cont: { ...editQuestion?.cont, [field]: value } });
    };

    //FALSE ANSWERS
    const handleChangeFalseAnswers = (prop, value, index) => {
        setEditQuestion((prev) => {
            const fls_ans = prev?.cont?.fls_ans || [{ solution: "", points: 0 }];
            fls_ans[index][prop] = value;
            return { ...prev, cont: { ...prev.cont, fls_ans: fls_ans } };
        });
    };
    const handleAddFalseAnswer = () => {
        setEditQuestion((prev) => {
            const new_answer = { solution: "", points: 0 };
            const existing_fls_ans = prev?.cont?.fls_ans || [];
            const new_fls_ans = [new_answer, ...existing_fls_ans];
            return { ...prev, cont: { ...prev.cont, fls_ans: new_fls_ans } };
        });
    };

    // function handleChangeBlank(e, index) {
    //     const newValue = e.target.value;
    //     const blanks_copy = q_blanks;
    //     const new_blanks = { ...blanks_copy, [index]: { solution: newValue } };
    //     console.log(new_blanks);
    //     setEditQuestion({ ...editQuestion, cont: { ...editQuestion?.cont, ["blanks"]: new_blanks } });
    // }

    function parseQGap() {
        if (bufferTimer) clearTimeout(bufferTimer);
        const newBuffer = setTimeout(() => {
            let index = 1;
            // console.log("replace", qBlanksData);
            const parsedQuizGapData = qBlanksData?.replaceAll(blanksRegex, (match) => {
                // const value = editQuestion.cont.blanks[number]?.solution;
                // console.log("REPLACING", match);
                const strippedText = match.match(blanksRegexNoG)[1];
                // console.log("REPLACED", strippedText);
                const input = `<input id="${index}" class="blankQInput" style="width: ${blanksInputWidth}px" />`;
                // if (!q_blanks?.[index])
                handleAddAnswer(index, strippedText);
                // else (true) handleRemoveAnswer();
                index++;
                return input;
            });
            handleResidualAnswers(index - 1);
            // handleRemoveAnswer();
            // console.log("PARSED", parsedQuizGapData);
            // setindex(1);
            setParsedQGap(<LexicalRichTextMax_ReadOnly lexicalData={parsedQuizGapData} />);
        }, 100);
        setBufferTimer(newBuffer);
    }

    useEffect(() => {
        parseQGap();
    }, [qBlanksData, triggerUpdate]);

    //ANSWER functions
    function handleAddAnswer(index, value) {
        setEditQuestion((prev) => {
            return { ...prev, cont: { ...prev.cont, blanks: { ...prev.cont.blanks, [index]: { solution: value, points: 1 } } } };
        });
    }

    const handleResidualAnswers = (n_gaps) => {
        // console.log("ResBlanks", q_blanks, n_gaps);
        // console.log("ResBlanks_2", Object.keys(q_blanks));
        if (Object.keys(q_blanks)?.length > n_gaps) {
            const new_q_blanks = spliceObject(q_blanks, n_gaps + 1);
            // console.log("ResBlanks_3", new_q_blanks);
            setEditQuestion((prev) => {
                return { ...prev, cont: { ...prev.cont, blanks: { ...new_q_blanks } } };
            });
        }
    };

    const handlePointsAnswers = (index, value) => {
        const newPoints = parseInt(value);
        const answerIndex = index + 1;
        let new_answers = q_blanks;
        // console.log("CHANGEPOINTS", new_answers, answerIndex, newPoints);
        new_answers[answerIndex].points = newPoints;
        // console.log("CHANGEPOINTS 2", new_answers);
        const maxPoints = sumPositives(new_answers.map((a) => a.points));
        setEditQuestion({
            ...editQuestion,
            props: { ...editQuestion.props, maxPoints },
            cont: { ...editQuestion.cont, answers: new_answers },
        });
    };

    const handleChangeAnswer = (index, value) => {
        let new_answers = q_blanks;
        new_answers[index + 1]["solution"] = value;
        setEditQuestion({
            ...editQuestion,
            cont: { ...editQuestion.cont, blanks: new_answers },
        });
    };

    // useEffect(() => {
    //     console.log("Gaps", q_blanks);
    // }, [q_blanks]);

    console.log("GAPS", q_blanks, editQuestion, q_question);

    return (
        <div className={classes.CreateFillInBlanks}>
            <LabeledSection label={translate("COMPOSE_QUESTION") + ":"}>
                <LexicalMax
                    config="blanks"
                    onChange={(newStateHTML) => {
                        setQBlanksData(newStateHTML);
                        handleChangeQuestion(newStateHTML, "q");
                    }}
                    handleImageUpload={(newImg, file, file_name, setprogress) =>
                        handleImageUploadQuizQuestion(storage_directory, newImg, file, file_name, setprogress)
                    }
                    initialState={q_question}
                />
            </LabeledSection>
            <LabeledSection label={translate("ANSWERS")} subLabel={translate("QR_ANSWERS_EXPL")} hoverActive={false}>
                <div className={classes.SolutionHolder}>
                    <BlanksAnswers
                        answers={q_blanks ? Object.values(q_blanks) : []}
                        handleChangeAnswer={handleChangeAnswer}
                        handlePointsAnswers={handlePointsAnswers}
                    />
                </div>
            </LabeledSection>
            <LabeledSection label={translate("QR_FINAL_RESULT")} subLabel={translate("QR_FINAL_RESULT_EXPL")} hoverActive={false}>
                {parsedQGap}
            </LabeledSection>
            {/* <LabeledSection label={translate("EVALUATION")} subLabel={"Set how points are calculated"} hoverActive={false}>
                <div className={classes.EvalSection}>
                    <div className={classes.DropdownHolder}>
                        <DropdownPicker
                            width={"220px"}
                            options={[
                                { display: "Points divided equally", value: "equal" },
                                { display: "Individual", value: "indiv" },
                            ]}
                            selected={pointsType}
                            setSelected={setPointsType}
                        />
                    </div>
                    {pointsType.value === "equal" ? (
                        <div className={classes.PointsInputHolder}>
                            <TextInput onChange={null}></TextInput>
                        </div>
                    ) : null}
                </div>
            </LabeledSection> */}
            <LabeledSection
                labelClass={classes.ShowOptionsSection}
                label={translate("QR_SHOW_OPTIONS") + ":"}
                subLabel={translate("QR_SHOW_OPTIONS_EXPL")}
                hoverActive={false}
            >
                <Switch onClick={(e) => handleChangeBool(e, "props", "sggstns")} checked={q_sggstns} />
            </LabeledSection>
            {q_sggstns && (
                <LabeledSection label={translate("QR_GIVE_FALSE_ANS") + ":"} subLabel={translate("QR_GIVE_FALSE_ANS_EXPL")}>
                    <Switch onClick={(e) => handleChangeBool(e, "props", "false_ans")} checked={q_false_ans} />
                </LabeledSection>
            )}
            {q_false_ans && (
                <div className={classes.AnswersContainer}>
                    {q_false_answers?.map((q_false_answer, index) => {
                        return (
                            <div className={classes.Answer} key={index}>
                                <TextInput
                                    label={null}
                                    onChange={(e) => handleChangeFalseAnswers("solution", e.target.value, index)}
                                    value={q_false_answer?.solution}
                                />
                                <input
                                    className={classes.PointInput}
                                    value={q_false_answer?.points}
                                    onChange={(e) => handleChangeFalseAnswers("points", e.target.value, index)}
                                    type="number"
                                />
                            </div>
                        );
                    })}
                    <div className={classes.AddBtnHolder}>
                        <EditButton size={26} onClick={handleAddFalseAnswer} type="add" />
                    </div>
                </div>
            )}
            <LabeledSection label={translate("EXPLANATION") + ": "}>
                <LexicalMax
                    handleImageUpload={(newImg, file, file_name, setprogress) =>
                        handleImageUploadQuizQuestion(storage_directory, newImg, file, file_name, setprogress)
                    }
                    minLines={2}
                    onChange={(newStateHTML) => handleChangeQuestion(newStateHTML, "expl")}
                    initialState={q_explanation}
                />
            </LabeledSection>
        </div>
    );
}

const BlanksAnswers = ({ handlePointsAnswers, handleChangeAnswer, answers }) => {
    const { translate } = useLanguage();
    function detectSpace(str) {
        return str.startsWith(" ") || str.endsWith(" ");
    }
    return (
        <div className={classes.AnswersContainer}>
            {answers?.map((answer, index) => {
                const hasSpace = detectSpace(answer.solution);
                return (
                    <>
                        <div className={joinClasses(classes.Answer, answer.cr && classes.Correct)} key={index}>
                            <TextInput disabled label={null} onChange={(e) => handleChangeAnswer(index, e.target.value)} value={answer.solution} />
                            <input
                                className={classes.PointInput}
                                value={answer.points}
                                onChange={(e) => handlePointsAnswers(index, e.target.value)}
                                type="number"
                            />
                        </div>
                        {hasSpace ? <div className={classes.AnswerAlert}>{translate("QR_WARNINGSPACE")}</div> : null}
                    </>
                );
            })}
        </div>
    );
};
