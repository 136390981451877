import { collection, doc, getDoc, getDocs, query, where } from "firebase/firestore";
import { firestore } from "../../../app/firebase";
import { getPathMemberStatsData, getPathStatsData } from "../../../functions/PathHelperFunctions";

export async function getPathByID(space_id, path_id) {
    try {
        const ref = doc(firestore, "spaces", space_id, "paths", path_id)
        const path_snap = await getDoc(ref)
        return path_snap.data();
    } catch (error) {
        console.log(error);
        return {}
    }
}
export async function getPathStatsByID(space_id, path_id) {
    try {
        const ref = doc(firestore, "spaces", space_id, "paths", path_id, "stats", "path_stats")
        const path_snap = await getDoc(ref)
        const { active_groups, archived_groups } = getPathStatsData(path_snap.data())
        return {
            active_groups, archived_groups,
        };
    } catch (error) {
        console.log(error);
        return {}
    }
}
export async function getPathMemberStatsByID(space_id, path_id) {
    try {
        const ref = doc(firestore, "spaces", space_id, "paths", path_id, "stats", "member_stats")
        const path_snap = await getDoc(ref)
        const { active_curators,
            active_teachers,
            active_students } = getPathMemberStatsData(path_snap.data())
        return {
            active_curators,
            active_teachers,
            active_students
        };
    } catch (error) {
        console.log(error);
        return {}
    }
}

export async function getPathsByIDs(space_id, path_ids) {
    const result = await Promise.all(path_ids.map((path_id) => getDoc(doc(firestore, "spaces", space_id, "paths", path_id))));
    return result
}


export async function getPathOverview(space_id, path_id) {
    const pathOverviewRef = doc(firestore, "spaces", space_id, "resources_overview_by_path_id", path_id)
    const pathOverviewSnapshot = await getDoc(pathOverviewRef);
    let pathOverviewResources = {}
    if (pathOverviewSnapshot.exists()) {
        pathOverviewResources = pathOverviewSnapshot.data()?.resources
    }
    return pathOverviewResources
}

export async function getPathOverviewsByIDs(space_id, path_ids) {
    const result = await Promise.all(path_ids.map((path_id) => getDoc(doc(firestore, "spaces", space_id, "resources_overview_by_path_id", path_id))));
    return result
}

export async function loadResource(space_id, path_id, local_id) {
    const resRef = doc(firestore, "spaces", space_id, "paths", path_id, "resources", local_id)
    const resSnapshot = await getDoc(resRef);
    let res = {}
    if (resSnapshot.exists()) {
        res = resSnapshot.data()
    }
    return res
}


export async function getResourcesByModule(space_id, path_id, module_number) {
    const pathResourcesRef = collection(firestore, "spaces", space_id, "paths", path_id, "resources")
    const resourcesQuery = query(pathResourcesRef,
        where("localData.module", "==", module_number),
        where("localData.clipboard", "==", false)
    );
    const snaps = await getDocs(resourcesQuery);
    const resources = []
    snaps.forEach((doc) => {
        resources.push(doc.data());
    });
    return resources
}

export async function getGroupResourcesByModule(space_id, path_id, module_number) {
    const pathResourcesRef = collection(firestore, "spaces", space_id, "groupResources")
    const resourcesQuery = query(pathResourcesRef,
        where("localData.module", "==", module_number),
        // where("localData.path_id", "==", path_id),
        // where("localData.clipboard", "==", false)
    );
    const snaps = await getDocs(resourcesQuery);
    const resources = []
    snaps.forEach((doc) => {
        resources.push(doc.data());
    });
    return resources
}


export async function getGlobalTags(lang) {
    const global_tags_ref = doc(firestore, "tags", lang)
    const global_tags_snapshot = await getDoc(global_tags_ref);
    let global_tags = {}
    if (global_tags_snapshot.exists()) {
        global_tags = global_tags_snapshot.data()?.tags
    }
    return global_tags
}

export async function getSpaceTags(space_id, lang) {
    const space_tags_ref = doc(firestore, "spaces", space_id, "tags", lang);
    const space_tags_snapshot = await getDoc(space_tags_ref);
    let space_tags = {}
    if (space_tags_snapshot.exists()) {
        space_tags = space_tags_snapshot.data()?.tags
    }
    return space_tags
}