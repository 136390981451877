import { useContext, useState, createContext } from "react"
import { useUpdateEffect } from "react-use";

import { useParams } from "react-router-dom";

import { getquestion_data_blueprint2 } from "../components/Helpers/BluePrints/DataBluePrints";
import { loadQuestionByID2 } from "../components/ResourcePath/Resources/QuizResource/functions/QuizFirestoreFetchFunctions";
import { createAlgoliaFilter } from "../components/Path/QuestionPool/QPTopSection/FilterSectionHandlers";
import { isAnyFilterSet } from "../functions/QuizEditHelperFunctions";

import { getQuestionDataNew } from "../components/ResourcePath/Resources/QuizResource/functions/QuizHelperFunctions";
import { useSpaceContext } from "./SpaceContext/SpaceContext";
import { useAuth } from "./AuthContext";
import { useSearchBox } from "react-instantsearch-hooks";

const QuizEditContext = createContext({
    //QPool
    showQuestionPool: false, setshowQuestionPool: null, // function
    sortDirectionDesc: {}, setSortDirectionDesc: null, // function
    algoFilters: [], setAlgoFilters: null, // function
    algoTags: [], setAlgoTags: null, // function
    selectedTags: [], setSelectedTags: null, // function
    setAlreadyUsedQuestionIDs: null, // function
    //EDIT
    showQuestionEditModal: false, setShowQuestionEditModal: null, // function
    showQuizEditorModal: false, setShowQuizEditorModal: null, // function
    handleOpenEditQuestion: null, // function
    handleOpenCreateQuestion: null, // function
    handleOpenImportQs: null, // function
    showImportQsModal: false,
    loadingQ: false,
    editQuestion: {}, setEditQuestion: null, // function
    pre_editQuestion: {}, editQuestionMode: "", setEditQuestionMode: null, // function
    storage_directory: "",
    //ALGOLIA Workaround
    updatedQuestionTitles: [], handleUpdateQuestionTitle: null, // function
    deletedQuestions: [], handleDeletedQuestion: null, // function
    newQuestions: [],
    setNewQuestions: null, // function
    handleClearFilter: null, // function
    ///FILTERS
    filters: {}, toggleFilterNew: null, // function
    algolia_filters: {}, setalgolia_filters: null, // function
    any_filter_set: false,
    //EDIT QUESTIONS,
    deleteModal: false, setdeleteModal: null, selectedQuestions: [], setSelectedQuestions: null,
    selected_to_be_deletedQuestions: [], setselected_to_be_deletedQuestions: null,
    handleChangeBool: null
})

export function useQuizEditContext() {
    return useContext(QuizEditContext)
}

//provider
export function QuizEditProvider({ children, isSpaceResource }) {
    const { path_id } = useParams();
    const { space_id } = useSpaceContext();
    const { user_id } = useAuth();
    const { clear } = useSearchBox();

    //QPOOL sorting
    const [showQuestionPool, setshowQuestionPool] = useState(false)
    const [sortDirectionDesc, setSortDirectionDesc] = useState({ attribute: "date_updated", direction: "desc" });

    const [algoFilters, setAlgoFilters] = useState("");
    const [algoTags, setAlgoTags] = useState("");
    const [selectedTags, setSelectedTags] = useState([]);

    const [algolia_filters, setalgolia_filters] = useState(isSpaceResource ? "" : "meta.path_ids: " + path_id);
    const [filters, setfilter] = useState({ alreadyUsedQuestionIDs: [], only_my: false, multipleChoice: { mc: false, sc: false, tf: false }, blanks: false, match: false });
    function toggleFilterNew(e, field, section = null) {
        if (section) setfilter(prev => { return { ...prev, [section]: { ...prev[section], [field]: !prev[section][field] } } });
        else setfilter(prev => { return { ...prev, [field]: !prev[field] } });
    }
    function setAlreadyUsedQuestionIDs(alreadyUsedQuestionIDs = []) {
        setfilter(prev => { return { ...prev, alreadyUsedQuestionIDs } });
    }

    useUpdateEffect(() => {
        const algolia_filters = createAlgoliaFilter(filters, algoTags, user_id);
        if (path_id) {
            if (algolia_filters) {
                setalgolia_filters("meta.path_ids:" + path_id + " AND " + algolia_filters)
            } else {
                setalgolia_filters("meta.path_ids:" + path_id)
            }
        }else{
            setalgolia_filters(algolia_filters)
        }

    }, [filters, algoTags])
    useUpdateEffect(() => {
        handleClearFilter();
    }, [path_id])
    function handleClearFilter() {
        setSelectedTags([]);
        setAlgoTags("")
        setalgolia_filters("meta.path_ids:" + path_id);
        setfilter({ alreadyUsedQuestionIDs: [], multipleChoice: { mc: false, sc: false, tf: false }, blanks: false, match: false, only_my: false })
        clear();
    }
    const any_filter_set = isAnyFilterSet(filters)

    //EDIT 
    const [loadingQ, setLoadingQ] = useState(null);
    const [showQuestionEditModal, setShowQuestionEditModal] = useState(false);
    const [editQuestion, setEditQuestion] = useState(getquestion_data_blueprint2(path_id, user_id));
    const [pre_editQuestion, setPreEditQuestion] = useState({});

    const [editQuestionMode, setEditQuestionMode] = useState({ mode: "", qid: null });
    const [showQuizEditorModal, setShowQuizEditorModal] = useState(false);

    const { q_id } = getQuestionDataNew(editQuestion);
    const storage_directory = "/spaces/" + space_id + "/paths/" + path_id + "/question_pool/" + q_id + "/";

    //FUNCTIONS
    //work around update -> algolia
    function handleUpdateQuestionTitle(newQuestion) {
        const newupdatedQuestionTitles = updatedQuestionTitles
        Object.assign(newupdatedQuestionTitles, { [newQuestion.meta.qid]: newQuestion })
        setUpdatedQuestionTitles(newupdatedQuestionTitles);
    }
    function handleDeletedQuestion(qids) {
        setdeletedQuestions([...deletedQuestions, ...qids])
    }
    function handleOpenCreateQuestion(mode) {
        setEditQuestion(getquestion_data_blueprint2(path_id, user_id))
        setEditQuestionMode({ mode, qid: null })
        setShowQuestionEditModal(true);
    }
    const [showImportQsModal, setShowImportQsModal] = useState(false)
    function handleOpenImportQs() {
        setShowImportQsModal(prev => !prev);
    }
    function handleChangeBool(e, section, field) {
        setEditQuestion((prev) => {
            if (field === "false_ans") {
                const new_answer = { solution: "", points: 0 };
                let fls_ans = prev?.cont?.fls_ans || [new_answer];
                console.log(fls_ans);
                return {
                    ...prev, [section]: { ...prev?.[section], [field]: e.target.checked }
                    , ["content"]: { ...prev?.["content"], ["fls_ans"]: fls_ans }
                };

            } else {
                return { ...prev, [section]: { ...prev?.[section], [field]: e.target.checked } };

            }
        });
    }

    async function handleOpenEditQuestion(e, qid) {
        e.preventDefault();
        e.stopPropagation();
        setLoadingQ(qid);
        setEditQuestionMode({ mode: "edit", qid });
        const question = await loadQuestionByID2(qid);
        setEditQuestion(question)
        setPreEditQuestion(question)
        setShowQuestionEditModal(true);
        setLoadingQ(null);
    };

    //EDIT QUESTIONS
    const [selectedQuestions, setSelectedQuestions] = useState([]); //TODO_C: maybe transform to object


    const [deleteModal, setdeleteModal] = useState(false)



    //ALGOLIA Workaround
    const [newQuestions, setNewQuestions] = useState([])
    const [updatedQuestionTitles, setUpdatedQuestionTitles] = useState({})
    const [deletedQuestions, setdeletedQuestions] = useState([])
    const [selected_to_be_deletedQuestions, setselected_to_be_deletedQuestions] = useState([])


    const value = {
        //QPool
        showQuestionPool, setshowQuestionPool,
        sortDirectionDesc, setSortDirectionDesc,

        algoFilters, setAlgoFilters,
        algoTags, setAlgoTags,
        selectedTags, setSelectedTags, setAlreadyUsedQuestionIDs,


        //EDIT
        showQuestionEditModal, setShowQuestionEditModal,
        showQuizEditorModal, setShowQuizEditorModal,
        handleOpenEditQuestion, handleOpenCreateQuestion,
        handleOpenImportQs, showImportQsModal,
        loadingQ,
        editQuestion, setEditQuestion, pre_editQuestion,
        editQuestionMode, setEditQuestionMode,
        storage_directory,
        //ALGOLIA Workaround
        updatedQuestionTitles, handleUpdateQuestionTitle,
        deletedQuestions, handleDeletedQuestion,
        newQuestions, setNewQuestions, handleClearFilter,
        //EDIT FUNCTIONS
        handleChangeBool,

        ///FILTERS
        filters, toggleFilterNew, algolia_filters, setalgolia_filters, any_filter_set,
        //EDIT QUESTIONS
        selectedQuestions, setSelectedQuestions,
        deleteModal, setdeleteModal, selected_to_be_deletedQuestions, setselected_to_be_deletedQuestions

    }
    return (
        <QuizEditContext.Provider value={value}>
            {children}
        </QuizEditContext.Provider>
    )
}