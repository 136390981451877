import { $createHeadingNode, $createQuoteNode } from "@lexical/rich-text";
import { $setBlocksType } from "@lexical/selection";
import { INSERT_CHECK_LIST_COMMAND, INSERT_ORDERED_LIST_COMMAND, INSERT_UNORDERED_LIST_COMMAND, REMOVE_LIST_COMMAND } from "@lexical/list";
import { $createCodeNode } from "@lexical/code";
import { $createParagraphNode, $getSelection, $isRangeSelection, DEPRECATED_$isGridSelection } from "lexical";

import classes from "./BlockFormatDropDown.module.css";

import { useLanguage } from "../../../../contexts/LanguageContext";

//components
import DropDown, { DropDownItem } from "../LexicalDropDown/LexicalDropDown";
import joinClasses from "../../../../utils/joinClasses";

export default function BlockFormatDropDown({ editor, blockType, blockTypeToBlockName, disabled = false }) {
    const { translate } = useLanguage();

    const formatParagraph = () => {
        if (blockType !== "paragraph") {
            editor.update(() => {
                const selection = $getSelection();
                if ($isRangeSelection(selection) || DEPRECATED_$isGridSelection(selection)) $setBlocksType(selection, () => $createParagraphNode());
            });
        }
    };

    const formatHeading = (headingSize) => {
        if (blockType !== headingSize) {
            editor.update(() => {
                const selection = $getSelection();
                if ($isRangeSelection(selection) || DEPRECATED_$isGridSelection(selection)) {
                    $setBlocksType(selection, () => $createHeadingNode(headingSize));
                }
            });
        }
    };

    const formatBulletList = () => {
        if (blockType !== "bullet") {
            editor.dispatchCommand(INSERT_UNORDERED_LIST_COMMAND, undefined);
        } else {
            editor.dispatchCommand(REMOVE_LIST_COMMAND, undefined);
        }
    };

    const formatCheckList = () => {
        if (blockType !== "check") {
            editor.dispatchCommand(INSERT_CHECK_LIST_COMMAND, undefined);
        } else {
            editor.dispatchCommand(REMOVE_LIST_COMMAND, undefined);
        }
    };

    const formatNumberedList = () => {
        if (blockType !== "number") {
            editor.dispatchCommand(INSERT_ORDERED_LIST_COMMAND, undefined);
        } else {
            editor.dispatchCommand(REMOVE_LIST_COMMAND, undefined);
        }
    };

    const formatQuote = () => {
        if (blockType !== "quote") {
            editor.update(() => {
                const selection = $getSelection();
                if ($isRangeSelection(selection) || DEPRECATED_$isGridSelection(selection)) {
                    $setBlocksType(selection, () => $createQuoteNode());
                }
            });
        }
    };

    const formatCode = () => {
        if (blockType !== "code") {
            editor.update(() => {
                let selection = $getSelection();

                if ($isRangeSelection(selection) || DEPRECATED_$isGridSelection(selection)) {
                    if (selection.isCollapsed()) {
                        $setBlocksType(selection, () => $createCodeNode());
                    } else {
                        const textContent = selection.getTextContent();
                        const codeNode = $createCodeNode();
                        selection.insertNodes([codeNode]);
                        selection = $getSelection();
                        if ($isRangeSelection(selection)) selection.insertRawText(textContent);
                    }
                }
            });
        }
    };

    return (
        <DropDown
            disabled={disabled}
            buttonClassName={classes.BlockControl}
            buttonIconClassName={joinClasses(classes.BlockTypeIcon, classes[blockType])}
            buttonLabel={blockTypeToBlockName?.[blockType]}
            buttonAriaLabel="Formatting options for text style"
        >
            <DropDownItem className={joinClasses(classes.DropDownItem, blockType === "paragraph" && classes.Active)} onClick={formatParagraph}>
                <i className={joinClasses(classes.Icon, classes.paragraph)} />
                <span className={classes.Text}>{translate("Normal")}</span>
            </DropDownItem>
            <DropDownItem className={joinClasses(classes.DropDownItem, blockType === "h1" && classes.Active)} onClick={() => formatHeading("h1")}>
                <i className={joinClasses(classes.Icon, classes.h1)} />
                <span className={classes.Text}>{translate("Heading 1")}</span>
            </DropDownItem>
            <DropDownItem className={joinClasses(classes.DropDownItem, blockType === "h2" && classes.Active)} onClick={() => formatHeading("h2")}>
                <i className={joinClasses(classes.Icon, classes.h2)} />
                <span className={classes.Text}>{translate("Heading 2")}</span>
            </DropDownItem>
            <DropDownItem className={joinClasses(classes.DropDownItem, blockType === "h3" && classes.Active)} onClick={() => formatHeading("h3")}>
                <i className={joinClasses(classes.Icon, classes.h3)} />
                <span className={classes.Text}>{translate("Heading 3")}</span>
            </DropDownItem>
            <DropDownItem className={joinClasses(classes.DropDownItem, blockType === "h4" && classes.Active)} onClick={() => formatHeading("h4")}>
                <i className={joinClasses(classes.Icon, classes.h4)} />
                <span className={classes.Text}>{translate("Heading 4")}</span>
            </DropDownItem>
            <DropDownItem className={joinClasses(classes.DropDownItem, blockType === "h5" && classes.Active)} onClick={() => formatHeading("h5")}>
                <i className={joinClasses(classes.Icon, classes.h5)} />
                <span className={classes.Text}>{translate("Small Text")}</span>
            </DropDownItem>
            <DropDownItem className={joinClasses(classes.DropDownItem, blockType === "h6" && classes.Active)} onClick={() => formatHeading("h6")}>
                <i className={joinClasses(classes.Icon, classes.h6)} />
                <span className={classes.Text}>{translate("Footnote")}</span>
            </DropDownItem>
            <DropDownItem className={joinClasses(classes.DropDownItem, blockType === "bullet" && classes.Active)} onClick={formatBulletList}>
                <i className={joinClasses(classes.Icon, classes.ul)} />
                <span className={classes.Text}>{translate("Bullet List")}</span>
            </DropDownItem>
            <DropDownItem className={joinClasses(classes.DropDownItem, blockType === "number" && classes.Active)} onClick={formatNumberedList}>
                <i className={joinClasses(classes.Icon, classes.ol)} />
                <span className={classes.Text}>{translate("Numbered List")}</span>
            </DropDownItem>
            {/* <DropDownItem className={joinClasses(classes.DropDownItem, blockType === "check" && classes.Active)} onClick={formatCheckList}>
                <i className={joinClasses(classes.Icon, classes.checkList)} />
                <span className={classes.Text}>{translate("Check List")}</span>
            </DropDownItem> */}
            <DropDownItem className={joinClasses(classes.DropDownItem, blockType === "quote" && classes.Active)} onClick={formatQuote}>
                <i className={joinClasses(classes.Icon, classes.quote)} />
                <span className={classes.Text}>{translate("Quote")}</span>
            </DropDownItem>
            {/* <DropDownItem className={joinClasses(classes.DropDownItem, blockType === "code" && classes.Active)} onClick={formatCode}>
                <i className={joinClasses(classes.Icon, classes.code)} />
                <span className={classes.Text}>{translate("Code Block")}</span>
            </DropDownItem> */}
        </DropDown>
    );
}
