import { useEffect, useState } from "react";
import useAnnouncementMessages from "../../../../Chat/hooks/messageHooks/useAnnouncementMessages";
import useSpaceMessages from "../../../../Chat/hooks/messageHooks/useSpaceMessages";
import { dynamicSort2 } from "../../../../../components/Helpers/HelperFunction";

export default function useLoadAnnouncementMessages(last_online_date, loading_presence) {
    const { announcementsMessages, loading_announcements_messages } = useAnnouncementMessages(last_online_date, loading_presence);
    const { spaceMessages, loading_space_messages } = useSpaceMessages(last_online_date, loading_presence);
    const [announcement_messages, setannouncement_messages] = useState([]);
    const loading_messages = loading_space_messages || loading_announcements_messages;
    useEffect(() => {
        if (!loading_messages) {
            const combinedMessages = [
                ...(announcementsMessages || []),
                ...(spaceMessages || []),
            ];
            setannouncement_messages(combinedMessages.sort(dynamicSort2("meta", "created_at")));
        }
    }, [announcementsMessages,spaceMessages, loading_messages]);
    return { announcement_messages, loading_messages };
}
