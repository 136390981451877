import type {
    DOMConversionMap,
    DOMConversionOutput,
    DOMExportOutput,
    EditorConfig,
    ElementFormatType,
    LexicalEditor,
    LexicalNode,
    NodeKey,
    Spread,
} from "lexical";

import { BlockWithAlignableContents } from "@lexical/react/LexicalBlockWithAlignableContents";
import { DecoratorBlockNode, SerializedDecoratorBlockNode } from "@lexical/react/LexicalDecoratorBlockNode";

type VideoComponentProps = Readonly<{
    className: Readonly<{
        base: string;
        focus: string;
    }>;
    format: ElementFormatType | null;
    nodeKey: NodeKey;
    videoID: string;
}>;

function VimeoComponent({ className, format, nodeKey, videoID }: VideoComponentProps) {
    return (
        <BlockWithAlignableContents className={className} format={format} nodeKey={nodeKey}>
            <iframe
                width="1182"
                height="665"
                src={`https://player.vimeo.com/video/${videoID}`}
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen={true}
                title="Video video"
            />
        </BlockWithAlignableContents>
    );
}

export type SerializedVimeoNode = Spread<
    {
        videoID: string;
        type: "vimeo";
        version: 1;
    },
    SerializedDecoratorBlockNode
>;

function convertVimeoElement(domNode: HTMLElement): null | DOMConversionOutput {
    const videoID = domNode.getAttribute("data-lexical-vimeo");
    if (videoID) {
        const node = $createVimeoNode(videoID);
        return { node };
    }
    return null;
}

export class VimeoVideoNode extends DecoratorBlockNode {
    __id: string;

    static getType(): string {
        return "vimeo";
    }

    static clone(node: VimeoVideoNode): VimeoVideoNode {
        return new VimeoVideoNode(node.__id, node.__format, node.__key);
    }

    static importJSON(serializedNode: SerializedVimeoNode): VimeoVideoNode {
        const node = $createVimeoNode(serializedNode.videoID);
        node.setFormat(serializedNode.format);
        return node;
    }

    exportJSON(): SerializedVimeoNode {
        return {
            ...super.exportJSON(),
            type: "vimeo",
            version: 1,
            videoID: this.__id,
        };
    }

    constructor(id: string, format?: ElementFormatType, key?: NodeKey) {
        super(format, key);
        this.__id = id;
    }

    exportDOM(): DOMExportOutput {
        const element = document.createElement("iframe");
        element.setAttribute("data-lexical-vimeo", this.__id);
        element.setAttribute("width", "1182");
        element.setAttribute("height", "665");
        element.setAttribute("src", `https://player.vimeo.com/video/${this.__id}`);
        element.setAttribute("frameborder", "0");
        element.setAttribute("allow", "accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture");
        element.setAttribute("allowfullscreen", "true");
        element.setAttribute("title", "Vimeo video");
        return { element };
    }

    static importDOM(): DOMConversionMap | null {
        return {
            iframe: (domNode: HTMLElement) => {
                if (!domNode.hasAttribute("data-lexical-vimeo")) {
                    return null;
                }
                return {
                    conversion: convertVimeoElement,
                    priority: 1,
                };
            },
        };
    }

    updateDOM(): false {
        return false;
    }

    getId(): string {
        return this.__id;
    }

    getTextContent(_includeInert?: boolean | undefined, _includeDirectionless?: false | undefined): string {
        return `https://www.youtube.com/watch?v=${this.__id}`;
    }

    decorate(_editor: LexicalEditor, config: EditorConfig): JSX.Element {
        const embedBlockTheme = config.theme.embedBlock || {};
        const className = {
            base: embedBlockTheme.base || "",
            focus: embedBlockTheme.focus || "",
        };
        return <VimeoComponent className={className} format={this.__format} nodeKey={this.getKey()} videoID={this.__id} />;
    }

    isInline(): false {
        return false;
    }
}

export function $createVimeoNode(videoID: string): VimeoVideoNode {
    return new VimeoVideoNode(videoID);
}

export function $isVimeoNode(node: VimeoVideoNode | LexicalNode | null | undefined): node is VimeoVideoNode {
    return node instanceof VimeoVideoNode;
}
