import { collection, limit, orderBy, query, where } from "firebase/firestore";
import { useCollectionData } from "react-firehooks";
import { useSpaceContext } from "../../../../contexts/SpaceContext/SpaceContext";
import { firestore } from "../../../../app/firebase";
import { useAuth } from "../../../../contexts/AuthContext";

export default function useMyMessages(last_online_date, loading_presence) {
    const { space_id } = useSpaceContext();
    const { user_id } = useAuth();

    let myMessagesRef, myMessagesQuery;
    if (user_id && !loading_presence) {
        myMessagesRef = collection(firestore, "spaces", space_id, "messages");
        myMessagesQuery = query(myMessagesRef,
        where('stats.users_active', "array-contains", user_id),
        // where('stats.reply_count', ">", 0),
            // orderBy("stats.reply_count", "desc"),
        where("meta.updated_at", ">", last_online_date),
        orderBy("meta.updated_at", "desc"),
        limit(5)
    );  
        
    } 
    const [myMessages, loading_my_messages, error_messages] = useCollectionData(myMessagesQuery);
    return { myMessages, loading_my_messages };
}
