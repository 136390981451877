import { collection, limit, orderBy, query, where } from "firebase/firestore";
import { useCollectionData } from "react-firehooks";
import { useSpaceContext } from "../../../../contexts/SpaceContext/SpaceContext";
import { firestore } from "../../../../app/firebase";
import { useAuth } from "../../../../contexts/AuthContext";

export default function useResourcesMessages(last_online_date, loading_presence) {
    const { space_id } = useSpaceContext();
    const { curatedTaughtAndEnrolled, isCuratorOfSpace } = useAuth();

     let resourcesMessagesRef, resourcesMessagesQuery;
    if (curatedTaughtAndEnrolled && last_online_date && !loading_presence) {
        resourcesMessagesRef = collection(firestore, "spaces", space_id, "messages");
        if (curatedTaughtAndEnrolled?.length > 10 || isCuratorOfSpace) { //TODO if curator or teacher > 10 filter after loading
            resourcesMessagesQuery = query(resourcesMessagesRef,
                where("meta.channel_type", "==", "RESOURCES"),
                where("meta.created_at", ">", last_online_date),
                orderBy("meta.created_at", "desc"),
                limit(10)
            );  
        } else if (curatedTaughtAndEnrolled?.length > 0) {
            resourcesMessagesQuery = query(resourcesMessagesRef,
                where("meta.channel_type", "==", "RESOURCES"),
                where("meta.created_at", ">", last_online_date),
                where("meta.path_id", "in", curatedTaughtAndEnrolled),
                orderBy("meta.created_at", "desc"),
                limit(10)
            );  
        }
    }
    const [resourcesMessages1, loading_resources_messages, error_messages] = useCollectionData(resourcesMessagesQuery);

    const resourcesMessages = (!isCuratorOfSpace && curatedTaughtAndEnrolled?.length > 10) ? resourcesMessages1?.filter(m => !curatedTaughtAndEnrolled?.includes(m?.res?.group_id)) : resourcesMessages1 ;
    return { resourcesMessages, loading_resources_messages };
}
