import { arrayUnion } from "firebase/firestore";
import { auth } from "../../../../../app/firebase";
import { relDiff, computeSDOnline, weightedAverageRounded, convertSecTo_hh_mm_ss, sumPositives } from "../../../../Helpers/HelperFunction";

import { checkIfAllsSelected } from "../QuizInProgress/QuestionInProgressTypes/M_Match/Q_MatchFunctions";
import { constructDateTimeObject } from "../../../../Helpers/DatetimeHelperfunctions";
import { blanksRegex } from "../../../../Path/QuestionPool/CreateQuestion/components/CreateFillInBlanks/CreateFillInBlanks";

export const dummyAnswers = [
    { an: "----", id: "1" },
    { an: "----", id: "2" },
    { an: "----", id: "3" },
    { an: "----", id: "4" },
];

export function getQuestionData(question) {
    //METADATA
    const q_id = question?.metaData?.qid;
    const q_local_quiz_ids = question?.metaData?.local_quiz_ids ? question.metaData.local_quiz_ids : [];
    const q_type = question?.metaData?.type ? question?.metaData?.type : "multipleChoice";
    const q_has_hint = question?.metaData?.hint ? question.metaData.hint : false;
    const q_updated = question?.metaData?.date_updated;
    const q_created = question?.metaData?.date_created;

    //CONTENT
    const q_question = question?.content?.question ? question.content.question : "<p></p>";
    const q_explanation = question?.content?.explanation;
    const q_hint = question?.content?.hint;

    //TYPES
    //multiplechoice
    const q_answers = question?.content?.answers;
    const q_multipleChoice = question?.properties?.multipleChoice;
    const q_prop_type = question?.properties?.type;

    //blanks
    const q_blanks = question?.content?.blanks ? question?.content?.blanks : {};
    const q_blanks_arr = Object.values(q_blanks);
    //Match
    const q_column_a = question?.content?.column_a ? question?.content?.column_a : { 1: "--" };
    const q_column_b = question?.content?.column_b ? question?.content?.column_b : { 1: "--" };
    const q_descr = question?.content?.descr;
    const q_fixed_column = question?.properties?.fixed;

    //PROPERTIES
    const q_tags = question?.metaData?.tags;
    const q_random = question?.properties?.random;
    const q_max_pts_1 = question?.properties?.maxPoints ? question?.properties?.maxPoints : 1;
    const q_max_pts = q_type === "blanks" ? sumPositives(q_blanks_arr.map((a) => a.pts)) : q_max_pts_1;

    //STATS
    const q_number_taken = question?.stats?.totals?.numberTaken ? question.stats.totals.numberTaken : 0;
    const q_average_score = question?.stats?.average_score ? Math.round(question?.stats?.average_score * 100) / 100 : 0;
    const q_m2_score = question?.stats?.m2_score ? question?.stats?.m2_score : 0;
    const q_average_relative_score = question?.stats?.average_relative_score ? question?.stats?.average_relative_score : 0;
    const q_m2_relative_score = question?.stats?.m2_relative_score ? question?.stats?.m2_relative_score : 0;

    return {
        //METADATA
        q_updated,
        q_created,
        q_id,
        q_local_quiz_ids,
        q_type,
        q_question,
        q_explanation,
        q_answers,
        q_blanks,
        q_tags,
        q_multipleChoice,
        q_prop_type,
        q_random,
        q_number_taken,
        q_average_relative_score,
        q_m2_relative_score,
        q_average_score,
        q_max_pts,
        q_m2_score,
        //Match
        q_column_a,
        q_column_b,
        q_fixed_column,
        q_descr,
        q_has_hint,
        q_hint,
    };
}

export function getQuestionDataNew(question) {
    //METADATA
    const q_meta = question?.meta;
    const q_id = q_meta?.qid;
    const q_quiz_ids_per_course = q_meta?.quiz_ids_per_course ? q_meta.quiz_ids_per_course : {};
    const q_type = q_meta?.type; // ? q_meta?.type : "multipleChoice";
    const q_has_hint = q_meta?.hint ? q_meta.hint : false;
    const q_has_text = q_meta?.text || false;
    const q_updated = q_meta?.dt_u;
    const q_created = q_meta?.dt_c;
    const q_tags = q_meta?.tags ? q_meta?.tags : [];
    const q_path_ids = q_meta?.path_ids;
    const q_space_ids = q_meta?.space_ids;

    //CONTENT
    const q_cont = question?.cont;
    const q_question = q_cont?.q;
    const q_explanation = q_cont?.expl;
    const q_hint = q_cont?.hint;
    const q_text = q_cont?.text;


    //PROPERTIES
    const q_props = question?.props;
    const q_prop_type = q_props?.type;
    const q_random = q_props?.random;
    const q_max_pts_1 = q_props?.maxPoints ? q_props?.maxPoints : 1;

    //TYPES
    //multiplechoice
    const q_answers = q_cont?.ans;

    //blanks
    const q_blanks = q_cont?.blanks || {};
    const q_blanks_arr = Object.values(q_blanks);
    const q_max_pts = q_type === "blanks" ? sumPositives(q_blanks_arr.map((a) => a.pts)) : q_max_pts_1;
    const q_false_ans = q_props?.false_ans; //include false answer options
    const q_false_answers = q_cont?.fls_ans || [];
    const q_sggstns = q_props?.sggstns; //show options available

    //Match
    const q_fixed_column = q_props?.fixed;
    const q_column_a = q_cont?.clm_a || { 1: "--" };
    const q_column_b = q_cont?.clm_b || { 1: "--" };
    const q_descr = q_cont?.descr;

    //STATS
    const q_number_taken = question?.stats?.totals?.numberTaken || 0;
    const q_average_score = question?.stats?.avg_scr ? Math.round(question?.stats?.avg_scr * 100) / 100 : 0;
    const q_m2_score = question?.stats?.m2_score || 0;
    const q_average_relative_score = question?.stats?.avg_rl_scr || 0;
    const q_m2_relative_score = question?.stats?.m2_relative_score || 0;

    return {
        //METADATA
        q_updated,
        q_created,
        q_id,
        q_quiz_ids_per_course,
        q_type,
        q_question,
        q_explanation,
        q_answers,
        q_blanks,
        q_tags,
        q_path_ids,q_space_ids,
        q_prop_type,
        q_random,
        q_number_taken,
        q_average_relative_score,
        q_m2_relative_score,
        q_average_score,
        q_max_pts,
        q_m2_score,
        //Match
        q_column_a,
        q_column_b,
        q_fixed_column,
        q_descr,
        q_has_hint, q_has_text,
        q_hint, q_text,
        //Blanks
        q_false_ans,
        q_sggstns,
        q_false_answers,
    };
}

export function getAttemptData(attempt) {
    const datetime = attempt?.date_created;
    const completed = constructDateTimeObject(datetime?.toDate());
    const attempt_time_display = convertSecTo_hh_mm_ss(attempt?.time_required);
    const attempt_completed_display = attempt?.date_created
        ? completed.day + "/" + completed.month + "/" + completed.year + "  " + completed.timeDisplay
        : "";
    const attempt_acquiredPercentage = attempt?.acquiredPercentage;
    const attempt_acquiredTotalPoints = attempt?.acquiredTotalPoints ? attempt.acquiredTotalPoints : 0;
    const totalPoints = attempt?.totalPoints ? attempt.totalPoints : 0;
    const attempt_points = attempt_acquiredTotalPoints + "/" + totalPoints;
    const attempt_correctAnswerCount = attempt?.correctAnswerCount ? attempt?.correctAnswerCount : 0;
    const attmpt_ans = attempt?.answersPerQuestions ? attempt?.answersPerQuestions : {};
    const no_of_qs = Object.values(attmpt_ans).length;
    return {
        attempt_completed_display,
        attempt_time_display,
        attempt_acquiredPercentage,
        attempt_points,
        attempt_correctAnswerCount,
        attempt_acquiredTotalPoints,
        no_of_qs,
    };
}

export function getUnansweredQuestions(answersPerQuestions, nQuestions) {
    let unansweredQuestion = [];
    Object.values(answersPerQuestions).forEach((questionAnswer) => {
        if (questionAnswer.answers.length === 0) {
            unansweredQuestion.push({ number: questionAnswer?.position, page: Math.ceil(questionAnswer?.position / nQuestions) });
        }
    });
    return unansweredQuestion;
}

export function getQuestionPoolTags(questions) {
    let questionPoolTags = [];
    for (let i = 0; i < questions?.length; i++) {
        for (let j = 0; j < questions[i]?.meta?.tags?.length; j++) {
            if (!questionPoolTags?.includes(questions[i]?.meta?.tags[j])) {
                questionPoolTags.push(questions[i]?.meta?.tags[j]);
            }
        }
    }
    return questionPoolTags;
}
export function getQuestionPoolTags2(questions) {
    let questionPoolTags = [];
    for (let i = 0; i < questions?.length; i++) {
        for (let j = 0; j < Object.keys(questions[i]?.meta?.tags)?.length; j++) {
            if (!questionPoolTags?.includes(Object.keys(questions[i]?.meta?.tags)[j])) {
                questionPoolTags.push(Object.keys(questions[i]?.meta?.tags)[j]);
            }
        }
    }
    return questionPoolTags;
}
export function getHierarchicalTags(hierarchy_3) {
    let hierarchicalTags = [];
    if (hierarchy_3) {
        const hierarchy_1_tags = Object.keys(hierarchy_3);
        hierarchicalTags = hierarchy_1_tags;
        const step0 = [].concat.apply([], Object.values(hierarchy_3));
        const hierarchy_2_tags = step0 && step0.length > 0 ? Object.keys(...step0) : [];
        const hierarchy_3_tags = [];
        step0.forEach((obj) => {
            Object.values(obj).forEach((obj) => {
                hierarchy_3_tags.push(...Object.keys(obj));
            });
        });
        hierarchicalTags = hierarchicalTags.concat(hierarchy_2_tags).concat(hierarchy_3_tags);
    }
    return hierarchicalTags.filter(onlyUnique);
}

export function sortByDatePublishedAttempts(attemps) {
    const sortedAttempts = attemps;
    sortedAttempts?.sort(function (a, b) {
        if (a?.date_created?.seconds > b?.date_created?.seconds) return -1;
        if (a?.date_created?.seconds < b?.date_created?.seconds) return 1;
        return 0;
    });
    return sortedAttempts;
}

export function getQuizMotivation(
    quizAttempts,
    correctAnswerCount,
    acquiredTotalPoints,
    passMarkTypeQuestions = false,
    acquiredPercentage,
    passMarkPercent
) {
    let successMeasure, successMeasureString;
    if (passMarkTypeQuestions) {
        successMeasure = correctAnswerCount;
        successMeasureString = "correctAnswerCount";
    } else {
        successMeasure = acquiredTotalPoints;
        successMeasureString = "acquiredTotalPoints";
    }
    //(1)First time pass
    const anyPassBefore = quizAttempts && quizAttempts?.length > 0 ? quizAttempts?.some((a) => a?.acquiredPercentage >= passMarkPercent) : false;
    const firstTimePass = anyPassBefore ? false : acquiredPercentage >= passMarkPercent;
    //(2)gap to pass rate
    const gapToPassRate = passMarkPercent - acquiredPercentage;
    const showGapToPassRate = gapToPassRate > 0 && gapToPassRate < 20;
    //(3)gap to best result
    const bestAttempt = Math.max.apply(
        Math,
        quizAttempts?.map(function (o) {
            if (o[successMeasureString]) {
                const points = o[successMeasureString];
                return points;
            } else return 0;
        })
    );
    const gapToBestResult = relDiff(successMeasure, bestAttempt);
    const showGapToBestResult = gapToBestResult > 20;
    const noMotivation = !showGapToBestResult && !firstTimePass && !showGapToPassRate;
    return { noMotivation, firstTimePass, gapToPassRate, showGapToPassRate, showGapToBestResult };
}

export function handleAnswerSelection(e, currentQuestion, answer_id, answersPerQuestions, setAnswersPerQuestions) {
    e.preventDefault();
    const { q_prop_type, q_id } = getQuestionDataNew(currentQuestion);
    const currentAnswerQuestion = answersPerQuestions[q_id] ? answersPerQuestions[q_id] : { answers: [] };
    if (currentAnswerQuestion?.answers?.includes(answer_id)) {
        currentAnswerQuestion.answers = currentAnswerQuestion?.answers?.filter((aid) => aid !== answer_id);
    } else {
        if (q_prop_type === "mc") {
            currentAnswerQuestion["answers"] = [...currentAnswerQuestion.answers, answer_id];
        } else {
            //if not multiple choice -> replace
            currentAnswerQuestion["answers"] = [answer_id];
        }
    }
    setAnswersPerQuestions((prev) => {
        return { ...prev, [q_id]: currentAnswerQuestion };
    });
}

export const handleBlanksAnswerInput = (q_id, answer_id, answer, setAnswersPerQuestions) => {
    // console.log("QTYPE_BLANKS INPUT");
    // console.log("QTYPE_BLANKS Answers", currentAnswers);
    setAnswersPerQuestions((prev) => {
        const currentAnswersData = prev?.[q_id];
        const currentAnswers = currentAnswersData?.answers ? currentAnswersData?.answers : { answers: {} };
        const newAnswers = { ...currentAnswers, [answer_id]: answer };
        return { ...prev, [q_id]: { ...currentAnswersData, answers: newAnswers } };
    });
};

export function createDummyQs(no_of_questions) {
    const dummyQs = [];
    for (let index = 0; index < no_of_questions; index++) {
        dummyQs.push({ meta: { qid: index } });
    }
    return dummyQs;
}

export function createAnswersPerQuestionObject(questions) {
    let newselected_ans_by_qid = {};
    questions.forEach((qid, index) => Object.assign(newselected_ans_by_qid, { [qid]: { qid, answers: [], position: index + 1 } }));
    return newselected_ans_by_qid;
}
export function getQuizStats(answersPerQuestions = {}, questions = [], passMarkTypeQuestions = false, passMarkPercent = 0.7) {
    const uid = auth.currentUser?.uid;
    const date = new Date();
    const number_of_questions = questions?.length;
    let totalPoints = 0;
    let totalRelScore = 0;
    let achievedPointsTotal = 0;
    let correctAnswerCount = 0;
    const correct_qids = [];
    const wrong_qids = [];
    const new_quizPerformance = [];
    const questionsStat = [];
    const user_questionsStat = [];

    let questions_quiz_stat = {};
    let tag_quiz_stat = {};
    const answer_array = [];

    //go through all questions
    questions.forEach((question) => {
        const {
            q_id,
            q_tags,
            q_number_taken,
            q_average_score: old_average_score,
            q_m2_score: old_m2_score,
            q_average_relative_score: old_average_relative_score,
            q_m2_relative_score: old_m2_relative_score,
            q_answers,
            q_type,
            q_blanks,
            q_column_a,
            q_column_b,
        } = getQuestionDataNew(question);

        const questionCorrectlyAnswered = [];
        const answers_selected = answersPerQuestions[q_id]?.answers;
        let score = 0;
        let maxPoints = 0;

        //SCORING per TYPE
        //MULTIPLE_CHOICE
        if (q_type === "multipleChoice") {
            // console.log("ANSWERS", q_answers);
            q_answers?.forEach((answer) => {
                if (answers_selected.includes(answer.id)) {
                    score = score + answer.pts; // +1 if correct; -1 if wrong in multipleChoice; 0;
                    if (answer.cr) {
                        questionCorrectlyAnswered.push(true);
                    } else {
                        questionCorrectlyAnswered.push(false);
                    }
                } else {
                    if (answer.cr) {
                        questionCorrectlyAnswered.push(false); //multipleChoice
                    }
                }
                if (answer.cr) {
                    //only positive points
                    maxPoints = maxPoints + answer.pts;
                }
            }); //BLANKS
        } else if (q_type === "blanks") {
            // console.log("BLANKS", q_blanks, answers_selected);
            for (const [index, answer] of Object.entries(q_blanks)) {
                maxPoints = maxPoints + 1; //1 point per field
                if (answers_selected[index] === answer?.solution) {
                    score = score + answer?.points;
                    questionCorrectlyAnswered.push(true);
                } else {
                    questionCorrectlyAnswered.push(false);
                }
            }
        } else if (q_type === "match") {
            for (const [id, answer] of Object.entries(q_column_a)) {
                maxPoints = maxPoints + 1; //1 point per field
                if (answers_selected?.[id] === id) {
                    // console.log(score);
                    score = score + 1;
                    questionCorrectlyAnswered.push(true);
                } else {
                    questionCorrectlyAnswered.push(false);
                }
            }
        }

        //go through all answers

        score = typeof score === "number" && score > 0 ? score : 0; //no minus points
        const correctly_answered = questionCorrectlyAnswered?.length === 0 ? false : questionCorrectlyAnswered?.every((c) => c);
        answer_array.push(correctly_answered);
        // const weight = calculateWeight(qs_rel_avg_old, old_sd_relative_score_questions, old_average_relative_score)
        const relative_score = score > 0 ? score / maxPoints : 0;
        //(4)Stats per question
        if (q_number_taken > 0) {
            const new_average_score = weightedAverageRounded(old_average_score, q_number_taken, 1, score);
            const { sd: new_sd_score, m2: new_m2_score } = computeSDOnline(
                old_m2_score,
                old_average_score,
                new_average_score,
                q_number_taken + 1,
                score
            );
            const new_average_relative_score = new_average_score / maxPoints;
            const { sd: new_sd_relative_score, m2: new_m2_relative_score } = computeSDOnline(
                old_m2_relative_score,
                old_average_relative_score,
                new_average_relative_score,
                q_number_taken + 1,
                relative_score
            );
            questionsStat.push({
                tags: q_tags,
                correct: correctly_answered,
                qid: q_id,
                answers_selected,
                average_score: new_average_score,
                prev_average_score: old_average_score,
                sd_score: new_sd_score,
                m2_score: new_m2_score,
                average_relative_score: new_average_relative_score,
                prev_average_relative_score: old_average_relative_score,
                sd_relative_score: new_sd_relative_score,
                m2_relative_score: new_m2_relative_score,
                q_type: q_type,
            });
        } else {
            //Edge case: first time question taken
            questionsStat.push({
                tags: q_tags,
                correct: correctly_answered,
                qid: q_id,
                first: true,
                maxPoints,
                answers_selected,
                average_score: score,
                prev_average_score: 0,
                sd_score: 0,
                m2_score: 0,
                average_relative_score: relative_score,
                prev_average_relative_score: 0,
                sd_relative_score: 0,
                m2_relative_score: 0,
                q_type: q_type,
            });
        }
        //(5) User question stats
        user_questionsStat.push({
            qid: q_id,
            answers_selected,
            score: score,
            correct: correctly_answered,
            q_type: q_type,
        });
        const rel_score =
            old_average_relative_score > 0 && typeof old_average_relative_score === "number" && old_average_relative_score > 0
                ? Math.round(relative_score * (1 / old_average_relative_score) * 10) / 10
                : typeof old_average_relative_score === "number"
                ? relative_score
                : 0.5;
        const rel_score_rounded = Math.round(rel_score * 100) / 100;
        totalRelScore = totalRelScore + rel_score_rounded;
        achievedPointsTotal = achievedPointsTotal + score;
        totalPoints = totalPoints + maxPoints;

        if (correctly_answered) {
            new_quizPerformance.push({ tags: q_tags, date: date, qid: q_id, correct: true }); //!["PHYSICS,BIOLOGY,CHEMISTRY"].includes(tag)
            correctAnswerCount = correctAnswerCount + 1;
            correct_qids.push(q_id);
        } else {
            new_quizPerformance.push({ tags: q_tags, date: date, qid: q_id, correct: false }); //!["PHYSICS,BIOLOGY,CHEMISTRY"].includes(tag)
            wrong_qids.push(q_id);
        }
        q_tags.forEach((tag) => {
            tag_quiz_stat = { ...tag_quiz_stat, [tag]: arrayUnion({ r_scr: Math.round(relative_score * 100) / 100, scr: score, date: new Date() }) };
        });
        questions_quiz_stat = {
            ...questions_quiz_stat,
            [q_id]: arrayUnion({ r_scr: Math.round(relative_score * 100) / 100, scr: score, date: new Date() }),
        };
    });
    //END PER QUESTION
    let acquiredPercentage = 0;
    if (achievedPointsTotal || correctAnswerCount) {
        if (passMarkTypeQuestions) {
            acquiredPercentage = Math.round((correctAnswerCount / number_of_questions) * 100);
        } else {
            acquiredPercentage = totalPoints > 0 ? Math.round((achievedPointsTotal / totalPoints) * 100) : 0;
        }
    }
    const quizStats = {
        attempts: arrayUnion({
            date: new Date(),
            score_to_pass: passMarkPercent,
            score: acquiredPercentage,
            points: achievedPointsTotal,
            totalpoints: totalPoints,
            passed: acquiredPercentage > passMarkPercent,
            user_id: uid,
            q_no: number_of_questions,
        }),
        tags: tag_quiz_stat,
        qids: questions_quiz_stat,
    };

    return {
        acquiredPercentage,
        achievedPointsTotal,
        totalPoints,
        totalRelScore,
        correctAnswerCount,
        new_quizPerformance,
        questionsStat,
        user_questionsStat,
        quizStats,
        correct_qids,
        wrong_qids,
        answer_array,
    };
}
export function getQuizCorrectAndWrongQIDs(questionsStat) {
    const correct_qids = [];
    const wrong_qids = [];
    for (const { qid, correct, tags } of questionsStat) {
        if (correct) {
            correct_qids.push(qid);
        } else {
            wrong_qids.push(qid);
        }
    }
    return { correct_qids, wrong_qids };
}

export function checkWhetherQuestionIsCorrectlyAnswered(answerIDs = [], answers = []) {
    let achievedPoints = 0;
    let maxPoints = 0;
    let questionCorrectlyAnswered = [];
    answers?.forEach((answer) => {
        const a_points = answer.pts ? answer.pts : 0;
        if (answerIDs?.includes(answer.id)) {
            achievedPoints = achievedPoints + a_points;
            if (answer.cr) {
                questionCorrectlyAnswered.push(true);
            } else {
                questionCorrectlyAnswered.push(false);
            }
        } else {
            if (answer.cr) {
                questionCorrectlyAnswered.push(false);
            }
        }
        if (answer.cr) {
            maxPoints = maxPoints + a_points;
        }
    });
    achievedPoints = achievedPoints < 0 ? 0 : achievedPoints;
    return { maxPoints, achievedPoints, questionCorrectlyAnswered };
}

//quizgenerator
export function getLevels1(tag, hierachy_2) {
    const levels1 = [];
    Object.entries(hierachy_2).forEach(([key, object]) => {
        // console.log(Object.keys(object));
        if (Object.keys(object)?.includes(tag)) {
            levels1.push(key);
        }
    });
    return levels1;
}
export function getLevels1AND2(tag, hierarchy_3) {
    let key1 = [],
        key2 = [];
    Object.keys(hierarchy_3)?.forEach((level_1_tag) => {
        const level_2 = Object.keys(hierarchy_3[level_1_tag]);

        level_2?.forEach((level_2_tag) => {
            const level_3_tags = Object.keys(hierarchy_3[level_1_tag][level_2_tag]);
            if (level_3_tags.includes(tag)) {
                key1.push(level_1_tag);
                key2.push(level_2_tag);
            }
        });
    });
    return [key1, key2];
}

export function getLevel2(tag, level1, hierarchy_3) {
    let key2;
    Object.entries(hierarchy_3[level1]).forEach(([key, level_3_object]) => {
        if (Object.keys(level_3_object).includes(tag)) {
            key2 = key;
        }
    });
    return key2;
}
export function getDistinctTags(questions = []) {
    const tags = [];
    questions?.forEach((q) => {
        q?.tags?.forEach((tag) => {
            if (!tags.includes(tag)) {
                tags.push(tag);
            }
        });
    });
    return tags;
}
export function getDistinctValues(array) {
    let tags = [];
    array.forEach((el) => {
        if (el) {
            tags.push(el);
        }
    });
    tags = tags.filter(onlyUnique);
    return tags;
}
export function onlyUnique(value, index, self) {
    return self.indexOf(value) === index;
}
export function getUniqueEntriesByProperty(array, property) {
    var flags = [],
        output = [];
    for (let i = 0; i < array.length; i++) {
        if (flags[array[i][property]]) continue;
        flags[array[i][property]] = true;
        output.push(array[i]);
    }
    return output;
}
export function onlyUniqueTags(value, index, self) {
    // console.log(value.tag + " "+index);
    return self.indexOf(value.tag) === index;
}
export function onlyUniqueCorrectly(value, index, self) {
    if (value.correct) {
        return self.indexOf(value) === index;
    }
    return null;
}
export function onlyUniqueWrongly(value, index, self) {
    if (!value.correct) {
        return self.indexOf(value) === index;
    }
    return null;
}

//QUIZ START

//QuizInProgress
export function getQuizProgress(nQuestions, generatedQuestions, numOfQuestions, answersPerQuestions) {
    let completedQuestion = [];
    for (let index = 0; index < numOfQuestions; index++) {
        let workedOnQuestions = [];
        const currentQuestions = generatedQuestions.slice(nQuestions * index, nQuestions * (index + 1));
        currentQuestions.forEach((question) => {
            const { q_id, q_type } = getQuestionDataNew(question);
            if (answersPerQuestions && Object.keys(answersPerQuestions)?.length > 0 && q_id) {
                const answers_per_q = answersPerQuestions[q_id]?.answers;
                let workedOnQuestion = false;
                if (q_type === "match") {
                    workedOnQuestion = checkIfAllsSelected(answers_per_q);
                } else {
                    if (Array.isArray(answers_per_q)) {
                        workedOnQuestion = answers_per_q.length > 0;
                    } else if (typeof answers_per_q === "object") {
                        workedOnQuestion = Object.keys(answers_per_q).length > 0;
                    }
                }
                workedOnQuestions.push(workedOnQuestion);
            }
        });
        completedQuestion.push(workedOnQuestions?.every((q) => q));
    }
    let completedPagesCount = 0;
    completedQuestion.forEach((boolean) => {
        if (boolean) completedPagesCount = completedPagesCount + 1;
    });
    let percentage = generatedQuestions?.length / nQuestions;
    percentage = Math.round((completedPagesCount / percentage) * 100);
    return percentage;
}

export const DummyQuestionPoolLong = [
    { cont: { q: "------------" }, id: "1" },
    { cont: { q: "------------" }, id: "2" },
    { cont: { q: "------------" }, id: "3" },
    { cont: { q: "------------" }, id: "4" },
    { cont: { q: "------------" }, id: "5" },
    { cont: { q: "------------" }, id: "6" },
    { cont: { q: "------------" }, id: "7" },
    { cont: { q: "------------" }, id: "8" },
    { cont: { q: "------------" }, id: "9" },
    { cont: { q: "------------" }, id: "10" },
    { cont: { q: "------------" }, id: "11" },
    { cont: { q: "------------" }, id: "12" },
    { cont: { q: "------------" }, id: "13" },
    { cont: { q: "------------" }, id: "14" },
    { cont: { q: "------------" }, id: "21" },
    { cont: { q: "------------" }, id: "31" },
    { cont: { q: "------------" }, id: "121" },
    { cont: { q: "------------" }, id: "1213" },
    { cont: { q: "------------" }, id: "153" },
    { cont: { q: "------------" }, id: "155" },
    { cont: { q: "------------" }, id: "166" },
    { cont: { q: "------------" }, id: "144" },
    { cont: { q: "------------" }, id: "133" },
    { cont: { q: "------------" }, id: "159" },
];

export function createReducedAlgoliaQuestion(fullQuestion) {
    const { q_type, q_updated, q_created, q_column_a, q_column_b, q_has_hint, q_descr } = getQuestionData(fullQuestion);
    const { content, metaData, properties } = fullQuestion;
    const { question } = content;
    const { tags, local_quiz_ids, qid, editors, ...other3 } = metaData;
    const prop_type = metaData?.type === "multipleChoice" ? (properties?.type ? properties?.type : "sc") : null;

    let q_q = question;
    if (other3?.type === "match") {
        q_q = q_descr ? q_descr : q_column_a?.["1"] + " -> " + q_column_b?.["1"];
    }

    let new_tags = [];
    if (tags?.length > 0) {
        new_tags = tags.filter((tag) => tag !== "no_tags");
    } else {
        new_tags = ["no_tags"];
    }
    const reduced_question = {
        _tags: new_tags,
        objectID: qid,
        cont: { question: q_q },
        metaData: { hint: q_has_hint, type: q_type, date_created: q_created, date_updated: q_updated },
        properties: { type: prop_type },
    };
    return reduced_question;
}
export function createReducedAlgoliaQuestion2(fullQuestion, qid) {
    const { q_type, q_updated, q_created, q_column_a, q_column_b, q_has_hint,q_has_text, q_descr, q_path_ids, q_space_ids } = getQuestionDataNew(fullQuestion);
    const { cont, meta, props, stats } = fullQuestion;
    const { q } = cont;
    const { tags, local_quiz_ids, editors, ...other3 } = meta;
    const prop_type = meta?.type === "multipleChoice" ? (props?.type ? props?.type : "mc") : null;
    const editors_cor = []; //TODO: change
    const score = Number.isFinite(stats?.avg_rl_scr) ? stats?.avg_rl_scr : null;
    let q_q = q;
    if (other3?.type === "match") {
        q_q = q_descr ? q_descr : q_column_a?.["1"] + " -> " + q_column_b?.["1"];
    }
    let new_tags = [];
    if (tags?.length > 0) {
        new_tags = tags.filter((tag) => tag !== "no_tags");
    } else {
        new_tags = ["no_tags"];
    }
    const reduced_question = {
        _tags: new_tags,
        objectID: qid,
        cont: { q: q_q },
        meta: { space_ids: q_space_ids, path_ids: q_path_ids, editors: editors_cor, text: q_has_text, hint: q_has_hint, type: q_type, dt_c: q_created, dt_u: q_updated },
        props: { type: prop_type },
        stats: { avg_rel_score: score },
    };
    return reduced_question;
}
//** BLANKS */

export function checkBlanksCorrecltyAnswered(answers = [], q_blanks = {}) {
    if (answers && q_blanks) {
        let maxPoints = 0;
        let achievedPoints = 0;
        let blanksCorrectlyAnswered = [];
        let blanksCorrect = false;
        const n_blanks = Object.keys(q_blanks).length;
        // console.log("BLANKS RESUTLS", answers, q_blanks);
        for (const [index, answer] of Object.entries(q_blanks)) {
            maxPoints = maxPoints + answer.points;
            if (answer?.solution === answers[index]) {
                achievedPoints = achievedPoints + answer?.points;
                blanksCorrectlyAnswered.push(index);
            }
            // console.log(`RESULT ${index}: ${answer.solution}`);
        }
        blanksCorrect = blanksCorrectlyAnswered?.length === n_blanks ? true : false;
        return { maxPoints, achievedPoints, blanksCorrectlyAnswered, blanksCorrect };
    } else return null;
}

export function getParsedBlanksQ(q_question, blanksCorrectlyAnswered, blanks_answers, q_blanks, mobile = false) {
    let gap_index = 1;
    const padding = mobile ? 24 : 32;
    const fontWidth = mobile ? 10 : 12;
    const parsedBlanksQ = q_question?.replaceAll(blanksRegex, (match) => {
        const answerClass = blanks_answers[gap_index] ? "" : "Empty";
        const answer = blanks_answers[gap_index] || "empty";
        const blankIsCorrect = blanksCorrectlyAnswered.includes(gap_index.toString());
        // console.log("PARSEBLANKS", gap_index, blankIsCorrect, gap_index.toString(), blanksCorrectlyAnswered);
        const input = `<span id="${gap_index}" class="blankQResult ${!blankIsCorrect ? " Wrong" : ""} ${
            mobile ? "Mobile" : ""
        } ${answerClass}" style="width: ${padding + fontWidth * answer?.length}px" >${answer}</span > ${
            !blankIsCorrect
                ? `<span id="${gap_index + "_correction"}"} class="blankQResult Correction ${mobile ? "Mobile" : ""}" style="width: ${
                      padding + fontWidth * q_blanks[gap_index]?.solution?.length
                  }px" >${q_blanks[gap_index]?.solution}</span>`
                : ""
        } `;
        gap_index++;
        return input;
    });
    return parsedBlanksQ;
}
