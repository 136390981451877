import classes from "./LexicalSelect.module.css";

type SelectIntrinsicProps = JSX.IntrinsicElements["select"];
interface SelectProps extends SelectIntrinsicProps {
    label: string;
}

export default function LexicalSelect({ children, label, className, ...other }: SelectProps): JSX.Element {
    return (
        <div className={classes.LexicalSelect}>
            <label className={classes.Label}>{label}</label>
            <select {...other} className={className || classes.Select}>
                {children}
            </select>
        </div>
    );
}
