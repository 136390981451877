import { LevelOne, LevelThree, LevelTwo, RatingStarEmpty, RatingStarFull, RatingStarHalf } from "../assets/icons/Icons";
import { dynamicSort } from "../components/Helpers/HelperFunction";

export function getPathData(path) {
    //PATH DATA

    //PATH METADATA
    const p_metaData = path?.metaData;

    const p_id = p_metaData?.id;
    const p_title = p_metaData?.title ? path.metaData.title : "";
    const p_sub_title = p_metaData?.subTitle;
    const p_descr = p_metaData?.description;
    const p_tags = p_metaData?.tags;
    const p_about_text = p_metaData?.aboutPath;
    // const p_spaces_object = p_metaData?.spaces ? p_metaData?.spaces : {};
    // const p_spaces = Object.values(p_spaces_object);
    // const p_space_ids = Object.keys(p_spaces_object);
    // const p_space_logo = p_spaces?.[0]?.image;
    //IMAGES
    const p_banner_modules = path?.images?.banner_modules;
    const p_small_card = path?.images?.small_card;
    const p_card = path?.images?.card;

    //content
    const p_modules_object = path?.content?.modules ? path?.content?.modules : {};
    const p_no_of_modules = Object.keys(p_modules_object)?.length;
    const p_modules = Object.values(p_modules_object);
    const p_modules_sorted = p_modules.sort(dynamicSort("position"));

    //OWNER
    const p_curator_id = path?.owner?.curator_id; // main curatxqor id
    const p_curators_object = path?.people?.curators ? path?.people?.curators : {};
    const p_curators = Object.values(p_curators_object);
    const p_curator_ids = Object.keys(p_curators_object);
    const p_main_curator = p_curators?.find((curator) => curator?.main);
    const p_main_curator_prof = p_main_curator?.profession;
    const p_main_curator_img = p_main_curator?.img;
    const p_main_curator_name = p_main_curator?.name;

    //OTHER
    const p_recommended_paths = path?.recommended_paths ? path?.recommended_paths : [];
    const p_sales_link = path?.sales?.link ? path?.sales?.link : "";

    //CONFIG
    const p_config = path?.config;
    const p_teachers_are_curators = p_config?.teachersAreCurators;
    const p_has_practice_questions = p_config?.hasPracticeQuestions;
    const p_pq_explore = p_config?.PQExplore && p_has_practice_questions;
    const p_has_auto_quiz = p_config?.hasAutoQuizGen;
    const p_has_quiz_duel = p_config?.hasQuizDuel;
    const p_is_published = p_config?.isPublished;
    const p_is_explorable = p_config?.isExplorable; // typeof p_config?.isExplorable === "boolean" ? : false;
    const p_is_free = typeof p_config?.isFree === "boolean" ? p_config?.isFree : false;
    const p_pq_category = typeof p_config?.hasPQCategory === "boolean" ? p_config?.hasPQCategory : true;
    const p_pq_date_time = typeof p_config?.hasDateTimeQuiz === "boolean" ? p_config?.hasDateTimeQuiz : true;

    const p_messages_are_editable = p_config?.messagesEditable;
    //PROPERTIES
    const p_difficulty = path?.properties?.difficulty;
    return {
        //DATA
        p_modules_object,
        p_modules,
        p_modules_sorted,
        p_no_of_modules,
        //PATH METADATA
        p_id,
        p_title,
        p_sub_title,
        p_descr,
        p_about_text,
        p_tags,
        //IMAGES
        p_small_card,
        p_card,
        p_banner_modules,
        //OWNER
        p_main_curator_prof,
        p_main_curator_img, p_curator_id,
        p_main_curator_name,
        p_curators,
        p_curator_id,
        p_curator_ids,
        // p_space_ids, p_spaces,
        // p_space_logo,
        //OTHER
        p_recommended_paths,
        p_sales_link,
        //CONFIG
        p_teachers_are_curators,
        p_is_published,
        p_is_explorable,
        p_is_free,
        p_has_practice_questions,
        p_pq_explore,
        p_has_auto_quiz,
        p_has_quiz_duel,
        p_pq_category,
        p_pq_date_time,
        p_messages_are_editable,
        //PROPERTIES
        p_difficulty,
    };
}
export function getPathStatsData(path_stat_data) {
    const p_stats = path_stat_data?.stats;
    //Groups
    const p_stats_groups = p_stats?.groups ? p_stats?.groups : {};
    const active_groups = p_stats_groups?.active;
    const archived_groups = p_stats_groups?.archived ? p_stats_groups?.archived : [];
    //Members
    //Curators
    const active_curators = p_stats?.members?.curators?.active;

    //Teachers
    const active_teachers = p_stats?.members?.teachers?.active;

    //Students
    const active_students = p_stats?.members?.students?.active;

    //
    const p_has_active_groups = p_stats_groups?.active?.length > 0;
    const p_time_to_complete = p_stats?.timeToComplete ? p_stats?.timeToComplete : 0;
    const p_rating = p_stats?.rating?.rating ? p_stats?.rating?.rating : 5;
    const p_rating_count = p_stats?.rating?.count ? p_stats?.rating?.count : 0;
    const p_rating_enabled = p_stats?.rating?.enabled;

    return {
        p_stats_groups,
        p_has_active_groups,
        active_groups,
        archived_groups,
        active_curators,
        active_teachers,
        active_students,
        p_rating,
        p_rating_count,
        p_rating_enabled,
        p_time_to_complete,
    };
}
export function getPathMemberStatsData(path_stat_data) {
    const path_stats = path_stat_data?.stats;
    const active_curators = path_stats?.members?.curators?.active;

    //Teachers
    const active_teachers = path_stats?.members?.teachers?.active;

    //Students
    const active_students = path_stats?.members?.students?.active;

    return {
        active_curators,

        active_teachers,
        active_students,
    };
}

export function getResourceOverview(resources_overview_object, resource_id) {
    return resources_overview_object?.[resource_id];
}

export function getModuleData(module) {
    const m_id = module?.id;
    const m_title = module?.title;
    const m_position = module?.position;
    const m_img = module?.img;
    const m_moduleDisplay = m_img ? m_position + ". " + m_title : m_title;

    return {
        m_id,
        m_title,
        m_position,
        m_img,
        m_moduleDisplay,
    };
}

export function getPathDifficulty(p_difficulty) {
    let difficultyIcon = <LevelOne size="90%" />;
    let difficulty = "YOUR_INTEREST";

    switch (p_difficulty) {
        case 1:
            difficultyIcon = <LevelOne size="90%" />;
            difficulty = "BEGINNER";
            break;
        case 2:
            difficultyIcon = <LevelTwo size="90%" />;
            difficulty = "INTERMEDIATE";
            break;
        case 3:
            difficultyIcon = <LevelThree size="90%" />;
            difficulty = "EXPERT";
            break;
        default:
            difficultyIcon = <LevelOne size="90%" />;
            difficulty = "BEGINNER";
    }
    return { difficultyIcon, difficulty };
}

export function getCourseStars(rating) {
    const fullStars = Math.floor(rating);
    const hasHalfStar = rating - fullStars >= 0.5;
    const emptyStars = 5 - fullStars - (hasHalfStar ? 1 : 0);

    let starComponents = [];

    for (let i = 0; i < fullStars; i++) {
        starComponents.push(<RatingStarFull size={"15px"} key={i} />);
    }

    if (hasHalfStar) {
        starComponents.push(<RatingStarHalf size={"15px"} />);
    }

    for (let i = 0; i < emptyStars; i++) {
        starComponents.push(<RatingStarEmpty size={"15px"} key={i} />);
    }

    return starComponents;
}
