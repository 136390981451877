import { useState } from "react";

import { INSERT_HORIZONTAL_RULE_COMMAND } from "@lexical/react/LexicalHorizontalRuleNode";

//UI
import classes from "./InsertDropdown.module.css";
import joinClasses from "../../../../utils/joinClasses";

import DropDown, { DropDownItem } from "../LexicalDropDown/LexicalDropDown";
import InsertImageDialog from "../InsertImageDialog/InsertImageDialog";
import { InsertEquationDialog } from "../InsertEquationDialog/InsertEquationDialog";
import { InsertInlineImageDialog } from "../InsertInLineImageDialog/InsertInLineImageDialog";
import InsertTableDialog from "../InsertTableDialog/InsertTableDialog";

//Lexical
import { INSERT_COLLAPSIBLE_COMMAND } from "../../plugins/CollapsiblePlugin/CollapsiblePlugin";
import { INSERT_EMBED_COMMAND } from "@lexical/react/LexicalAutoEmbedPlugin";

//context
import { useLanguage } from "../../../../contexts/LanguageContext";
import { EmbedConfigs } from "../../plugins/AutoEmbedPlugin/AutoEmbedPlugin";

export default function InsertDropdown({ editorConfig, activeEditor, editor, disabled = false, handleImageUpload }) {
    const { translate } = useLanguage();
    const [showImageDialog, setshowImageDialog] = useState(false);
    const [showEquationDialog, setshowEquationDialog] = useState(false);
    const [showInLineImageDialog, setshowInLineImageDialog] = useState(false);
    const [showTableDialog, setshowTableDialog] = useState(false);

    return (
        <>
            {showImageDialog && (
                <InsertImageDialog
                    editorConfig={editorConfig}
                    activeEditor={activeEditor}
                    setshowImageDialog={setshowImageDialog}
                    handleImageUpload={handleImageUpload}
                />
            )}
            {showEquationDialog ? (
                <InsertEquationDialog editorConfig={editorConfig} activeEditor={activeEditor} setshowEquationDialog={setshowEquationDialog} />
            ) : null}
            {showInLineImageDialog && (
                <InsertInlineImageDialog
                    editorConfig={editorConfig}
                    activeEditor={activeEditor}
                    setshowInLineImageDialog={setshowInLineImageDialog}
                    handleImageUpload={handleImageUpload}
                />
            )}
            {showTableDialog ? (
                <InsertTableDialog editorConfig={editorConfig} activeEditor={activeEditor} setshowTableDialog={setshowTableDialog} />
            ) : null}
            <DropDown
                disabled={disabled}
                buttonClassName={classes.BlockControl}
                buttonIconClassName={joinClasses(classes.BlockTypeIcon, classes.Plus)}
                buttonLabel={translate("Insert")}
                buttonAriaLabel="Insert specialized editor node"
            >
                <DropDownItem
                    onClick={() => {
                        activeEditor.dispatchCommand(INSERT_HORIZONTAL_RULE_COMMAND, undefined);
                    }}
                    className={classes.DropDownItem}
                >
                    <i className={joinClasses(classes.Icon, classes.HorizontalRule)} />
                    <span className={classes.Text}>{translate("Horizontal Rule")}</span>
                </DropDownItem>
                <DropDownItem onClick={() => setshowImageDialog(true)} className={classes.DropDownItem}>
                    <i className={joinClasses(classes.Icon, classes.Image)} />
                    <span className={classes.Text}>{translate("Image")}</span>
                </DropDownItem>
                <DropDownItem onClick={() => setshowInLineImageDialog(true)} className={classes.DropDownItem}>
                    <i className={joinClasses(classes.Icon, classes.Image)} />
                    <span className={classes.Text}>{translate("Inline Image")}</span>
                </DropDownItem>
                {editorConfig?.table ? (
                    <DropDownItem onClick={() => setshowTableDialog(true)} className={classes.DropDownItem}>
                        <i className={joinClasses(classes.Icon, classes.Table)} />
                        <span className={classes.Text}>{translate("Table")}</span>
                    </DropDownItem>
                ) : null}
                {/* <DropDownItem
                onClick={() =>
                    insertGifOnClick({
                        altText: "Cat typing on a laptop",
                        src: catTypingGif,
                    })
                }
                className={classes.DropDownItem}
            >
                <i className="icon gif" />
                <span className={classes.Text}>GIF</span>
            </DropDownItem> */}
                {/* <DropDownItem
                onClick={() => {
                    activeEditor.dispatchCommand(INSERT_EXCALIDRAW_COMMAND, undefined);
                }}
                className={classes.DropDownItem}
            >
                <i className="icon diagram-2" />
                <span className={classes.Text}>{translate("Excalidraw")}</span>
            </DropDownItem> */}

                {/* <DropDownItem
                onClick={() => {
                    showModal("Insert Table", (onClose) => <InsertNewTableDialog activeEditor={activeEditor} onClose={onClose} />);
                }}
                className={classes.DropDownItem}
            >
                <i className="icon table" />
                <span className={classes.Text}>Table (Experimental)</span>
            </DropDownItem> */}
                {/* <DropDownItem
                onClick={() => {
                    showModal("Insert Poll", (onClose) => <InsertPollDialog activeEditor={activeEditor} onClose={onClose} />);
                }}
                className={classes.DropDownItem}
            >
                <i className="icon poll" />
                <span className={classes.Text}>Poll</span>
            </DropDownItem> */}

                <DropDownItem onClick={() => setshowEquationDialog(true)} className={classes.DropDownItem}>
                    <i className={joinClasses(classes.Icon, classes.Equation)} />
                    <span className={classes.Text}>{translate("Equation")}</span>
                </DropDownItem>
                {/* <DropDownItem
                onClick={() => {
                    editor.update(() => {
                        const root = $getRoot();
                        const stickyNode = $createStickyNode(0, 0);
                        root.append(stickyNode);
                    });
                }}
                className={classes.DropDownItem}
            >
                <i className="icon sticky" />
                <span className={classes.Text}>Sticky Note</span>
            </DropDownItem> */}
                <DropDownItem
                    onClick={() => {
                        editor.dispatchCommand(INSERT_COLLAPSIBLE_COMMAND, undefined);
                    }}
                    className={classes.DropDownItem}
                >
                    <i className={joinClasses(classes.Icon, classes.CaretRight)} />
                    <span className={classes.Text}>{translate("Collapsible container")}</span>
                </DropDownItem>
                {EmbedConfigs.map((embedConfig) => (
                    <DropDownItem
                        key={embedConfig.type}
                        onClick={() => {
                            activeEditor.dispatchCommand(INSERT_EMBED_COMMAND, embedConfig.type);
                        }}
                        className={classes.DropDownItem}
                    >
                        <i className={joinClasses(classes.Icon, classes[embedConfig.icon])} />
                        <span className={classes.Text}>{embedConfig.contentName}</span>
                    </DropDownItem>
                ))}
            </DropDown>
        </>
    );
}
