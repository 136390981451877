import { atom } from "recoil";

export function getMultFaktor() {
    const doc = getComputedStyle(document.body);
    const multFaktor = doc.getPropertyValue("--multFaktor");
    // console.log("MULTFAKTOR", multFaktor);
    return parseFloat(multFaktor);
}

export const localStorageEffect =
    (key) =>
    ({ setSelf, onSet }) => {
        const savedValue = localStorage.getItem(key);
        if (savedValue != null) {
            setSelf(JSON.parse(savedValue));
        }

        onSet((newValue, _, isReset) => {
            isReset ? localStorage.removeItem(key) : localStorage.setItem(key, JSON.stringify(newValue));
        });
    };

export const MainNavBarState = atom({
    key: "mainNavBar",
    default: "expanded",
    effects: [localStorageEffect("mainNavBar")],
});

export const FirstNavBarState = atom({
    key: "mainNavBarTutorial",
    default: true,
    effects: [localStorageEffect("mainNavBarTutorial")],
});
