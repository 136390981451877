import { useEffect, useMemo, useRef } from "react";

import classes from "./Button.module.css";
import joinClasses from "../../../utils/joinClasses";

export default function Button({
    children = null,
    onClick = null,
    //
    size = null,
    standardSize = "M",
    fontSize = null,
    fontWeight = null,
    //styles
    variant = "contained", // OPTIONS: contained || outlined || text
    rounded = false,
    iconOnly = false,
    loading = false,
    disabled = false,
    noShadow = false,
    noHover = false,
    square = false,
    //icon
    startIcon = null,
    endIcon = null,
    //colors
    color = "primary", //  primary || secondary || cancel || delete
    btnColor = null,
    btnColorHover = null,
    btnIconColor = null,
    btnIconColorHover = null,
    btnFontColor = null,
    btnFontColorHover = null,
    //customClasses
    buttonClass = null, //change this to change text e.g. border-radius, padding etc.
    textClass = null, //change this to change text e.g. fontfamily, font-size
    style = null,
}) {
    const btnRef = useRef();
    const variantClass = useMemo(() => getVariantClass(variant), [variant]);
    useEffect(() => {
        if (btnRef.current && color && variant) {
            setButtonColors(
                btnRef.current,
                color,
                variant,
                btnColor,
                btnColorHover,
                btnIconColor,
                btnIconColorHover,
                btnFontColor,
                btnFontColorHover
            );
        }
    }, [btnRef, color, variant]);

    const loadingTextOnly = loading && !endIcon && !startIcon; //&&variant === "text"
    const iconOnlyClass = iconOnly ? classes.IconOnly : null;

    let sizeClass;
    if (standardSize === "M") {
        sizeClass = classes.SizeM;
    } else if (standardSize === "XS") {
        sizeClass = classes.SizeXS;
    } else if (standardSize === "S") {
        sizeClass = classes.SizeS;
    } else if (standardSize === "L") {
        sizeClass = classes.SizeL;
    } else if (standardSize === "XL") {
        sizeClass = classes.SizeXL;
    } else if (standardSize === "XXL") {
        sizeClass = classes.SizeXXL;
    }

    return (
        <button
            ref={btnRef}
            // id={"ui_button"}
            onClick={disabled ? () => void 0 : onClick}
            className={joinClasses(
                classes.Button,
                variantClass,
                buttonClass && buttonClass,
                noShadow && classes.NoShadow,
                iconOnlyClass && iconOnlyClass,
                disabled && classes.Disabled,
                loading && classes.Loading,
                rounded && classes.Rounded,
                square && classes.Square,
                noHover && classes.NoHover,
                sizeClass
            )}
            style={{ height: size ? size + "px" : null, ...style }}
        >
            {loading && startIcon ? (
                <div className={classes.LoaderStart}>
                    <ButtonLoader size={20} thickness={3} />
                </div>
            ) : (
                startIcon && <div className={classes.IconHolderStart}>{startIcon}</div>
            )}
            {
                <div
                    style={{ fontSize: fontSize ? fontSize + "px" : null, fontWeight: fontWeight ? fontWeight : null }}
                    className={joinClasses(classes.Text, "NoSelect", textClass ? textClass : null, loadingTextOnly && classes.Loading)}
                >
                    {loadingTextOnly ? (
                        <div className={classes.LoaderHolder}>
                            <ButtonLoader size={20} thickness={3} />
                        </div>
                    ) : (
                        children
                    )}
                </div>
            }
            {loading && endIcon ? (
                <div className={classes.LoaderEnd}>
                    <ButtonLoader size={20} thickness={3} />
                </div>
            ) : (
                endIcon && <div className={classes.IconHolderEnd}>{endIcon}</div>
            )}
        </button>
    );
}

function getVariantClass(variant) {
    if (variant === "contained") {
        return classes.Contained;
    } else if (variant === "outlined") {
        return classes.Outlined;
    } else if (variant === "text") {
        return classes.TextOnly;
    }
}

function setButtonColors(
    btnRef,
    color,
    variant,
    btnColor = null,
    btnColorHover = null,
    btnIconColor = null,
    btnIconColorHover = null,
    btnFontColor = null,
    btnFontColorHover = null
) {
    let buttonColor, buttonColorHover, buttonIconColor, buttonIconColorHover, buttonFontColor, buttonFontColorHover;
    switch (color) {
        case "primary":
            buttonColor = btnColor ? btnColor : "#001C3E";
            buttonColorHover = btnColorHover ? btnColorHover : "#002550";
            buttonIconColor = btnIconColor ? btnIconColor : variant === "outlined" ? btnColor : "#ffffff";
            buttonIconColorHover = btnIconColorHover ? btnIconColorHover : "#ffffff";
            buttonFontColor = btnFontColor ? btnFontColor : "#ffffff";
            buttonFontColorHover = btnFontColorHover ? btnFontColorHover : buttonFontColor;
            break;
        case "secondary":
            buttonColor = btnColor ? btnColor : "#0076dd";
            buttonColorHover = btnColorHover ? btnColorHover : "#0088ff";
            buttonIconColor = btnIconColor ? btnIconColor : variant === "outlined" ? btnColor : "#ffffff";
            buttonIconColorHover = btnIconColorHover ? btnIconColorHover : "#ffffff";
            buttonFontColor = btnFontColor ? btnFontColor : "#ffffff";
            buttonFontColorHover = btnFontColorHover ? btnFontColorHover : "#ffffff";
            break;
        case "cancel":
            buttonColor = btnColor ? btnColor : "#acacac";
            buttonColorHover = btnColorHover ? btnColorHover : "#9b9b9b";
            buttonIconColor = btnIconColor ? btnIconColor : "#ffffff";
            buttonIconColorHover = btnIconColorHover ? btnIconColorHover : "#ffffff";
            buttonFontColor = btnFontColor ? btnFontColor : "#ffffff";
            buttonFontColorHover = btnFontColorHover ? btnFontColorHover : "#ffffff";
            break;
        case "delete":
            buttonColor = btnColor ? btnColor : "#b40000";
            buttonColorHover = btnColorHover ? btnColorHover : "#a10000";
            buttonIconColor = btnIconColor ? btnIconColor : "#ffffff";
            buttonIconColorHover = btnIconColorHover ? btnIconColorHover : "#ffffff";
            buttonFontColor = btnFontColor ? btnFontColor : "#ffffff";
            buttonFontColorHover = btnFontColorHover ? btnFontColorHover : "#ffffff";
            break;
        default:
            buttonColor = btnColor ? btnColor : "#001C3E";
            buttonColorHover = btnColorHover ? btnColorHover : buttonColor;
            buttonIconColor = btnIconColor ? btnIconColor : variant === "outlined" ? btnColor : "#ffffff";
            buttonIconColorHover = btnIconColorHover ? btnIconColorHover : "#ffffff";
            buttonFontColor = btnFontColor ? btnFontColor : "#ffffff";
            buttonFontColorHover = btnFontColorHover ? btnFontColorHover : buttonFontColor;
            break;
    }

    if (variant === "outlined" || variant === "text") {
        if (color === "cancel") {
            buttonFontColor = "#868686";
            buttonIconColor = "#868686";
        } else {
            buttonFontColor = buttonColor;
            buttonIconColor = buttonColor;
        }
    }
    const buttonElStyle = btnRef?.style;
    // console.log("BUTTON_SET_PROP", document.getElementById("ui_button")?.className);
    if (buttonElStyle) {
        try {
            buttonElStyle.setProperty("--buttonColor", buttonColor);
            buttonElStyle.setProperty("--buttonColorHover", buttonColorHover);
            buttonElStyle.setProperty("--buttonIconColor", buttonIconColor);
            buttonElStyle.setProperty("--buttonIconColorHover", buttonIconColorHover);
            buttonElStyle.setProperty("--buttonFontColor", buttonFontColor);
            buttonElStyle.setProperty("--buttonFontColorHover", buttonFontColorHover);
        } catch (error) {
            console.log("Error setting button color styles: ", error);
        }
    }
}

const ButtonLoader = ({ thickness, size }) => {
    return (
        <div className={classes.Circle} style={{ width: size + "px", height: size + "px" }}>
            <div className={classes.Loader} style={{ borderBottomWidth: thickness + "px", borderLeftWidth: thickness + "px" }} />
        </div>
    );
};
