import { useEffect } from "react";

import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";

const OnFocusPlugIn = ({ inFocus, setInFocus, editable, scrollerID, scrollRef }) => {
    const [editor] = useLexicalComposerContext();

    useEffect(() => {
        if (!editable || !inFocus) {
            editor.setEditable(false);
            editor.blur();
        } else if (inFocus) {
            // console.log("LX_enable editing");
            editor.setEditable(true);
        }
    }, [editable, inFocus]);

    useEffect(() => {
        if (typeof document === "undefined" || (!scrollerID && !scrollRef)) return;
        const scrollContainer = scrollRef || document.getElementById(scrollerID);
        // console.log("LEXICAL_Scroll");
        const handleScroll = () => setInFocus(false);
        if (scrollContainer) scrollContainer.addEventListener("scroll", handleScroll);
        return () => {
            if (scrollContainer) scrollContainer.removeEventListener("scroll", handleScroll);
        };
    }, [scrollerID, scrollRef]);

    return null;
};

export default OnFocusPlugIn;

// import { useEffect } from 'react';

// import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';

// const OnFocusPlugIn = ({ inFocus, editable,  setInFocus }) => {
//   const [editor] = useLexicalComposerContext();

//   useEffect(() => {
//     if (!editable  !inFocus) {
//       editor.setEditable(false);
//       editor.blur();
//     } else if (inFocus) editor.setEditable(true);
//   }, [editable, inFocus]);
