import mixpanel from "mixpanel-browser";
import { useContext, createContext } from "react";
import { auth } from "../app/firebase";

import { trackUserAcivitySpace } from "../pages/CourseBrowse/Functions/SpaceFirestoreFunctions";
import { dynamicSort } from "../components/Helpers/HelperFunction";

import { useAuthState } from "react-firehooks";
import useLocalStorage from "../customHooks/use-local-storage_TBC";
import { useUpdateEffect } from "react-use";

import { useSpaceContext } from "./SpaceContext/SpaceContext";
import useSpaceMember from "../hooks/memberHooks/useSpaceMember";
import { constructDateTimeObject } from "../components/Helpers/DatetimeHelperfunctions";

export const AuthContext = createContext({
    currentUser: null, auth_loading: false,
    //USER DATA
    user_id: "", user_name: "", user_email: "",
    first_name: "", last_name: "", user_image_small: "", user_image_big: "", user_image_large: [],
    //USER DATA 2
    curatedTaughtAndEnrolled: [],
    //SPACE MEMBER
    space_member: {},
    user_role: "",
    user_has_profile: false,
    isPathCuratorOfSpace: false, isPathTeacherOfSpace: false, isPathStudentOfSpace: false,
    loading_spacemember: false,
    m_active_curator_path_ids: [], m_active_student_group_ids: [],
    m_active_teacher_path_ids: [], m_active_student_path_ids: [], curatedAndTaught: [], m_active_student_pq_ids: [],
    part_of_space: false, paths_curator_by_space: [], space_ids_curated: [], hasEnrolledPaths: false, isCuratorOfSpace: false,
    //ROLES
    isAdmin: false,
    //GROUPS
    teacherGroupsOfSpace: {}, groupsOfSpace: {},
    isTeacherOfGroup: (channel_id) => (channel_id),
    //FUNCTIONS
    //VISITOR
    visitor_progress: {}, visitor_progress_per_path: {}, visitor_tasks_per_path: {}, setvisitor_progress: null, visitor_quiz_attempts: {}, setvisitor_quiz_attempts: null, visitor_correct_qids_per_path: {},
    spc_paths_filtered: [], curatedTaughtAndEnrolled_sorted: [],
    //SETTINGS
    only_teacher: true,
    handlesetonly_teacher: null,
});

export function useAuth() {
    return useContext(AuthContext);
}

export function AuthProvider({ children }) {
    const { space_id, spc_paths, spc_curator_ids, has_internet } = useSpaceContext();

    const [currentUser, auth_loading, auth_error] = useAuthState(auth);


    const user_name = currentUser?.displayName;
    const user_email = currentUser?.email;

    const first_name = user_name?.split(" ")?.[0];
    const last_name = user_name?.split(" ")[user_name?.split(" ")?.length - 1];
    const user_id = currentUser?.uid;
    const user_image_small = currentUser?.photoURL;

    const isAdmin = (user_id === "wL5UiBe0gBaWSUqGliQ9scHjz6p2");
    //use for access
    const {
        //access
        space_member, user_pic,
        m_active_curator_path_ids, m_active_teacher_path_ids, m_active_student_path_ids, m_active_student_pq_ids,
        m_active_teacher_group_ids, m_active_student_group_ids,
        user_has_profile,
        user_role,
        isPathTeacherOfSpace, isPathCuratorOfSpace, isPathStudentOfSpace,
        loading_spacemember, m_products_purchased
    } = useSpaceMember(user_id);

    const teacherGroupsOfSpace = m_active_teacher_group_ids;
    const groupsOfSpace = m_active_teacher_group_ids?.concat(m_active_student_group_ids);
    const user_image_big = user_pic ? user_pic : user_image_small;
    const curatedTaughtAndEnrolled = m_active_student_path_ids?.concat(m_active_curator_path_ids).concat(m_active_teacher_path_ids);
    const curatedAndTaught = m_active_curator_path_ids?.concat(m_active_teacher_path_ids);
    const hasEnrolledPaths = curatedTaughtAndEnrolled?.length > 0;


    useUpdateEffect(() => {
        if ((user_role && !isAdmin)) {
            trackUserAcivitySpace(space_id, user_role);
        }
    }, [user_role, isAdmin]);

    const isCuratorOfSpace = spc_curator_ids?.includes(user_id) || isAdmin;
    const part_of_space = space_member || isAdmin;
    const curatedTaughtAndEnrolled_sorted = spc_paths?.filter((p) => curatedTaughtAndEnrolled?.includes(p.path_id))?.sort(dynamicSort("title"));
    const spc_paths_filtered = isCuratorOfSpace ? spc_paths?.filter((p) => !curatedTaughtAndEnrolled?.includes(p.path_id))?.sort(dynamicSort("title")) : [];

    function isTeacherOfGroup(group_id) {
        return teacherGroupsOfSpace?.includes(group_id);
    }
    //VISITOR
    const [visitor_progress, setvisitor_progress] = useLocalStorage("progress", { markAsDone: {}, tasks: {}, upvotes: [], downvotes: [], correct_qids: {} });
    const visitor_progress_per_path = visitor_progress?.markAsDone;
    const visitor_tasks_per_path = visitor_progress?.tasks;
    const visitor_correct_qids_per_path = visitor_progress?.correct_qids;
    const [visitor_quiz_attempts, setvisitor_quiz_attempts] = useLocalStorage("visitor_quiz_attempts", {});
    //MIXPANEL
    mixpanel.init("6f04b6650966388f0b0476140fcf0afe", { debug: false, ignore_dnt: true });
    useUpdateEffect(() => {
        const roles = [];
        if (isPathCuratorOfSpace) roles.push("curator"); // isCuratorOfSpace
        if (isPathTeacherOfSpace) roles.push("teacher");
        if (isPathStudentOfSpace) roles.push("student");
        if (isAdmin) roles.push("admin");
        mixpanel.register({ user_id, space_id, ...roles });
    }, [isAdmin, isPathTeacherOfSpace, isPathCuratorOfSpace, isPathStudentOfSpace]);

    const [only_teacher, setonly_teacher] = useLocalStorage("only_teacher_", true);
    function handlesetonly_teacher() {
        setonly_teacher(prev => !prev);
    }

    const value = {
        currentUser,
        auth_loading,
        //USER DATA
        user_id,
        user_name,
        user_email,
        first_name,
        last_name,
        user_image_small,
        user_image_big,
        //USER DATA 2
        curatedTaughtAndEnrolled,
        // loading_checked_resources,
        //USER DATA PRIVATE
        curatedAndTaught,
        part_of_space,
        hasEnrolledPaths,
        isCuratorOfSpace,
        //ROLES
        isAdmin,
        //FUNCTIONS
        //VISITOR
        visitor_progress, setvisitor_progress, visitor_progress_per_path, visitor_tasks_per_path, visitor_correct_qids_per_path,
        visitor_quiz_attempts, setvisitor_quiz_attempts,
        spc_paths_filtered,
        curatedTaughtAndEnrolled_sorted,
        //SETTINGS
        only_teacher,
        handlesetonly_teacher,
        //MEMBER DATA
        space_member,
        user_has_profile,
        isPathTeacherOfSpace, isPathCuratorOfSpace, isPathStudentOfSpace, loading_spacemember,
        m_active_curator_path_ids, m_active_teacher_path_ids, m_active_student_path_ids, m_active_student_pq_ids,
        m_active_teacher_group_ids, m_active_student_group_ids,
        //GROUPS
        teacherGroupsOfSpace, groupsOfSpace, isTeacherOfGroup,
        //Products
        m_products_purchased

    };

    //use context insider the provider
    return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}
