import { useState } from "react";
import classes from "./ModalContainerPlugin.module.css";
import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";

export default function ModalContainerPlugin({ editorConfig }) {
    const [editor] = useLexicalComposerContext();
    const [activeEditor, setActiveEditor] = useState(editor);
    const [isEditable, setIsEditable] = useState(() => editor.isEditable());

    return activeEditor === editor && isEditable ? <div id={editorConfig?.editorID} className={classes.ModalContainer} /> : null;
}
