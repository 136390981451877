import { StrictMode, useEffect } from "react";
import reportWebVitals from "./reportWebVitals";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import { createRoot } from "react-dom/client";
import { BrowserRouter, createRoutesFromChildren, matchRoutes, useLocation, useNavigationType } from "react-router-dom";

//app
import AppInit from "./app/AppInit";

//contexts
import { SpaceContext, SpaceProvider } from "./contexts/SpaceContext/SpaceContext";
import { LanguageProvider } from "./contexts/LanguageContext";

const root = createRoot(document.getElementById("root"));
root.render(
    <StrictMode>
        <BrowserRouter>
            <SpaceProvider>
                <SpaceContext.Consumer>
                    {({ spc_lang }) => (
                        <LanguageProvider spc_lang={spc_lang}>
                            <AppInit />
                        </LanguageProvider>
                    )}
                </SpaceContext.Consumer>
            </SpaceProvider>
        </BrowserRouter>
    </StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals(console.log);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister();
