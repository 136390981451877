import { useEffect, useState } from "react";
import { INSERT_TABLE_COMMAND } from "@lexical/table";
import { LexicalEditor } from "lexical";
// import { INSERT_NEW_TABLE_COMMAND } from "../../plugins/TablePlugin/TableContext";

//ui
import classes from "./InsertTableDialog.module.css";

//components
import ReactPortal from "../../../UI/Modal/ReactPortal";
import Modal from "../../../UI/Modal/Modal";
import LexicalModalHeader from "../LexicalModalHeader/LexicalModalHeader";
import Button from "../../../ComponentLibrary/Button/Button";

//contexts
import { useLanguage } from "../../../../contexts/LanguageContext";
import TextInput from "../../../ComponentLibrary/TextInput/TextInput";
import LabeledSection from "../../../ComponentLibrary/LabeledSection/LabeledSection";

export default function InsertTableDialog({
    activeEditor,
    setshowTableDialog,
    editorConfig,
}: {
    activeEditor: LexicalEditor;
    setshowTableDialog: () => void;
}): JSX.Element {
    const [rows, setRows] = useState("5");
    const [columns, setColumns] = useState("5");
    const [isDisabled, setIsDisabled] = useState(true);
    const { translate } = useLanguage();

    useEffect(() => {
        const row = Number(rows);
        const column = Number(columns);
        if (row && row > 0 && row <= 500 && column && column > 0 && column <= 50) {
            setIsDisabled(false);
        } else {
            setIsDisabled(true);
        }
    }, [rows, columns]);

    const onClick = () => {
        activeEditor.dispatchCommand(INSERT_TABLE_COMMAND, {
            columns,
            rows,
        });

        setshowTableDialog();
    };

    return (
        <ReactPortal wrapperId={editorConfig?.editorID}>
            <Modal setModalOpen={setshowTableDialog}>
                <div className={classes.InsertTableDialog}>
                    <LexicalModalHeader title={translate("Insert Image")} onClose={() => setshowTableDialog(false)} />
                    <div className={classes.Spacer}></div>
                    <LabeledSection noMargin label={"Rows"}>
                        <TextInput placeholder={"# of rows (1-500)"} onChange={(e) => setRows(e.target.value)} value={rows} />
                    </LabeledSection>
                    <LabeledSection noMargin label={"Column"}>
                        <TextInput placeholder={"# of columns (1-50)"} onChange={(e) => setColumns(e.target.value)} value={columns} />
                    </LabeledSection>
                    <Button disabled={isDisabled} onClick={onClick} buttonClass={classes.ChoiceButton}>
                        {translate("CONFIRM")}
                    </Button>
                </div>
            </Modal>
        </ReactPortal>
    );
}

// export function InsertNewTableDialog({ activeEditor, onClose }: { activeEditor: LexicalEditor; onClose: () => void }): JSX.Element {
//     const [rows, setRows] = useState("");
//     const [columns, setColumns] = useState("");
//     const [isDisabled, setIsDisabled] = useState(true);

//     useEffect(() => {
//         const row = Number(rows);
//         const column = Number(columns);
//         if (row && row > 0 && row <= 500 && column && column > 0 && column <= 50) {
//             setIsDisabled(false);
//         } else {
//             setIsDisabled(true);
//         }
//     }, [rows, columns]);

//     const onClick = () => {
//         activeEditor.dispatchCommand(INSERT_NEW_TABLE_COMMAND, { columns, rows });
//         onClose();
//     };

//     return (
//         <>
//             <TextInput placeholder={"# of rows (1-500)"} label="Rows" onChange={setRows} value={rows} data-test-id="table-modal-rows" type="number" />
//             <TextInput
//                 placeholder={"# of columns (1-50)"}
//                 label="Columns"
//                 onChange={setColumns}
//                 value={columns}
//                 data-test-id="table-modal-columns"
//                 type="number"
//             />
//             <DialogActions data-test-id="table-model-confirm-insert">
//                 <Button disabled={isDisabled} onClick={onClick}>
//                     Confirm
//                 </Button>
//             </DialogActions>
//         </>
//     );
// }
