import { dynamicSort, shuffleArr } from "../../../../../../Helpers/HelperFunction";
import { getKeyByValue } from "../../../../../../Helpers/HelperFunctions_2";

export function getColumnBSorted(column_b = {}) {
    const column_b_array = Object.entries(column_b)?.map(([id, text], index) => {
        return { id, text };
    });
    const column_b_array_sorted = column_b_array.sort(dynamicSort("text"));
    return column_b_array_sorted;
}

export function getColumnASorted(column_a = {}, rnd = false) {
    const array = Object.entries(column_a)?.map(([id, text], index) => {
        return { id, text };
    });
    if (rnd) {
        return shuffleArr(array);
    } else {
        return array;
    }
}

export function checkIfAllsSelected(answers = {}) {
    const entries = Object.values(answers);
    return entries?.length > 0 && entries?.every((answer) => answer);
}

export function handleQMatchAnswerSelection(new_answer_id, q_id, column_a_id, answersPerQuestions, setAnswersPerQuestions) {
    const currentAnswerQuestion = answersPerQuestions[q_id] ? answersPerQuestions[q_id] : { answers: {} };
    const current_key_of_new_answer_id = getKeyByValue(currentAnswerQuestion.answers, new_answer_id);
    if (current_key_of_new_answer_id) {
        if (checkIfAllsSelected(currentAnswerQuestion)) {
            //SWAP if all options taken
            const current_answer_id = currentAnswerQuestion.answers[column_a_id] ? currentAnswerQuestion.answers[column_a_id] : "";
            currentAnswerQuestion.answers[current_key_of_new_answer_id] = current_answer_id;
        } else {
            //empty other option
            currentAnswerQuestion.answers[current_key_of_new_answer_id] = "";
        }
    }
    currentAnswerQuestion.answers[column_a_id] = new_answer_id; //set new key
    setAnswersPerQuestions((prev) => {
        return { ...prev, [q_id]: currentAnswerQuestion };
    });
}
export function checkWhetherQuestionIsCorrectlyAnsweredMatch(answerIDs = {}) {
    let achievedPoints = 0;
    let maxPoints = 0;
    let questionCorrectlyAnswered = [];
    Object.entries(answerIDs).forEach(([id, answer_id]) => {
        maxPoints = maxPoints + 1;
        if (id === answer_id) {
            achievedPoints = achievedPoints + 1; //answer.points;
            questionCorrectlyAnswered.push(true);
        } else {
            questionCorrectlyAnswered.push(false);
        }
    });
    achievedPoints = achievedPoints < 0 ? 0 : achievedPoints;
    const isCorrect = questionCorrectlyAnswered?.length !== 0 && questionCorrectlyAnswered?.every((c) => c);
    return { maxPoints, achievedPoints, isCorrect };
}
