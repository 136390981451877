import toast from "react-hot-toast";
import {
    markAsDoneResourcePath,
    markAsDoneResourceSpace,
    markUnDoneResourcePath,
    markUnDoneResourceSpace,
} from "../../../components/Helpers/UserProgressFirestoreFunctions";
import { updateUserEnrolledPaths } from "../../../components/Helpers/UserDataFirestoreFunctions";

export async function handleMarkDonePath(
    space_id,
    path_id,
    global_id,
    local_id,
    rsrc_tags,
    allTaskIDsNotChecked,
    allTaskTimesNotChecked,
    next_module,
    roleOfSelectedPath = null,
) {
    try {
        const date = new Date();
        const checked_resource_by_tag_date = rsrc_tags?.map((tag) => {
            return { tag: tag, date: date, local_id, global_id };
        });
        const promises = [];
        promises.push(markAsDoneResourcePath(space_id, path_id, global_id, local_id, checked_resource_by_tag_date, allTaskIDsNotChecked, allTaskTimesNotChecked));
        if (roleOfSelectedPath) {
            promises.push(updateUserEnrolledPaths(path_id, parseInt(next_module), roleOfSelectedPath));
        }
        Promise.all(promises);
    } catch (error) {
        console.log(error);
        toast.error("Error: " + error);
    }
}

export async function handleMarkUnDonePath(
    space_id,
    path_id,
    global_id,
    local_id,
    current_module,
    checkedResourcesByDate = [],
    roleOfSelectedPath = null
) {
    try {
        const new_checked_local_resources_by_date = checkedResourcesByDate?.filter((cr) => cr.local_id !== local_id);
        const promises = [];
        promises.push(markUnDoneResourcePath(space_id, path_id, global_id, local_id, new_checked_local_resources_by_date));
        if (roleOfSelectedPath) {
            promises.push(updateUserEnrolledPaths(path_id, parseInt(current_module), roleOfSelectedPath));
        }
        await Promise.all(promises);
    } catch (error) {
        console.log(error);
        toast.error("Error: " + error);
    }
}

//SPACE
export async function handleMarkDoneSpace(space_id, local_id, allTaskIDsNotChecked, allTaskTimesNotChecked) {
    try {
        await markAsDoneResourceSpace(space_id, local_id, allTaskIDsNotChecked, allTaskTimesNotChecked)
    } catch (error) {
        console.log(error);
        toast.error("Error: " + error);
    }
}
export async function handleMarkUnDoneSpace(space_id, local_id) {
    try {
        await markUnDoneResourceSpace(space_id, local_id);
    } catch (error) {
        console.log(error);
        toast.error("Error: " + error);
    }
}

// export function checkLastResourceToCheck(resources_of_current_module, checked_path_resources, resource_id) {
//     const checkLastResourceToCheck = resources_of_current_module
//         ?.filter((resource) => resource?.localData?.local_id !== resource_id)
//         .every((resource) => checked_path_resources?.includes(resource?.localData?.local_id) || resource?.localData?.optional);
//     return checkLastResourceToCheck;
// }
export function checkModuleCompleted(resources_by_module, checked_path_resources) {
    const moduleCompleted = resources_by_module?.every(
        (resource2) => checked_path_resources?.includes(resource2?.localData?.local_id) || resource2?.localData?.optional) &&
        !(!resources_by_module?.some((resource2) => checked_path_resources?.includes(resource2?.localData?.local_id)) && resources_by_module?.every((resource2) => resource2?.localData?.optional)); //if all optional but no progress: not finished
    return moduleCompleted;
}
export function getAllUncheckedTaskIDs(tasks, task_checks) {
    const allTasksNotChecked = [];
    tasks?.forEach((task) => {
        if (!task_checks?.includes(task?.id)) {
            allTasksNotChecked.push(task?.id);
        }
    });
    return allTasksNotChecked;
}

export function getAllUncheckedTaskTimes(tasks, task_checks) {
    let allTasksNotChecked = 0;
    tasks?.forEach((task) => {
        if (!task_checks?.includes(task?.id)) {
            const task_time = task?.time ? task.time : 0;
            allTasksNotChecked = allTasksNotChecked + task?.time;
        }
    });
    return allTasksNotChecked;
}
