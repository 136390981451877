import { arrayRemove, arrayUnion, deleteField, doc, increment, writeBatch } from "firebase/firestore";
import { auth, firestore } from "../../../app/firebase";
import { channel_blueprint, space_data_blueprint, space_info_data_blueprint, space_stats_blueprint, space_theme_data_blueprint } from "../../../components/Helpers/BluePrints/DataBluePrints";
import { pad } from "../../../components/Helpers/HelperFunction";


export async function createNewSpace(new_space_setup) {
    const batch = writeBatch(firestore);
    //(1) New Path
    const new_space_id = new_space_setup.space_id;
    const spaceRef = doc(firestore, "spaces", new_space_id);
    const new_space = space_data_blueprint;
    new_space.metaData.title = new_space_setup.title;
    new_space.metaData.url = new_space_setup.url;
    new_space.metaData.id = new_space_id;
    new_space.config.default_language = new_space_setup.lang;
    batch.set(spaceRef, new_space);
    //(1.1)theme
    const spaceThemeRef = doc(firestore, "spaces", new_space_id, "section", "theme");
    const new_space_theme = space_theme_data_blueprint;
    batch.set(spaceThemeRef, new_space_theme);
    //(1.2)info
    const spaceInfoRef = doc(firestore, "spaces", new_space_id, "section", "info");
    const new_space_info = space_info_data_blueprint;
    batch.set(spaceInfoRef, new_space_info);
        //(1.3)info
    const spaceSalesRef = doc(firestore, "spaces", new_space_id, "section", "sales");
    const new_space_sales = {};
    batch.set(spaceSalesRef, new_space_sales);
    //(2)stats
    const spaceStatsRef = doc(firestore, "spaces", new_space_id, "stats", "space_stats");
    const new_space_stats = space_stats_blueprint;
    batch.set(spaceStatsRef, new_space_stats);
    const spaceMStatsRef = doc(firestore, "spaces", new_space_id, "stats", "space_member_stats");
    batch.set(spaceMStatsRef, { stats: { members: { curators: {active:[], inactive:[], deleted:[], pending:[]}, students: {active:[], inactive:[], deleted:[], pending:[]}, teachers: {active:[], inactive:[], deleted:[], pending:[]} } } });

    const stats_ref = doc(firestore, 'spaces', new_space_id, "stats", "quiz_question_stats");
    batch.set(stats_ref, { numberOfQuestions: 0, question_ids: 0 });


    //(3) channels
    const defaultChannels = ["support", "announcements", "teachers"]
    defaultChannels.forEach((defaultChannel) => {
        const channel_data = channel_blueprint
        channel_data.metaData.name = defaultChannel.toUpperCase()
        channel_data.metaData.id = new_space_id + "_" + defaultChannel;
        channel_data.metaData.type = defaultChannel.toUpperCase()
        channel_data.metaData.origin = "SPACE"
        const channelRef = doc(firestore, "spaces", new_space_id, "channels", channel_data.metaData.id);
        batch.set(channelRef, channel_data);
    });

    return batch.commit();
}


export async function deletePathFromSpace(space_id, path_id) {
    const batch = writeBatch(firestore);
    //(1) New Path
    const spaceRef = doc(firestore, "spaces", space_id);

    batch.update(spaceRef, {
        ["content.path_ids"]: arrayRemove(path_id),
        ["content.paths." + path_id]: deleteField()
    });
    const space_statsRef = doc(firestore, "spaces", space_id, "stats", "space_stats");
    batch.update(space_statsRef, {
        ["stats.paths.active_count"]: increment(-1),
        ["stats.resources.votes." + path_id]: deleteField()
    });
    return batch.commit();
}

export async function updatePathImgInSpace(space_id, path_id, new_img_url) {
    const batch = writeBatch(firestore);
    //(1) New Path
    const spaceRef = doc(firestore, "spaces", space_id);
    batch.update(spaceRef, {
        ["content.paths." + path_id + ".img"]: new_img_url
    })
    return batch.commit();
}

export async function updateImageOfSpace(space_id, field, new_img_url) {
    const batch = writeBatch(firestore);
    //(1) New Path
    const spaceRef = doc(firestore, "spaces", space_id);
    batch.update(spaceRef, {
        ["images." + field]: new_img_url
    })
    return batch.commit();
}




//META

export async function updateQAMessages(space_id, path_id, channel_id, channel_origin, message_id, join) {
    const batch = writeBatch(firestore);
    const userRef = doc(firestore, 'spaces', space_id, "stats", "message_stats")
    let section, sub_section, sub_sub_section
    switch (channel_origin) {
        case "GROUP":
            section = "groups"
            sub_section = channel_id
            break;
        case "SPACE":
            section = "spaces"
            sub_section = space_id
            break;
        default:
            section = "paths"
            sub_section = path_id
            sub_sub_section = channel_id
            break;
    }
    let messages_stats
    if (sub_sub_section) {
        messages_stats = {
            messages: {
                [space_id]: {
                    [section]: {
                        [sub_section]: {
                            [sub_sub_section]: join ? arrayUnion(message_id) : arrayRemove(message_id)
                        }
                    }
                }
            }
        }
    } else {
        messages_stats = {
            moderations: {
                [space_id]: {
                    [section]: {
                        [sub_section]: join ? arrayUnion(message_id) : arrayRemove(message_id)
                    }
                }
            }
        }
    }

    batch.set(userRef, {
        ["stats"]: messages_stats
    }, { merge: true });
    await batch.commit();
}


export async function updateMessageContribution(space_id, path_id, channel_id, channel_origin) {
    const uid = auth.currentUser?.uid;
    const batch = writeBatch(firestore);
    const userRef = doc(firestore, 'spaces', space_id, "stats", "message_stats")
    let section, sub_section, sub_sub_section
    switch (channel_origin) {
        case "GROUP":
            section = "groups"
            sub_section = channel_id
            break;
        case "SPACE":
            section = "spaces"
            sub_section = space_id
            break;
        default:
            section = "paths"
            sub_section = path_id
            sub_sub_section = channel_id
            break;
    }
    let messages_stats
    if (sub_sub_section) {
        messages_stats = {
            messages: {
                [space_id]: {
                    [section]: {
                        [sub_section]: {
                            [sub_sub_section]: arrayUnion({ user_id: uid, date: new Date() })
                        }
                    }
                }
            }
        }
    } else {
        messages_stats = {
            moderations: {
                [space_id]: {
                    [section]: {
                        [sub_section]: arrayUnion({ user_id: uid, date: new Date() })
                    }
                }
            }
        }
    }

    batch.set(userRef, {
        ["stats"]: messages_stats
    }, { merge: true });
    await batch.commit();
}



export async function setSpacePathPublished(space_id, path_id, boolean) {
    const batch = writeBatch(firestore);
    //(1) New Path
    const spaceRef = doc(firestore, "spaces", space_id);

    batch.update(spaceRef, {
        ["content.paths." + path_id + ".not_published"]: boolean
    });
    const space_statsRef = doc(firestore, "spaces", space_id, "stats", "space_stats");
    const incr = boolean ? -1 : 1; //from published -> unpublished -1
    batch.update(space_statsRef, {
        ["stats.paths.active_count"]: increment(incr),
    });
    return batch.commit();
}


export async function setSpacePathExplorable(space_id, path_id, boolean) {
    const batch = writeBatch(firestore);
    //(1)
    const spaceRef = doc(firestore, "spaces", space_id);
    batch.update(spaceRef, {
        ["content.paths." + path_id + ".isExplorable"]: boolean
    });
    //(2)
    const spaceStatsRef = doc(firestore, "spaces", space_id, "stats", "space_stats");
    batch.update(spaceStatsRef, {
        ["stats.paths.active_count"]: increment(boolean ? 1 : -1)
    });
    return batch.commit();
}



export async function setSpacePathPQ(space_id, path_id, boolean) {
    const batch = writeBatch(firestore);
    const spaceRef = doc(firestore, "spaces", space_id);
    batch.update(spaceRef, {
        ["content.paths." + path_id + ".pq"]: boolean
    });
    const PQRef = doc(firestore, "spaces", space_id, "paths", path_id, "stats", "practice_questions");
    batch.set(PQRef, {
        path_id
    }, { merge: true });
    return batch.commit();
}
export async function setSpacePathQD(space_id, path_id, boolean) {
    const batch = writeBatch(firestore);
    const spaceRef = doc(firestore, "spaces", space_id);
    batch.update(spaceRef, {
        ["content.paths." + path_id + ".qd"]: boolean
    });
    const qdRef = doc(firestore, "spaces", space_id, "paths", path_id, "stats", "quiz_duel_catalog")
    batch.set(qdRef, {
        path_id
    }, { merge: true });
    return batch.commit();
}




export async function trackUserAcivitySpace(space_id, user_role) {
    const now = new Date();
    const day = pad(now.getDate());
    const month = pad(now.getMonth() + 1);
    const year = now.getFullYear();
    const uid = auth.currentUser?.uid;
    if (!uid) return
    const batch = writeBatch(firestore);
    //per space & role
    if (user_role) {
        const spaceRef = doc(firestore, 'spaces', space_id, "stats", "activity_stats", "months", year + "_" + month)
        const stats = { [user_role + "s"]: { [day]: arrayUnion(uid) } }
        batch.set(spaceRef, { roles: stats }, { merge: true })
    }
    //(2)
    const userRef = doc(firestore, 'progress_by_user', uid, "progress_spaces", space_id, "activity_stats", "daily_activity")
    const user_activity_stats = {
        [year]: {
            [year + "_" + month]: arrayUnion(year + "_" + month + "_" + day)
        }
    }
    batch.set(userRef, {
        years: user_activity_stats
    }, { merge: true });
    await batch.commit();
}
export async function trackUserAcivityPath(space_id, user_role, path_id) {
    const now = new Date();
    const day = pad(now.getDate());
    const month = pad(now.getMonth() + 1);
    const year = now.getFullYear();
    const uid = auth.currentUser?.uid;
    if (!uid) return
    const batch = writeBatch(firestore);
    //per space & role
    const activityPathRef = doc(firestore, 'spaces', space_id, "stats", "activity_stats", "paths", path_id, "months", year + "_" + month)
    const stats = { [user_role + "s"]: { [day]: arrayUnion(uid) } }
    batch.set(activityPathRef, { roles: stats, path_id }, { merge: true })
    await batch.commit();
}


export async function saveMonthlyActiveMembers(space_id, activity_stats, path_id = null) {
    const uid = auth.currentUser?.uid;
    if (!uid) return
    const batch = writeBatch(firestore);
    let statsRef
    if (path_id) {
        statsRef = doc(firestore, 'spaces', space_id, "stats", "activity_stats", "paths", path_id)
    } else {
        statsRef = doc(firestore, 'spaces', space_id, "stats", "activity_stats")
    }
    batch.set(statsRef, {
        "active": activity_stats
    }, { merge: true });
    await batch.commit();
}