import { auth, firestore } from "../../../app/firebase";
import { doc } from "firebase/firestore";
import { useDocumentDataOnce } from "react-firehooks";

export default function useUserLangOnce() {
    const uid = auth.currentUser?.uid;

    let user_data_ref;
    if (uid) user_data_ref = doc(firestore, "users", uid);
    const [user_data, loading_user_data, error_path] = useDocumentDataOnce(user_data_ref);

    //User data
    //USER
    const user_name = user_data?.user?.name;
    const user_email = user_data?.user?.email;
    const user_image = user_data?.user?.user_image;

    //TODO_C: bring user language into REALTIMEDB
    const user_language = user_data?.config?.language;

    return {
        user_name, user_email, user_image,
        loading_user_data, user_language
    };
}
