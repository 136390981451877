import { doc } from "firebase/firestore";
import { useDocumentData } from "react-firehooks";
import { firestore } from "../../app/firebase";
import { getSpaceData } from "../../functions/DataGetterFunctions";

export default function useSpaceData(space_id, condition = true) {
    let spaceRef;
    if (space_id && condition) {
        spaceRef = doc(firestore, "spaces", space_id);
    }
    const [space, loading_spaceData, errorLoading_spaceData] = useDocumentData(spaceRef);
    const spaceData = getSpaceData(space);

    return {
        space,
        loading_spaceData,
        errorLoading_spaceData,
        //METADATA
        ...spaceData,
    };
}
