import { ref } from "firebase/database";
import { useObjectValue } from "react-firehooks";
import { auth, rlt_db } from "../../../../app/firebase";

export default function useUserConnections(space_id) {
    const uid = auth.currentUser?.uid;
    const [user_connections, loading_presence, error] = useObjectValue(
        ref(rlt_db, "user_presence/spaces/" + space_id + "/" + uid + "/my_connections")
    );
    const rtdb_connections = user_connections ? user_connections : {};
    const active_devices = Object.keys(rtdb_connections);
    const no_of_active_devices = active_devices?.length;
    return { active_devices, no_of_active_devices, loading_presence, rtdb_connections, user_connections };
}

export function getNActiveTabsPerDevice(device, rtdb_connections) {
    return Object.keys(rtdb_connections[device])?.length;
}
