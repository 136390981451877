import { pad } from "./HelperFunction";

const days = ["SUNDAY", "MONDAY", "TUESDAY", "WEDNESDAY", "THURSDAY", "FRIDAY", "SATURDAY"];
export const monthNames = ["JANUARY", "FEBRUARY", "MARCH", "APRIL", "MAY", "JUNE", "JULY", "AUGUST", "SEPTEMBER", "OCTOBER", "NOVEMBER", "DECEMBER"];

export function constructDateTimeObject(date) {
    const time = date?.getTime();
    const day = date?.getDate();
    const weekDay = days[date?.getDay()];
    const month = date?.getMonth() + 1;
    const year = date?.getFullYear();
    let hr = date?.getHours() ? date.getHours() : "00";
    const min = date?.getMinutes() ? pad(date.getMinutes()) : "00";
    let ampm = null;
    let dateDisplay;
    // if (lang === "en") {
    //     ampm = hr >= 12 ? "pm" : "am";
    //     hr = hr % 12;
    //     dateDisplay = pad(month) + "/" + pad(day) + "/" + year;
    // } else {
    dateDisplay = pad(day) + "/" + pad(month) + "/" + year;
    // }
    hr = pad(hr);
    const timeDisplay = hr + ":" + min;

    return { date, time, day, weekDay, month, year, hr, min, ampm, timeDisplay, dateDisplay };
}
export function getDateDescription(date, translate) {
    let msg = "";
    if (!date) return "";
    if (isToday(date.toDate())) {
        if (isWithinLastHour(date.toDate())) {
            msg = getMinutesDifference(date.toDate()) + " " + translate("MINUTES"); //minutes
        } else {
            msg = getHoursDifference(date.toDate()) + " " + translate("HOURS"); //hours
        }
    } else if (isDateThisMonth(date.toDate())) {
        msg = getDaysDifference(date.toDate()) + " " + translate("DAYS"); //days
    } else {
        msg = constructDateTimeObject(date.toDate())?.dateDisplay; //simply show date
    }
    return translate("RP_LAST_EDIT") + " " + msg;
}

function getMinutesDifference(date) {
    const now = new Date();
    const differenceInMilliseconds = Math.abs(now - date);
    return Math.floor(differenceInMilliseconds / 1000 / 60);
}
function getHoursDifference(date) {
    const now = new Date();
    const differenceInMilliseconds = Math.abs(now - date);
    return Math.floor(differenceInMilliseconds / 1000 / 60 / 60);
}
function getDaysDifference(date) {
    const now = new Date();
    const differenceInMilliseconds = Math.abs(now - date);
    return Math.floor(differenceInMilliseconds / 1000 / 60 / 60 / 24);
}



function isWithinLastHour(date) {
    const now = new Date();
    const oneHourAgo = new Date(now.getTime() - 1000 * 60 * 60);
    return date >= oneHourAgo;
}
export function isToday(date) {
    const today = new Date();
    return date?.toDateString() == today.toDateString();
}
function isTmrw(date) {
    const tmrw = new Date();
    tmrw.setDate(tmrw.getDate() + 1);
    return date?.toDateString() == tmrw.toDateString();
}
export function isSameDay(date1, date2) {
    return date1.getFullYear() === date2.getFullYear() && date1.getMonth() === date2.getMonth() && date1.getDate() === date2.getDate();
}
export function isYesterday(date) {
    const yesterday = new Date();
    yesterday.setDate(yesterday.getDate() - 1);
    return yesterday.toDateString() === date.toDateString();
    // if (typeof date === 'object' && date !== null && 'toDateString' in date){
    //     return date?.toDateString() == yesterday.toDateString();
    // }else return false
}

export function isDateInThisWeek(date) {
    // get the current date and time
    const now = new Date();
    // get the start of the current week
    const startOfWeek = new Date(now.getFullYear(), now.getMonth(), now.getDate() - now.getDay());

    // get the end of the current week
    const endOfWeek = new Date(now.getFullYear(), now.getMonth(), now.getDate() - now.getDay() + 6);

    // check if the given date is between the start and end of the current week
    return date >= startOfWeek && date <= endOfWeek;
}
function isDateWithinNext7Days(date) {
    // get the current date and time
    const now = new Date();
    // get the start of tomorrow
    const startOfTomorrow = new Date(now.getFullYear(), now.getMonth(), now.getDate() + 1);
    // get the end of next week
    const endOfNextWeek = new Date(now.getFullYear(), now.getMonth(), now.getDate() + 7);

    // check if the given date is between tomorrow and the end of next week
    return date >= startOfTomorrow && date <= endOfNextWeek;
}
function isDateWithinLast7Days(date) {
    const now = new Date();
    // get the start of the day 7 days ago
    const startOfLastWeek = new Date(now.getFullYear(), now.getMonth(), now.getDate() - 7);
    // get the end of yesterday
    const endOfYesterday = new Date(now.getFullYear(), now.getMonth(), now.getDate() - 1);

    // check if the given date is between last week and yesterday
    return date >= startOfLastWeek && date <= endOfYesterday;
}

export function isDateThisMonth(date) {
    const today = new Date();
    return date.getMonth() == today.getMonth() && date.getFullYear() == today.getFullYear();
}
function isDateThisYear(date) {
    const today = new Date();
    return date.getFullYear() == today.getFullYear();
}
function isDateNextYear(date) {
    const today = new Date();
    return date.getFullYear() == today.getFullYear() + 1;
}

function dateInFuture(date) {
    const today = new Date();
    if (date >= today) {
        return true;
    }
    return false;
}



export function getEventsSectionWise(events = []) {
    const today_events = [];
    const tmrw_events = [];
    const lastSeDays = [];
    const remainingPastEvents = [];
    const next7daysEvents = [];
    const currentMonthEvents = [];
    const this_year_events = [];
    const next_year_events = [];
    const remainingFutureEvents = [];

    events?.forEach((event) => {
        const start = event.typeData.start.toDate()
        if (isToday(start)) {
            today_events.push(event);
        } else if (!dateInFuture(start)) {
            if (isDateWithinLast7Days(start)) {
                lastSeDays.push(event);
            } else {
                remainingPastEvents.push(event);
            }
        } else if (isTmrw(start)) {
            tmrw_events.push(event);
        } else if (isDateWithinNext7Days(start)) {
            next7daysEvents.push(event);
        } else if (isDateThisMonth(start)) {
            currentMonthEvents.push(event);
        } else if (isDateThisYear(start)) {
            this_year_events.push(event);
        } else if (isDateNextYear(start)) {
            next_year_events.push(event);
        } else {
            remainingFutureEvents.push(event);
        }
    });
    return {
        today_events,
        tmrw_events,
        lastSeDays,
        remainingPastEvents,
        next7daysEvents,
        currentMonthEvents,
        this_year_events,
        next_year_events,
        remainingFutureEvents,
    };
}
