//ui
import classes from "./Dialog.module.css";

// type Props = Readonly<{
//     "data-test-id"?: string,
//     children: ReactNode,
// }>;

export function DialogButtonsList({ children }) {
    return <div className={classes.DialogButtonsList}>{children}</div>;
}

export function DialogActions({ "data-test-id": dataTestId, children }) {
    return (
        <div className={classes.DialogActions} data-test-id={dataTestId}>
            {children}
        </div>
    );
}
