import { arrayUnion, deleteField, doc, increment, Timestamp, updateDoc, writeBatch } from "firebase/firestore";
import { auth, firestore } from "../../../../app/firebase";
import { makeid } from "../../../Helpers/HelperFunction";




//PATH OVERVIEW
export async function updateResourcesOverviewBoolean(space_id, path_id, local_id, field, boolean) {
    const batch = writeBatch(firestore);
    console.log(path_id);
    const resourcesPathOverviewRef = doc(firestore, "spaces", space_id, "resources_overview_by_path_id", path_id);
    batch.update(resourcesPathOverviewRef, {
        ["resources." + local_id + ".localData." + field]: boolean
    })
    await batch.commit();
}
export async function updatePathOverviewModules(space_id, path_id, resourcesDown, resourcesUp, incr = 1) {
    const batch = writeBatch(firestore);
    const res_overviewRef = doc(firestore, "spaces", space_id, 'resources_overview_by_path_id', path_id);
    resourcesUp.forEach(local_id => {
        batch.update(res_overviewRef, {
            ["resources." + local_id + ".localData.module"]: increment(incr)
        })
    });
    resourcesDown.forEach(local_id => {
        batch.update(res_overviewRef, {
            ["resources." + local_id + ".localData.module"]: increment(-incr)
        })
    });
    await batch.commit();
}


export async function addResourceToOverview(space_id, path_id, added_resource_overview) {
    const batch = writeBatch(firestore);
    const resourcesPathOverviewRef = doc(firestore, "spaces", space_id, "resources_overview_by_path_id", path_id);
    batch.update(resourcesPathOverviewRef, {
        ["resources." + added_resource_overview.localData.local_id]: added_resource_overview
    })
    await batch.commit();
}
export async function removeResourceFromOverview(space_id, path_id, local_id) {
    const batch = writeBatch(firestore);
    const resourcesPathOverviewRef = doc(firestore, "spaces", space_id, "resources_overview_by_path_id", path_id);
    batch.update(resourcesPathOverviewRef, {
        ["resources." + local_id]: deleteField()
    })
    await batch.commit();
}

export async function rearrangeResourcesOverview(space_id, path_id, resourcesDown, resourcesUp) {
    const batch = writeBatch(firestore);
    const resourcesPathOverviewRef = doc(firestore, "spaces", space_id, "resources_overview_by_path_id", path_id);
    resourcesDown?.forEach(local_id => {
        console.log(local_id);
        batch.update(resourcesPathOverviewRef, {
            ["resources." + local_id + ".localData.position"]: increment(-1)
        })
    })
    resourcesUp?.forEach(local_id => {
        console.log(local_id);
        batch.update(resourcesPathOverviewRef, {
            ["resources." + local_id + ".localData.position"]: increment(1)
        })
    })
    await batch.commit();
}
export async function swapResourcesOverview(space_id, path_id, targeted_resource_id, targeted_new_position, effected_resource_id, effected_new_position) {
    const batch = writeBatch(firestore);
    const resourcesPathOverviewRef = doc(firestore, "spaces", space_id, "resources_overview_by_path_id", path_id);
    batch.update(resourcesPathOverviewRef, {
        ["resources." + targeted_resource_id + ".localData.position"]: targeted_new_position,
        ["resources." + effected_resource_id + ".localData.position"]: effected_new_position
    })
    await batch.commit();
}


export async function rearrangeResourcesOverviewModules(space_id, path_id, resourcesDown, resourcesUp, incr = 1) {
    const batch = writeBatch(firestore);
    const resourcesPathOverviewRef = doc(firestore, "spaces", space_id, "resources_overview_by_path_id", path_id);
    resourcesDown.forEach(local_id => {
        batch.update(resourcesPathOverviewRef, {
            ["resources." + local_id + ".localData.module"]: increment(-incr)
        })
    })
    resourcesUp.forEach(local_id => {
        batch.update(resourcesPathOverviewRef, {
            ["resources." + local_id + ".localData.module"]: increment(incr)
        })
    })
    await batch.commit();
}


export async function rearrangePathResources(space_id, path_id, resourcesDown, resourcesUp) {
    const batch = writeBatch(firestore);
    resourcesDown?.forEach(local_id => {
        const resourcePathRef = doc(firestore, "resources", local_id);
        batch.update(resourcePathRef, {
            "localData.position": increment(-1)
        })
    })
    resourcesUp?.forEach(local_id => {
        const resourcePathRef = doc(firestore, "resources", local_id);
        batch.update(resourcePathRef, {
            "localData.position": increment(1)
        })
    })
    await batch.commit();
}

//GLOBAL Resources
export async function createGlobalResource(new_resource, globalData, global_id) {
    const batch = writeBatch(firestore);
    //(1) create global resource
    const resourcesRef = doc(firestore, "globalResources", global_id);
    batch.set(resourcesRef, { metaData: new_resource.metaData, typeData: new_resource.typeData, globalData });
    //(2) repository to save all "local"-tasks of resource
    const resourcesTasksRef = doc(firestore, "globalResources", new_resource.localData.global_id, "meta", "tasks");
    batch.set(resourcesTasksRef, { tasks: [] });
    await batch.commit();
}
export async function updateGlobalResource(global_id, metaData, typeData) {
    const batch = writeBatch(firestore);
    const resourcesRef = doc(firestore, "globalResources", global_id);
    batch.update(resourcesRef,
        { "metaData": metaData, "typeData": typeData }
    );
    await batch.commit();
}
export async function deleteResourceGlobally(global_id) {
    const batch = writeBatch(firestore);
    const resourcesRef = doc(firestore, "globalResources", global_id);
    batch.delete(resourcesRef);
    await batch.commit();
}


//


//RICH TEXT
export async function updateRichText(space_id, path_id, local_id, richtext) {
    const batch = writeBatch(firestore);
    const ref = doc(firestore, "resources", local_id);
    batch.update(ref, {
        ["typeData.richtext"]: richtext,
        ["metaData.date_updated"]: Timestamp.fromDate(new Date())
    })
    await batch.commit()
}


export async function saveRichTextThumbnail(space_id, path_id, local_id, url, file_name, height, width) {
    if (local_id) {
        const batch = writeBatch(firestore);
        const resourcesPathOverviewRef = doc(firestore, "resources", local_id);
        console.log({ src: url, file_name, height, width, selected: true });

        batch.update(resourcesPathOverviewRef, {
            ["typeData.images"]: arrayUnion({ src: url, file_name, height, width, selected: true })
        })
        await batch.commit();
    }
}
export async function selectRichTextThumbnail(space_id, path_id, local_id, image_links) {
    if (local_id) {
        console.log(image_links);
        const batch = writeBatch(firestore);
        const resourcesPathOverviewRef = doc(firestore, "resources", local_id);
        batch.update(resourcesPathOverviewRef, {
            ["typeData.images"]: image_links
        })
        await batch.commit();
    }
}
export async function removeRichTextThumbnail(space_id, path_id, local_id, image_links) {
    if (local_id && image_links) {
        const batch = writeBatch(firestore);
        const resourcesPathOverviewRef = doc(firestore, "resources", local_id);
        batch.update(resourcesPathOverviewRef, {
            ["typeData.images"]: image_links
        })
        await batch.commit();
    }
}

export async function saveNewRichTextVersion(space_id, path_id, local_id, richtext) {
    const uid = auth.currentUser?.uid;
    console.log(local_id);
    const batch = writeBatch(firestore);
    const version_id = makeid(8);
    const richTextVersionRef = doc(firestore, "resources", local_id, "versions", version_id);
    const version_data = {
        metaData: {
            date_created: Timestamp.fromDate(new Date())
        },
        typeData: {
            richtext
        }
    }
    batch.set(richTextVersionRef, version_data)
    const richTextVersionsRef = doc(firestore, "resources", local_id, "richtext_stats", "versions");
    const versions = {
        [version_id]: {
            version_id,
            date_created: Timestamp.fromDate(new Date()),
            user_id: uid
        }
    }
    batch.set(richTextVersionsRef, { versions }, { merge: true })
    await batch.commit();
}

export async function restoreVersion(space_id, path_id, local_id, richtext) {
    const richTextVersionsRef = doc(firestore, "resources", local_id);
    await updateDoc(richTextVersionsRef, {
        ["metaData.date_updated"]: Timestamp.fromDate(new Date()),
        ["typeData.richtext"]: richtext
    })
}

