import { collection, limit, orderBy, query, where } from "firebase/firestore";
import { useCollectionData } from "react-firehooks";
import { useSpaceContext } from "../../../../contexts/SpaceContext/SpaceContext";
import { firestore } from "../../../../app/firebase";
import { useAuth } from "../../../../contexts/AuthContext";

export default function useGroupMessages(last_online_date, loading_presence) {
    const { space_id } = useSpaceContext();
    const { groupsOfSpace, isCuratorOfSpace, isPathStudentOfSpace } = useAuth();

    let groupsMessagesRef, groupsMessagesQuery;
    if (groupsOfSpace && last_online_date && !loading_presence) {
        groupsMessagesRef = collection(firestore, "spaces", space_id, "messages");
        if (groupsOfSpace?.length > 10 || isCuratorOfSpace) { //TODO if not isCuratorOfSpace but > 10 filter after loading
            groupsMessagesQuery = query(groupsMessagesRef,
                where("meta.channel_type", "==", "GROUP"),
                where("meta.created_at", ">", last_online_date),
                orderBy("meta.created_at", "desc"),
                limit(10)
            );  
        } else if (groupsOfSpace?.length > 0) {
            groupsMessagesQuery = query(groupsMessagesRef,
                where("meta.channel_type", "==", "GROUP"),
                where("meta.created_at", ">", last_online_date),
                where("meta.path_id", "in", groupsOfSpace),
                orderBy("meta.created_at", "desc"),
                limit(10)
            );  
    }        
    }
    const [groupsMessages1, loading_groups_messages, error_messages] = useCollectionData(groupsMessagesQuery);
    const groupsMessages = (!isCuratorOfSpace && groupsOfSpace?.length > 10) ? groupsMessages1?.filter(m => !groupsOfSpace?.includes(m?.res?.group_id)) : groupsMessages1;
    return { groupsMessages, loading_groups_messages };
}
