import { doc } from "firebase/firestore";
import { useDocumentData } from "react-firehooks";
import { firestore } from "../../app/firebase";
import { getSpaceSalesData } from "../../functions/DataGetterFunctions";

export default function useSpaceSalesData(space_id, condition = true) {
    let spaceRef;
    if (space_id && condition) {
        spaceRef = doc(firestore, "spaces", space_id, "section", "sales");
    }
    const [space_sales, loading_spaceSalesData, errorLoading_spaceSalesData] = useDocumentData(spaceRef);
    const { spc_products, spc_customer_portal_link, spc_product_upgrade, spc_product_pq,
        spc_product_bundle, spc_promo_code } = getSpaceSalesData(space_sales)

    return {
        spc_products, spc_product_upgrade, spc_customer_portal_link, space_sales, loading_spaceSalesData, errorLoading_spaceSalesData,
        spc_product_pq, spc_product_bundle, spc_promo_code
    };
}
