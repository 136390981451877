//ui
import classes from "./SpaceLoader.module.css";

//context
import { useSpaceContext } from "../../../contexts/SpaceContext/SpaceContext";

export default function SpaceLoader() {
    const { spc_icon, mainNav_backgroundColor } = useSpaceContext();
    return (
        <div className={classes.SpaceLoader} style={{ backgroundColor: mainNav_backgroundColor }}>
            <div className={classes.SpaceIcon} style={{ backgroundImage: `url(${spc_icon})` }} />
        </div>
    );
}
