import { TextNode, type Klass, type LexicalNode } from "lexical";
import { CodeHighlightNode, CodeNode } from "@lexical/code";
import { HashtagNode } from "@lexical/hashtag";
import { AutoLinkNode, LinkNode } from "@lexical/link";
import { ListItemNode, ListNode } from "@lexical/list";
import { MarkNode } from "@lexical/mark";
import { OverflowNode } from "@lexical/overflow";
import { HorizontalRuleNode } from "@lexical/react/LexicalHorizontalRuleNode";
import { HeadingNode, QuoteNode } from "@lexical/rich-text";
import { TableNode, TableRowNode, TableCellNode } from "@lexical/table";

//** Custom Nodes ** //
import { CollapsibleContainerNode } from "../../plugins/CollapsiblePlugin/CollapsibleContainerNode";
import { CollapsibleContentNode } from "../../plugins/CollapsiblePlugin/CollapsibleContentNode";
import { CollapsibleTitleNode } from "../../plugins/CollapsiblePlugin/CollapsibleTitleNode";
// import { AutocompleteNode } from "../../nodes/AutocompleteNode";

import { EmojiNode } from "../../nodes/EmojiNode";
import { EquationNode } from "../../nodes/EquationNode";
// import { ExcalidrawNode } from "../../nodes/ExcalidrawNode";
import { FigmaNode } from "../../nodes/FigmaNode";
import { ImageNode } from "../../nodes/ImageNode";
import { KeywordNode } from "../../nodes/KeywordNode";
import { MentionNode } from "../../nodes/MentionNode";
import { PollNode } from "../../nodes/PollNode";
// import { StickyNode } from "../../nodes/StickyNode";
// import { TableNode as NewTableNode } from "../../nodes/TableNode";
import { TweetNode } from "../../nodes/TweetNode";
import { YouTubeNode } from "../../nodes/YouTubeNode";
import { VimeoVideoNode } from "../../nodes/VimeoVideoNode";
import { ExtendedTextNode } from "../../nodes/ExtendedTextNode";
import { InlineImageNode } from "../../nodes/InLineImageNode";
import { TableCellNode as CustomTableCellNode } from "../../nodes/ClassicTableNodes/TableCellNode";

const LexicalMaxNodes: Array<Klass<LexicalNode>> = [
    HeadingNode,
    ListNode,
    ListItemNode,
    QuoteNode,
    CodeNode,
    TableNode,
    TableCellNode,
    // CustomTableCellNode,
    // {
    //     replace: TableCellNode,
    //     with: (node: TableCellNode) => {
    //         return new CustomTableCellNode();
    //     },
    // },
    TableRowNode,
    HashtagNode,
    CodeHighlightNode,
    AutoLinkNode,
    LinkNode,
    {
        replace: LinkNode,
        with: (node: LinkNode) => {
            node.setTarget("_blank");
            return new LinkNode(node.getURL(), { target: node.getTarget() }, undefined);
        },
    },
    ExtendedTextNode,
    { replace: TextNode, with: (node: TextNode) => new ExtendedTextNode(node.__text, node.__key) },
    OverflowNode,
    PollNode,
    // StickyNode,
    ImageNode,
    InlineImageNode,
    MentionNode,
    EmojiNode,
    // ExcalidrawNode, //this is cool
    EquationNode,
    // AutocompleteNode,
    KeywordNode,
    HorizontalRuleNode,
    TweetNode,
    YouTubeNode,
    FigmaNode,
    MarkNode,
    CollapsibleContainerNode,
    CollapsibleContentNode,
    CollapsibleTitleNode,
    VimeoVideoNode,
    // NewTableNode,
];

export default LexicalMaxNodes;
