import classes from "./LexicalModalHeader.module.css";
import { CrossMark } from "../../../../assets/icons/Icons";

import Button from "../../../ComponentLibrary/Button/Button";
import joinClasses from "../../../../utils/joinClasses";

export default function LexicalModalHeader({ title, onClose, noLine = false }) {
    return (
        <div className={joinClasses(classes.LexicalModalHeader, noLine && classes.NoLine)}>
            <div className={classes.Title}>{title}</div>
            <Button
                buttonClass={classes.CloseBtn}
                iconOnly={true}
                variant={"text"}
                color={"cancel"}
                startIcon={<CrossMark size={"14px"} />}
                onClick={() => onClose()}
            />
        </div>
    );
}
