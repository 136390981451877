import { doc } from "firebase/firestore";
import { useEffect, useState } from "react";
import { useDocumentData } from "react-firehooks";
import { firestore } from "../../app/firebase";
import { useSpaceContext } from "../../contexts/SpaceContext/SpaceContext";


export default function useSpaceTags(lang) {
    const { space_id } = useSpaceContext();

    let spaceRef
    if (lang && space_id) {
        spaceRef = doc(firestore, "spaces", space_id, "tags", lang);
    }
    const [tags_per_path, loading_spaceData, error_spaceData] = useDocumentData(spaceRef);
    const [space_tags_of_all_paths, setspace_tags_of_all_paths] = useState({})
    useEffect(() => {
        if (tags_per_path) {
            const tagsperpath = tags_per_path?.tags;
            let space_tags_of_all_paths = {}
            Object.values(tagsperpath).forEach(tags => {
                space_tags_of_all_paths = { ...space_tags_of_all_paths, ...tags }
            });
            setspace_tags_of_all_paths(space_tags_of_all_paths)
        }
    }, [tags_per_path])

    return { space_tags: space_tags_of_all_paths, tags_per_path: tags_per_path?.tags }
}
