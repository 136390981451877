import { getMultFaktor } from "../../../functions/AppHelperFunctions";

const VERTICAL_GAP = 6;
const HORIZONTAL_OFFSET = 4;

export function setFloatingElemPosition(
    targetRect: ClientRect | null,
    floatingElem: HTMLElement,
    anchorElem: HTMLElement,
    verticalGap: number = VERTICAL_GAP,
    horizontalOffset: number = HORIZONTAL_OFFSET
): void {
    const scrollerElem = anchorElem.parentElement;

    if (targetRect === null || !scrollerElem) {
        floatingElem.style.opacity = "0";
        floatingElem.style.transform = "translate(-10000px, -10000px)";
        return;
    }

    const multFaktor = getMultFaktor();
    const floatingElemRect = floatingElem.getBoundingClientRect();
    const anchorElementRect = anchorElem.getBoundingClientRect();
    const editorScrollerRect = scrollerElem.getBoundingClientRect();

    let top = targetRect.top * multFaktor - floatingElemRect.height * multFaktor - verticalGap;
    let left = targetRect.left * multFaktor - horizontalOffset;

    if (top < editorScrollerRect.top * multFaktor) {
        top += floatingElemRect.height * multFaktor + targetRect.height * multFaktor + verticalGap * 2;
    }

    if (left + floatingElemRect.width * multFaktor > editorScrollerRect.right * multFaktor) {
        left = editorScrollerRect.right * multFaktor - floatingElemRect.width * multFaktor - horizontalOffset;
    }

    top -= anchorElementRect.top * multFaktor;
    left -= anchorElementRect.left * multFaktor;

    floatingElem.style.opacity = "1";
    floatingElem.style.transform = `translate(${left}px, ${top}px)`;
}
