import { collection, orderBy, query, where } from "firebase/firestore";
import { useCollectionData } from "react-firehooks";
import { firestore } from "../../../../app/firebase";

import { useSpaceContext } from "../../../../contexts/SpaceContext/SpaceContext";
import { useAuth } from "../../../../contexts/AuthContext";


export default function useUserGroupsData(path_id, condition = true) {
    const { groupsOfSpace, isCuratorOfSpace, isPathCuratorOfSpace, isPathTeacherOfSpace  } = useAuth();
    const { space_id } = useSpaceContext();

    const condition2 = isCuratorOfSpace || isPathCuratorOfSpace

    let groupsQuery, groupsRef, groupsQuery_2, groupsQuery_3;
    if (condition) {
        groupsRef = collection(firestore, "spaces", space_id, "groups");
        //ROLE
        if (condition2) { //curatorOfSpace of inside path overview load all (path)groups on click
            if (path_id) {
                groupsQuery = query(
                    groupsRef,
                    where("content.path_ids", "array-contains", path_id),
                    where("metaData.status", "==", "active"),
                    orderBy("metaData.name", "asc")
                );
            } else {
                groupsQuery = query(
                    groupsRef,
                    where("metaData.status", "==", "active"),
                    orderBy("metaData.name", "asc")
                );
            }
        } else if (groupsOfSpace?.length > 0) {
            //TODO_C: load by every 10
            if (path_id) {
                groupsQuery = query(
                    groupsRef,
                    where("content.path_ids", "array-contains", path_id),
                    where("metaData.id", "in", groupsOfSpace.slice(0, 10)), //max of 10!
                    where("metaData.status", "==", "active"),
                    orderBy("metaData.name", "asc")
                );
            } else {
                groupsQuery = query(
                    groupsRef,
                    where("metaData.id", "in", groupsOfSpace.slice(0, 10)), //max of 10!
                    where("metaData.status", "==", "active"),
                    orderBy("metaData.name", "asc")
                );
            }
            if (groupsOfSpace?.length > 10) {
                if (path_id) {
                    groupsQuery_2 = query(
                        groupsRef,
                        where("content.path_ids", "array-contains", path_id),
                        where("metaData.id", "in", groupsOfSpace.slice(10, 20)), //max of 10!
                        where("metaData.status", "==", "active"),
                        orderBy("metaData.name", "asc")
                    );
                } else {
                    groupsQuery_2 = query(
                        groupsRef,
                        where("metaData.id", "in", groupsOfSpace.slice(10, 20)), //max of 10!
                        where("metaData.status", "==", "active"),
                        orderBy("metaData.name", "asc")
                    );
                }
            }
            if (groupsOfSpace?.length > 20) {
                if (path_id) {
                    groupsQuery_3 = query(
                        groupsRef,
                        where("content.path_ids", "array-contains", path_id),
                        where("metaData.id", "in", groupsOfSpace.slice(20, 30)), //max of 10!
                        where("metaData.status", "==", "active"),
                        orderBy("metaData.name", "asc")
                    );
                } else {
                    groupsQuery_3 = query(
                        groupsRef,
                        where("metaData.id", "in", groupsOfSpace.slice(20, 30)), //max of 10!
                        where("metaData.status", "==", "active"),
                        orderBy("metaData.name", "asc")
                    );
                }
            }
        }
    }

    const [groups_1, loading_groups, error_groups] = useCollectionData(groupsQuery);

    const [groups_2, loading_groups_2, error_groups_2] = useCollectionData(groupsQuery_2);
    const [groups_3, loading_groups_3, error_groups_3] = useCollectionData(groupsQuery_3);

    const groups = groups_2?.length > 0 ? groups_3?.length > 0 ? [...groups_1, ...groups_2, ...groups_3] : [...groups_1, ...groups_2] : groups_1;

    return { groups, loading_groups, error_groups }
}