import { createContext, useContext, useEffect, useState } from "react";

import useLocalStorage from "../customHooks/use-local-storage_TBC";
import { updateLanguage } from "../components/Helpers/FirestoreFunctions";
import useSpaceTags from "../hooks/spaceHooks/useSpaceTags";
import useUserLangOnce from "../pages/Chat/hooks/useUserLangOnce";
import { useUpdateEffect } from "react-use";

// the translations
const available_languages = ["de", "en", "cs"];

export const LanguageContext = createContext({
    lang: "",
    setLanguage: () => void 0,
    translate: void 0,
    hasTranslation: () => void 0,
    translateTags: void 0,
    space_tags: {},
    tags_per_path: {},
    hasTag: () => void 0,
    languageDB: null,
});

export function useLanguage() {
    return useContext(LanguageContext);
}

// TRANSLATION COPY FROM SHEET PROCESS
// (1) COPY THE TAG & THE LANG COLUMNS INTO NEW SHEET (VALUES ONLY)
// (2) SET FILTER ON FIRST COLUMN -> CLEAR -> SELECT BLANKS -> DELETE ROWS
// (3) ANOTHER FILTER: TEXT CONTAINS "===" -> DELETE ROWS
// (4) REMOVE FILTER
// (5) PASTE TRANSPOSED & DOWNLOAD CSV
// (6) UPLOAD CSV TO JSON TRANSFORMER https://www.convertcsv.com/csv-to-json.htm

//provider
export function LanguageProvider({ children, spc_lang }) {
    const { user_language, loading_user_data } = useUserLangOnce();

    //GET LANG
    const getBrowserLanguage = () => {
        const browserLanguageCode = ((navigator.languages && navigator.languages[0]) || navigator.language || navigator.userLanguage).toLowerCase();
        let browser_language = browserLanguageCode;
        //from de-de -> de
        if (browserLanguageCode !== "zh-tw" && browserLanguageCode.indexOf("-") !== -1) {
            const browserLanguageArray = browserLanguageCode.split("-");
            browser_language = browserLanguageArray[0];
        }
        //if the translations are not available, default to en
        return available_languages.includes(browser_language) ? browser_language : "en";
    };

    const [local_lang, setLocalLanguage] = useLocalStorage("radix_lang", "");
    const [lang, setlang] = useState("");
    const [languageDB, setlanguageDB] = useState(null);

    useEffect(() => {
        if (!loading_user_data) {
            let lang;
            if ((local_lang && available_languages.includes(local_lang)) || user_language) {
                //(1)User
                lang = local_lang ? local_lang : user_language;
            } else if (spc_lang) {
                //(2)Space
                lang = spc_lang;
            } else {
                //(3) Browser
                lang = getBrowserLanguage();
            }
            if (lang) {
                setlang(lang);
                importLanguagePackage(lang);
            }
        }
    }, [spc_lang, loading_user_data, local_lang, user_language]);

    useUpdateEffect(() => {
        if (lang) {
            importLanguagePackage(lang);
        }
    }, [lang]);

    //SETTERS
    function setLanguage(language = "") {
        if (typeof language === "string") {
            updateLanguage(language);
            setLocalLanguage(language);
        }
    }
    async function importLanguagePackage(lang) {
        const language_package = await import("../assets/localization/" + lang + "/translation" + lang.toUpperCase() + ".json");
        setlanguageDB(language_package);
    }
    const { space_tags, tags_per_path } = useSpaceTags(lang);

    //TRANSLATIONS
    function hasTranslation(str) {
        if (available_languages.includes(lang)) {
            const languageDb = languageDB;
            return languageDb?.words.hasOwnProperty(str);
        } else {
            const languageDb = languageDB;
            return languageDb?.words.hasOwnProperty(str);
        }
    }

    function translate(str) {
        if (available_languages.includes(lang)) {
            const languageDb = languageDB;
            if (languageDb?.words.hasOwnProperty(str)) {
                return languageDb?.words[str];
            }
            return str;
        } else {
            const languageDb = languageDB;
            if (languageDb?.words.hasOwnProperty(str)) {
                return languageDb?.words[str];
            }
            return str;
        }
    }

    const translateTags = (str) => {
        if (available_languages.includes(lang)) {
            if (space_tags?.hasOwnProperty(str)) {
                return space_tags[str];
            }
            return str;
        } else {
            if (space_tags?.hasOwnProperty(str)) {
                return space_tags[str];
            }
            return str;
        }
    };
    function hasTag(str) {
        return space_tags?.hasOwnProperty(str);
    }

    const value = {
        lang,
        setLanguage,
        translate,
        hasTranslation,
        translateTags,
        space_tags,
        tags_per_path,
        hasTag,
        languageDB,
    };

    //use context insider the provider
    return <LanguageContext.Provider value={value}>{children}</LanguageContext.Provider>;
}
