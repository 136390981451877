import { FORMAT_TEXT_COMMAND } from "lexical";

import classes from "./FontFormatDropdown.module.css";
import joinClasses from "../../../../utils/joinClasses";

import DropDown, { DropDownItem } from "../LexicalDropDown/LexicalDropDown";
import { useLanguage } from "../../../../contexts/LanguageContext";

export default function FontFormatDropdown({ isStrikethrough, isSubscript, isSuperscript, editor, disabled = false }) {
    const { translate } = useLanguage();
    return (
        <DropDown
            disabled={disabled}
            buttonClassName={classes.BlockControl}
            buttonIconClassName={joinClasses(classes.BlockTypeIcon, classes.DropdownMore)}
            buttonLabel={null}
            buttonAriaLabel="Formatting options for additional text styles"
        >
            <DropDownItem
                onClick={() => {
                    editor.dispatchCommand(FORMAT_TEXT_COMMAND, "strikethrough");
                }}
                className={joinClasses(classes.DropDownItem, isStrikethrough && classes.Active)}
                title="Strikethrough"
                aria-label="Format text with a strikethrough"
            >
                <i className={joinClasses(classes.Icon, classes.Strikethrough)} />
                <span className={classes.Text}>{translate("Strikethrough")}</span>
            </DropDownItem>
            <DropDownItem
                onClick={() => {
                    editor.dispatchCommand(FORMAT_TEXT_COMMAND, "subscript");
                }}
                className={joinClasses(classes.DropDownItem, isSubscript && classes.Active)}
                title="Subscript"
                aria-label="Format text with a subscript"
            >
                <i className={joinClasses(classes.Icon, classes.Sub)} />
                <span className={classes.Text}>{translate("Subscript")}</span>
            </DropDownItem>
            <DropDownItem
                onClick={() => {
                    editor.dispatchCommand(FORMAT_TEXT_COMMAND, "superscript");
                }}
                className={joinClasses(classes.DropDownItem, isSuperscript && classes.Active)}
                title="Superscript"
                aria-label="Format text with a superscript"
            >
                <i className={joinClasses(classes.Icon, classes.Super)} />
                <span className={classes.Text}>{translate("Superscript")}</span>
            </DropDownItem>
        </DropDown>
    );
}
