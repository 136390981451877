import { useCallback, useEffect, useState } from "react";
import { useUpdateEffect } from "react-use";
import { dynamicSort2, filterResourcesForEventsByGroupID } from "../../../../../components/Helpers/HelperFunction";

import { useAuth } from "../../../../../contexts/AuthContext";
import { getEventsSectionWise } from "../../../../../components/Helpers/DatetimeHelperfunctions";

export default function useDashboardEventsNew(user_path_overviews, loading_path_overviews, my_group_overview_res=[], loading_group_overs) {
    const { user_id, only_teacher, isPathStudentOfSpace } = useAuth();


    const [loadingEvents, setloadingEvents] = useState(true);
    const [dashboard_events, setdashboard_events] = useState([]);
    const [sorted_dashboard_events, setsorted_dashboard_events] = useState([]);
    //(1)
    useEffect(() => {
        if (!loading_path_overviews && !loading_group_overs) {
            setloadingEvents(true) //PROCESSING STARTS HERE
            handleGetEvents()
        }
    }, [my_group_overview_res, user_path_overviews]);

    const handleGetEvents = useCallback(() => {
        const all_events = [];
        my_group_overview_res?.forEach(resource => {
            all_events.push(resource);
        });
        user_path_overviews?.forEach((data) => {
            const path_overview = data?.resources || {};
            const path_overview_res = Object.values(path_overview) ||[];
            if (path_overview_res?.length > 0) {
                const path_overview_res_filtered = filterResourcesForEventsByGroupID(path_overview_res, [])
                all_events.push(...path_overview_res_filtered);
            }
        });
        setdashboard_events(all_events);
    }, [my_group_overview_res, user_path_overviews]);

    const has_events = dashboard_events?.length > 0;
    useUpdateEffect(() => {
        if (has_events) {
            if (only_teacher && !isPathStudentOfSpace) {
                setsorted_dashboard_events(dashboard_events?.filter(ev => ev.typeData?.speakers?.includes(user_id)).sort(dynamicSort2("typeData", "start", false)))
            } else {
                setsorted_dashboard_events(dashboard_events.sort(dynamicSort2("typeData", "start", false)))
            }
        }
        setloadingEvents(false) //PROCESSING ENDS HERE
    }, [dashboard_events, only_teacher]);

    const { today_events, tmrw_events,
        lastSeDays, remainingPastEvents,
        next7daysEvents, currentMonthEvents, this_year_events, next_year_events, remainingFutureEvents
    } = getEventsSectionWise(sorted_dashboard_events)
    const noCurrentEvents = today_events?.length === 0 && tmrw_events?.length === 0 && next7daysEvents?.length === 0 && currentMonthEvents?.length === 0;
    const no_this_year_events = this_year_events?.length === 0;
    const no_next_year_events = next_year_events?.length === 0;
    const have_future_events = !no_next_year_events || !no_this_year_events || !noCurrentEvents;
    const have_past_events = remainingPastEvents?.length !== 0 || lastSeDays?.length !== 0;

    return {
        loadingEvents, dashboard_events, sorted_dashboard_events,
        today_events, tmrw_events,
        lastSeDays, remainingPastEvents,
        next7daysEvents, currentMonthEvents, this_year_events, next_year_events, remainingFutureEvents,
        have_future_events, have_past_events, noCurrentEvents, no_this_year_events, no_next_year_events, has_events

    };
}

export const dummyEvents = [
    {
        metaData: {
            title: "---------",
        },
        typeData: {
        },
        localData: {
            local_id: "1",
        },
    },
    {
        metaData: {
            title: "---------",
        },
        typeData: {
        },
        localData: {
            local_id: "2",
        },
    },
    {
        metaData: {
            title: "---------",
        },
        typeData: {
        },
        localData: {
            local_id: "3",
        },
    },
    {
        metaData: {
            title: "---------",
        },
        typeData: {
        },
        localData: {
            local_id: "4",
        },
    },
    {
        metaData: {
            title: "---------",
        },
        typeData: {
        },
        localData: {
            local_id: "5",
        },
    },
    {
        metaData: {
            title: "---------",
        },
        typeData: {
        },
        localData: {
            local_id: "6",
        },
    },
    {
        metaData: {
            title: "---------",
        },
        typeData: {
        },
        localData: {
            local_id: "7",
        },
    },
    {
        metaData: {
            title: "---------",
        },
        typeData: {
        },
        localData: {
            local_id: "8",
        },
    },
    {
        metaData: {
            title: "---------",
        },
        typeData: {
        },
        localData: {
            local_id: "9",
        },
    }
];
