import "./LexicalMaxTheme.css";

const LexicalMaxTheme = {
    characterLimit: "LexicalMaxTheme__characterLimit",
    code: "LexicalMaxTheme__code",
    codeHighlight: {
        atrule: "LexicalMaxTheme__tokenAttr",
        attr: "LexicalMaxTheme__tokenAttr",
        boolean: "LexicalMaxTheme__tokenProperty",
        builtin: "LexicalMaxTheme__tokenSelector",
        cdata: "LexicalMaxTheme__tokenComment",
        char: "LexicalMaxTheme__tokenSelector",
        class: "LexicalMaxTheme__tokenFunction",
        "class-name": "LexicalMaxTheme__tokenFunction",
        comment: "LexicalMaxTheme__tokenComment",
        constant: "LexicalMaxTheme__tokenProperty",
        deleted: "LexicalMaxTheme__tokenProperty",
        doctype: "LexicalMaxTheme__tokenComment",
        entity: "LexicalMaxTheme__tokenOperator",
        function: "LexicalMaxTheme__tokenFunction",
        important: "LexicalMaxTheme__tokenVariable",
        inserted: "LexicalMaxTheme__tokenSelector",
        keyword: "LexicalMaxTheme__tokenAttr",
        namespace: "LexicalMaxTheme__tokenVariable",
        number: "LexicalMaxTheme__tokenProperty",
        operator: "LexicalMaxTheme__tokenOperator",
        prolog: "LexicalMaxTheme__tokenComment",
        property: "LexicalMaxTheme__tokenProperty",
        punctuation: "LexicalMaxTheme__tokenPunctuation",
        regex: "LexicalMaxTheme__tokenVariable",
        selector: "LexicalMaxTheme__tokenSelector",
        string: "LexicalMaxTheme__tokenSelector",
        symbol: "LexicalMaxTheme__tokenProperty",
        tag: "LexicalMaxTheme__tokenProperty",
        url: "LexicalMaxTheme__tokenOperator",
        variable: "LexicalMaxTheme__tokenVariable",
    },
    embedBlock: {
        base: "LexicalMaxTheme__embedBlock",
        focus: "LexicalMaxTheme__embedBlockFocus",
    },
    hashtag: "LexicalMaxTheme__hashtag",
    heading: {
        h1: "LexicalMaxTheme__h1",
        h2: "LexicalMaxTheme__h2",
        h3: "LexicalMaxTheme__h3",
        h4: "LexicalMaxTheme__h4",
        h5: "LexicalMaxTheme__h5",
        h6: "LexicalMaxTheme__h6",
    },
    indent: "LexicalMaxTheme__indent",
    image: "LexicalMaxTheme__editor-image",
    link: "LexicalMaxTheme__link",
    list: {
        listitem: "LexicalMaxTheme__listItem",
        listitemChecked: "LexicalMaxTheme__listItemChecked",
        listitemUnchecked: "LexicalMaxTheme__listItemUnchecked",
        nested: {
            listitem: "LexicalMaxTheme__nestedListItem",
        },
        olDepth: ["LexicalMaxTheme__ol1", "LexicalMaxTheme__ol2", "LexicalMaxTheme__ol3", "LexicalMaxTheme__ol4", "LexicalMaxTheme__ol5"],
        ul: "LexicalMaxTheme__ul",
    },
    ltr: "LexicalMaxTheme__ltr",
    mark: "LexicalMaxTheme__mark",
    markOverlap: "LexicalMaxTheme__markOverlap",
    paragraph: "LexicalMaxTheme__paragraph",
    quote: "LexicalMaxTheme__quote",
    rtl: "LexicalMaxTheme__rtl",
    table: "LexicalMaxTheme__table",
    tableAddColumns: "LexicalMaxTheme__tableAddColumns",
    tableAddRows: "LexicalMaxTheme__tableAddRows",
    tableCell: "LexicalMaxTheme__tableCell",
    tableCellActionButton: "LexicalMaxTheme__tableCellActionButton",
    tableCellActionButtonContainer: "LexicalMaxTheme__tableCellActionButtonContainer",
    tableCellEditing: "LexicalMaxTheme__tableCellEditing",
    tableCellHeader: "LexicalMaxTheme__tableCellHeader",
    tableCellPrimarySelected: "LexicalMaxTheme__tableCellPrimarySelected",
    tableCellResizer: "LexicalMaxTheme__tableCellResizer",
    tableCellSelected: "LexicalMaxTheme__tableCellSelected",
    tableCellSortedIndicator: "LexicalMaxTheme__tableCellSortedIndicator",
    tableResizeRuler: "LexicalMaxTheme__tableCellResizeRuler",
    tableSelected: "LexicalMaxTheme__tableSelected",
    text: {
        bold: "LexicalMaxTheme__textBold",
        code: "LexicalMaxTheme__textCode",
        italic: "LexicalMaxTheme__textItalic",
        strikethrough: "LexicalMaxTheme__textStrikethrough",
        subscript: "LexicalMaxTheme__textSubscript",
        superscript: "LexicalMaxTheme__textSuperscript",
        underline: "LexicalMaxTheme__textUnderline",
        underlineStrikethrough: "LexicalMaxTheme__textUnderlineStrikethrough",
    },
    inlineImage: "LexicalMaxTheme__inline-editor-image",
};

export default LexicalMaxTheme;
