import classes from "./EditButton.module.css";
import { useState } from "react";
import { Add, CheckmarkSqrd, CrossMark, Move, Repeat, PenDown, Image, ChevronSimpleRounded, DeleteTrash } from "../../../assets/icons/Icons";
import PropTypes from "prop-types";

export default function EditButton({
    type,
    customIcon = null,
    size = null,
    style,
    className = null,
    onClick,
    disabled = false,
    id = null,
    children = null,
}) {
    const [hovered, setHovered] = useState(false);
    let color = disabled ? "#a5a5a5" : hovered ? "#ffffff" : "#0C75CD";

    let Icon, customClass;
    switch (type) {
        case "edit":
            Icon = <PenDown size="60%" color={color} />;
            break;
        case "add":
            Icon = <Add size="60%" color={color} />;
            break;
        case "done":
            Icon = <CheckmarkSqrd size="100%" color={color} />;
            break;
        case "up":
            Icon = <ChevronSimpleRounded className={classes.UpIcon} size="58%" color={color} />;
            break;
        case "down":
            Icon = <ChevronSimpleRounded className={classes.DownIcon} size="58%" color={color} />;
            break;
        case "delete":
            Icon = <CrossMark size="60%" color={color} />;
            break;
        case "trash":
            customClass = classes.TrashBtn;
            Icon = <DeleteTrash size="70%" />;
            break;
        case "clipboard":
            Icon = <Move size="60%" color={color} />;
            break;
        case "reset":
            Icon = <Repeat size="60%" color={color} />;
            break;
        case "image":
            Icon = <Image size="54%" />;
            break;
        case "custom":
            Icon = customIcon;
            break;
        default:
            Icon = null;
    }

    const button_style = {
        width: size + "px",
        height: size + "px",
    };

    return (
        <div
            id={id && id}
            className={[classes.EditBtn, className ? className : null, disabled ? classes.Disabled : null, customClass ? customClass : null].join(
                " "
            )}
            onClick={!disabled ? onClick : void 0}
            style={{ ...style, ...button_style }}
            onMouseEnter={() => {
                !disabled ? setHovered(true) : void 0;
            }}
            onMouseLeave={() => {
                !disabled ? setHovered(false) : void 0;
            }}
        >
            {Icon} {children}
        </div>
    );
}

EditButton.defaultProps = {
    size: 30,
};

EditButton.propTypes = {
    type: PropTypes.string.isRequired,
    onClick: PropTypes.func,
    size: PropTypes.number,
    customIcon: PropTypes.element,
    style: PropTypes.object,
};
