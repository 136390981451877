import { useEffect, useRef, useState } from "react";

//UI
import classes from "./InsertInLineImageDialog.module.css";
import ReactPortal from "../../../UI/Modal/ReactPortal";
import { LexicalFileInput } from "../LexicalFileInput/LexicalFileInput";

//InlineImage specific
import type { Position } from "../../nodes/InLineImageNode";
import { INSERT_INLINE_IMAGE_COMMAND } from "../../plugins/InLineImagePlugin/InLineImagePlugin";
import LexicalModalHeader from "../LexicalModalHeader/LexicalModalHeader";

//contexts
import { useLanguage } from "../../../../contexts/LanguageContext";
import Modal from "../../../UI/Modal/Modal";
import Button from "../../../ComponentLibrary/Button/Button";
import LexicalSelect from "../LexicalSelect/LexicalSelect";
import { createImageFileName } from "../../../Helpers/HelperFunction";

export function InsertInlineImageDialog({ activeEditor, setshowInLineImageDialog, handleImageUpload, editorConfig }) {
    const hasModifier = useRef(false);
    const { translate } = useLanguage();

    const [src, setSrc] = useState("");
    const [newImgDim, setNewImgDim] = useState({
        height: 100,
        width: 100,
    });

    const [file_name, setfile_name] = useState("");
    const [progress, setprogress] = useState(0);

    const [altText, setAltText] = useState("");
    const [showCaption, setShowCaption] = useState(false);
    const [position, setPosition] = useState<Position>("left");

    const isDisabled = src === "";

    const handleShowCaptionChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setShowCaption(e.target.checked);
    };

    const handlePositionChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
        setPosition(e.target.value as Position);
    };

    async function loadImage(files) {
        console.log(files);
        const newImg = new Image();
        const file = files[0];
        newImg.src = URL.createObjectURL(file);
        newImg.onload = async function () {
            if (files !== null) {
                const file = files[0];
                const { file_name_with_format: file_name } = createImageFileName(file);
                setfile_name(file_name);
                const url_base = URL.createObjectURL(file);
                setSrc(file);
                setNewImgDim({ height: newImg.height, width: newImg.width });
            }
        };
    }

    useEffect(() => {
        hasModifier.current = false;
        const handler = (e: KeyboardEvent) => {
            hasModifier.current = e.altKey;
        };
        document.addEventListener("keydown", handler);
        return () => {
            document.removeEventListener("keydown", handler);
        };
    }, [activeEditor]);

    const handleOnClick = async (payload) => {
        const url = await handleImageUpload(newImgDim, src, file_name, setprogress);
        console.log(url);
        insertImage({ src: url, altText: "", maxWidth: 1182, position: position });
    };
    const insertImage = (payload) => {
        activeEditor.dispatchCommand(INSERT_INLINE_IMAGE_COMMAND, payload);
        setshowInLineImageDialog(false);
    };
    return (
        <ReactPortal wrapperId={editorConfig?.editorID}>
            <Modal setModalOpen={setshowInLineImageDialog}>
                <div className={classes.InsertImageDialog}>
                    <LexicalModalHeader title={translate("Insert Inline Image")} onClose={() => setshowInLineImageDialog(false)} />
                    <LexicalFileInput label="Image Upload" onChange={loadImage} accept="image/*" data-test-id="image-modal-file-upload" />
                    <div className={classes.SelectHolder}>
                        <LexicalSelect label="Position" name="position" id="position-select" onChange={handlePositionChange}>
                            <option value="left">{translate("Left")}</option>
                            <option value="right">{translate("Right")}</option>
                            <option value="full">{translate("Full Width")}</option>
                        </LexicalSelect>
                    </div>
                    <Button buttonClass={classes.ConfirmBtn} onClick={() => handleOnClick({ altText, position, showCaption, src })}>
                        {translate("CONFIRM")}
                    </Button>
                </div>
            </Modal>
        </ReactPortal>
    );
}
