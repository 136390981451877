import { Assignment, Book, Cube3D, Event, FilledTick, GradLaptop, Mic, Quiz, RichText, Slides, Website, Youtube } from "../../assets/icons/Icons";

export const ResourceIcons = {
    vimeo: <Youtube color="#00ADEF" size="15px" />,
    youtube: <Youtube color="#E62117" size="15px" />,
    video: <Youtube color="#E62117" size="15px" />,
    audio: <Mic color="#E69317" size="18px" />,
    course: <GradLaptop color="#005120" size="16px" />,
    book: <Book color="#8C0000" size="13px" />,
    slide: <Slides color="#8C0000" size="14px" />,
    richtext: <RichText color="#000000" size="18px" />,
    website: <Website color="#005B9D" size="15px" />,
    "3D": <Cube3D color="#00B0A6" size="15px" />,
    checked: <FilledTick color="green" size="16px" />,
    event: <Event color="#000000" size="16px" />,
    assignment: <Assignment color="#005B9D" size="18px" />,
    quiz: <Quiz color="#005B9D" size="16px" />,
};

// export default function ResourceIconStyled({ resourceType, style, className, color}){
//     return
// }

export const ResourceColors = {
    youtube: "#E62117",
    vimeo: "#00ADEF",
    audio: "#E69317",
    course: "#005120",
    book: "#8C0000",
    slide: "#8C0000",
    richtext: "#000000",
    website: "#005B9D",
    "3D": "#00B0A6",
    event: "#000000",
    assignment: "#005B9D",
    quiz: "#005B9D",
    checked: "green",
};
