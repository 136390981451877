import { doc } from "firebase/firestore";
import { useDocumentData } from "react-firehooks";
import { auth, firestore } from "../../app/firebase";
import { getMemberData } from "../../pages/MembersAndGroups/Helpers/MembersHelperFunctions";
import { useSpaceContext } from "../../contexts/SpaceContext/SpaceContext";

export default function useSpaceMember(user_id) {
    const { space_id } = useSpaceContext();
    let spaceRef
    if (space_id && user_id) {
        spaceRef = doc(firestore, "spaces", space_id, "members", user_id);
    }
    const [space_member, loading_spacemember, error_spaceData] = useDocumentData(spaceRef);

    const {        //user
        user_name, user_id: m_user_id, user_pic, user_background, user_role,
        //access
        user_has_profile,
        m_active_curator_path_ids,
        m_active_teacher_path_ids,
        m_active_student_path_ids, m_active_student_pq_ids,
        m_active_teacher_group_ids,
        m_active_student_group_ids, 
        m_products_purchased, m_products_full_bundle } = getMemberData(space_member)

    const isPathCuratorOfSpace = user_role === "curator";
    const isPathTeacherOfSpace = user_role === "teacher";
    const isPathStudentOfSpace = user_role === "student";
    return {
        //user
        space_member, user_has_profile,
        user_name, user_id, user_pic, user_background, user_role,
        //access
        m_active_curator_path_ids,
        m_active_teacher_path_ids,
        m_active_student_path_ids, m_active_student_pq_ids,
        m_active_teacher_group_ids,
        m_active_student_group_ids,
        //ROLE
        isPathCuratorOfSpace, isPathTeacherOfSpace, isPathStudentOfSpace,
        loading_spacemember,
        //Products
        m_products_purchased, m_products_full_bundle

    };
}
