import { useEffect, useState } from "react";
import useGeneralMessages from "../../../../Chat/hooks/messageHooks/useGeneralMessages";
import useResourcesMessages from "../../../../Chat/hooks/messageHooks/useResourcesMessages";
import { dynamicSort2 } from "../../../../../components/Helpers/HelperFunction";

export default function useLoadCourseMessages(last_online_date, loading_presence) {
    const { generalMessages, loading_general_messages } = useGeneralMessages(last_online_date, loading_presence);
    const { resourcesMessages, loading_resources_messages } = useResourcesMessages(last_online_date, loading_presence);

    const [coursemessages, setcoursemessages] = useState([]);
    const loading_course_messages = loading_resources_messages || loading_general_messages;
    useEffect(() => {
        if (!loading_course_messages) {
            const combinedMessages = [
                ...(resourcesMessages || []),
                ...(generalMessages || [])
            ];
            setcoursemessages(combinedMessages.sort(dynamicSort2("meta", "created_at",true)));
        }
    }, [generalMessages,resourcesMessages, loading_course_messages]);
    return { coursemessages, loading_course_messages };
}
