import { auth, firestore } from "../../../app/firebase";
import { doc } from "firebase/firestore";
import { useDocumentData } from "react-firehooks";

import { useSpaceContext } from "../../../contexts/SpaceContext/SpaceContext";

export default function useCheckedSpaceResources(condition = true) {
    const uid = auth.currentUser?.uid
    const { space_id } = useSpaceContext();

    let user_space_ref
    if (uid && condition) user_space_ref = doc(firestore, "progress_by_user", uid, "progress_spaces", space_id);
    const [users_space_progress, loading_space_progress, error_space_progress] = useDocumentData(user_space_ref);
    //resources: 
    const users_checked_space_resources = users_space_progress?.resources?.checked_space_resources
    const users_checked_space_resource_tasks = users_space_progress?.tasks?.checked_space_resource_tasks
    const checked_space_tasks_count = users_space_progress?.stats?.checked_space_tasks_count
    const checked_space_resources_by_tag_date = users_space_progress?.tags?.checked_space_resources_by_tag_date
    //CONFIG
    const mark_as_read_ids = users_space_progress?.config?.mark_as_read;
    const show_download_calendar = users_space_progress?.config?.download_calendar;
    //STATS  
    const time_worked = users_space_progress?.stats?.time_worked;

    //voting: 
    const space_upvotes = users_space_progress?.voting?.upvotes ? users_space_progress?.voting?.upvotes : []
    const space_downvotes = users_space_progress?.voting?.downvotes ? users_space_progress?.voting?.downvotes : []
    return {
        users_space_progress, loading_space_progress, error_space_progress,
        users_checked_space_resources,
        users_checked_space_resource_tasks, checked_space_tasks_count,
        checked_space_resources_by_tag_date,
        //voting: 
        space_upvotes, space_downvotes,
        //STATS  
        time_worked,
        //CONFIG
        mark_as_read_ids, show_download_calendar
    };
}