export function isObjEmpty(obj) {
    return !obj || obj === "undefined" || Object.keys(obj).length === 0;
}

export function getKeyByValue(object={}, value) {
    for (let key in object) {
        if (object.hasOwnProperty(key) && object[key] === value) {
            return key;
        }
    }
}

export function spliceObject(obj, index) {
    for (const key in obj) {
      if (Number(key) >= index) {
        delete obj[key];
      }
    }
    return obj;
  }