import { useState } from "react";

import classes from "./InsertImageDialog.module.css";

//components
import Button from "../../../ComponentLibrary/Button/Button";
import TextInput from "../../../ComponentLibrary/TextInput/TextInput";
import Modal from "../../../UI/Modal/Modal";
import ReactPortal from "../../../UI/Modal/ReactPortal";
import { LexicalFileInput } from "../LexicalFileInput/LexicalFileInput";

//functions
import { createImageFileName } from "../../../Helpers/HelperFunction";

//lexical
import { INSERT_IMAGE_COMMAND } from "../../plugins/ImagesPlugin/ImagesPlugin";
import LexicalModalHeader from "../LexicalModalHeader/LexicalModalHeader";

//context
import { useLanguage } from "../../../../contexts/LanguageContext";

export default function InsertImageDialog({ activeEditor, setshowImageDialog, handleImageUpload, editorConfig }) {
    const { translate } = useLanguage();
    const [mode, setMode] = useState(null);
    const [img_url, setImgUrl] = useState("");
    const [progress, setprogress] = useState(0);

    // console.log("ACTIVE_EDITOR", activeEditor);
    function handleTypeImgURL(url) {
        setImgUrl(url);
    }
    function handleSetImgURL() {
        insertImage({ src: img_url, altText: "", maxWidth: 1182 });
    }

    const insertImage = (payload) => {
        activeEditor.dispatchCommand(INSERT_IMAGE_COMMAND, payload);
        setshowImageDialog(false);
    };

    async function loadImage(files) {
        const newImg = new Image();
        const file = files[0];
        newImg.src = URL.createObjectURL(file);
        newImg.onload = async function () {
            const { file_name_with_format: file_name } = createImageFileName(file);
            const url_base = URL.createObjectURL(file);
            // insertImage({ src: url_base, altText: "", maxWidth: 1182 }) //DUMMY IMAGE, TODO: Transparent gray overlay
            //TODO: DELETE DUMMY IMAGE
            const url = await handleImageUpload(newImg, file, file_name, setprogress);
            console.log(url);
            insertImage({ src: url, altText: "", maxWidth: 1182 }); //DUMMY IMAGE, TODO: Transparent gray overlay
        };
    }

    return (
        <ReactPortal wrapperId={editorConfig?.editorID}>
            <Modal setModalOpen={setshowImageDialog}>
                <div className={classes.InsertImageDialog}>
                    <LexicalModalHeader title={translate("Insert Image")} onClose={() => setshowImageDialog(false)} />
                    {mode === "url" ? (
                        <div className={classes.URLInput}>
                            <TextInput label={translate("Image URL")} onChange={(e) => handleTypeImgURL(e.target.value)} value={img_url} />
                            <Button onClick={handleSetImgURL}>{translate("CONFIRM")}</Button>
                        </div>
                    ) : mode === "upload" ? (
                        <div className={classes.FileInput}>
                            <LexicalFileInput label="Image Upload" onChange={loadImage} accept="image/*" data-test-id="image-modal-file-upload" />
                            {/* <Button onClick={() => insertImage({ src, altText: "", maxWidth: 1182 })}>{translate("CONFIRM")}</Button> */}
                        </div>
                    ) : (
                        <div className={classes.Buttons}>
                            <Button onClick={() => setMode("url")} buttonClass={classes.ChoiceButton}>
                                {translate("From URL")}
                            </Button>
                            <Button onClick={() => setMode("upload")} buttonClass={classes.ChoiceButton}>
                                {translate("Upload File")}
                            </Button>
                        </div>
                    )}
                    {progress > 0 && progress + "%"}
                </div>
            </Modal>
        </ReactPortal>
    );
}
