import joinClasses from "../../../../utils/joinClasses";
import "../../themes/LexicalMaxTheme/LexicalMaxTheme.css";

export default function LexicalRichTextMax_ReadOnly({ lexicalData, card, className = null, mobile = false }) {
    return (
        <div
            className={joinClasses("RichTextMax_Root", mobile && "Mobile", card && "Card", className && className)}
            dangerouslySetInnerHTML={{ __html: lexicalData }}
        />
    );
}
