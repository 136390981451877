// Loading Spinner
import classes from "./Loader.module.css";

export default function Loader({ style = null, className = null, children = null }) {
    return (
        <div style={style && style} className={[classes.loader, className].join(" ")}>
            {children}
        </div>
    );
}
