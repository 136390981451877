import { collection, limit, orderBy, query, where } from "firebase/firestore";
import { useCollectionData } from "react-firehooks";
import { useSpaceContext } from "../../../../contexts/SpaceContext/SpaceContext";
import { firestore } from "../../../../app/firebase";
import { useAuth } from "../../../../contexts/AuthContext";

export default function useSpaceMessages(last_online_date, loading_presence) {
    const { space_id } = useSpaceContext();
    const { user_id, isCuratorOfSpace, isPathTeacherOfSpace, isPathCuratorOfSpace } = useAuth();

     let spaceMessagesRef, spaceMessagesQuery;
    if (user_id && last_online_date && !loading_presence) {
        spaceMessagesRef = collection(firestore, "spaces", space_id, "messages");
        if (isCuratorOfSpace || isPathTeacherOfSpace || isPathCuratorOfSpace) { //ALL but student
            spaceMessagesQuery = query(spaceMessagesRef,
                where("meta.channel_origin", "==", "SPACE"),
                where("meta.created_at", ">", last_online_date),
                orderBy("meta.created_at", "desc"),
                limit(10)
            );  
        };  
    }        
    const [spaceMessages, loading_space_messages, error_messages] = useCollectionData(spaceMessagesQuery);
    return { spaceMessages, loading_space_messages };
}
