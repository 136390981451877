import {
    EventLocationFilled,
    MSTeams,
    MSTeamsWithFont,
    VideoCallFilled,
    YoutubeLogoFont,
    YoutubeLogoIcon,
    ZOOMLogo,
    ZOOMLogoWithFont,
} from "../../../../assets/icons/Icons";
import { constructDateTimeObject, monthNames } from "../../../Helpers/DatetimeHelperfunctions";
import { dynamicSort } from "../../../Helpers/HelperFunction";

export function getRichTextData(typeData) {
    const richtext_data = typeData;
    const richtext = richtext_data?.richtext ? richtext_data.richtext : "<p></p>";
    const richtext_short = richtext_data?.richtext ? richtext_data.richtext.substring(0, 4000) : "<p></p>";
    const rchtxt_authors = richtext_data?.authors?.map((spk) => {
        if (spk.user) {
            return spk.user.user_id;
        } else {
            return spk;
        }
    });

    const rchtxt_audio_data = richtext_data?.audio;
    const rchtxt_audio_url = rchtxt_audio_data?.url;
    const rchtxt_voice_id = rchtxt_audio_data?.voice_id || null;
    const rchtxt_audio_dur = rchtxt_audio_data?.dur;
    const rchtxt_audio_date_updated = rchtxt_audio_data?.date_updated;
    const has_rchtxt_textVoiceSync = richtext_data?.textVoiceSync;

    const thumbnail_images = richtext_data?.images ? richtext_data.images : [];
    const selected_thumbnail_images = thumbnail_images?.filter((img) => img.selected);
    return {
        rchtxt_authors,
        richtext_data,
        richtext,
        richtext_short,
        thumbnail_images,
        selected_thumbnail_images,
        rchtxt_audio_data,
        rchtxt_audio_url,
        rchtxt_voice_id,
        rchtxt_audio_date_updated,
        rchtxt_audio_dur,
        has_rchtxt_textVoiceSync,
    };
}

export function getQuizData(quiz_data, loading_data = false) {
    const timeLimitNumber = typeof quiz_data?.timeLimitNumber === "number" ? quiz_data.timeLimitNumber : 0;
    const isTimeUnlimited = loading_data ? true : quiz_data?.isTimeUnlimited;

    const onlySinglePage = quiz_data?.onlySinglePage;
    const questions_per_page_no = quiz_data?.nQuestions;
    const questions_per_page = onlySinglePage ? 1 : questions_per_page_no; //For creation;
    const questions_per_page_view = questions_per_page ? Math.max(Math.round(questions_per_page), 1) : 1; //For view;

    const quiz_passMarkPercent = quiz_data?.passMarkPercent ? quiz_data.passMarkPercent : 0;
    const quiz_passMark = quiz_data?.passMark ? quiz_data.passMark : 0;

    const quiz_passMarkType = loading_data ? true : quiz_data?.passMarkTypeQuestions;
    const quiz_orderType = quiz_data?.isOrderTypeRandom;
    const quiz_checkType = quiz_data?.isCheckTypeInstant ? quiz_data?.isCheckTypeInstant : false;

    //ATTEMPTS
    const quiz_attempts_unlimited = loading_data ? true : quiz_data?.isAttemptsUnlimited;
    const quiz_attempts_number = quiz_data?.attemptsNumber ? quiz_data.attemptsNumber : 0;

    const quiz_questions_object = quiz_data?.q_obj ? quiz_data?.q_obj : {};

    const quiz_questions = quiz_questions_object ? Object.values(quiz_questions_object) : [];
    const quiz_question_ids = quiz_data?.q_ids ? quiz_data?.q_ids : [];
    const quiz_number_qs = quiz_question_ids?.length;

    return {
        timeLimitNumber,
        isTimeUnlimited,
        onlySinglePage,
        questions_per_page,
        questions_per_page_view,
        quiz_questions,
        quiz_number_qs,
        quiz_questions_object,
        quiz_question_ids,
        quiz_attempts_unlimited,
        quiz_attempts_number,
        quiz_passMarkPercent,
        quiz_passMarkType,
        quiz_orderType,
        quiz_checkType,
        quiz_data,
        quiz_passMark,
    };
}

export function getVideoData(video_data) {
    const vd_link = video_data?.link;
    const vd_host = video_data?.host;
    const vd_title = video_data?.title;
    const vd_length = video_data?.videoLength ? video_data.videoLength : 100;
    const vd_dscrptn = video_data?.videoDescription;
    const vd_channel_name = video_data?.channel;
    const vd_date_published = video_data?.datePublished;
    const vd_id = video_data?.videoId
        ? video_data?.videoId
        : vd_link?.includes("youtu.be")
        ? vd_link.substring(vd_link.lastIndexOf("/") + 1, vd_link.length)
        : vd_link?.split("v=")[1];

    return {
        video_data,
        vd_link,
        vd_title,
        vd_host,
        vd_length,
        vd_dscrptn,
        vd_channel_name,
        vd_date_published,
        vd_id,
    };
}

export function getEventData(event_data, translate) {
    //DATE
    const event_date_start = event_data?.start?.toDate();
    const event_date_end = event_data?.end?.toDate();
    const diffTime = Math.abs(event_date_start - event_date_end);
    const event_duration = millisToMinutesAndSeconds(diffTime);
    //People
    // const event_speakers = event_data?.speakers;
    const event_speakers = event_data?.speakers?.map((spk) => {
        if (spk.user) {
            return spk.user.user_id;
        } else {
            return spk;
        }
    });

    const event_hasSpeakers = event_data?.speakers?.length > 0;
    //Location
    const event_type = event_data?.eventType;
    const event_is_online = event_type === "remote";
    const event_location = event_data?.location;
    const event_room = event_data?.room;
    const event_locationDisplay = event_location ? (event_room ? event_location + ", " + event_room : event_location) : "";
    const event_location_icon =
        event_type === "remote" ? <VideoCallFilled size={"20px"} /> : event_type === "inclass" ? <EventLocationFilled size={"24px"} /> : "";
    const event_remoteLink = event_data?.remoteLink;
    const event_video_call_provider = event_data?.video_call_provider ? event_data?.video_call_provider : getProviderFromLink(event_remoteLink);
    const event_recurrence = event_data?.recurrence;
    const event_recurrence_type = event_data?.recurrence_type; //weekly, monthly
    const event_recurrence_id = event_data?.recurrence_id;

    //Other
    const event_previewSlides = event_data?.previewSlides;
    const event_hasPreviewSlides = event_data?.previewSlides?.length > 0;
    const event_slidesOrientation = event_data?.orientation;

    let remote_logo, remote_logo_with_font, remote_logo_with_font_small, remote_name;
    if (event_type === "remote") {
        switch (event_video_call_provider) {
            case "zoom":
                remote_name = "Zoom";
                remote_logo = <ZOOMLogo size={"24px"} />;
                remote_logo_with_font = <ZOOMLogoWithFont size={"38px"} />;
                remote_logo_with_font_small = <ZOOMLogoWithFont size={"28px"} style={{ marginTop: "2px" }} />;
                break;
            case "msteams":
                remote_name = "Microsoft Teams";
                remote_logo = <MSTeams size={"23px"} />;
                remote_logo_with_font = <MSTeamsWithFont size={"38px"} />;
                remote_logo_with_font_small = <MSTeamsWithFont size={"29px"} style={{ marginTop: "2px" }} />;
                break;
            case "youtube":
                remote_name = "YouTube";
                remote_logo = <YoutubeLogoIcon size={"19px"} />;
                remote_logo_with_font = <YoutubeLogoFont size={"27px"} />;
                remote_logo_with_font_small = <YoutubeLogoFont size={"20px"} style={{ marginTop: "4px" }} />;
                break;
            default:
                remote_name = "";
                remote_logo = "";
                remote_logo_with_font = "";
                remote_logo_with_font_small = "";
        }
    }
    const getLocationDisplay = (small = false) => {
        const event_location_field =
            event_type === "remote"
                ? small
                    ? remote_logo_with_font_small
                    : remote_logo_with_font
                : event_type === "inclass"
                ? event_locationDisplay
                : "";
        return event_location_field;
    };

    const {
        dateDisplay: event_start_dateDisplay,
        timeDisplay: event_start_timeDisplay,
        weekDay: event_start_weekDayKey,
        day: event_startDay,
        month: event_startMonth,
        year: event_startYear,
        date: event_startDate,
        hr: hr_start,
        min: min_start,
    } = constructDateTimeObject(event_date_start);
    const event_startWeekDay = translate ? translate(event_start_weekDayKey) : "";
    const {
        dateDisplay: event_end_dateDisplay,
        timeDisplay: event_end_timeDisplay,
        weekDay: event_end_weekDayKey,
        day: event_endDay,
        month: event_endMonth,
        year: event_endYear,
        date: event_endDate,
        hr: hr_end,
        min: min_end,
    } = constructDateTimeObject(event_date_end);
    const event_endWeekDay = translate ? translate(event_end_weekDayKey) : "";
    event_end_weekDayKey;

    const event_startMonthF3 = translate ? translate(monthNames[event_startMonth - 1])?.substr(0, 3) : "";
    const event_timeDisplay = event_start_timeDisplay + " - " + event_end_timeDisplay;

    const getDidEventAlreadyHappen = (event_data) => {
        const now = Date.now();
        const closedTime = event_data?.end?.seconds * 1000;
        const happenedAlready = Math.sign(closedTime - now) < 0;
        return happenedAlready;
    };

    const event_happenedAlready = getDidEventAlreadyHappen(event_data);

    return {
        //DATE
        event_date_start,
        event_date_end,
        event_duration,
        event_recurrence,
        event_recurrence_type,
        event_startDate,
        event_endDate,
        event_startDay,
        event_startMonth,
        event_startYear,
        event_startWeekDay,
        event_startMonthF3,
        event_start_dateDisplay,
        event_end_dateDisplay,
        event_timeDisplay,
        hr_start,
        min_start,
        hr_end,
        min_end,
        event_endDay,
        event_endWeekDay,
        event_endMonth,
        event_endYear,
        event_happenedAlready,
        //PEOPLE
        event_speakers,
        event_hasSpeakers,
        //Location
        event_type,
        event_is_online,
        //remote
        remote_name,
        remote_logo,
        remote_logo_with_font,
        remote_logo_with_font_small,
        getLocationDisplay,
        event_location_icon,
        event_location,
        event_room,
        event_locationDisplay,
        event_remoteLink,
        event_video_call_provider,
        event_recurrence_id,
        //Other
        event_previewSlides,
        event_hasPreviewSlides,
        event_slidesOrientation,
    };
}

function millisToMinutesAndSeconds(millis) {
    var minutes = Math.round(millis / 60000);
    return minutes;
}

export function getSlideData(slide_data, loading_resource = false) {
    const sld_url = slide_data?.url;
    const sld_file_name = slide_data?.file_name;
    const sld_file_type = slide_data?.file_type;
    const sld_file_path = slide_data?.path;
    const sld_slides_count = slide_data?.slides_count;

    const sld_previewSlides = slide_data?.previewSlides;
    const sld_orientation = slide_data?.orientation ? slide_data?.orientation : "vertical";

    const sld_isDownloadable = slide_data?.isDownloadable;

    return {
        sld_url,
        sld_file_name,
        sld_file_type,
        sld_file_path,
        sld_previewSlides,
        sld_orientation,
        sld_slides_count,
        sld_isDownloadable,
        slide_data,
    };
}

function getProviderFromLink(event_remoteLink) {
    if (event_remoteLink?.includes("zoom")) {
        return "zoom";
    } else if (event_remoteLink?.includes("youtu")) {
        return "youtube";
    } else if (event_remoteLink?.includes("teams")) {
        return "msteams";
    } else {
        return "";
    }
}

export function getResourceData(resource, checkedResources = [], space = false) {
    //MetaData
    const rsrc_metaData = resource?.metaData;
    const rsrc_title = rsrc_metaData?.title;
    const rsrc_type = rsrc_metaData?.type;
    const atResourceTag = rsrc_metaData?.title;
    const rsrc_tags = rsrc_metaData?.tags ? resource.metaData.tags : [];
    const rsrc_hasTags = rsrc_tags?.length > 0;
    const rsrc_created = rsrc_metaData?.date_created;
    const rsrc_updated = rsrc_metaData?.date_updated;

    const rsrc_editors_sorted = rsrc_metaData?.editors && rsrc_metaData?.editors?.length > 0 ? rsrc_metaData?.editors?.sort(dynamicSort("date")) : [];

    //LocalData
    const rsrc_local_data = resource?.localData;
    const rsrc_module_number = rsrc_local_data?.module;
    const rsrc_global_id = space ? rsrc_metaData?.global_id : rsrc_local_data?.global_id;
    const rsrc_local_id = space ? rsrc_local_data?.local_space_id : rsrc_local_data?.local_id;
    const rsrc_isGroup = rsrc_local_data?.group;
    const rsrc_group_id = rsrc_local_data?.group_id;
    const rsrc_position = rsrc_local_data?.position;
    const rsrc_group_position = rsrc_local_data?.groupPosition;
    const rsrc_time_to_complete = rsrc_local_data?.time_to_complete;

    const rsrc_isOptional = rsrc_local_data?.optional;
    const rsrc_isExplorable = rsrc_local_data?.explorable ? rsrc_local_data?.explorable : false;
    const rsrc_path_id = rsrc_local_data?.path_id;

    //attachments
    const rsrc_blocks = resource?.blocks;
    const rsrc_attachments = rsrc_blocks ? Object.values(rsrc_blocks) : [];
    const rsrc_files = rsrc_attachments?.filter((a) => a.type === "file");
    const rsrc_tasks = rsrc_attachments?.filter((a) => a.type === "task");
    const rsrc_no_of_task = rsrc_tasks?.length;

    //stats
    const rsrc_upVoteCount = resource?.stats?.upvote_count ? resource.stats.upvote_count : 0;
    const rsrc_downVoteCount = resource?.stats?.downvote_count ? resource.stats.downvote_count : 0;

    const rsrc_message_count = resource?.stats?.messages?.message_count ? resource.stats.messages.message_count : 0;
    const rsrc_deleted_message_count = resource?.stats?.messages?.deleted_message_count ? resource.stats.messages.deleted_message_count : 0;
    const rsrc_effective_message_count = rsrc_message_count - rsrc_deleted_message_count > 0 ? rsrc_message_count - rsrc_deleted_message_count : 0;
    //TIMESTAMPS
    const rsrc_timestamp_count = resource?.stats?.messages?.timestamp_count ? resource.stats.messages.timestamp_count : 0;
    const deleted_timestamp_count = resource?.stats?.messages?.deleted_timestamp_count ? resource.stats.messages.deleted_timestamp_count : 0;
    const rsrc_effective_timestamp_count = rsrc_timestamp_count - deleted_timestamp_count > 0 ? rsrc_timestamp_count - deleted_timestamp_count : 0;
    const rsrc_timestamps = resource?.stats?.messages?.timestamps ? Object.values(resource?.stats?.messages?.timestamps) : [];

    //type data
    const rsrc_type_data = resource?.typeData;

    //VIDEOS
    const rsrc_host = resource?.typeData?.host ? resource?.typeData?.host : "";

    //Other
    const rsrc_authors = resource?.typeData?.authors?.map((spk) => {
        if (spk.user) {
            return spk.user.user_id;
        } else {
            return spk;
        }
    });
    const rsrc_hasAuthors = rsrc_authors?.length > 0;
    const rsrc_descr = resource?.typeData?.description;

    const markedAsDone = checkedResources?.includes(rsrc_local_id);

    return {
        //metaData
        rsrc_title,
        rsrc_metaData,
        rsrc_type,
        rsrc_tags,
        rsrc_descr,
        rsrc_hasTags,
        rsrc_created,
        rsrc_updated,
        rsrc_editors_sorted,
        //localData
        rsrc_local_data,
        rsrc_module_number,
        rsrc_position,
        rsrc_group_position,
        rsrc_global_id,
        rsrc_local_id,
        rsrc_isGroup,
        rsrc_group_id,
        rsrc_isOptional,
        rsrc_isExplorable,
        rsrc_path_id,
        rsrc_time_to_complete,
        //attachments
        rsrc_attachments,
        rsrc_tasks,
        rsrc_blocks,
        rsrc_files,
        rsrc_no_of_task,
        //stats
        rsrc_upVoteCount,
        rsrc_downVoteCount,
        rsrc_effective_message_count,
        rsrc_timestamp_count,
        rsrc_effective_timestamp_count,
        rsrc_timestamps,
        //otherData
        atResourceTag,
        markedAsDone,
        //TypeData
        rsrc_type_data,
        rsrc_hasAuthors,
        //Video
        rsrc_host,
        //other
        rsrc_authors,
    };
}
