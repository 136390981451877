import { doc, collection, getDoc, getDocs, where, query, limit } from "@firebase/firestore";
import { firestore, auth } from "../../../../../app/firebase";

export async function loadQuestionsByIDs(qids) {
    const result = await Promise.all(qids.map((qid) => getDoc(doc(firestore, "quiz_questions", qid))));
    return result
}

export async function loadQuestionByID2(qid) {
    const docRef = doc(firestore, "quiz_questions", qid)
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
        return docSnap.data();
    } else {
        return {}
    }
}



export async function getQuizzesContainingCertainQuestions(space_id, path_id, question_ids = []) {
    const pathResourcesRef = collection(firestore, "spaces", space_id, "paths", path_id, "resources");
    let quizzesQuery;
    if (question_ids?.length > 10) {
        quizzesQuery = query(pathResourcesRef,
            where("metaData.type", "==", "quiz"),
        );
    } else {
        quizzesQuery = query(pathResourcesRef,
            where("metaData.type", "==", "quiz"),
            where("typeData.q_ids", "array-contains-any", question_ids)
        );
    }
    const quizzes = await getDocs(quizzesQuery);
    return quizzes
}



export async function loadQuizPerformanceStats(space_id, path_id = null) {
    const uid = auth.currentUser?.uid;
    let docRef
    if (path_id) {
        docRef = doc(firestore, "progress_by_user", uid, "progress_spaces", space_id, "progress_paths", path_id, "quiz_performance_stats", "quiz_performance_stats")
    } else {
        docRef = doc(firestore, "progress_by_user", uid, "progress_spaces", space_id, "quiz_performance_stats", "quiz_performance_stats")
    }
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
        const data = docSnap.data()
        const basket_1 = data?.baskets?.basket_1 ? data.baskets.basket_1 : []
        const basket_2 = data?.baskets?.basket_2 ? data.baskets.basket_2 : []
        const basket_3 = data?.baskets?.basket_3 ? data.baskets.basket_3 : []
        const basket_learnt = data?.baskets?.basket_learnt ? data.baskets.basket_learnt : []
        return {
            basket_1,
            basket_2,
            basket_3,
            basket_learnt
        };
    } else {
        return {
            p_adj_rel_avg_old: 0, p_adj_rel_m2_old: 0,
            p_rel_avg_old: 0, p_rel_m2_old: 0,
            p_avg_old: 0, p_m2_old: 0,
            basket_1: [],
            basket_2: [],
            basket_3: [],
            basket_learnt: []
        }
    }
}

export async function loadQuizDataStats(space_id, path_id) {
    const docRef = doc(firestore, "spaces", space_id, "paths", path_id, "stats", "quiz_data_stats")
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
        //(1) score across persons
        const stats = docSnap.data()
        const old_aggregated_average_adjusted_relative_persons = stats?.analytics?.person_scores?.adjusted_relative?.average ? stats.analytics.person_scores.adjusted_relative.average : 0
        const old_m2_aggregated_adjusted_relative_persons = stats?.analytics?.person_scores?.adjusted_relative?.m2 ? stats.analytics.person_scores.adjusted_relative.m2 : 0

        const old_aggregated_average_relative_persons = stats?.analytics?.person_scores?.relative?.average ? stats.analytics.person_scores.relative.average : 0
        const old_m2_aggregated_average_relative_persons = stats?.analytics?.person_scores?.relative?.m2 ? stats.analytics.person_scores.relative.m2 : 0

        const old_aggregated_average_persons = stats?.analytics?.person_scores?.score?.average ? stats.analytics.person_scores.score.average : 0
        const old_m2_aggregated_relative_persons = stats?.analytics?.person_scores?.score?.m2 ? stats.analytics.person_scores.score.m2 : 0

        //(2) score across questions
        const qs_rel_avg_old = stats?.analytics?.question_scores?.relative?.average ? stats.analytics.question_scores.relative.average : 0
        const old_sd_relative_score_questions = stats?.analytics?.question_scores?.relative?.sd ? stats.analytics.question_scores.relative.sd : 0
        const qs_rel_m2_old = stats?.analytics?.question_scores?.relative?.m2 ? stats.analytics.question_scores.relative.m2 : 0

        const qs_avg_old = stats?.analytics?.question_scores?.score?.average ? stats.analytics.question_scores.score.average : 0
        const old_sd_score_questions = stats?.analytics?.question_scores?.score?.sd ? stats.analytics.question_scores.score.sd : 0
        const qs_m2_old = stats?.analytics?.question_scores?.score?.m2 ? stats.analytics.question_scores.score.m2 : 0


        const total_number_of_people_taken_questions = stats?.analytics?.totals?.number_of_people ? stats.analytics.totals.number_of_people : 0
        const total_number_of_questions_taken = stats?.analytics?.totals?.number_of_questions_taken ? stats.analytics.totals.number_of_questions_taken : 0

        const ps_avg_questions_taken_old = stats?.analytics?.questions_per_person?.average ? stats.analytics.questions_per_person?.average : 0
        const ps_m2_questions_taken_old = stats?.analytics?.questions_per_person?.m2 ? stats.analytics.questions_per_person.m2 : 0

        //(3) number of quizzes taken across person
        const old_average_number_of_quizzes_taken_per_person = stats?.analytics?.quizzes_per_person?.average ? stats.analytics.quizzes_per_person.average : 0
        const old_m2_quizzes_taken_per_person = stats?.analytics?.quizzes_per_person?.m2 ? stats.analytics.quizzes_per_person.m2 : 0

        return {
            old_aggregated_average_adjusted_relative_persons, old_m2_aggregated_adjusted_relative_persons,
            old_aggregated_average_relative_persons, old_m2_aggregated_average_relative_persons,
            old_aggregated_average_persons, old_m2_aggregated_relative_persons,

            total_number_of_people_taken_questions,
            qs_rel_avg_old,
            old_sd_relative_score_questions,
            qs_rel_m2_old,
            qs_avg_old,

            old_sd_score_questions,
            qs_m2_old,
            total_number_of_questions_taken,
            ps_avg_questions_taken_old,
            ps_m2_questions_taken_old,
            old_average_number_of_quizzes_taken_per_person,
            old_m2_quizzes_taken_per_person
        };
    } else {
        return {
            old_aggregated_average_adjusted_relative_persons: 0, old_m2_aggregated_adjusted_relative_persons: 0,
            old_aggregated_average_relative_persons: 0, old_m2_aggregated_average_relative_persons: 0,
            old_aggregated_average_persons: 0, old_m2_aggregated_relative_persons: 0,

            old_sd_relative_score_questions: 0,
            qs_rel_m2_old: 0,
            old_m2_aggregated_average_score_persons: 0,
            old_sd_score_questions: 0,
            qs_m2_old: 0,
            total_number_of_people_taken_questions: 0,
            qs_rel_avg_old: 0,
            total_number_of_questions_taken: 0,
            ps_avg_questions_taken_old: 0,
            ps_m2_questions_taken_old: 0,
            average_number_of_quizzes_taken_per_person: 0,
            old_m2_quizzes_taken_per_person: 0
        }
    }
}


export async function loadUserProgressPath(space_id, path_id) {
    const uid = auth.currentUser?.uid;
    const checkedRef = doc(firestore, "progress_by_user", uid, "progress_spaces", space_id, "progress_paths", path_id);
    const docSnap = await getDoc(checkedRef);
    let checked_local_resources_by_date = []
    if (docSnap.exists()) {
        checked_local_resources_by_date = docSnap.data().checked_local_resources_by_date
    }
    return checked_local_resources_by_date
}


export async function getQIDsFromQPool(space_id, path_id) {
    const poolStatsRef = doc(firestore, 'spaces', space_id, 'paths', path_id, "stats", "quiz_data_stats");
    const docSnap = await getDoc(poolStatsRef);
    let question_ids = []
    if (docSnap.exists()) {
        question_ids = docSnap.data().question_ids
    }
    return (question_ids)
}




export async function getQuestionPoolTagsData(space_id, path_id) {
    const questionPoolStatsRef = doc(firestore, "spaces", space_id, "paths", path_id, "stats", "pool_tags");
    const docSnap = await getDoc(questionPoolStatsRef);
    let question_tags = []
    if (docSnap.exists()) {
        question_tags = docSnap.data()?.tags
    }
    return (question_tags)
}

