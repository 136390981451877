export function deepLLanguageCodeMapping(lang) {
    let trans_lang = lang
    switch (lang) {
        case "en":
            trans_lang = "en-US"
            break;
        default:
            trans_lang = lang
            break;
    }
    return trans_lang
}