import { doc } from "firebase/firestore";
import { useDocumentData } from "react-firehooks";
import { firestore } from "../../app/firebase";
import { getSpaceStats } from "../../pages/MembersAndGroups/Helpers/MembersHelperFunctions";


export default function useSpaceStats(space_id, condition = true) {
    let spaceRef
    if (condition && space_id) {
        spaceRef = doc(firestore, "spaces", space_id, "stats", "space_stats");
    }
    const [space_stats, loading_space_stats, error_spaceData] = useDocumentData(spaceRef);

    const {
        spc_total_member_count,
        all_active_member_ids,
        spc_teacher_curator_ids,
        spc_stats_members,
        spc_member_students, spc_stats_member_statuses,
        spc_member_curators_ids,
        spc_member_teacher_ids,
        spc_teachers_curators_count,
        //PATHS
        spc_paths_active_count, spc_stats_paths,
        //Groups
        spc_stats_groups, spc_active_group_ids,
        //resources
        spc_resource_ids, space_votes_per_path
    } = getSpaceStats(space_stats)


    return {
        loading_space_stats,
        //members
        spc_stats_members,
        spc_member_students,
        spc_total_member_count,
        all_active_member_ids, spc_teacher_curator_ids,
        spc_stats_member_statuses,
        spc_member_curators_ids,
        spc_teachers_curators_count,
        spc_member_teacher_ids,
        //PATHS
        spc_paths_active_count, spc_stats_paths,
        //groups
        spc_stats_groups, spc_active_group_ids,
        //resources
        spc_resource_ids, space_votes_per_path
    }
}
