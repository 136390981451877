import { Timestamp } from "@firebase/firestore";
import { useEffect, useRef } from "react";
import { checkModuleCompleted } from "../../containers/LearningMode/ResourcePath/ResourcePathHelperFunctions";
import { getTaskData } from "../../functions/DataGetterFunctions";
import { getResourceData } from "../ResourcePath/Resources/functions/ResourceGetterFunctions";
import { translateTag } from "./HTTPRequests/DeepLRequests";
import { email_regex } from "./RegExpressions";

export const defaultColors = ["#9BE7EC", "#86DA9E", "#72B8BC", "#D57F7F", "#DDBC8B"];

const days = ["SUNDAY", "MONDAY", "TUESDAY", "WEDNESDAY", "THURSDAY", "FRIDAY", "SATURDAY"];
const months = ["january", "february", "march", "april", "may", "june", "july", "august", "september", "october", "november", "december"];
const months_Capitalized = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];

const monthNames = ["JANUARY", "FEBRUARY", "MARCH", "APRIL", "MAY", "JUNE", "JULY", "AUGUST", "SEPTEMBER", "OCTOBER", "NOVEMBER", "DECEMBER"];
export const everyHalfAnHour = [
    { hour: 6, minute: 0 },
    { hour: 6, minute: 30 },
    { hour: 7, minute: 30 },
    { hour: 8, minute: 0 },
    { hour: 8, minute: 30 },
    { hour: 9, minute: 0 },
    { hour: 9, minute: 30 },
    { hour: 10, minute: 0 },
    { hour: 10, minute: 30 },
    { hour: 11, minute: 0 },
    { hour: 11, minute: 30 },
    { hour: 12, minute: 0 },
    { hour: 12, minute: 30 },
    { hour: 13, minute: 0 },
    { hour: 13, minute: 30 },
    { hour: 14, minute: 0 },
    { hour: 14, minute: 30 },
    { hour: 15, minute: 0 },
    { hour: 15, minute: 30 },
    { hour: 16, minute: 0 },
    { hour: 16, minute: 30 },
    { hour: 17, minute: 0 },
    { hour: 17, minute: 30 },
    { hour: 18, minute: 0 },
    { hour: 18, minute: 30 },
    { hour: 19, minute: 0 },
    { hour: 19, minute: 30 },
    { hour: 20, minute: 0 },
    { hour: 20, minute: 30 },
    { hour: 21, minute: 0 },
    { hour: 21, minute: 30 },
    { hour: 22, minute: 0 },
    { hour: 22, minute: 30 },
];
export const quizTimes = [
    { hour: 0, minute: 30 },
    { hour: 1, minute: 0 },
    { hour: 1, minute: 30 },
    { hour: 2, minute: 0 },
    { hour: 2, minute: 30 },
    { hour: 3, minute: 0 },
    { hour: 3, minute: 30 },
    { hour: 4, minute: 0 },
    { hour: 4, minute: 30 },
    { hour: 5, minute: 0 },
    { hour: 6, minute: 0 },
    { hour: 7, minute: 0 },
    { hour: 8, minute: 0 },
    { hour: 9, minute: 0 },
    { hour: 10, minute: 0 },
    { hour: 15, minute: 0 },
    { hour: 20, minute: 0 },
    { hour: 30, minute: 0 },
];

export function sortOnlyResources(resourcesUnsorted, modules) {
    let resourcesPerModule = {};
    for (let x = 1; x < modules?.length + 1; x++) {
        resourcesPerModule[x] = resourcesUnsorted?.filter((resource) => resource?.localData?.module === x);
        resourcesPerModule[x]?.sort(function (a, b) {
            const positionA = a?.localData?.group ? a.localData.position + a?.localData?.groupPosition / 10 : a.localData.position;
            const positionB = b?.localData?.group ? b.localData.position + b?.localData?.groupPosition / 10 : b.localData.position;
            if (positionA < positionB) return -1;
            if (positionA > positionB) return 1;
            return 0;
        });
    }
    return resourcesPerModule;
}

export function arrayEquals(a, b) {
    return Array.isArray(a) && Array.isArray(b) && a.length === b.length && a.every((val) => b.includes(val));
}

export function sortGroupAndPathResources(all_resources) {
    return all_resources?.sort(function (a, b) {
        const positionA = a?.localData?.group ? a.localData.position + a?.localData?.groupPosition / 10 : a.localData.position;
        const positionB = b?.localData?.group ? b.localData.position + b?.localData?.groupPosition / 10 : b.localData.position;
        if (positionA < positionB) return -1;
        if (positionA > positionB) return 1;
        return 0;
    });
}

export function makeid(length) {
    var result = "";
    var characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
}

export function shuffleArr(array = []) {
    for (let i = array.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        const temp = array[i];
        array[i] = array[j];
        array[j] = temp;
    }
    return array;
}

export function groupBy(arr = [], propName) {
    return arr.reduce((result, obj) => {
        const key = obj[propName];
        if (!result[key]) {
            result[key] = [];
        }
        result[key].push(obj);
        return result;
    }, {});
}
export function debounce(fn, delay) {
    let timerId;
    return function (...args) {
        if (timerId) {
            clearTimeout(timerId);
        }
        timerId = setTimeout(() => {
            fn(...args);
            timerId = null;
        }, delay);
    };
}
export function getDaysInCurrentMonth() {
    // Get the current date
    var currentDate = new Date();
    // Get the first day of the month
    var firstDay = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);
    // Get the last day of the month
    var lastDay = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0);
    // Set up an array to hold the dates
    var days = [];
    // Add the first day to the array
    days.push(firstDay);
    // Loop through the remaining days, adding them to the array
    for (var i = firstDay.getDate() + 1; i <= lastDay.getDate(); i++) {
        days.push(new Date(currentDate.getFullYear(), currentDate.getMonth(), i));
    }
    // Return the array of dates
    return days;
}

//EVENTS
export function filterResourcesForEventsByGroupID(resources_overview, group_path_resources_overview = []) {
    const events = [];
    const resources = resources_overview ? Object.values(resources_overview) : [];
    resources?.forEach((resource) => {
        if (resource?.metaData?.type === "event") {
            events.push(resource);
        }
    });
    group_path_resources_overview?.forEach((resource) => {
        if (resource?.metaData?.type === "event") {
            events.push(resource);
        }
    });
    return events;
}
export function sortEvents(events) {
    let eventsSorted = events;
    eventsSorted?.sort(function (a, b) {
        if (new Date(a.typeData.start.toDate()) < new Date(b.typeData.start.toDate())) return -1;
        if (new Date(a.typeData.start.toDate()) > new Date(b.typeData.start.toDate())) return 1;
        return 0;
    });
    return eventsSorted;
}

export function arrangeEventsIntoDays(events) {
    let monthEvents = {};
    if (!events) return;
    for (const dateIndex in events) {
        let day = new Date(events[dateIndex]?.typeData?.start?.toDate())?.getDate();
        let eventData = events[dateIndex];
        if (monthEvents[day]) {
            //case: more than one event per day!
            monthEvents[day].push(eventData);
        } else {
            monthEvents[day] = [eventData];
        }
    }

    return monthEvents;
}
export function groupEventsByYearAndMonth(events) {
    let groupedEvents = {};
    for (let i = 0; i < events?.length; i++) {
        const date = events[i]?.start?.toDate();
        const year = date?.getFullYear();
        if (groupedEvents[year] === undefined) {
            groupedEvents[year] = { months: {} };
        }
        const month = date?.getMonth();
        if (groupedEvents[year]?.months[month] === undefined) {
            groupedEvents[year].months[month] = { events: [] };
        }
        groupedEvents[year]?.months[month]?.events.push(events[i]);
    }
    return groupedEvents;
}
export function groupEventsByYearAndMonth2(events) {
    let groupedEvents = {};
    for (let i = 0; i < events?.length; i++) {
        const date = events[i]?.typeData?.start?.toDate();
        const year = date?.getFullYear();
        if (groupedEvents[year] === undefined) {
            groupedEvents[year] = { months: {} };
        }
        const month = date?.getMonth();
        if (groupedEvents[year]?.months[month] === undefined) {
            groupedEvents[year].months[month] = { events: [] };
        }
        groupedEvents[year]?.months[month]?.events.push(events[i]);
    }
    return groupedEvents;
}

export function sortByPosition(array) {
    array?.sort(function (a, b) {
        if (a.position < b.position) return -1;
        if (a.position > b.position) return 1;
        return 0;
    });
    return array;
}

export function sortResourcesPathOverview(modules, resources_unsorted, selectedGroupIDs) {
    let resourcesPerModule = {};
    //sort resources by module
    for (let x = 1; x < modules.length + 1; x++) {
        //sort resources by module
        resourcesPerModule[x] = resources_unsorted?.filter((resource) => {
            //group events
            const partOfGroup = resource?.localData?.group ? resource?.localData?.group_id === selectedGroupIDs : true;
            if (resource?.localData?.module === x && partOfGroup) return resource;
        });
        resourcesPerModule[x]?.sort(function (a, b) {
            const positionA = a?.localData?.group ? a.localData?.position + a?.localData?.groupPosition / 10 : a.localData?.position;
            const positionB = b?.localData?.group ? b.localData?.position + b?.localData?.groupPosition / 10 : b.localData?.position;
            if (positionA < positionB) return -1;
            if (positionA > positionB) return 1;
            return 0;
        });
    }
    return resourcesPerModule;
}
export function sortResourcesOverviewByModule(
    modules,
    resources_unsorted,
    group_path_resources_overview,
    user_group_ids = [],
    checked_path_resources = [],
    access = true
) {
    let all_resources_object = {};

    let resourcesPerModule = {};
    const finishedModules = [];
    let finishedResources = 0;
    let resources_count = 0;
    let progressPerModule2 = {};

    let uncheckedResourcePositionPerModule = {};
    //sort resources by module
    let resources = resources_unsorted;
    let gr_resources = group_path_resources_overview;

    for (let x = 1; x < modules.length + 1; x++) {
        //sort resources by module
        const remaining_resources = [];
        const remaining_gr_resources = [];

        const module_resources = [];

        resources?.forEach((resource) => {
            all_resources_object = { ...all_resources_object, [resource?.localData?.local_id]: resource };
            const inThisModule = resource?.localData?.module === x;
            if (inThisModule) {
                module_resources.push(resource);
                resources_count = resources_count + 1;
            } else {
                remaining_resources.push(resource);
            }
        });
        resources = remaining_resources;
        if (user_group_ids?.length > 0) {
            gr_resources?.forEach((resource) => {
                all_resources_object = { ...all_resources_object, [resource?.localData?.local_id]: resource };
                //group res
                const inThisModule = resource?.localData?.module === x;
                const partOfGroup = user_group_ids?.includes(resource?.localData?.group_id);
                if (inThisModule && partOfGroup) {
                    module_resources.push(resource);
                    resources_count = resources_count + 1;
                } else {
                    remaining_gr_resources.push(resource);
                }
            });
            gr_resources = remaining_gr_resources;
        }
        resourcesPerModule[x] = sortResources(module_resources);
        progressPerModule2[x] = {
            progress: resourcesPerModule[x]?.filter((resource) => checked_path_resources?.includes(resource?.localData?.local_id))?.length,
            number: resourcesPerModule[x]?.length,
        };
        //Modules done or not
        if (resourcesPerModule[x]?.length > 0 && checkModuleCompleted(resourcesPerModule[x], checked_path_resources)) {
            finishedResources = finishedResources + resourcesPerModule[x]?.length;
            finishedModules.push(x);
        } else {
            uncheckedResourcePositionPerModule[x] = resourcesPerModule[x]?.map((resource) => {
                if (!checked_path_resources?.includes(resource?.localData?.local_id)) {
                    if (access || resource?.localData?.explorable) {
                        return {
                            position: resource?.localData?.position,
                            local_id: resource?.localData?.local_id,
                        };
                    } else {
                        return { position: 1000 };
                    }
                } else {
                    finishedResources = finishedResources + 1;
                    return { position: 1000 };
                }
            });
        }
    }
    return {
        resourcesPerModule,
        uncheckedResourcePositionPerModule,
        finishedModules,
        progressPerModule2,
        finishedResources,
        resources_count,
        all_resources_object,
    };
}
export function sortResources(resources, reverse = false) {
    return resources?.sort(function (a, b) {
        const positionA =
            a?.localData?.module * 10 + (a?.localData?.group ? a.localData?.position + a?.localData?.groupPosition / 10 : a.localData?.position);
        const positionB =
            b?.localData?.module * 10 + (b?.localData?.group ? b.localData?.position + b?.localData?.groupPosition / 10 : b.localData?.position);
        if (positionA < positionB) return reverse ? 1 : -1;
        if (positionA > positionB) return reverse ? -1 : 1;
        return 0;
    });
}

export function sortResourcesOverviewData(resources_unsorted) {
    let resources = resources_unsorted && Object.values(resources_unsorted);
    resources?.sort(function (a, b) {
        const positionA = a?.localData?.group
            ? a.localData?.module * 10 + a.localData?.position + a?.localData?.groupPosition / 10
            : a.localData?.module * 10 + a.localData?.position;
        const positionB = b?.localData?.group
            ? b.localData?.module * 10 + b.localData?.position + b?.localData?.groupPosition / 10
            : b.localData?.module * 10 + b.localData?.position;
        if (positionA < positionB) return -1;
        if (positionA > positionB) return 1;
        return 0;
    });
    return resources;
}

export function sortResourcesOverview(
    modules,
    resources_overview = [],
    group_path_resources_overview = [],
    checked_path_resources = [],
    user_group_ids = [],
    access = true
) {
    const {
        resourcesPerModule,
        uncheckedResourcePositionPerModule,
        finishedModules,
        progressPerModule2,
        finishedResources,
        resources_count,
        all_resources_object,
    } = sortResourcesOverviewByModule(modules, resources_overview, group_path_resources_overview, user_group_ids, checked_path_resources, access); //TODO: if other than your groups should be displayed
    const activeModule = parseInt(Object.keys(uncheckedResourcePositionPerModule)[0]);
    const activeResourcePositionAndLocalID = getActiveResourcePosition(uncheckedResourcePositionPerModule, activeModule);
    const res_next_resource = resources_overview?.find((res) => res?.localData?.local_id === activeResourcePositionAndLocalID?.local_id);
    const next_resource = res_next_resource
        ? res_next_resource
        : group_path_resources_overview?.find((res) => res?.localData?.local_id === activeResourcePositionAndLocalID?.local_id);
    const resources_progress = finishedResources + " / " + resources_count;
    const path_progress = Math.round((finishedResources / resources_count) * 100);

    return { next_resource, resourcesPerModule, path_progress, finishedModules, resources_progress, progressPerModule2, all_resources_object };
}

export function animateNumber(callback, from, to, duration) {
    let start = null,
        animate = (timestamp) => {
            start = start || timestamp;
            let progress = Math.min((timestamp - start) / duration, 1);
            callback(progress * (to - from) + from);
            if (progress < 1) {
                window.requestAnimationFrame(animate);
            }
        };
    window.requestAnimationFrame(animate);
}

export function relDiff(a, b) {
    return (100 * (a - b)) / ((a + b) / 2);
}

export function weightedAverageRounded(old_average, n, m, new_value) {
    let new_average;
    if (n + m === 0) {
        console.log("failure!");
        new_average = old_average;
    } else {
        new_average = (old_average * n + new_value) / (n + m);
    }
    new_average = Math.round(new_average * 10000) / 10000;

    return new_average;
}

export function getRoundedDivision(x = 1, y = 1, decimals = 2, perc = false) {
    if (y === 0) {
        return 0;
    }
    let factor = Math.pow(10, decimals);
    let eps = 1 / (2 * factor);
    const divi = perc ? 1 : factor;
    return Math.round((x / y + eps) * factor) / divi;
}

export function computeSDOnline(old_m2, old_mean, new_mean, n, new_value, firstTime = false) {
    if (n + 1 === 1 || firstTime) {
        return { sd: 0, m2: 0 };
    } else {
        const { sd, m2 } = sdOnlineWelford(old_m2, old_mean, new_mean, n, new_value);
        return { sd, m2 };
    }
}
function sdOnlineWelford(old_m2, old_mean, new_mean, n, new_value) {
    let m2 = old_m2 + (new_value - old_mean) * (new_value - new_mean);
    let sd = Math.sqrt(Math.abs(m2 / n));
    m2 = Math.round(m2 * 10000) / 10000;
    sd = Math.round(sd * 10000) / 10000;
    return { sd, m2 };
}
export function processModuleNoFromRoute(module_no, p_no_of_modules) {
    if (module_no === "undefined" || isNaN(parseInt(module_no)) || parseInt(module_no) > p_no_of_modules) {
        return "1";
    } else {
        return module_no;
    }
}

export function getCumulativeAttachments(numberOfLGsTogether, attachmentsSorted, index) {
    let cumulatedTasks = [];
    for (let i = numberOfLGsTogether; i > 0; i--) {
        cumulatedTasks.push(attachmentsSorted[index + 1 - i]);
    }
    return cumulatedTasks;
}

export function getTimeLeft(resources, checkedTasksPerResource, checked_path_resources) {
    let totalTime = 0;
    let timeWorked = 0;
    let taskBoolean = [];
    //each resource
    resources?.forEach((resource) => {
        const { rsrc_tasks, rsrc_isOptional, rsrc_local_id } = getResourceData(resource);
        let alreadyWorkedOn = false;
        let id = rsrc_local_id;
        if (checkedTasksPerResource) {
            if (checkedTasksPerResource[id]) {
                alreadyWorkedOn = true;
                taskBoolean = checkedTasksPerResource[id];
            }
        }
        if (rsrc_tasks) {
            if (!rsrc_isOptional) {
                if (rsrc_tasks?.length !== 0) {
                    //each task
                    for (let j = 0; j < rsrc_tasks?.length; j++) {
                        const taskTime = rsrc_tasks[j]?.time;
                        if (taskTime && Number.isInteger(taskTime)) {
                            totalTime = totalTime + taskTime;
                            if (alreadyWorkedOn) {
                                if (taskBoolean?.includes(rsrc_tasks[j]?.id)) {
                                    timeWorked = timeWorked + taskTime;
                                }
                            }
                        }
                    }
                }
            }
        }
    });
    return { totalTime, timeWorked };
}
export function getTotalTime(resources, userGroupID) {
    let totalTime = 0;
    //each resource
    resources?.forEach((resource) => {
        const { rsrc_tasks } = getResourceData(resource);
        if (
            rsrc_tasks
            // && groupOfUser
        ) {
            if (!resource?.localData?.optional) {
                if (rsrc_tasks?.length !== 0) {
                    //each task
                    for (let j = 0; j < rsrc_tasks?.length; j++) {
                        const taskTime = rsrc_tasks[j]?.time;
                        if (taskTime && Number.isInteger(taskTime)) {
                            totalTime = totalTime + taskTime;
                        }
                    }
                }
            }
        }
    });
    return totalTime;
}
export function getTimeLeft2(resources_overview, checked_path_resources = [], user_group_ids = []) {
    let totalTime = 0;
    let timeWorked = 0;
    //each resource
    if (resources_overview) {
        const resources = Object.values(resources_overview);
        resources?.forEach((resource) => {
            const relevant_res =
                !resource?.localData?.optional && (!resource?.localData?.group || user_group_ids?.includes(resource?.localData?.group_id));
            if (relevant_res) {
                const id = resource?.localData?.local_id;
                const resource_time = parseInt(resource?.localData?.time_to_complete);
                if (resource_time) totalTime = totalTime + resource_time;
                if (checked_path_resources && checked_path_resources?.includes(id)) {
                    if (resource_time) timeWorked = timeWorked + resource_time;
                }
            }
        });
    }
    return { totalTime, timeWorked };
}

export function getActiveResourcePosition(uncheckedResourcePositionPerModule, activeModule) {
    const activeResourcePositionsOfActiveModule = uncheckedResourcePositionPerModule[activeModule];
    let activeResourcePosition = null;
    let activeResourcePositionAndLocalID;
    if (activeResourcePositionsOfActiveModule) {
        //next_resource is the first unchecked resource
        activeResourcePosition = Math.min(...activeResourcePositionsOfActiveModule.map((a) => a.position));
        activeResourcePositionAndLocalID = activeResourcePositionsOfActiveModule.find((a) => a.position === activeResourcePosition);
    }
    return activeResourcePositionAndLocalID;
}

export function generateThreeDistinctPictures(max, numberOfPictures) {
    let arr = [];
    while (arr.length < numberOfPictures) {
        let r = Math.floor(Math.random() * max);
        if (arr.indexOf(r) === -1) arr.push(r);
    }
    return arr;
}
export function getPosition(string, subString, index) {
    return string?.split(subString, index)?.join(subString)?.length;
}
export function calculateTimeLeft(tasks, task_checks) {
    let timeLeft = 0;
    let totalTime = 0;

    tasks?.map((task) => {
        const { task_isChecked, task_time } = getTaskData(task, task_checks);
        if (Number.isInteger(task_time)) {
            totalTime = totalTime + task_time;
            if (task_checks) {
                if (!task_isChecked) {
                    timeLeft = timeLeft + task_time;
                }
            } else {
                timeLeft = timeLeft + task_time;
            }
        }
    });
    return timeLeft;
}
export function getPastTwelveMonths() {
    const months = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11];
    const currentMonth = new Date().getMonth();
    const pastTwelveMonths = [];
    for (let i = 0; i < 12; i++) {
        pastTwelveMonths.unshift(months[(currentMonth - i + 12) % 12]);
    }
    return pastTwelveMonths;
}

export function getVideoCallProvider(link) {
    const providers = [
        { matchWords: ["teams.microsoft"], provider: "msteams" },
        { matchWords: ["zoom"], provider: "zoom" },
        { matchWords: ["webex"], provider: "webex" },
        { matchWords: ["jitsi"], provider: "jitsi" },
        { matchWords: ["google_hangout"], provider: "google_hangout" },
        { matchWords: ["youtube", "youtu.be"], provider: "youtube" },
    ];
    let video_call_provider = "";
    providers.forEach((provider) => {
        provider.matchWords.forEach((match) => {
            if (link?.includes(match)) {
                video_call_provider = provider.provider;
            }
        });
    });
    return video_call_provider;
}

export const sumPositives = (arr = []) => {
    const isPositive = (num) => typeof num === "number" && num > 0;
    const res = arr.reduce((acc, val) => {
        if (isPositive(val)) {
            acc += val;
        }
        return acc;
    }, 0);
    return res;
};

export function constructTimeVariable(opendate, deadline) {
    const weekDay = days[deadline?.getDay()];
    const day = pad(deadline?.getDate());
    const month = pad(deadline?.getMonth() + 1);
    const year = deadline?.getFullYear();
    const hr = deadline?.getHours();
    const min = pad(deadline?.getMinutes());

    const displayTime = hr + ":" + min;
    const currentYear = new Date().getFullYear();
    const displayDate = year !== currentYear ? day + "." + month + "." + year : day + "." + month;

    let now = Timestamp.fromDate(new Date()).seconds * 1000; //.getTime()
    let open = Math.sign(opendate?.getTime() - now) < 0;
    let closed = Math.sign(opendate?.getTime() - now) > 0;
    let overdue = Math.sign(deadline?.getTime() - now) < 0;

    return { displayTime, displayDate, open, closed, overdue, weekDay };
}
export function pad(num, size = 2) {
    //TODO_M: substring !!different logic!!
    return ("00" + num).substr(-size);
}

export function timeConvert(n, HOUR_UNIT, MIN_UNIT) {
    const hours = Math.floor(n / 60);
    const min = n % 60;
    let time_message = "";
    if (hours > 0) {
        time_message += hours + " " + HOUR_UNIT + " ";
        if (min > 0) {
            time_message += MIN_UNIT ? pad(min) + " " + MIN_UNIT : "";
        } else {
            //time_message += 0 + " " + MIN_UNIT;
        }
    } else {
        if (min > 0) {
            time_message += MIN_UNIT ? min + " " + MIN_UNIT : "";
        } else {
            time_message += MIN_UNIT ? 0 + " " + MIN_UNIT : "";
        }
    }
    return time_message;
}

export function equalArray(array1, array2) {
    // if the other array is a falsy value, return
    if (!array2) return false;
    // compare lengths - can save a lot of time
    if (array1.length != array2.length) return false;
    for (var i = 0, l = array1.length; i < l; i++) {
        // Check if we have nested arrays
        if (array1[i] instanceof Array && array2[i] instanceof Array) {
            // recurse into the nested arrays
            if (!array1[i].equals(array2[i])) return false;
        } else if (array1[i] != array2[i]) {
            // Warning - two different object instances will never be equal: {x:20} != {x:20}
            return false;
        }
    }
    return true;
}
//Converts seconds to hh:mm:ss format
//TODO: check correctness --> had wrong time in timestamps component
export function convertSecTo_hh_mm_ss(t) {
    const h = Math.floor(t / 3600);
    const min_left = t % 3600;
    const min = Math.floor(min_left / 60);
    const sec = min_left % 60;

    let time_display = "";
    if (h > 0) {
        time_display += pad(h) + ":";
    }
    if (min >= 0) {
        time_display += pad(min) + ":";
    }
    if (sec >= 0) {
        time_display += pad(sec);
    } else {
        time_display += 0 + ":";
    }
    return time_display;
}

//TODO: maybe do a convert export function that does not spit out 0 e.g 00:36 --> 36
// export function convertSecTo_hh_mm_ssnoZeros(t) {
//     const h = Math.floor(t / 3600);
//     const min_left = t % 3600;
//     const min = Math.floor(min_left / 60);
//     const sec = min_left % 60;

//     let time_display = "";
//     if (h > 0) {
//         time_display += pad(h) + ":";
//     }
//     if (min >= 0) {
//         time_display += pad(min) + ":";
//     }
//     if (sec >= 0) {
//         time_display += pad(sec);
//     } else {
//         time_display += 0 + ":";
//     }
//     return time_display;
// }

export function mapLinebreaks(description_nobreaks) {
    let description = "";
    if (typeof description_nobreaks != "undefined") {
        description = description_nobreaks.replace(/<br\/>/g, "\n");
        return description;
    } else return description_nobreaks;
}

export function mapNLineBreaks(str) {
    const mappedBreaks = str?.split("\n").map((str) => (
        <>
            {str}
            <br />
        </>
    ));
    return mappedBreaks;
}

export function getRandomInt(max) {
    return Math.floor(Math.random() * max);
}

export function getUniqueRandomInts(max, n_numbers) {
    let arr = [];
    while (arr.length < n_numbers) {
        const r = Math.floor(Math.random() * max) + 1;
        if (arr.indexOf(r) === -1) arr.push(r);
    }
    return arr;
}

export function useTraceUpdate(props) {
    const prev = useRef(props);
    useEffect(() => {
        const changedProps = Object.entries(props).reduce((ps, [k, v]) => {
            if (prev.current[k] !== v) {
                ps[k] = [prev.current[k], v];
            }
            return ps;
        }, {});
        if (Object.keys(changedProps).length > 0) {
        }
        prev.current = props;
    });
}

export function getInitials(name) {
    if (!name) return { fname: "", lname: "" };
    // console.log("NAME", name);
    const names = name.split(" ");
    let fname = null;
    let lname = null;
    fname = names[0][0];
    lname = names[names?.length - 1][0];
    return { fname, lname };
}

export function RNG(seed) {
    // LCG using GCC's constants
    this.m = 0x80000000; // 2**31;
    this.a = 1103515245;
    this.c = 12345;

    this.state = seed ? seed : Math.floor(Math.random() * (this.m - 1));
}
RNG.prototype.nextInt = function () {
    this.state = (this.a * this.state + this.c) % this.m;
    return this.state;
};
RNG.prototype.nextFloat = function () {
    // returns in range [0,1]
    return this.nextInt() / (this.m - 1);
};
RNG.prototype.nextRange = function (start, end) {
    // returns in range [start, end): including start, excluding end
    // can't modulu nextInt because of weak randomness in lower bits
    const rangeSize = end - start;
    const randomUnder1 = this.nextInt() / this.m;
    return start + Math.floor(randomUnder1 * rangeSize);
};
RNG.prototype.choice = function (array) {
    return array[this.nextRange(0, array.length)];
};

//sorts array of objects by key value passed in as "property"
export function dynamicSort(property, reversed = false) {
    var sortOrder = 1;
    if (reversed) sortOrder = -1;
    return function (a, b) {
        const result = a?.[property] < b?.[property] ? -1 : a?.[property] > b?.[property] ? 1 : 0;
        return result * sortOrder;
    };
}
export function dynamicSort2props(property1, property2, reversed = false) {
    var sortOrder = 1;
    if (reversed) sortOrder = -1;
    return function (a, b) {
        const a_ = a?.[property1] + a?.[property2] / 1000;
        const b_ = b?.[property1] + b?.[property2] / 1000;
        const result = a_ < b_ ? -1 : a_ > b_ ? 1 : 0;
        return result * sortOrder;
    };
}

export function dynamicSort2(property1, property2, reversed = false) {
    var sortOrder = 1;
    if (reversed) sortOrder = -1;
    return function (a, b) {
        const a_ = a?.[property1]?.[property2];
        const b_ = b?.[property1]?.[property2];
        const result = a_ < b_ ? -1 : a_ > b_ ? 1 : 0;
        return result * sortOrder;
    };
}
export function meteDataCreatedSort(reversed = false) {
    var sortOrder = 1;
    if (reversed) sortOrder = -1;
    return function (a, b) {
        const result = a.metaData.created_at < b.metaData.created_at ? -1 : a.metaData.created_at > b.metaData.created_at ? 1 : 0;
        return result * sortOrder;
    };
}
export function getMaxYouTubeThumbnail(data) {
    const thumbnailLink = data?.thumbnailMaxRes
        ? data?.thumbnailMaxRes
        : data?.thumbnail_480p
        ? data?.thumbnail_480p
        : data?.thumbnail_360p
        ? data?.thumbnail_360p
        : data?.thumbnail_180p;
    return thumbnailLink;
}

export function getMaxVimeoThumbnail(data) {
    const thumbnailLink = data?.thumbnailMaxRes
        ? data?.thumbnailMaxRes
        : data?.thumbnail_720p
        ? data?.thumbnail_720p
        : data?.thumbnail_540p
        ? data?.thumbnail_540p
        : data?.thumbnail_360p
        ? data?.thumbnail_360p
        : data?.thumbnail_166p;
    return thumbnailLink;
}

//TODO_M: merge with other converter export function?? what is the other?
export function secondsToHms(d) {
    //NOT USED
    let prefixWithZero = (arg) => {
        let numString = arg.toString().replace(/-/g, ""); //different
        if (numString.length === 1) {
            numString = "0" + numString;
        }
        return numString;
    };

    d = Number(d);
    let h = Math.round(d / 3600);
    let m = Math.round((d % 3600) / 60);
    let s = Math.round((d % 3600) % 60);

    m = prefixWithZero(m);
    s = prefixWithZero(s);

    const belowZero = d < 0; //different
    let hms = (belowZero ? "-" : "") + (h > 0 ? h + " : " : "") + m + " : " + s; //different

    // console.log("time", d, belowZero, hms, m);

    return !!hms ? hms : "00 : 00";
}

export function secondsToHM(d) {
    //NOT USED
    let prefixWithZero = (arg) => {
        let numString = arg.toString().replace(/-/g, ""); //different
        if (numString.length === 1) {
            numString = "0" + numString;
        }
        return numString;
    };
    d = Number(d);
    let h = Math.round(d / 60);
    let m = Math.round((d % 3600) / 60);
    m = prefixWithZero(m);
    const belowZero = d < 0; //different
    let hms = (belowZero ? "-" : "") + (h > 0 ? h + " : " : "") + m; //different

    // console.log("time", d, belowZero, hms, m);

    return !!hms ? hms : "00 : 00";
}

export { monthNames, months, months_Capitalized, days }; // a list of exported variables

export function focusElement(element) {
    if (element.current === null) return;
    if (element.current) {
        const focusElement = element.current;
        focusElement.focus();
        focusElement.setSelectionRange(focusElement.value.length, focusElement.value.length);
    }
}
export function isOdd(num) {
    return num % 2 === 1;
}

export function containsChild(parent, child) {
    if (parent && child) {
        return parent !== child && parent.contains(child);
    } else return false;
}

export function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
}

export function brightnessByColor(color) {
    const stringColor = "" + color;
    var m = stringColor.substr(1).match(stringColor.length == 7 ? /(\S{2})/g : /(\S{1})/g);
    if (m)
        var r = parseInt(m[0], 16),
            g = parseInt(m[1], 16),
            b = parseInt(m[2], 16);
    if (typeof r != "undefined") return (r * 299 + g * 587 + b * 114) / 1000;
}

export function increase_brightness(hex, percent) {
    // strip the leading # if it's there
    hex = hex.replace(/^\s*#|\s*$/g, "");

    // convert 3 char codes --> 6, e.g. `E0F` --> `EE00FF`
    if (hex.length == 3) {
        hex = hex.replace(/(.)/g, "$1$1");
    }

    var r = parseInt(hex.substr(0, 2), 16),
        g = parseInt(hex.substr(2, 2), 16),
        b = parseInt(hex.substr(4, 2), 16);

    return (
        "#" +
        (0 | ((1 << 8) + r + ((256 - r) * percent) / 100)).toString(16).substr(1) +
        (0 | ((1 << 8) + g + ((256 - g) * percent) / 100)).toString(16).substr(1) +
        (0 | ((1 << 8) + b + ((256 - b) * percent) / 100)).toString(16).substr(1)
    );
}

//TODO prop should be parked elsewhere
//MEMBER ALGOLIA FILTER
export function createFilterFromPathIDs(path_ids, memberStatus, role) {
    let filter = "";

    path_ids?.forEach((path_id, index) => {
        if (index === path_ids.length - 1) {
            filter = filter + "paths." + memberStatus + "_" + role + "_path_ids: " + path_id;
        } else {
            filter = filter + "paths." + memberStatus + "_" + role + "_path_ids: " + path_id + " OR ";
        }
    });
    return filter;
}
export function getCuratorData(curator) {
    //USER
    const c_name = curator?.user?.name;
    const c_prefix = curator?.user?.prefix;
    const c_image = curator?.user?.image;
    const c_description = curator?.user?.description;

    ///METADATA
    const c_id = curator?.metaData?.id;
    const c_roles = curator?.metaData?.roles;
    const c_cover_img = curator?.metaData?.images?.cover_img;

    const c_stats = curator?.stats;

    //BACKGROUND
    const c_profession = curator?.background?.profession;
    const c_degree = curator?.background?.degree;

    const c_city = curator?.background?.location?.city;
    const c_country = curator?.background?.location?.country;

    //CONTENT
    const c_path_ids = curator?.content?.curator_path_ids ? curator?.content?.curator_path_ids : [];
    const c_paths_by_space = curator?.content?.paths_by_space ? curator?.content?.paths_by_space : {};
    const c_top_paths = curator?.content?.top_paths;
    const c_spaces = curator?.content?.spaces || [];

    const c_has_path_ids = Object.keys(c_paths_by_space)?.length;

    //SOCIAL MEDIA
    const social_media_list = ["instagram", "linkedin", "youtube", "twitter", "github"];
    const c_social_media = curator?.social_media;
    const c_instagram = c_social_media?.instagram;
    const c_linked_in = c_social_media?.linked_in;
    const c_youtube = c_social_media?.youtube;
    const c_twitter = c_social_media?.twitter;
    const c_github = c_social_media?.github;

    return {
        c_id,
        c_name,
        c_prefix,
        c_image,
        c_cover_img,
        c_description,
        c_profession,
        c_degree,
        c_city,
        c_country,
        c_stats,
        c_roles,
        c_top_paths,
        c_spaces,
        c_path_ids,
        c_paths_by_space,
        c_has_path_ids,
        //SOCIAL MEDIA
        c_social_media,
        social_media_list,
        c_instagram,
        c_linked_in,
        c_youtube,
        c_twitter,
        c_github,
    };
}

export function createFilterFromPathIDsForAllStatus(path_ids, role) {
    let filter = "";
    const statuse = ["active", "inactive", "deleted"];
    statuse.forEach((status, index1) => {
        path_ids?.forEach((path_id, index2) => {
            if ((index1 + 1) * (index2 + 1) === path_ids.length * statuse.length) {
                filter = filter + "paths." + status + "_" + role + "_path_ids: " + path_id;
            } else {
                filter = filter + "paths." + status + "_" + role + "_path_ids: " + path_id + " OR ";
            }
        });
    });
    return filter;
}

export function truncateString(string, max_length) {
    if (!string) return;
    if (string.length > max_length) {
        return string.substring(0, max_length - 3) + "...";
    } else return string;
}

//KEYS
export const keyPressResourceLearningMode = (
    e,
    handleNextPrev,
    handlePressNext,
    isBannerCollapsed,
    setBannerCollapsed,
    markedAsDone,
    markAsDone,
    setShowResourceSlider,
    openResourcesChannel
) => {
    let { key, code } = e;
    key = key.toLowerCase();
    code = code.toLowerCase();
    let { shiftKey: isShiftKeyPressed } = e;
    if (!isShiftKeyPressed) return;
    else if (isShiftKeyPressed) {
        const isEnter = key === "enter" || code === "enter";
        const isLeft = key === "arrowleft" || code === "arrowleft";
        const isUp = key === "arrowup" || code === "arrowup";
        const isDown = key === "arrowdown" || code === "arrowdown";
        const isRight = key === "arrowright" || code === "arrowright";
        const isSpace = key === " " || code === "space";
        // console.log("KEYPRESS", isEnter, isShiftKeyPressed, isSpace);
        if (isEnter) {
            if (isEnter && isShiftKeyPressed) isBannerCollapsed && !markedAsDone ? markAsDone() : !isBannerCollapsed ? handlePressNext(e) : null;
        } else if (isLeft) {
            if (!isBannerCollapsed) setBannerCollapsed(true);
            handleNextPrev(e, -1);
        } else if (isRight) {
            if (!isBannerCollapsed) setBannerCollapsed(true);
            handleNextPrev(e, 1);
        } else if (isUp) {
            if (!isBannerCollapsed) setBannerCollapsed(true);
            setShowResourceSlider(true);
        } else if (isDown) {
            if (!isBannerCollapsed) setBannerCollapsed(true);
            setShowResourceSlider(false);
        } else if (isSpace && openResourcesChannel) {
            openResourcesChannel(e);
        }
    }
};

export function getOriginByArea(area, space_id, path_id, module_pos = 1) {
    let path;
    switch (area) {
        case "path":
            path = "path/" + path_id;
            break;
        case "learn":
            path = "path/" + path_id + "/learn/" + module_pos;
            break;
        case "edit":
            path = "path/" + path_id + "/learn/edit/" + module_pos;
            break;
        case "user":
            path = "user";
            break;
        case "space":
            path = "space/" + space_id;
            break;
        default:
            path = area;
            break;
    }
    return path;
}

export function transformAndSortTags2(tags) {
    const tagss = [];
    if (tags && Object.keys(tags)?.length > 0 && Object?.entries(tags)) {
        for (const [key, value] of Object.entries(tags)) {
            tagss.push({ value: key, label: value });
        }
        return tagss.sort(dynamicSort("label"));
    } else {
        return [];
    }
}
export async function transformTags(tags, hasTag, current_tags = [], source_lang) {
    const new_tags = [];
    const q_tags = [];
    for (let index = 0; index < tags?.length; index++) {
        const t = tags[index];
        let trans_t = transformTag(t);
        if (!current_tags.includes(trans_t) && !hasTag(trans_t)) {
            //check if new
            if (source_lang !== "en") {
                // USE ENGLISH AS TAG KEY
                const res = await translateTag(t, source_lang, "en-US");
                console.log(res);
                console.log(res?.data?.text);
                trans_t = res?.data?.text ? res?.data?.text : t; //IF Translation did not work use source_lang as tag
                trans_t = transformTag(trans_t);
            } else {
                trans_t = transformTag(t);
            }
            new_tags.push({ key: trans_t, value: capitalizeFirstLetter(t) });
        }
        q_tags.push(trans_t);
    }
    return { new_tags, q_tags };
}

export function transformTag(tag) {
    return tag
        .toUpperCase()
        .replace(/[^\w\s]/gi, "_")
        .replaceAll(" ", "_"); // remove special chars
}

export function transformTags2(tags) {
    const new_tags = [];
    tags.forEach((tag) => {
        const upper_case = tag.toUpperCase();
        const space_filled = upper_case.replace(/[^\w\s]/gi, "_").replaceAll(" ", "_"); // remove special chars
        new_tags.push(space_filled);
    });
    return new_tags;
}
export function transformAndSortTags(tags, translateTags) {
    const tagss = [];
    tags.forEach((tag) => {
        tagss.push({ value: tag, label: translateTags(tag) });
    });
    return tagss.sort(dynamicSort("label"));
}
export function transformAndSortPoolTags(tags = {}, translateTags) {
    const tagss = [];
    Object.entries(tags).forEach(([tag, count]) => {
        tagss.push({ value: tag, label: translateTags(tag), count: count });
    });
    return tagss.sort(dynamicSort("count", true));
}

export function removeAccentsAndDiacriticsFromTags(tags, input) {
    return tags?.filter((tag) =>
        tag?.label
            ?.toLowerCase()
            ?.normalize("NFD")
            ?.replace(/[\u0300-\u036f]/g, "")
            ?.includes(
                input
                    ?.toLowerCase()
                    .normalize("NFD")
                    ?.replace(/[\u0300-\u036f]/g, "")
            )
    );
}
export function removeAccentsAndDiacritics(word) {
    return word
        ?.toLowerCase()
        .normalize("NFD")
        .replace(/[\u0300-\u036f]/g, "");
}

export function stringSimilarity(s1, s2) {
    var longer = s1;
    var shorter = s2;
    if (s1.length < s2.length) {
        longer = s2;
        shorter = s1;
    }
    var longerLength = longer.length;
    if (longerLength == 0) {
        return 1.0;
    }
    return (longerLength - editDistance(longer, shorter)) / parseFloat(longerLength);
}

function editDistance(s1, s2) {
    s1 = s1.toLowerCase();
    s2 = s2.toLowerCase();

    var costs = new Array();
    for (var i = 0; i <= s1.length; i++) {
        var lastValue = i;
        for (var j = 0; j <= s2.length; j++) {
            if (i == 0) costs[j] = j;
            else {
                if (j > 0) {
                    var newValue = costs[j - 1];
                    if (s1.charAt(i - 1) != s2.charAt(j - 1)) newValue = Math.min(Math.min(newValue, lastValue), costs[j]) + 1;
                    costs[j - 1] = lastValue;
                    lastValue = newValue;
                }
            }
        }
        if (i > 0) costs[s2.length] = lastValue;
    }
    return costs[s2.length];
}

export function getAllGroupResourceIDs(g_resourcesPerPath) {
    const all_ids = [];
    if (g_resourcesPerPath) {
        Object.entries(g_resourcesPerPath).forEach(([path_id, group_resources]) => {
            if (Object.keys(group_resources)?.length > 0) {
                all_ids.push(...Object.keys(group_resources));
            }
        });
    }
    return all_ids;
}

export function createImageFileName(file) {
    const file_size = parseInt((file.size / 1024 / 1024).toFixed(4)); // MB
    const file_id = makeid(6);
    const lastDot = file.name.lastIndexOf(".");
    let ext = file.name.substring(lastDot + 1);
    const file_type_1 = file.type.substring(0, file.type.lastIndexOf("/"));
    let file_format = file.type.replace(file_type_1 + "/", "");
    file_format = file_format === "jpg" ? "jpeg" : file_format;
    ext = ext === "jpg" ? "jpeg" : ext;
    const file_type = file_format === "pdf" ? "pdf" : file_type_1;
    let file_name_with_format, file_name_without_format;
    if (ext === file_format) {
        const fileName = file.name
            .substring(0, lastDot)
            .replace(/[^\w\s]/gi, "")
            .replaceAll(" ", "_");
        file_name_with_format = fileName + "." + file_format;
        file_name_without_format = fileName;
    } else {
        const fileName = file.name.replace(/[^\w\s]/gi, "").replaceAll(" ", "_");
        file_name_with_format = fileName + "." + file_format;
        file_name_without_format = fileName;
    }
    return { file_name_with_format, file_name_without_format, file_type, file_format, file_size, file_id };
}

export function extractHTMLTags(s) {
    var span = document.createElement("span");
    span.innerHTML = s;

    var children = span.querySelectorAll("*");
    for (var i = 0; i < children.length; i++) {
        if (children[i].textContent) children[i].textContent += " ";
        else children[i].innerText += " ";
    }
    return [span.textContent || span.innerText].toString().replace(/ +/g, " ");
}

//DOMAIN
export function getParameterFromURL(item) {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const value = urlParams.get(item);
    return value;
}

//ALGOLIA
export const emptyAlgoliaReq = {
    hits: [],
    nbHits: 0,
    nbPages: 0,
    page: 0,
    processingTimeMS: 0,
    hitsPerPage: 0,
    exhaustiveNbHits: false,
    query: "",
    params: "",
};

//VALIDATIONS
export const validateEmail = (email) => {
    return String(email).replace(" ", "").toLowerCase().match(email_regex);
};

export function getLanguageString(langShort, translate) {
    let langString;
    switch (langShort.toLowerCase()) {
        case "en":
            langString = translate("ENGLISH");
            break;
        case "de":
            langString = translate("GERMAN");
            break;
    }
    return langString;
}
