import { useCallback, useEffect, useRef, useState } from "react";

//LexicalLib
import {
    CAN_REDO_COMMAND,
    CAN_UNDO_COMMAND,
    UNDO_COMMAND,
    REDO_COMMAND,
    SELECTION_CHANGE_COMMAND,
    FORMAT_TEXT_COMMAND,
    $getSelection,
    $isRangeSelection,
    $isRootOrShadowRoot,
    COMMAND_PRIORITY_CRITICAL,
} from "lexical";
import { $isCodeNode, CODE_LANGUAGE_MAP } from "@lexical/code";
import { $isLinkNode, TOGGLE_LINK_COMMAND } from "@lexical/link";
import { $isParentElementRTL, $wrapNodes, $getSelectionStyleValueForProperty, $patchStyleText } from "@lexical/selection";
import { $getNearestNodeOfType, mergeRegister, $findMatchingParent } from "@lexical/utils";
import { INSERT_ORDERED_LIST_COMMAND, INSERT_UNORDERED_LIST_COMMAND, REMOVE_LIST_COMMAND, $isListNode, ListNode } from "@lexical/list";
import { $createQuoteNode, $isHeadingNode } from "@lexical/rich-text";
import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";

//ui
import classes from "./ToolbarMaxPlugin.module.css";
import joinClasses from "../../../../utils/joinClasses";

//utils
import { IS_APPLE } from "../../utils/environments";
import { sanitizeUrl } from "../../utils/url-utils";
import { getSelectedNode } from "../../utils/getSelectedNode";

//components
import BlockFormatDropDown from "../../components/BlockFormatDropdown/BlockFormatDropdown";
import LexicalColorPicker from "../../components/LexicalColorPicker/LexicalColorPicker";
import FontFormatDropdown from "../../components/FontFormatDropdown/FontFormatDropdown";
import AlignmentFormatDropdown from "../../components/AlignmentFormatDropdown/AlignmentFormatDropdown";
import InsertDropdown from "../../components/InsertDropdown/InsertDropdown";
import { Marker } from "../../../../assets/icons/Icons";
import { useLanguage } from "../../../../contexts/LanguageContext";
import DropDown from "../../components/LexicalDropDown/LexicalDropDown";

const LowPriority = 1;

export function ToolbarDivider() {
    return <div className={classes.ToolbarDivider} />;
}

const blockTypeToBlockName = {
    bullet: "Bulleted List",
    check: "Check List",
    code: "Code Block",
    h1: "Heading 1",
    h2: "Heading 2",
    h3: "Heading 3",
    h4: "Heading 4",
    h5: "Heading 5",
    h6: "Heading 6",
    number: "Numbered List",
    paragraph: "Normal",
    quote: "Quote",
};

export default function ToolbarMaxPlugin({ editorConfig, handleImageUpload }) {
    const { translate } = useLanguage();
    const [editor] = useLexicalComposerContext();
    const [activeEditor, setActiveEditor] = useState(editor);
    const toolbarRef = useRef(null);
    const [canUndo, setCanUndo] = useState(false);
    const [canRedo, setCanRedo] = useState(false);
    const [blockType, setBlockType] = useState("paragraph");
    const [selectedElementKey, setSelectedElementKey] = useState(null);
    const [isRTL, setIsRTL] = useState(false);
    const [isLink, setIsLink] = useState(false);
    const [isBold, setIsBold] = useState(false);
    const [isItalic, setIsItalic] = useState(false);
    const [isUnderline, setIsUnderline] = useState(false);
    const [isStrikethrough, setIsStrikethrough] = useState(false);
    const [isSubscript, setIsSubscript] = useState(false);
    const [isSuperscript, setIsSuperscript] = useState(false);
    const [isCode, setIsCode] = useState(false);
    // const [isBulletList, setIsBulletList] = useState(false);
    const [isEditable, setIsEditable] = useState(() => editor.isEditable());
    const [fontFamily, setFontFamily] = useState("Soleil");
    // const [fontSize, setFontSize] = useState("15px");
    const [fontColor, setFontColor] = useState("#000");
    const [bgColor, setBgColor] = useState("transparent");
    // const [isNumberList, setIsNumberList] = useState(false);
    // const [isQuote, setIsQuote] = useState(false);
    // const [isCode, setIsCode] = useState(false);
    const [codeLanguage, setCodeLanguage] = useState("");
    // const [modal, setModal] = useState(null);

    //* FONTCOLORPICKER
    // const fontColorPicker = useRef();
    // useClickAway(fontColorPicker, (event) => {
    //     if (event.target?.id !== "FontColorPicker") {
    //         setShowFontColorPicker(false);
    //     }
    // });

    // //* BGCOLORPICKER
    // const bgColorPicker = useRef();
    // useClickAway(bgColorPicker, (event) => {
    //     if (event.target?.id !== "BGPicker") {
    //         setShowFontBackdropPicker(false);
    //     }
    // });

    const updateToolbar = useCallback(() => {
        const selection = $getSelection();
        if ($isRangeSelection(selection)) {
            const anchorNode = selection.anchor.getNode();
            let element =
                anchorNode.getKey() === "root"
                    ? anchorNode
                    : $findMatchingParent(anchorNode, (e) => {
                          const parent = e.getParent();
                          return parent !== null && $isRootOrShadowRoot(parent);
                      });

            if (element === null) {
                element = anchorNode.getTopLevelElementOrThrow();
            }

            const elementKey = element.getKey();
            const elementDOM = activeEditor.getElementByKey(elementKey);

            // Update text format
            setIsBold(selection.hasFormat("bold"));
            setIsItalic(selection.hasFormat("italic"));
            setIsUnderline(selection.hasFormat("underline"));
            setIsStrikethrough(selection.hasFormat("strikethrough"));
            setIsSubscript(selection.hasFormat("subscript"));
            setIsSuperscript(selection.hasFormat("superscript"));
            setIsCode(selection.hasFormat("code"));
            setIsRTL($isParentElementRTL(selection));

            // Update links
            const node = getSelectedNode(selection);
            const parent = node.getParent();
            if ($isLinkNode(parent) || $isLinkNode(node)) {
                setIsLink(true);
            } else {
                setIsLink(false);
            }

            if (elementDOM !== null) {
                setSelectedElementKey(elementKey);
                if ($isListNode(element)) {
                    const parentList = $getNearestNodeOfType(anchorNode, ListNode);
                    const type = parentList ? parentList.getListType() : element.getListType();
                    setBlockType(type);
                } else {
                    const type = $isHeadingNode(element) ? element.getTag() : element.getType();
                    if (type in blockTypeToBlockName) {
                        setBlockType(type);
                    }
                    if ($isCodeNode(element)) {
                        const language = element.getLanguage();
                        setCodeLanguage(language ? CODE_LANGUAGE_MAP[language] || language : "");
                        return;
                    }
                }
            }
            // Handle buttons
            // setFontSize($getSelectionStyleValueForProperty(selection, "font-size", "15px"));
            setFontColor($getSelectionStyleValueForProperty(selection, "color", "#000"));
            setBgColor($getSelectionStyleValueForProperty(selection, "background-color", "#fff"));
            setFontFamily($getSelectionStyleValueForProperty(selection, "font-family", "Soleil"));
        }
    }, [activeEditor]);

    useEffect(() => {
        return editor.registerCommand(
            SELECTION_CHANGE_COMMAND,
            (_payload, newEditor) => {
                updateToolbar();
                setActiveEditor(newEditor);
                return false;
            },
            COMMAND_PRIORITY_CRITICAL
        );
    }, [editor, updateToolbar]);

    const applyStyleText = useCallback(
        (styles) => {
            activeEditor.update(() => {
                const selection = $getSelection();
                if ($isRangeSelection(selection)) {
                    $patchStyleText(selection, styles);
                }
            });
        },
        [activeEditor]
    );

    const onFontColorSelect = useCallback(
        (value) => {
            applyStyleText({ color: value });
        },
        [applyStyleText]
    );

    const onBgColorSelect = useCallback(
        (value) => {
            applyStyleText({ "background-color": value });
        },
        [applyStyleText]
    );

    const formatBulletList = () => {
        if (blockType !== "bullet") {
            editor.dispatchCommand(INSERT_UNORDERED_LIST_COMMAND, undefined);
        } else {
            editor.dispatchCommand(REMOVE_LIST_COMMAND, undefined);
        }
    };

    const formatNumberedList = () => {
        if (blockType !== "number") {
            editor.dispatchCommand(INSERT_ORDERED_LIST_COMMAND, undefined);
        } else {
            editor.dispatchCommand(REMOVE_LIST_COMMAND, undefined);
        }
    };

    // const formatQuote = () => {
    //     if (blockType !== "quote") {
    //         editor.update(() => {
    //             const selection = $getSelection();
    //             if ($isRangeSelection(selection)) {
    //                 $wrapNodes(selection, () => $createQuoteNode());
    //             }
    //         });
    //     } else {
    //         editor.update(() => {
    //             const selection = $getSelection();
    //             if ($isRangeSelection(selection)) {
    //                 $wrapNodes(selection, () => $createParagraphNode());
    //             }
    //         });
    //     }
    // };

    const insertLink = useCallback(() => {
        if (!isLink) {
            editor.dispatchCommand(TOGGLE_LINK_COMMAND, sanitizeUrl("https://"));
        } else {
            editor.dispatchCommand(TOGGLE_LINK_COMMAND, null);
        }
    }, [editor, isLink]);

    useEffect(() => {
        return mergeRegister(
            editor.registerUpdateListener(({ editorState }) => {
                editorState.read(() => {
                    updateToolbar();
                });
            }),
            editor.registerCommand(
                SELECTION_CHANGE_COMMAND,
                (_payload, newEditor) => {
                    updateToolbar();
                    return false;
                },
                LowPriority
            ),
            editor.registerCommand(
                CAN_UNDO_COMMAND,
                (payload) => {
                    setCanUndo(payload);
                    return false;
                },
                LowPriority
            ),
            editor.registerCommand(
                CAN_REDO_COMMAND,
                (payload) => {
                    setCanRedo(payload);
                    return false;
                },
                LowPriority
            )
        );
    }, [editor, updateToolbar]);

    const SelectedFontColorIcon = (
        <div className={classes.SelectedFontIcon}>
            <div className={classes.Text}>A</div>
            <div className={classes.FontColor} style={{ backgroundColor: fontColor }}></div>
        </div>
    );

    const SelectedBGColorIcon = (
        <div className={classes.SelectedFontIcon}>
            <div className={classes.Icon}>
                <Marker size={"15px"} />
            </div>
            <div className={classes.FontColor} style={{ backgroundColor: bgColor }}></div>
        </div>
    );

    useEffect(() => {
        console.log("COLOR CHANGED", fontColor);
    }, [fontColor]);

    return (
        <div className={classes.ToolbarMaxPlugin} ref={toolbarRef}>
            {editorConfig?.redoUndo ? (
                <>
                    <button
                        disabled={!canUndo || !isEditable}
                        onClick={() => {
                            editor.dispatchCommand(UNDO_COMMAND, undefined);
                        }}
                        title={IS_APPLE ? "Undo (⌘Z)" : "Undo (Ctrl+Z)"}
                        className={classes.ToolbarBtn}
                        aria-label="Undo"
                    >
                        <i className={joinClasses(classes.Icon, classes.Undo)} />
                    </button>
                    <button
                        disabled={!canRedo || !isEditable}
                        onClick={() => {
                            editor.dispatchCommand(REDO_COMMAND, undefined);
                        }}
                        title={IS_APPLE ? "Redo (⌘Y)" : "Redo (Ctrl+Y)"}
                        className={classes.ToolbarBtn}
                        aria-label="Redo"
                    >
                        <i className={joinClasses(classes.Icon, classes.Redo)} />
                    </button>
                </>
            ) : null}
            {editorConfig?.insertBlank ? (
                <>
                    <ToolbarDivider />
                    <button
                        onClick={() => {
                            editor.dispatchCommand(FORMAT_TEXT_COMMAND, "strikethrough");
                        }}
                        className={joinClasses(classes.ToolbarBtn, classes.InsertBlank, isStrikethrough ? classes.Active : null)}
                        aria-label="Format Strikethrough"
                    >
                        <i className={joinClasses(classes.Icon, classes.Blanks)} />
                        <div className={classes.Text}>{translate("QR_MAKE_BLANK")}</div>
                    </button>
                </>
            ) : null}
            {editorConfig?.redoUndo ? <ToolbarDivider /> : null}
            {/* TODO_M: add config conditinal rendering */}
            {blockType in blockTypeToBlockName && activeEditor === editor && (
                <BlockFormatDropDown blockTypeToBlockName={blockTypeToBlockName} disabled={!isEditable} blockType={blockType} editor={editor} />
            )}
            <ToolbarDivider />
            {editorConfig?.bold ? (
                <button
                    onClick={() => {
                        editor.dispatchCommand(FORMAT_TEXT_COMMAND, "bold");
                    }}
                    className={joinClasses(classes.ToolbarBtn, isBold ? classes.Active : null)}
                    aria-label="Format Bold"
                >
                    <i className={joinClasses(classes.Icon, classes.Bold)} />
                </button>
            ) : null}
            {editorConfig?.italic ? (
                <button
                    onClick={() => {
                        editor.dispatchCommand(FORMAT_TEXT_COMMAND, "italic");
                    }}
                    className={joinClasses(classes.ToolbarBtn, isItalic ? classes.Active : null)}
                    aria-label="Format Italics"
                >
                    <i className={joinClasses(classes.Icon, classes.Italic)} />
                </button>
            ) : null}
            {editorConfig?.underline ? (
                <button
                    onClick={() => {
                        editor.dispatchCommand(FORMAT_TEXT_COMMAND, "underline");
                    }}
                    className={joinClasses(classes.ToolbarBtn, isUnderline ? classes.Active : null)}
                    aria-label="Format Underline"
                >
                    <i className={joinClasses(classes.Icon, classes.Underline)} />
                </button>
            ) : null}
            {editorConfig?.changeColor ? (
                <DropDown
                    disabled={!isEditable}
                    buttonClassName={joinClasses(classes.ToolbarBtn, classes.ColorPicker)}
                    buttonAriaLabel="Formatting text color"
                    buttonIconClassName={joinClasses(classes.Icon)}
                    icon={SelectedFontColorIcon}
                >
                    <LexicalColorPicker
                        color={fontColor}
                        onChange={onFontColorSelect}
                        customColors={[
                            { label: "Course Colors", colors: ["#002966", "#0066cc", "#00aca6"] },
                            { label: "Space Colors", colors: [] },
                        ]}
                    />
                </DropDown>
            ) : null}
            {editorConfig?.changeHighlight ? (
                <DropDown
                    disabled={!isEditable}
                    buttonClassName={joinClasses(classes.ToolbarBtn, classes.ColorPicker)}
                    buttonAriaLabel="Formatting background color"
                    buttonIconClassName={joinClasses(classes.Icon)}
                    icon={SelectedBGColorIcon}
                >
                    <LexicalColorPicker color={bgColor} onChange={onBgColorSelect} />
                </DropDown>
            ) : null}
            {editorConfig?.changeColor || editorConfig?.changeHighlight ? <ToolbarDivider /> : null}
            {editorConfig?.bulletList ? (
                <button
                    onClick={formatBulletList}
                    className={joinClasses(classes.ToolbarBtn, blockType === "bullet" ? classes.Active : null)}
                    aria-label="Format Bullet-List"
                >
                    <i className={joinClasses(classes.Icon, classes.BulletList)} />
                </button>
            ) : null}
            {editorConfig?.numberedList ? (
                <button
                    onClick={formatNumberedList}
                    className={joinClasses(classes.ToolbarBtn, blockType === "number" ? classes.Active : null)}
                    aria-label="Format Numbered-List"
                >
                    <i className={joinClasses(classes.Icon, classes.NumberedList)} />
                </button>
            ) : null}
            {editorConfig?.link ? (
                <button onClick={insertLink} className={joinClasses(classes.ToolbarBtn, isLink ? classes.Active : null)} aria-label="Insert Link">
                    <i className={joinClasses(classes.Icon, classes.Link)} />
                </button>
            ) : null}
            <ToolbarDivider />
            {activeEditor === editor ? (
                <FontFormatDropdown
                    isStrikethrough={isStrikethrough}
                    isSubscript={isSubscript}
                    isSuperscript={isSuperscript}
                    disabled={!isEditable}
                    editor={activeEditor}
                />
            ) : null}
            {activeEditor === editor ? <AlignmentFormatDropdown isRTL={isRTL} disabled={!isEditable} activeEditor={activeEditor} /> : null}
            <ToolbarDivider />
            <InsertDropdown
                editorConfig={editorConfig}
                disabled={!isEditable}
                editor={editor}
                activeEditor={activeEditor}
                handleImageUpload={handleImageUpload}
            />
            <ToolbarDivider />
        </div>
    );
}
