import { useContext, createContext, useState } from "react";
import { dynamicSort2 } from "../components/Helpers/HelperFunction";

import usePathsData from "../hooks/pathHooks/usePathsData";
import useProgressPathsData from "../hooks/pathHooks/useProgressPathsData";
import useGroupOverviewData from "../pages/Chat/hooks/groupHooks/useGroupOverviewData";
import useUserGroupsData from "../pages/Chat/hooks/groupHooks/useUserGroupsData";
import useMemberLastOnline from "../pages/Chat/hooks/realTimeDBHooks/useMemberLastOnline";
import useCheckedSpaceResources from "../pages/Chat/hooks/useCheckedSpaceResources";
import usePathOverviews from "../pages/Chat/hooks/usePathOverviews";
import useLoadAnnouncementMessages from "../pages/Dashboards/Components/StudentDashboard/hooks/useLoadAnnouncementMessages";
import useDashboardEventsNew from "../pages/Dashboards/Components/StudentDashboard/hooks/useDashboardEventsNew";
import useMyMessages from "../pages/Chat/hooks/messageHooks/useMyMessages";
import useLoadCourseMessages from "../pages/Dashboards/Components/StudentDashboard/hooks/useLoadCourseMessages";
import useGroupMessages from "../pages/Chat/hooks/messageHooks/useGroupMessages";
import useYourTurnQuizDuels from "../components/Path/PracticeQuestions/pages/QuizDuel/hooks/useYourTurnQuizDuels";

const UserDataContext = createContext({
    user_path_overviews: [],
    loading_path_overviews: false,
    user_paths: [],
    loading_user_paths: false,
    error_user_paths: false,
    sorted_user_paths: [],
    user_progress_paths: [],
    loading_user_progress_paths: false,
    error_user_progress_paths: false,

    //GROUPS
    groups: [],
    loading_groups: false,
    getGroupByID: null, // function
    group_overviews: [],
    loading_group_overs: false,
    all_group_overview_res: [],
    my_group_overview_res: [],
    selected_group_ids: [],
    handleChangeSelectedGroupIDs: null, // function

    //SPACE User STATS
    users_checked_space_resources: [],
    users_checked_space_resource_tasks: [],
    time_worked: 0,
    checked_space_tasks_count: 0,
    checked_space_resources_by_tag_date: [],
    space_upvotes: [],
    space_downvotes: [],
    mark_as_read_ids: [],
    show_download_calendar: false,

    //RTDB
    last_online: null, // Likely a date or timestamp
    loading_presence: false,

    //LOADED RES
    loaded_resources: [],
    addNewlyLoadedResource: null, // function
    addNewlyLoadedResourceTitle: null, // function
    loaded_paths: [],
    addNewlyLoadedPath: null, // function
    loaded_paths_stats: [],
    addNewlyLoadedPathStats: null, // function
    loaded_res_overview: [],
    addNewlyLoadedResOverview: null, // function

    //DASHBOARD
    groupsMessages: [], loading_groups_messages: false,
    coursemessages: [], loading_course_messages: false,
    announcement_messages: [], loading_messages: false,
    myMessages:[], loading_my_messages:false,
    has_events:false,dashboard_events: [],loadingEvents: false,
    today_events: [],tmrw_events: [],next7daysEvents: [],
    currentMonthEvents: [], this_year_events: [], next_year_events: [],
    lastSeDays:[], remainingPastEvents:[], remainingFutureEvents:[],
    have_future_events: false, have_past_events: false, noCurrentEvents: false, no_this_year_events: false, no_next_year_events: false,
    your_turn_qds:[], loading_your_turn_qds:false,
});

export function useUserDataContext() {
    return useContext(UserDataContext);
}

//provider
export function UserDataProvider({ mobile = false, children }) {
    //PATHS
    const { user_path_overviews, loading_path_overviews, error_path_overviews } = usePathOverviews();
    const { user_paths, loading_user_paths, error_user_paths } = usePathsData();
    const sorted_user_paths = user_paths?.sort(dynamicSort2("metaData", "title"));
    const { user_progress_paths, loading_user_progress_paths, error_user_progress_paths } = useProgressPathsData();
    //GROUPS
    const { groups, loading_groups } = useUserGroupsData(null, true);

    const [selected_group_ids, setselected_group] = useState([]); //useLocalStorage("path_selected_groups", [])
    function handleChangeSelectedGroupIDs(group_ids) {
        setselected_group(group_ids);
    }
    const { group_overviews, loading_group_overs, all_group_overview_res, my_group_overview_res } = useGroupOverviewData(selected_group_ids, true);
    function getGroupByID(group_id) {
        return groups?.find((group) => group?.metaData?.id === group_id);
    }
    //SPACE
    const {
        users_checked_space_resources,
        users_checked_space_resource_tasks,
        checked_space_tasks_count,
        checked_space_resources_by_tag_date,
        space_upvotes,
        space_downvotes,
        //STATS
        time_worked,
        quiz_history,
        //CONFIG
        mark_as_read_ids,
        show_download_calendar,
    } = useCheckedSpaceResources();

    //USER DATA
    const { last_online, loading_presence } = useMemberLastOnline(); //from RTDB <- ONCE!


    //STATES
    const [loaded_paths, setloaded_paths] = useState({})
    function addNewlyLoadedPath(path, path_id) {
        setloaded_paths(prev => {
            return ({ ...prev, [path_id]: path })
        })
    }
    const [loaded_paths_stats, setloaded_paths_stats] = useState({})
    function addNewlyLoadedPathStats(path_stats, path_id) {
        setloaded_paths_stats(prev => {
            return ({ ...prev, [path_id]: path_stats })
        })
    }
    const [loaded_res_overview, setloaded_res_overview] = useState({})
    function addNewlyLoadedResOverview(path_id, overview) {
        setloaded_res_overview(prev => {
            return ({ ...prev, [path_id]: overview })
        })
    }
    const [loaded_resources, setloaded_resources] = useState({})
    function addNewlyLoadedResource(res) {
        setloaded_resources(prev => {
            return ({ ...prev, [res?.localData?.local_id]: res })
        })
    }
    function addNewlyLoadedResourceTitle(res_local_id, res_title) {
        setloaded_resources(prev => {
            return ({
                ...prev, [res_local_id]: {
                    ...prev?.[res_local_id], metaData:
                        { ...prev?.[res_local_id]?.metaData, title: res_title }
                }
            })
        })
    }

    //DASHBOARD
    const last_online_date = new Date(last_online || 100000);
    last_online_date.setDate(last_online_date.getDate() - 7);//7 days back -> last time online - 7 days
     
    const { myMessages, loading_my_messages } = useMyMessages(last_online_date, loading_presence);
    const { announcement_messages, loading_messages } = useLoadAnnouncementMessages(last_online_date, loading_presence);
    const { coursemessages, loading_course_messages } = useLoadCourseMessages(last_online_date, loading_presence);
    const { groupsMessages, loading_groups_messages } = useGroupMessages(last_online_date, loading_presence)
    const { dashboard_events, loadingEvents, today_events, tmrw_events, next7daysEvents, currentMonthEvents, this_year_events, next_year_events,                                              
    lastSeDays, remainingPastEvents, remainingFutureEvents, have_future_events, have_past_events, noCurrentEvents, no_this_year_events, no_next_year_events,has_events} = useDashboardEventsNew(user_path_overviews, loading_path_overviews, my_group_overview_res, loading_group_overs);
    
    //DUELS
    const { your_turn_qds, loading_your_turn_qds, error_your_turn_qds } = useYourTurnQuizDuels(true);

    const value = {
        user_path_overviews,
        loading_path_overviews,
        user_paths,
        loading_user_paths,
        error_user_paths,
        sorted_user_paths,
        user_progress_paths,
        loading_user_progress_paths,
        error_user_progress_paths,
        //GROUPS
        groups, loading_groups, getGroupByID,
        group_overviews, loading_group_overs, all_group_overview_res, my_group_overview_res,
        selected_group_ids, handleChangeSelectedGroupIDs,
        //SPACE User STATS
        users_checked_space_resources,
        users_checked_space_resource_tasks,
        time_worked,
        checked_space_tasks_count,
        checked_space_resources_by_tag_date,
        space_upvotes,
        space_downvotes,
        mark_as_read_ids,
        show_download_calendar,
        quiz_history,
        //RTDB
        last_online,
        loading_presence,
        //LOADED RES
        loaded_resources, addNewlyLoadedResource, addNewlyLoadedResourceTitle,
        loaded_paths, addNewlyLoadedPath,
        loaded_paths_stats, addNewlyLoadedPathStats,
        loaded_res_overview, addNewlyLoadedResOverview,
        //DASHBOARD
        groupsMessages, loading_groups_messages,
       coursemessages, loading_course_messages,
        announcement_messages, loading_messages,
        myMessages, loading_my_messages,
        has_events, dashboard_events, loadingEvents, today_events, tmrw_events, next7daysEvents, currentMonthEvents, this_year_events, next_year_events,
        lastSeDays, remainingPastEvents, remainingFutureEvents, have_future_events, have_past_events, noCurrentEvents, no_this_year_events, no_next_year_events,
        your_turn_qds, loading_your_turn_qds
    };
    return <UserDataContext.Provider value={value}>{children}</UserDataContext.Provider>;
}
