import classes from "./AlignmentFormatDropdown.module.css";
import joinClasses from "../../../../utils/joinClasses";

import DropDown, { DropDownItem } from "../LexicalDropDown/LexicalDropDown";

import { FORMAT_ELEMENT_COMMAND, INDENT_CONTENT_COMMAND, OUTDENT_CONTENT_COMMAND } from "lexical";
import { useLanguage } from "../../../../contexts/LanguageContext";

export default function AlignmentFormatDropdown({ isRTL, activeEditor, disabled = false }) {
    const { translate } = useLanguage();
    return (
        <DropDown
            disabled={disabled}
            buttonLabel={translate("Align")}
            buttonClassName={classes.BlockControl}
            buttonIconClassName={joinClasses(classes.BlockTypeIcon, classes.LeftAlign)}
            buttonAriaLabel="Formatting options for text alignment"
        >
            <DropDownItem
                onClick={() => {
                    activeEditor.dispatchCommand(FORMAT_ELEMENT_COMMAND, "left");
                }}
                className={classes.DropDownItem}
            >
                <i className={joinClasses(classes.Icon, classes.LeftAlign)} />
                <span className={classes.Text}>{translate("Left Align")}</span>
            </DropDownItem>
            <DropDownItem
                onClick={() => {
                    activeEditor.dispatchCommand(FORMAT_ELEMENT_COMMAND, "center");
                }}
                className={classes.DropDownItem}
            >
                <i className={joinClasses(classes.Icon, classes.CenterAlign)} />
                <span className={classes.Text}>{translate("Center Align")}</span>
            </DropDownItem>
            <DropDownItem
                onClick={() => {
                    activeEditor.dispatchCommand(FORMAT_ELEMENT_COMMAND, "right");
                }}
                className={classes.DropDownItem}
            >
                <i className={joinClasses(classes.Icon, classes.RightAlign)} />
                <span className={classes.Text}>{translate("Right Align")}</span>
            </DropDownItem>
            <DropDownItem
                onClick={() => {
                    activeEditor.dispatchCommand(FORMAT_ELEMENT_COMMAND, "justify");
                }}
                className={classes.DropDownItem}
            >
                <i className={joinClasses(classes.Icon, classes.JustifyAlign)} />
                <span className={classes.Text}>{translate("Justify Align")}</span>
            </DropDownItem>
            <Divider />
            <DropDownItem
                onClick={() => {
                    activeEditor.dispatchCommand(OUTDENT_CONTENT_COMMAND, undefined);
                }}
                className={classes.DropDownItem}
            >
                <i className={joinClasses(classes.Icon, isRTL ? classes.Indent : classes.Outdent)} />
                <span className={classes.Text}>{translate("Outdent")}</span>
            </DropDownItem>
            <DropDownItem
                onClick={() => {
                    activeEditor.dispatchCommand(INDENT_CONTENT_COMMAND, undefined);
                }}
                className={classes.DropDownItem}
            >
                <i className={joinClasses(classes.Icon, isRTL ? classes.Outdent : classes.Indent)} />
                <span className={classes.Text}>{translate("Indent")}</span>
            </DropDownItem>
        </DropDown>
    );
}

const Divider = () => {
    return <div className={classes.Divider}></div>;
};
