import { doc } from "firebase/firestore";
import { useDocumentData } from "react-firehooks";
import { firestore } from "../../app/firebase";
import { getSpaceThemeData } from "../../functions/DataGetterFunctions";

export default function useSpaceThemeData(space_id, condition = true) {
    let spaceRef;
    if (space_id && condition) {
        spaceRef = doc(firestore, "spaces", space_id, "section", "theme");
    }
    const [space_theme, loading_spaceThemeData, errorLoading_spaceThemeData] = useDocumentData(spaceRef);
    const spaceThemeData = getSpaceThemeData(space_theme);

    return {
        space_theme,
        loading_spaceThemeData,
        errorLoading_spaceThemeData,
        ...spaceThemeData,
    };
}
