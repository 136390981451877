import { collection, orderBy, query, where } from "firebase/firestore";
import { useCollectionData } from "react-firehooks";
import { firestore } from "../../../../app/firebase";

import { useSpaceContext } from "../../../../contexts/SpaceContext/SpaceContext";
import { useAuth } from "../../../../contexts/AuthContext";
import { useEffect, useState } from "react";


export default function useGroupOverviewData(selected_group_ids = [], condition = true) {
    const { groupsOfSpace, isCuratorOfSpace, curatedTaughtAndEnrolled, isPathCuratorOfSpace } = useAuth();

    const { space_id } = useSpaceContext();
    const is_curator = isCuratorOfSpace || isPathCuratorOfSpace;
    const group_ids = is_curator ? [...selected_group_ids, ...groupsOfSpace] : groupsOfSpace;
    let groupsQuery, groupsQuery_2, groupsQuery_3;
    const groupresourceOverviewRef = collection(firestore, "spaces", space_id, 'resources_overview_by_group_id');
    //ROLE
    if (group_ids?.length > 0) {
        //TODO_C: load by every 10
        if (group_ids?.length > 10) {
            groupsQuery = query(groupresourceOverviewRef);
        }else{
            groupsQuery = query(groupresourceOverviewRef,
                where("group_id", "in", group_ids), //max of 10!
                );
            }
        }

    //     if (groupsOfSpace?.length > 10) {
    //         groupsQuery_2 = query(
    //             groupresourceOverviewRef,
    //             where("group_id", "in", group_ids.slice(10, 20)), //max of 10!
    //         );
    //     }
    // }
    // if (groupsOfSpace?.length > 20) {
    //     groupsQuery_3 = query(
    //         groupresourceOverviewRef,
    //         where("group_id", "in", group_ids.slice(20, 30)), //max of 10!
    //     );
    // }


    const [groups_1, loading_groups_1, error_groups_1] = useCollectionData(groupsQuery);
    // const [groups_2, loading_groups_2, error_groups_2] = useCollectionData(groupsQuery_2);
    // const [groups_3, loading_groups_3, error_groups_3] = useCollectionData(groupsQuery_3);

    const group_overviews = (groupsOfSpace?.length > 10) ? groups_1?.filter(g => groupsOfSpace.includes(g?.group_id)): groups_1;// groups_2?.length > 0 ? groups_3?.length > 0 ? [...groups_1, ...groups_2, ...groups_3] : [...groups_1, ...groups_2] : groups_1;
    const loading_group_overs = loading_groups_1 //|| loading_groups_2 || loading_groups_3;

    const [all_group_overview_res, setall_group_overview_res] = useState([]);
    const [my_group_overview_res, setmy_group_overview_res] = useState([]);

    useEffect(() => {
        if (!loading_group_overs && group_overviews?.length > 0) {
            const res_group_overviews = group_overviews?.map(group_overview => {
                const group_overview_res = group_overview?.resources || {};
                return (
                    Object.values(group_overview_res)?.filter(res => (is_curator || curatedTaughtAndEnrolled.includes(res?.localData?.path_id)))
                )
            }
            ).flat();
            setall_group_overview_res(res_group_overviews)
            if (is_curator) {
                const res_my_group_overviews = group_overviews?.filter(go => groupsOfSpace.includes(go.group_id))?.map(group_overview => {
                    console.log(Object.values(group_overview.resources));
                    if (!group_overview.resources) {
                        return [];
                    }
                    return Object.values(group_overview.resources).filter(res => curatedTaughtAndEnrolled.includes(res?.localData?.path_id));
                }).flat() || [];
                setmy_group_overview_res(res_my_group_overviews)
            } else {
                //for non-curators: all_group_overview_res same as my_group_overview_res;
                setmy_group_overview_res(res_group_overviews)
            }
        }
    }, [groups_1,loading_group_overs]);

    return { group_overviews, loading_group_overs, all_group_overview_res, my_group_overview_res }
}