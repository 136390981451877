import { transformTag } from "../../../Helpers/HelperFunction";
import { addTagToQPoolTags, updateSpaceAndGlobalTags } from "../../../ResourcePath/Resources/QuizResource/functions/QuizFirestoreFunctions";

// export function toggleFilter(field, filters, alreadyUsedQuestionIDs, setfilter, algoTags, setAlgoFilters, setAlgoTagsAndFilters, setfilterOpen, filterOpen) {
//     console.log(filters);
//     const new_filters = { ...filters, [field]: !filters[field] };
//     console.log(new_filters);
//     setfilter(new_filters);
//     let newFilters, excludeAlreadySelectedFilters, newAlgoFilters = ""

//     if (new_filters.mc) {
//         if (algoTags.length > 0) newFilters = algoTags + "OR (meta.type:multipleChoice)"
//         else newFilters = "(meta.type:multipleChoice)"
//     }
//     if (new_filters.blanks) {
//         if (algoTags.length > 0) newFilters = algoTags + "OR (meta.type:blanks)"
//         else newFilters = "(meta.type:blanks)"
//     }
//     if (new_filters.match) {
//         if (algoTags.length > 0) newFilters = algoTags + "OR (meta.type:match)"
//         else newFilters = "(meta.type:match)"
//     }
//     if (new_filters.excludeAlreadySelected) {
//         alreadyUsedQuestionIDs?.forEach((qid, index) => {
//             if (index === 0) {
//                 excludeAlreadySelectedFilters = "NOT objectID:" + qid
//             } else {
//                 excludeAlreadySelectedFilters = excludeAlreadySelectedFilters + " AND NOT objectID:" + qid
//             }
//         })
//     }
//     if (excludeAlreadySelectedFilters && newFilters) {
//         newAlgoFilters = newFilters + "AND" + "(" + excludeAlreadySelectedFilters + ")"
//     } else if (excludeAlreadySelectedFilters) {
//         newAlgoFilters = excludeAlreadySelectedFilters
//     } else if (newFilters) {
//         newAlgoFilters = newFilters
//     }
//     setAlgoFilters(newAlgoFilters)
//     setAlgoTagsAndFilters(newAlgoFilters)
//     setfilterOpen(!filterOpen);
// }

export function createAlgoliaFilter(filters, algoTags = "", user_id) {
    let algolia_filters = "";
    let all_filter = "";
    let excludeAlreadySelectedFilters = "";
    let only_my_filter = "";

    //(1)All multiple choice filters
    Object.entries(filters.multipleChoice).forEach(([filter, bool]) => {
        if (bool) {
            if (algolia_filters) algolia_filters = algolia_filters + " OR props.type:" + filter
            else algolia_filters = "props.type:" + filter
        }
    })
    //(2) other
    const { multipleChoice, alreadyUsedQuestionIDs,only_my, ...otherFilters } = filters;
    Object.entries(otherFilters).forEach(([filter, bool]) => {
        if (bool) {
            if (algolia_filters) algolia_filters = algolia_filters + " OR meta.type:" + filter
            else algolia_filters = "meta.type:" + filter
        }
    })
    if (filters.alreadyUsedQuestionIDs?.length > 0) {
        filters.alreadyUsedQuestionIDs?.forEach((qid, index) => {
            if (index === 0) {
                excludeAlreadySelectedFilters = "NOT objectID:" + qid
            } else {
                excludeAlreadySelectedFilters = excludeAlreadySelectedFilters + " AND NOT objectID:" + qid
            }
        })
    }
    if (filters.only_my) {
        only_my_filter = "meta.editors:" + user_id
    }
    //JOIN TOGETHER
    if (algolia_filters) {
        all_filter = "(" + algolia_filters + ")";
    }
    if (algoTags) {
        if (algolia_filters) {
            all_filter = "(" + algolia_filters + ") AND " + algoTags;
        } else {
            all_filter = algoTags;
        }
    }
    if (excludeAlreadySelectedFilters) {
        if (all_filter) {
            all_filter = "(" + excludeAlreadySelectedFilters + ") AND " + all_filter;
        } else {
            all_filter = excludeAlreadySelectedFilters;
        }
    }
    if (only_my_filter) {
        if (all_filter) {
            all_filter = "(" + only_my_filter + ") AND " + all_filter;
        } else {
            all_filter = only_my_filter;
        }
    }
    return all_filter;
}



export async function handleChangeTags(e, algoFilters, space_id = null, path_id = null, hasTag = null, source_lang = null, spc_avail_languages = []) {
    let new_tag = e?.find((tag) => tag.__isNew__);
    if (new_tag) {
        new_tag = await handleCreateQPoolTag(space_id, path_id, new_tag.value, hasTag, source_lang, spc_avail_languages);
    }
    const tags = e?.filter((tag) => !tag.__isNew__).map((tag) => tag.value);
    const alltags = new_tag ? [...tags, new_tag.value] : tags; // for display
    const allkeys = new_tag ? [...tags, new_tag.key] : tags; //for filter
    let newAlgoTagsAndFilters;
    const algoTags = createAlgoliaTagsFilter(allkeys);
    if (algoTags && algoFilters) newAlgoTagsAndFilters = algoTags + " AND " + algoFilters;
    else if (algoFilters) newAlgoTagsAndFilters = algoFilters
    else newAlgoTagsAndFilters = algoTags;
    return {algoTags, alltags}
};

function createAlgoliaTagsFilter(tags) {
    let algoTags = "";
    for (let i = 0; i < tags.length; i++) {
        const tag = "(" + tags[i] + ")";
        algoTags = algoTags + tag;
        if (i + 1 < tags.length) {
            algoTags = algoTags + "AND";
        }
    }
    return algoTags
}



export async function handleCreateQPoolTag(space_id, path_id, raw_tag, hasTag, source_lang, spc_avail_languages) {
    const transf_tag = transformTag(raw_tag);
    const new_tag = { key: transf_tag, value: raw_tag };
    //(1)Check if part of space tags
    if (!hasTag(transf_tag)) {
        updateSpaceAndGlobalTags(space_id, path_id, [new_tag], source_lang, spc_avail_languages)
    }
    //(1) QPool tags
    await addTagToQPoolTags(space_id, path_id, new_tag.key)
    return new_tag
};