import { useMemo, useState } from "react";
//UI
import "../../themes/LexicalMaxTheme/LexicalMaxTheme.css";
import classes from "./LexicalMax.module.css";
import joinClasses from "../../../../utils/joinClasses";

//Lexical Components
import LexicalMaxNodes from "./LexicalMaxNodes";
import LexicalMaxTheme from "../../themes/LexicalMaxTheme/LexicalMaxTheme";
import { SharedHistoryContext } from "../../contexts/SharedHistoryContext";

//Lexical Lib
import { LinkPlugin } from "@lexical/react/LexicalLinkPlugin";
import { ListPlugin } from "@lexical/react/LexicalListPlugin";
import { OnChangePlugin } from "@lexical/react/LexicalOnChangePlugin";
import LexicalErrorBoundary from "@lexical/react/LexicalErrorBoundary";
import { LexicalComposer } from "@lexical/react/LexicalComposer";
import { RichTextPlugin } from "@lexical/react/LexicalRichTextPlugin";
import { ContentEditable } from "@lexical/react/LexicalContentEditable";
// import { AutoFocusPlugin } from "@lexical/react/LexicalAutoFocusPlugin";
import { HistoryPlugin } from "@lexical/react/LexicalHistoryPlugin";
import { HorizontalRulePlugin } from "@lexical/react/LexicalHorizontalRulePlugin";

//Custom Plugins
import ToolbarMaxPlugin from "../../plugins/ToolbarMaxPlugin/ToolbarMaxPlugin";
import AutoLinkPlugin from "../../plugins/AutoLinkPlugin/AutoLinkPlugin";
import OnMountPlugIn from "../../plugins/OnMountPlugin/OnMountPlugin";
import FloatingLinkEditorPlugin from "../../plugins/FloatingLinkEditorPlugin/FloatingLinkEditorPlugin";
import { $generateHtmlFromNodes } from "@lexical/html";
import ImagesPlugin from "../../plugins/ImagesPlugin/ImagesPlugin";
import CollapsiblePlugin from "../../plugins/CollapsiblePlugin/CollapsiblePlugin";
import EquationsPlugin from "../../components/InsertEquationDialog/InsertEquationDialog";
import YouTubePlugin from "../../plugins/YoutubePlugin/YoutubePlugin";
import AutoEmbedPlugin from "../../plugins/AutoEmbedPlugin/AutoEmbedPlugin";
import VimeoPlugin from "../../plugins/VimeoPlugin/VimeoPlugin";
import OnFocusPlugIn from "../../plugins/OnFocusPlugin/OnFocusPlugin";
import InlineImagePlugin from "../../plugins/InLineImagePlugin/InLineImagePlugin";
import { TablePlugin } from "../../plugins/TablePlugin/TablePlugin";
import { TableContext } from "../../plugins/TablePlugin/TableContext";
import TableActionMenuPlugin from "../../plugins/TableActionMenuPlugin/TableActionMenuPlugin";
import TableCellResizerPlugin from "../../plugins/TableCellResizer/TableCellResizer";
import ModalContainerPlugin from "../../plugins/ModalContainerPlugin/ModalContainerPlugin";
import MentionsPlugin from "../../plugins/MentionsPlugin/MentionsPlugin";

const PADDING = 16;
const LINEHEIGHT = 25;
const MIN_LINES = 4; //Lines - 1 Line = 26px //padding around input = 16px in total

const MAX_CONFIG = [
    "redoUndo",
    "standardText",
    "h1",
    "h2",
    "h3",
    "bulletList",
    "numberedList",
    "quote",
    "bold",
    "italic",
    "underline",
    "changeColor",
    "changeHighlight",
    "link",
    "strikethrough",
    "superscript",
    "subscript",
    "leftAlign",
    "centerAlign",
    "rightAlign",
    "justifyAlign",
    "outdent",
    "indent",
    "horizontalRule",
    "imageInline",
    "image",
    "equation",
    "collapsibleContainer",
    "youtube",
    "vimeo",
    "table",
    "tagResource",
];

const MAX_BLANKS_CONFIG = [
    "redoUndo",
    "standardText",
    "h1",
    "h2",
    "h3",
    "h4",
    "h5",
    "h6",
    "bulletList",
    "numberedList",
    "quote",
    "bold",
    "italic",
    "underline",
    "changeColor",
    "changeHighlight",
    "link",
    "strikethrough",
    "superscript",
    "subscript",
    "leftAlign",
    "centerAlign",
    "rightAlign",
    "justifyAlign",
    "outdent",
    "indent",
    "horizontalRule",
    "imageInline",
    "image",
    "equation",
    "collapsibleContainer",
    "youtube",
    "vimeo",
    // "table",
    "insertBlank",
    "tagResource",
];

const BASIC_CONFIG = ["redoUndo", "bulletList", "numberedList", "quote", "bold", "italic", "underline", "link", "strikethrough"];

export default function LexicalMax({
    onChange,
    initialState = null,
    minLines = MIN_LINES,
    maxHeight = null,
    maxWidth = null,
    // autoFocus = true,
    config = "max",
    scrollerID = "",
    scrollRef = null,
    resourceStyle = false,
    handleImageUpload = null,
}) {
    // const {
    //     settings: { isCollab, emptyEditor, measureTypingPerf },
    // } = useSettings();

    const [inFocus, setInFocus] = useState(false);
    const [editable, setEditable] = useState(false);

    const initialConfig = {
        editorState: undefined,
        namespace: "Playground",
        nodes: [...LexicalMaxNodes],
        onError: (error) => {
            throw error;
        },
        theme: LexicalMaxTheme,
    };

    const [floatingAnchorElem, setFloatingAnchorElem] = useState(null);
    const onRef = (floatingAnchorElem) => {
        if (floatingAnchorElem !== null) {
            setFloatingAnchorElem(floatingAnchorElem);
        }
    };

    //editor config
    const editorConfig = getEditorConfig(
        config === "max" ? MAX_CONFIG : config === "basic" ? BASIC_CONFIG : config === "blanks" ? MAX_BLANKS_CONFIG : null
    );

    //END

    const handleChange = (editorState, editor) => {
        const currentComposedHTML = editorState.read(() => {
            const editorStateHTML = $generateHtmlFromNodes(editor, null); //null to convert everything
            return editorStateHTML;
        });
        onChange(currentComposedHTML);
        // console.log("EDITOR_HAS_CHANGED", currentComposedHTML);
    };

    return (
        <div
            className={joinClasses(classes.LexicalMax, resourceStyle && classes.ResourceStyle, editable && inFocus && classes.EditFocus)}
            id={"LexicalEditor"}
            style={{ maxWidth: `${maxWidth}px` }}
            ref={onRef}
            onClick={() => {
                setEditable(true);
                setInFocus(true);
            }}
            onFocus={() => {
                setEditable(true);
                setInFocus(true);
            }}
            onBlur={() => {
                setEditable(false);
                setInFocus(false);
            }}
        >
            <LexicalComposer initialConfig={initialConfig}>
                <ModalContainerPlugin editorConfig={editorConfig} />
                <SharedHistoryContext>
                    <TableContext>
                        <>
                            {/*<SharedAutocompleteContext> */}
                            <div className={classes.ToolbarContainer}>
                                <ToolbarMaxPlugin editorConfig={editorConfig} handleImageUpload={handleImageUpload} />
                            </div>
                            <div className={classes.EditorInner} id={editorConfig?.editorContentID}>
                                <RichTextPlugin
                                    contentEditable={
                                        <ContentEditable
                                            style={{ minHeight: `${minLines * LINEHEIGHT + PADDING}px`, maxHeight: `${maxHeight}px` }}
                                            className={joinClasses(classes.TextInput, "RichTextMax_Root")}
                                        />
                                    }
                                    placeholder={null}
                                    ErrorBoundary={LexicalErrorBoundary}
                                />
                                {<OnChangePlugin onChange={handleChange} />}
                                <OnFocusPlugIn
                                    inFocus={inFocus}
                                    setInFocus={setInFocus}
                                    editable={editable}
                                    scrollerID={scrollerID}
                                    scrollRef={scrollRef}
                                />
                                {initialState ? <OnMountPlugIn value={initialState} setEditable={setEditable} setInFocus={setInFocus} /> : null}
                                {editorConfig?.redoUndo ? <HistoryPlugin /> : null}
                                {editorConfig?.bulletList && editorConfig?.numberedList ? <ListPlugin /> : null}
                                {editorConfig?.horizontalRule ? <HorizontalRulePlugin /> : null}
                                {editorConfig?.youtube || editorConfig?.vimeo ? <AutoEmbedPlugin /> : null}
                                {editorConfig?.image ? <ImagesPlugin captionsEnabled={false} /> : null}
                                {editorConfig?.youtube ? <YouTubePlugin /> : null}
                                {editorConfig?.vimeo ? <VimeoPlugin /> : null}
                                {editorConfig?.link ? <LinkPlugin /> : null}
                                {editorConfig?.link ? <AutoLinkPlugin /> : null}
                                {editorConfig?.tagResource && floatingAnchorElem ? (
                                    <MentionsPlugin editorConfig={editorConfig} anchorElem={floatingAnchorElem} />
                                ) : null}
                                {editorConfig?.collapsibleContainer ? <CollapsiblePlugin /> : null}
                                {editorConfig?.equation ? <EquationsPlugin /> : null}
                                {editorConfig?.imageInline ? <InlineImagePlugin /> : null}
                                {editorConfig?.table ? (
                                    <>
                                        <TablePlugin hasCellMerge={true} hasCellBackgroundColor={true} />
                                        {floatingAnchorElem && (
                                            <TableActionMenuPlugin editorConfig={editorConfig} anchorElem={floatingAnchorElem} cellMerge={true} />
                                        )}
                                        <TableCellResizerPlugin />
                                        <div id={"TableAnchor"}></div>
                                    </>
                                ) : null}
                                {floatingAnchorElem && editorConfig?.link ? <FloatingLinkEditorPlugin anchorElem={floatingAnchorElem} /> : null}
                                {/* <MarkdownShortcutPlugin transformers={TRANSFORMERS} /> */}
                                {/* <TreeViewPlugin /> */}
                                {/* {autoFocus ? <AutoFocusPlugin /> : null} */}
                            </div>
                            {/* </SharedAutocompleteContext> */}
                        </>
                    </TableContext>
                </SharedHistoryContext>
            </LexicalComposer>
        </div>
    );
}

function getEditorConfig(configArray) {
    const editorID = useMemo(() => uniqueLexicalID(), []);
    const editorContentID = useMemo(() => uniqueLexicalID(), []);
    const redoUndo = configArray.includes("redoUndo");
    const standardText = configArray.includes("standardText");
    const h1 = configArray.includes("h1");
    const h2 = configArray.includes("h2");
    const h3 = configArray.includes("h3");
    const h4 = configArray.includes("h4");
    const h5 = configArray.includes("h5");
    const h6 = configArray.includes("h6");
    const bulletList = configArray.includes("bulletList");
    const numberedList = configArray.includes("numberedList");
    const quote = configArray.includes("quote");
    const bold = configArray.includes("bold");
    const italic = configArray.includes("italic");
    const underline = configArray.includes("underline");
    const changeColor = configArray.includes("changeColor");
    const changeHighlight = configArray.includes("changeHighlight");
    const link = configArray.includes("link");
    const strikethrough = configArray.includes("strikethrough");
    const superscript = configArray.includes("superscript");
    const subscript = configArray.includes("subscript");
    const leftAlign = configArray.includes("leftAlign");
    const centerAlign = configArray.includes("centerAlign");
    const rightAlign = configArray.includes("rightAlign");
    const justifyAlign = configArray.includes("justifyAlign");
    const outdent = configArray.includes("outdent");
    const indent = configArray.includes("indent");
    const horizontalRule = configArray.includes("horizontalRule");
    const image = configArray.includes("image");
    const equation = configArray.includes("equation");
    const collapsibleContainer = configArray.includes("collapsibleContainer");
    const youtube = configArray.includes("youtube");
    const vimeo = configArray.includes("vimeo");
    const imageInline = configArray.includes("imageInline");
    const table = configArray.includes("table");
    const insertBlank = configArray.includes("insertBlank");
    const tagResource = configArray.includes("tagResource");

    return {
        editorID,
        editorContentID,
        redoUndo,
        standardText,
        h1,
        h2,
        h3,
        h4,
        h5,
        h6,
        bulletList,
        numberedList,
        quote,
        bold,
        italic,
        underline,
        changeColor,
        changeHighlight,
        link,
        strikethrough,
        superscript,
        subscript,
        leftAlign,
        centerAlign,
        rightAlign,
        justifyAlign,
        outdent,
        indent,
        horizontalRule,
        image,
        equation,
        collapsibleContainer,
        youtube,
        vimeo,
        imageInline,
        table,
        insertBlank,
        tagResource,
    };
}

function uniqueLexicalID() {
    return Date.now().toString(36) + Math.random().toString(36).substr(2);
}
