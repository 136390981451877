import { saveRichTextThumbnail } from "../../EditMode/EditModulesOverview/Functions/CreateResourceFirestoreFunctions";
import { uploadFile2 } from "../../Helpers/StorageFunctions";

export async function handleImageUploadRichTextResource(space_id, path_id, rsrc_local_id, file_dir, newImg, src, file_name, setprogress){
        const url = await uploadFile2(src, file_name, file_dir, setprogress);
        const height = newImg.height;
        const width = newImg.width;
        //RichTextResource
        saveRichTextThumbnail(space_id, path_id, rsrc_local_id, url, file_name, height, width);
                console.log(url);
        return url
}

export async function handleImageUploadQuizQuestion(file_dir, newImg={},src, file_name, setprogress){
        const url = await uploadFile2(src, file_name, file_dir, setprogress);
        console.log(url);
        return url
}