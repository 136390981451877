import { useCallback, useEffect, useRef, useState } from "react";
import { useClickAway, useKeyPressEvent, useMouse } from "react-use";
import mixpanel from "mixpanel-browser";
//UI
import classes from "./ResourcePicker.module.css";
import { KeyboardEnter } from "../../../../../../assets/icons/Icons";
import ResourceIcon from "../../../../../../components/UI/ResourceIcon/ResourceIcon";

//functions
import { dynamicSort2, removeAccentsAndDiacritics } from "../../../../../../components/Helpers/HelperFunction";
import { getResourceData } from "../../../../../../components/ResourcePath/Resources/functions/ResourceGetterFunctions";

//hooks

//contexts
import { useLanguage } from "../../../../../../contexts/LanguageContext";
import { useSpaceContext } from "../../../../../../contexts/SpaceContext/SpaceContext";
import KeyTooltip from "../../../../../../components/ComponentLibrary/KeyTooltip/KeyTooltip";
import { useUserDataContext } from "../../../../../../contexts/UserDataContext";
import joinClasses from "../../../../../../utils/joinClasses";
import { useParams } from "react-router-dom";

export default function ResourcePickerRT({ filter, onSelection, setShowAtPicker }) {
    const { translate } = useLanguage();
    const { space_id } = useSpaceContext();
    const { path_id } = useParams();
    const [selected, setSelected] = useState(null);
    const [sortedresbytitle, setsortedresbytitle] = useState([]);
    const [filteredrsrcs, setFilteredrsrcs] = useState([]);
    const [preventHover, setpreventHover] = useState(false);
    const pickerRef = useRef(null);
    const { docX, docY } = useMouse(pickerRef);

    useClickAway(pickerRef, (event) => {
        setShowAtPicker(false);
    });

    useEffect(() => {
        if (preventHover) {
            setpreventHover(false);
        }
    }, [docX, docY]);

    const { user_path_overviews, group_overviews } = useUserDataContext();

    const overview_data = user_path_overviews?.find((d) => d.metaData.path_id === path_id);
    const resources_overview_object = overview_data ? overview_data?.resources : {};

    useEffect(() => {
        if (Object.keys(resources_overview_object)?.length > 0) {
            const resources = Object.values(resources_overview_object)?.filter((r) => r?.metaData?.title && !r?.localData?.group);
            const sorted = resources?.sort(dynamicSort2("metaData", "title"));
            setsortedresbytitle(sorted);
        }
    }, [resources_overview_object]);

    useEffect(() => {
        if (sortedresbytitle && filter?.length > 1) {
            const filtered = sortedresbytitle.filter((res) => {
                const { rsrc_title, rsrc_type } = getResourceData(res, null);
                const filterLower = removeAccentsAndDiacritics(filter.toLowerCase());
                const titleLower = removeAccentsAndDiacritics(removeAccentsAndDiacritics(rsrc_title).toLowerCase());
                const typeLower = rsrc_type.toLowerCase();
                const typeLowerTranslated = removeAccentsAndDiacritics(translate(rsrc_type.toUpperCase()).toLowerCase());
                console.log("FILTER", typeLowerTranslated, typeLower, filterLower);
                if (titleLower.includes(filterLower) || typeLower.includes(filterLower) || typeLowerTranslated.includes(filterLower)) return true;
            });
            setFilteredrsrcs(filtered);
        } else setFilteredrsrcs(sortedresbytitle);
    }, [sortedresbytitle, filter]);

    const n_resource = filteredrsrcs?.length;
    const selectedUp = useCallback(() => {
        setpreventHover(true);
        let nextPos;
        if (selected === 0 || selected === null) {
            nextPos = n_resource - 1;
            setSelected(nextPos);
        } else {
            nextPos = selected - 1;
            setSelected(nextPos);
        }
        document.getElementById("atResourcePickerItem_" + nextPos)?.scrollIntoView({ block: "nearest", inline: "nearest" });
    }, [selected, n_resource]);

    useEffect(() => {
        if (selected >= filteredrsrcs?.length) {
            setSelected(0);
        }
    }, [selected, filteredrsrcs]);

    const selectedDown = useCallback(
        (e) => {
            setpreventHover(true);
            let nextPos;
            if (selected === n_resource - 1 || selected === null) {
                nextPos = 0;
                setSelected(nextPos);
            } else {
                nextPos = selected + 1;
                setSelected(nextPos);
            }
            document.getElementById("atResourcePickerItem_" + nextPos)?.scrollIntoView({ block: "nearest", inline: "nearest" });
        },
        [selected, n_resource]
    );

    const selectedEnter = useCallback(() => {
        console.log(filteredrsrcs[selected]);
        clickSetAtResource(filteredrsrcs[selected]);
    }, [space_id, path_id, filteredrsrcs, selected]);

    useKeyPressEvent("Enter", () => selectedEnter());

    useKeyPressEvent("ArrowUp", (e) => {
        e.preventDefault();
        selectedUp();
    });

    useKeyPressEvent("ArrowDown", (e) => {
        e.preventDefault();
        selectedDown();
    });

    function clickSetAtResource(resource) {
        console.log("Mention Tagging", resource);
        onSelection(resource);
        // mixpanel.track("Richtext @Resource", { type: "Resource Picker", location: area, path_id: path_id, module: resource?.module });
    }

    console.log(filteredrsrcs, sortedresbytitle, "RESOURCES");

    return (
        <div className={joinClasses(classes.ResourcePicker, classes.RT)} ref={pickerRef}>
            <div className={classes.Header}>
                <div className={[classes.Label, "NoSelect"].join(" ")}>
                    {translate("RESOURCES")}
                    <div className={classes.nRsrcs}>{n_resource}</div>
                </div>
                <div className={classes.KeysTooltip}>
                    <div className={classes.Tip}>
                        <KeyTooltip type={"enter"} />
                        <div className={classes.Text}>{translate("RT_TO_SELECT")}</div>
                    </div>
                    <div className={classes.Tip}>
                        <KeyTooltip type={"down"} />
                        <KeyTooltip type={"up"} />
                        <div className={classes.Text}>{translate("RT_TO_NAV")}</div>
                    </div>
                    <div className={classes.Tip}>
                        <KeyTooltip type={"esc"} />
                        <div className={classes.Text}>{translate("RT_TO_CLEAR")}</div>
                    </div>
                </div>
            </div>
            <div className={[classes.Resources, "ScrollerSmall"].join(" ")}>
                {filteredrsrcs?.map((resource, index) => {
                    const { rsrc_title, rsrc_local_id } = getResourceData(resource);
                    const isSelected = selected === index;
                    return (
                        <div
                            id={"atResourcePickerItem_" + index}
                            key={rsrc_local_id}
                            className={[classes.Resource, isSelected ? classes.Selected : null, preventHover ? classes.PreventHover : null].join(" ")}
                            onClick={() => clickSetAtResource(resource)}
                            onMouseEnter={() => {
                                if (!preventHover) {
                                    setSelected(index);
                                }
                            }}
                        >
                            <ResourceIcon resource={resource} size={24} />
                            <div className={classes.RsrcTitle}>{rsrc_title}</div>
                            {isSelected ? (
                                <div className={classes.EnterIcon}>
                                    <KeyboardEnter size={"17px"} />
                                </div>
                            ) : null}
                        </div>
                    );
                })}
            </div>
        </div>
    );
}
