import { doc } from "firebase/firestore";
import { useDocumentData } from "react-firehooks";
import { firestore } from "../../app/firebase";
import { getSpaceInfoData } from "../../functions/DataGetterFunctions";

export default function useSpaceInfoData(space_id, condition = true) {
    let spaceRef;
    if (space_id && condition) {
        spaceRef = doc(firestore, "spaces", space_id, "section", "info");
    }
    const [space_info, loading_spaceinfoData, errorLoading_spaceinfoData] = useDocumentData(spaceRef);
    const spaceInfoData = getSpaceInfoData(space_info);

    return {
        space_info,
        loading_spaceinfoData,
        errorLoading_spaceinfoData,
        ...spaceInfoData,
    };
}
