import { collection, limit, orderBy, query, where } from "firebase/firestore";
import { useCollectionData } from "react-firehooks";
import { useSpaceContext } from "../../../../contexts/SpaceContext/SpaceContext";
import { firestore } from "../../../../app/firebase";
import { useAuth } from "../../../../contexts/AuthContext";

export default function useGeneralMessages(last_online_date, loading_presence) {
    const { space_id } = useSpaceContext();
    const { curatedTaughtAndEnrolled, isCuratorOfSpace} = useAuth();

    let generalMessagesRef, generalMessagesQuery;
    if (curatedTaughtAndEnrolled && last_online_date && !loading_presence) {
        generalMessagesRef = collection(firestore, "spaces", space_id, "messages");
        if (curatedTaughtAndEnrolled?.length > 10 || isCuratorOfSpace) { //TODO if curator or teacher > 10 filter after loading
            generalMessagesQuery = query(generalMessagesRef,
                where("meta.channel_type", "==", "GENERAL"),
                where("meta.created_at", ">", last_online_date),
                orderBy("meta.created_at", "desc"),
                limit(10)
            );  
        } else if (curatedTaughtAndEnrolled?.length > 0) {
            generalMessagesQuery = query(generalMessagesRef,
                where("meta.channel_type", "==", "GENERAL"),
                where("meta.created_at", ">", last_online_date),
                where("meta.path_id", "in", curatedTaughtAndEnrolled),
                orderBy("meta.created_at", "desc"),
                limit(10)
            );  
    }        
    }
    const [generalMessages1, loading_general_messages, error_messages] = useCollectionData(generalMessagesQuery);
    const generalMessages = (!isCuratorOfSpace && curatedTaughtAndEnrolled?.length > 10) ? generalMessages1?.filter(m => !curatedTaughtAndEnrolled?.includes(m?.res?.group_id)) : generalMessages1;
    return { generalMessages, loading_general_messages };
}
