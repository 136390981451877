
import { onAuthStateChanged } from "firebase/auth";
import { onDisconnect, onValue, push, ref, remove, serverTimestamp, set, update } from "firebase/database";
import { auth, rlt_db } from "../../app/firebase";
import { makeid } from "./HelperFunction";

//ON LOGOUT
export async function updateUserPresenceInRealTimeDB2(space_id, status) {
    const user_id = auth.currentUser?.uid;
    const user_name = auth.currentUser?.displayName;
    const user_image_small = auth.currentUser?.photoURL;
    const rlt_db_ref = ref(rlt_db, "user_presence/spaces/" + space_id + "/" + user_id);
    return update(rlt_db_ref, {
        user_name: user_name || "",
        user_id: user_id,
        user_image: user_image_small,
        status: status,
        is_online: false,
        last_online: serverTimestamp(), //update on logout
    });
}

//manually switch presence
export async function updateUserPresenceInRealTimeDB(space_id, status = true) {
    const user_id = auth.currentUser?.uid;
    if (user_id) {
         const rlt_db_ref = ref(rlt_db, "user_presence/spaces/" + space_id + "/" + user_id);
    if (typeof status === "boolean") {
        update(rlt_db_ref, {
            status,
        });
    }   
    }
}

export function goOnlineInSpaceChat(new_status, space_id) {
    const user_id = auth.currentUser?.uid;
    const user_name = auth.currentUser?.displayName;
    const user_image = auth.currentUser?.photoURL;
    if (!user_id || !user_name || !user_image) return;

    const connectedRef = ref(rlt_db, ".info/connected");
    onValue(connectedRef, (snap) => {
        if (snap.val() == false) {
            return;
            // If we're not currently connected, don't do anything.
        };
        const rlt_db_ref = ref(rlt_db, "user_presence/spaces/" + space_id + "/" + user_id);
        onDisconnect(rlt_db_ref) //(1) set this
            .update({
                status: false,
                last_online: serverTimestamp(), //update (on close browser / lose connection )
            })
            .then((promise) => { //(2) do this
                update(rlt_db_ref, {
                    status: new_status
                });
            });
    })
}


export async function updateUserImageRT(space_id, url_small) {
    const user_id = auth.currentUser?.uid;
    const rlt_db_ref = ref(rlt_db, "user_presence/spaces/" + space_id + "/" + user_id);
    update(rlt_db_ref, {
        user_image: url_small,
    });
}

export async function updateUserNameRT(space_id, user_id, user_name) {
    const rlt_db_ref = ref(rlt_db, "user_presence/spaces/" + space_id + "/" + user_id);
    update(rlt_db_ref, {
        user_name: user_name,
    });
}
export async function updateUserImgNameRT(space_id, user_id, user_name, url_small) {
    const rlt_db_ref = ref(rlt_db, "user_presence/spaces/" + space_id + "/" + user_id);
    update(rlt_db_ref, {
        user_name: user_name,
        user_image: url_small

    });
}

export async function updateUserImage_BigRT(space_id, url_big, url_small) {
    const user_id = auth.currentUser?.uid;
    const rlt_db_ref = ref(rlt_db, "user_presence/spaces/" + space_id + "/" + user_id);
    update(rlt_db_ref, {
        user_image_big: url_big,
        user_image: url_small
    });
}

//NO OF CONNECTIONS
export async function regulateNoOfConnections(space_id, device_id, new_tab_id, sethas_internet) {
    const connectedRef = ref(rlt_db, ".info/connected");
    sethas_internet(true);
    onValue(connectedRef, (snap) => {
        let is_tab_active = true;
        document.addEventListener("visibilitychange", () => {
            is_tab_active = !document.hidden;
        });
        const no_conc = !snap.val() && is_tab_active;
        sethas_internet(!no_conc)
        onAuthStateChanged(auth, (user) => {
            if (user) {
                const uid = user.uid;
                //(1) Connections
                const myConnectionsIDRef = ref(rlt_db, "user_presence/spaces/" + space_id + "/" + uid + "/my_connections/" + device_id + "/" + new_tab_id);
                onDisconnect(myConnectionsIDRef).remove().then((promise) => {
                    set(myConnectionsIDRef, new_tab_id);
                })
                //(2) Online status
                const rlt_db_online_ref = ref(rlt_db, "user_presence/spaces/" + space_id + "/" + uid + "/is_online");
                onDisconnect(rlt_db_online_ref).set(false).then((promise) => { //(2) does not work with multiple devices!
                    set(rlt_db_online_ref, true);
                });
            }
        });
    })
}

export const getDeviceType = () => {
    const ua = navigator.userAgent;
    if (/(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i.test(ua)) {
        return "tablet";
    }
    if (
        /Mobile|iP(hone|od)|Android|BlackBerry|IEMobile|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(
            ua
        )
    ) {
        return "mobile";
    }
    return "desktop";
};

export async function setNewConnection(space_id) {
    const user_id = auth.currentUser?.uid;
    const con_id = makeid(4);
    const myConnectionsRef = ref(rlt_db, "user_presence/spaces/" + space_id + "/" + user_id + "/my_connections/" + con_id);
    set(myConnectionsRef, getDeviceType());
}

export async function removeConnection(space_id, con_id) {
    const user_id = auth.currentUser?.uid;
    const myConnectionsRef = ref(rlt_db, "user_presence/spaces/" + space_id + "/" + user_id + "/my_connections/" + con_id);
    return remove(myConnectionsRef);
}