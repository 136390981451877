import { collection, limit, orderBy, query, where } from "firebase/firestore";
import { useCollectionData } from "react-firehooks";
import { useSpaceContext } from "../../../../contexts/SpaceContext/SpaceContext";
import { firestore } from "../../../../app/firebase";
import { useAuth } from "../../../../contexts/AuthContext";

export default function useAnnouncementMessages(last_online_date, loading_presence) {
    const { space_id } = useSpaceContext();
    const { user_id, curatedTaughtAndEnrolled, isCuratorOfSpace, isPathStudentOfSpace } = useAuth();

    const path_ids = isPathStudentOfSpace ? [...curatedTaughtAndEnrolled, space_id] : curatedTaughtAndEnrolled; // for student include space_id for space announcements! genius move🤯
    let announcementsMessagesRef, announcementsMessagesQuery;
    if (user_id && last_online_date && !loading_presence) {
        announcementsMessagesRef = collection(firestore, "spaces", space_id, "messages");
        if (curatedTaughtAndEnrolled?.length > 10 || isCuratorOfSpace) { //TODO if not isCuratorOfSpace but > 10 filter after loading
            announcementsMessagesQuery = query(announcementsMessagesRef,
                where("meta.channel_type", "==", "ANNOUNCEMENTS"),
                where("meta.created_at", ">", last_online_date),
                orderBy("meta.created_at", "desc"),
                limit(10)
            );  
        } else if (path_ids?.length > 0) {
            announcementsMessagesQuery = query(announcementsMessagesRef,
                where("meta.channel_type", "==", "ANNOUNCEMENTS"),
                where("meta.created_at", ">", last_online_date),
                where("meta.path_id", "in", path_ids),
                orderBy("meta.created_at", "desc"),
                limit(10)
            );  
    }        
    }
    const [announcementsMessages1, loading_announcements_messages, error_messages] = useCollectionData(announcementsMessagesQuery);

    const announcementsMessages = (!isCuratorOfSpace && curatedTaughtAndEnrolled?.length > 10) ? announcementsMessages1?.filter(m => !curatedTaughtAndEnrolled?.includes(m?.res?.group_id)) : announcementsMessages1;
    return { announcementsMessages, loading_announcements_messages };
}
