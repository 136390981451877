
import { storage } from "../../app/firebase";
import { deleteObject, getDownloadURL, ref, uploadBytesResumable, listAll, updateMetadata } from "@firebase/storage";

//UPLOAD
export async function uploadFile(file, file_id, directory) {
    try {
        const imageRef = ref(storage, `${directory}/${file_id}`);
        const task = uploadBytesResumable(imageRef, file, { contentType: file.type });
        const snapshot = await task;
        const url = getDownloadURL(snapshot.ref);
        return url;
    } catch (error) {
        throw error
    }
};
export async function uploadFile2(file, file_id, directory, setprogress) {
    let resolveFinalUrl;
    let rejectFinalUrl;

    const urlPromise = new Promise((resolve, reject) => {
        resolveFinalUrl = resolve;
        rejectFinalUrl = reject;
    });

    let uploadProgress = 0;
    try {
        const imageRef = ref(storage, `${directory}/${file_id}`);
        const uploadTask = uploadBytesResumable(imageRef, file, { contentType: file.type });
        uploadTask.on(
            'state_changed',
            (snapshot) => {
                // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
                uploadProgress = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
                setprogress(uploadProgress)
            },
            (error) => {
                rejectFinalUrl(error);
            },
            async () => {
                try {
                    const url = await getDownloadURL(uploadTask.snapshot.ref);
                    resolveFinalUrl(url); // Resolve the Promise with the URL
                } catch (error) {
                    rejectFinalUrl(error);
                }
            }
        );
        const url = await urlPromise; // Await until URL is available
        return url;
    } catch (error) {
        throw error;
    }
}
export async function uploadAudioBlob(audio_blob, file_id, directory) {
    try {
        // setUploading(true);
        const imageRef = ref(storage, `${directory}/${file_id}`);
        const task = uploadBytesResumable(imageRef, audio_blob);
        const snapshot = await task;
        const url = getDownloadURL(snapshot.ref);
        return url;
    } catch (error) {
        throw error
    }
};

export async function uploadImageAsPromise(imageBlob, directory, handleProgress, callback) {
    const imageRef = ref(storage, directory);
    const uploadTask = uploadBytesResumable(imageRef, imageBlob);
    uploadTask.on('state_changed',
        async (snapshot) => {
            // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
            const progress = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
            handleProgress(progress)
            switch (snapshot.state) {
                case 'paused':
                    console.log('Upload is paused');
                    break;
                case 'running':
                    // console.log('Upload is running');
                    break;
            }
        },
        (error) => {
            // A full list of error codes is available at
            // https://firebase.google.com/docs/storage/web/handle-errors
            switch (error.code) {
                case 'storage/unauthorized':
                    console.log("User doesn't have permission to access the object");
                    break;
                case 'storage/canceled':
                    console.log('User canceled the upload');
                    break;
                // ...
                case 'storage/unknown':
                    console.log('Unknown error occurred, inspect error.serverResponse');
                    break;
            }
        }, () => {
            getDownloadURL(uploadTask.snapshot.ref).then(function (url) {
                callback(url) //update url on db
            });
        }
    );
}


//DELETE
export async function deleteUploadedFile(directory) {
    console.log(directory);
    const imageRef = ref(storage, directory);
    // Delete the file
    deleteObject(imageRef).then(() => {
        console.log("File deleted successfully")
    }).catch((error) => {
        console.log(error);
        // Uh-oh, an error occurred!
    });
};


export function deleteAttachmentStorage(space_id, path_id, resource_id, attachment_name) {
    let ref2
    if (path_id) {
        ref2 = ref(storage, `spaces/${space_id}/paths/${path_id}/resources/${resource_id}/${attachment_name}`);
    } else {
        ref2 = ref(storage, `spaces/${space_id}/resources/${resource_id}/${attachment_name}`);
    }
    deleteObject(ref2).then(() => {
    }).catch((error) => {
        console.log(error)
    });
}




export function deleteAllFilesOfDirectory(directory) {
    const listRef = ref(storage, directory);
    // Find all the prefixes and items.
    listAll(listRef)
        .then((res) => {
            res.prefixes.forEach(async (folderRef) => {
                const res_2 = await listAll(folderRef)
                res_2.items.forEach((itemRef) => {
                    deleteObject(itemRef)
                })
                // All the prefixes under listRef.
                // You may call listAll() recursively on them.
            });
            res.items.forEach((itemRef) => {
                deleteObject(itemRef)
                // All the items under listRef.
            });
        }).catch((error) => {
            // Uh-oh, an error occurred!
        });
}

export async function getAllFilesOfChannel(directory) {
    const channelRef = ref(storage, directory);
    const msg_files_refs = [];
    const res = await listAll(channelRef)
    for (let index = 0; index < res.prefixes?.length; index++) {
        const message_folders = res.prefixes[index];
        const res_2 = await listAll(message_folders)
        for (let index = 0; index < res_2.prefixes?.length; index++) {
            const message_file_folders = res_2.prefixes[index];
            const res_3 = await listAll(message_file_folders)
            for (let index = 0; index < res_3.items?.length; index++) {
                const message_file_ref = res_3.items[index];
                msg_files_refs.push(message_file_ref)
            }
        }
    }
    return msg_files_refs;
}


export async function setMetaDataToDeleted(directory) {
    const fileRef = ref(storage, directory);

    // Create file metadata to update
    const newMetadata = {
        customMetadata: {
            status: "deleted"
        }
    };
    // Update metadata properties
    updateMetadata(fileRef, newMetadata)
        .then((metadata) => {
            console.log(metadata);
            // Updated metadata for 'images/forest.jpg' is returned in the Promise
        }).catch((error) => {
            console.log(error);
            throw (error)
            // Uh-oh, an error occurred!
        });
}