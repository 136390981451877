import { collection, query, where } from "firebase/firestore";
import { useCollectionData } from "react-firehooks";
import { firestore } from "../../app/firebase";
import { useSpaceContext } from "../../contexts/SpaceContext/SpaceContext";
import { useAuth } from "../../contexts/AuthContext";

export default function useProgressPathsData(condition = true) {
    const { space_id } = useSpaceContext();
    const { curatedTaughtAndEnrolled, user_id, isCuratorOfSpace } = useAuth();

    let checked_path_resources_ref, checked_path_resources_query;
    if (space_id && user_id && condition) {
        checked_path_resources_ref = collection(firestore, "progress_by_user", user_id, "progress_spaces", space_id, "progress_paths");
        if (isCuratorOfSpace) {
            //ALL
            checked_path_resources_query = checked_path_resources_ref;
        } else if (curatedTaughtAndEnrolled?.length > 0) {
            checked_path_resources_query = query(
                checked_path_resources_ref,
                where("metaData.path_id", "in", curatedTaughtAndEnrolled.slice(0, 10)), //max of 10!
            );
        }
    }
    const [user_progress_paths, loading_user_progress_paths, error_user_progress_paths] = useCollectionData(checked_path_resources_query);

    return { user_progress_paths, loading_user_progress_paths, error_user_progress_paths };
}
