import { arrayUnion, increment } from "firebase/firestore";

export function getAnswerStats(q_type, answers_selected) {
    const answer_stats = { stats: {} };

    switch (q_type) {
        case "match":
            answer_stats.stats.answer_correct_counts = {};
            for (let [answer_field, answer] of Object.entries(answers_selected)) {
                answer_stats.stats.answer_correct_counts[answer_field] = increment(answer_field === answer ? 1 : 0);
            }
            break;
        case "multipleChoice":
            answer_stats.stats.answer_counts = {};
            for (let answer_id of answers_selected) {
                answer_stats.stats.answer_counts[answer_id] = increment(1);
            }
            break;
        case "blanks":
            answer_stats.stats.answers = {};
            for (let [answer_field, answer] of Object.entries(answers_selected)) {
                answer_stats.stats.answers[answer_field] = arrayUnion(answer);
            }
            break;
    }
    
    return answer_stats;
}
