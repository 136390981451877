import { initializeApp } from "firebase/app";

// import { getAnalytics } from "firebase/analytics";
import { getFirestore } from "firebase/firestore";
import { getDatabase } from "firebase/database";
import { getAuth } from "firebase/auth";
import { getStorage } from "firebase/storage";
import { getFunctions } from "firebase/functions";


//command to set config values: firebase:config:set REACT_APP_FIREBASE_KEY="AIzaSyChz27peKZdb5saw9Tfl66npaY-4peHdhU"
// const app = initializeApp({
//     apiKey: process.env.REACT_APP_FIREBASE_KEY,
//     authDomain: process.env.REACT_APP_FIREBASE_DOMAIN,
//     databaseURL: process.env.REACT_APP_FIREBASE_DATABASE,
//     projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
//     storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
//     messagingSenderId: process.env.REACT_APP_FIREBASE_SENDER_ID,
//     appId: process.env.REACT_APP_MESSAGING_APP_ID,
//     measurementId: process.env.REACT_APP_MEASUREMENTID,
// });

// Your web app's Firebase configuration
// Import the functions you need from the SDKs you need
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyAgQqcO7etYtGYTU68FhNJfjRVgVg-aLoc",
  authDomain: "testprep-app-d2c33.firebaseapp.com",
  projectId: "testprep-app-d2c33",
  storageBucket: "testprep-app-d2c33.appspot.com",
  messagingSenderId: "174797058292",
  appId: "1:174797058292:web:ef730cf06ed13b14db6ea1",
  databaseURL: "https://testprep-app-d2c33-default-rtdb.europe-west1.firebasedatabase.app"
};


// Initialize Firebase
const app = initializeApp(firebaseConfig);


export const auth = getAuth(app);
export const storage = getStorage(app);
export const firestore = getFirestore(app);
export const rlt_db = getDatabase(app);
// export const analytics = getAnalytics(app);
export const functions = getFunctions(app, "europe-west1");
