import classes from "./TextInput.module.css";
import { useEffect, useRef, useState } from "react";
import { HidePassword, ShowPassword } from "../../../assets/icons/Icons";
import joinClasses from "../../../utils/joinClasses";

//TODO_M: WIP --> do not use yet!
export default function TextInput({
    onChange,
    value,
    label = null,
    type = "text",
    autoFocus = false,
    disabled = false,
    required = true,
    autocomplete = "off",
    elementClass = null,
    activeElementClass = null,
    wrapperClass = null,
    inputClass = null,
    labelClass = null,
    placeholder = "",
    charLimit = null,
    growToSize = false,
    fullWidth = true,
}) {
    const [isActive, setisActive] = useState(false);
    const [width, setWidth] = useState(0);
    const spanRef = useRef();
    const inputFieldRef = useRef();
    const focusInput = (e) => {
        e.stopPropagation();
        if (inputFieldRef) {
            inputFieldRef?.current?.focus();
        }
    };

    const [isProtected, setProtected] = useState(true);
    const pw_field = useRef();
    function toggleIcon() {
        setProtected(!isProtected);
        pw_field?.current?.focus();
    }

    // useEffect(() => {
    //     if (charLimit !== 0) {
    //         if (value?.length > charLimit) {
    //             value = value?.slice(0, charLimit);
    //             console.log("SLCIGIN", value?.slice(0, charLimit));
    //         }
    //     }
    // }, [value]);

    useEffect(() => {
        setWidth(spanRef?.current?.offsetWidth);
    }, [value, spanRef.current]);

    return (
        <div
            className={joinClasses(
                elementClass,
                classes.TextInputElement,
                disabled && classes.Disabled,
                isActive && activeElementClass,
                isActive && classes.Active,
                fullWidth && classes.FullWidth
            )}
            onClick={focusInput}
        >
            {label ? <label className={joinClasses(labelClass, classes.Label)}>{label}</label> : null}
            <div className={joinClasses(wrapperClass, classes.InputWrapper)}>
                <span className={joinClasses(inputClass, classes.TextInput, "NoSelect", classes.HideSpan)} ref={spanRef}>
                    {value}
                </span>
                <input
                    type={type === "password" ? (isProtected ? "password" : "text") : type}
                    ref={inputFieldRef}
                    placeholder={placeholder}
                    style={growToSize ? { width } : null}
                    className={joinClasses(inputClass, classes.TextInput)}
                    value={value}
                    onChange={onChange}
                    // onChange={onChange}
                    disabled={disabled}
                    autoFocus={autoFocus}
                    required={required}
                    autoComplete={autocomplete}
                    onFocus={() => setisActive(true)}
                    onBlur={() => setisActive(false)}
                />
                {type === "password" ? (
                    <div className={joinClasses(classes.IconHolder, classes.Password)} onClick={toggleIcon} ref={pw_field}>
                        {isProtected ? <ShowPassword size={"16px"} /> : <HidePassword size={"16px"} />}
                    </div>
                ) : null}
            </div>
            {charLimit ? <div className={classes.CharLimit}>{value?.length + "/" + charLimit}</div> : null}
        </div>
    );
}
