import { collection, query, where } from "firebase/firestore";
import { useCollectionData } from "react-firehooks";
import { firestore } from "../../app/firebase";
import { useSpaceContext } from "../../contexts/SpaceContext/SpaceContext";
import { useAuth } from "../../contexts/AuthContext";

export default function usePathsData(condition = true) {
    const { space_id } = useSpaceContext();
    const { curatedTaughtAndEnrolled, isCuratorOfSpace } = useAuth();

    let pathOverviewRef, pathOverviewQuery;
    if (space_id && condition) {
        pathOverviewRef = collection(firestore, "spaces", space_id, "paths");
        if (isCuratorOfSpace) {
            //ALL
            pathOverviewQuery = pathOverviewRef;
        } else if (curatedTaughtAndEnrolled?.length > 0) {
            pathOverviewQuery = query(
                pathOverviewRef,
                where("metaData.id", "in", curatedTaughtAndEnrolled.slice(0, 10)), //max of 10!
            );
        }
    }
    const [user_paths, loading_user_paths, error_user_paths] = useCollectionData(pathOverviewQuery);
    return {
        user_paths, loading_user_paths, error_user_paths,

    };
}
