import { serverTimestamp, Timestamp } from "firebase/firestore";
import { makeid } from "../HelperFunction";

// SPACE
const space_data_blueprint = {
    config: {
        default_language: "en",
        avail_languages: ["en", "de"],
        signInEnabled: true,
        signUpEnabled: true,
    },
    metaData: {
        title: "",
        url: "", 
        homePage: "", 
        id: "",
    },
    roles: {
        curator_ids: [], // maybe rename to space_owner
    },
};
const space_info_data_blueprint = {
    about: {
        aboutTitle: "",
        description: "",
        stats: [{ stat: "", text: "" }],
        images:{aboutImage:"", bannerImg:""}
    },
    support:{email:""}
};
const space_theme_data_blueprint = {
    theme: {
        bigChat: {
            chatRooms_background: "",
            chatRooms_horizontalLine: "",
            chatRooms_roomActive: "#ffffff",
        },
        browseBanner: {
            browseBanner_accentColor: "",
        },
        canvas: {
            canvas: "",
            canvas_btn: "",
        },
        main: {
            main_accent: "",
        },
        main_nav:{
            mainNav_backgroundColor:"#ffffff",
            mainNav_middleBtnColorActive:"",
            mainNav_middleBtnHover:""
        },
        images: {
            small_card: "",
            space_logo: "",
            space_logo_font: "",
        },
    }
};

const space_stats_blueprint = {
    stats: {
        groups: {
            active: [],
            archived: [],
        },
        members: {
            curators: {
                active: [],
                inactive: [],
                archived: [],
                pending: [],
                deleted: [],
            },
            teachers: {
                active: [],
                inactive: [],
                archived: [],
                pending: [],
                deleted: [],
            },
            students: {
                active: [],
                inactive: [],
                archived: [],
                pending: [],
                deleted: [],
            },
        },
        paths: {
            active_count: 0,
            drafted: 0,
        },
        resources: {},
    },
};

export { space_data_blueprint, space_stats_blueprint, space_theme_data_blueprint, space_info_data_blueprint };

//PATH
const path_data_blueprint = {
    config: {
        isPublished: false,
        hasPracticeQuestions: false,
        teachersAreCurators: false,
        hasAutoQuizGen: false,
        isExplorable: false,
        isFree: false,
        messagesEditable: false,
    },
    properties: {
        difficulty: 1,
    },
    recommended_paths: [],
    stats: {
        groups: {
            active_count: 0,
        },
        timeToComplete: 0,
    },
    people: { curators: {} },
    owner: {
        curator_id: "",
    },
    metaData: {
        date_created: serverTimestamp(),
        date_updated: serverTimestamp(),
        id: "",
        created_by_id: "",
        aboutPath: "<p></p>",
        title: "",
        tags: [],
        subTitle: "",
        description: "",
    },
    content: {
        modules: {
            1: {
                title: "",
                img: "",
                position: 1,
                id: makeid(4),
            },
        },
    },
    images: {
        banner_modules: "",
        banner_overview: "",
        card: "",
        small_card: "",
    },
    sales: {
        link: "",
    },
};
const path_stats_blueprint = {
    stats: {
        groups: {
            active: [],
        },
        quiz: { numberOfQuestions: 0 },
        rating: { count: 0, enabled: true },
        timeToComplete: 0,
        time_to_complete_by_group: {},
    },
};
const path_member_stats_blueprint = {
    stats: {
        members: {
            curators: { active: [], pending: [], inactive: [], deleted: [] },
            students: { active: [], pending: [], inactive: [], deleted: [] },
            teachers: { active: [], pending: [], inactive: [], deleted: [] },
        },
    },
};

export { path_data_blueprint, path_stats_blueprint, path_member_stats_blueprint };

const member_stats_blueprint = {
    metaData: {
        date_updated: serverTimestamp(),
        updated_at: serverTimestamp(),
    },
    stats: {
        members: {
            student_ids: [],
            teacher_ids: [],
        },
    },
};
const memberData_blueprint = {
    user: {
        email: "",
        role: "student",
        user_pic: "",
        name: "",
        user_id: makeid(15),
    },
    metaData: {
        date_joined_space: new Date(),
        date_updated: new Date(),
    },
    groups: {
        //TEACHER
        active_teacher_group_ids: [],
        archived_teacher_group_ids: [],
        deleted_teacher_group_ids: [],
        //STUDENT
        active_student_group_ids: [],
        archived_student_group_ids: [],
        deleted_student_group_ids: [],
        joined_groups: {},
        left_groups: {},
    },
    paths: {
        //TEACHER
        active_teacher_path_ids: [],
        inactive_teacher_path_ids: [],
        deleted_teacher_path_ids: [],
        //STUDENT
        active_student_path_ids: [],
        inactive_student_path_ids: [],
        deleted_student_path_ids: [],
        //CURATOR
        active_curator_path_ids: [],
        inactive_curator_path_ids: [],
        deleted_curator_path_ids: [],
        joined_paths: {},
        left_paths: {},
        date_expired: {},
    },
};

//GROUP
const group_blueprint = {
    properties: { color: "" },
    metaData: {
        date_created: new Date(),
        date_archived: null,
        date_updated: new Date(),
        id: "",
        name: "",
        status: "active",
        created_by_id: "",
        type: "",
    },
    stats: { message_count: 0, deleted_message_count: 0 },
    content: { path_ids: [], resources: {} },
};
export { group_blueprint };

//QPool
const quiz_data_stats_blueprint = {
    analytics: {},
    numberOfQuestions: 0,
    question_ids: [],
};
const qpool_stats_blueprint = {
    tags: [],
};
function getpractice_question_blueprint(path_id) {
    return {
        path_id: path_id,
        hierarchy_1: {},
        hierarchy_2: {},
        hierarchy_3: {},
    };
}

function getquestion_data_blueprint(user_id) {
    return {
        metaData: {
            date_created: { _seconds: Timestamp.fromDate(new Date())?.seconds },
            date_updated: { _seconds: Timestamp.fromDate(new Date())?.seconds },
            editors: [{ user_id, date: new Date() }],
            local_quiz_ids: [],
            tags: [],
            qid: makeid(15),
            type: null,
        },
        content: {
            question: "<p></p>",
            explanation: "<p></p>",
        },
        properties: {
            // multipleChoice: false,
            // random: false,
            // maxPoints: 1
        },
        stats: {
            average_relative_score: 0,
            average_score: 0,
            totals: { numberTaken: 0 },
        },
    };
}
function getquestion_data_blueprint2(path_id, user_id) {
    return {
        meta: {
            dt_c: { _seconds: Timestamp.fromDate(new Date())?.seconds },
            dt_u: { _seconds: Timestamp.fromDate(new Date())?.seconds },
            editors: [user_id],
            quiz_ids_per_course: {},
            tags: [],
            qid: makeid(15),
            type: "",
            hint: false,
            path_ids: [path_id],
        },
        cont: {
            ans: [{ cr: true, an: "<p></p>", pts: 1, id: "1" }],
            q: "<p></p>",
            expl: "<p></p>",
            hint: "",
        },
        props: {
            // multipleChoice: false,
            // random: false,
            // maxPoints: 1
        },
        stats: {
            avg_rl_scr: 0.5, //cold start
            avg_scr: 0,
            timesUsed: 0,
            totals: { numberTaken: 0 },
        },
    };
}
function convertQuizQuestionFormat(oldObject, qid, path_id, path_ids) {
    const q_type = oldObject?.metaData?.type || "multipleChoice";
    const new_q = {
        meta: {
            dt_c: oldObject?.metaData?.date_created || null,
            dt_u: oldObject?.metaData?.date_updated || null,
            editors: oldObject?.metaData?.editors ? oldObject?.metaData?.editors?.map((e) => e.user_id) : [],
            quiz_ids_per_course: { [path_id]: oldObject?.metaData?.local_quiz_ids || [] },
            path_ids: path_ids,
            tags: oldObject?.metaData?.tags || [],
            qid: qid,
            type: q_type,
            hint: oldObject?.metaData?.hint ? oldObject?.metaData?.hint : false, // This is a new key, so setting it to false
        },
        cont: {
            expl: oldObject?.content?.explanation || "<p></p>",
            hint: oldObject?.content?.hint || "", // This is a new key, so setting it to empty string
        },
        props: oldObject.properties,
        stats: {
            avg_rl_scr: oldObject?.stats?.average_relative_score || 0,
            avg_scr: oldObject?.stats?.average_score || 0,
            timesUsed: oldObject?.stats?.timesUsed || 0,
            total: oldObject?.stats?.totals,
        },
    };
    if (q_type === "multipleChoice") {
        new_q["cont"]["q"] = oldObject?.content?.question ? oldObject.content.question : "<p></p>";
        new_q["cont"]["ans"] = (oldObject?.content.answers || []).map((answer, index) => ({
            cr: typeof answer.isCorrect === "boolean" ? answer.isCorrect : false,
            an: answer.answer,
            pts: typeof answer.points === "number" ? answer.points : typeof answer.isCorrect === "boolean" ? (answer.isCorrect ? 1 : 0) : 0,
            id: answer.id ? answer.id : index.toString(),
        }));
    } else if (q_type === "blanks") {
        new_q["cont"]["blanks"] = oldObject.content.blanks || {};
        new_q["cont"]["q"] = oldObject?.content?.question ? oldObject.content.question : "<p></p>";
    } else if (q_type === "match") {
        new_q["cont"]["clm_a"] = oldObject.content.column_a || { 1: "", 2: "" };
        new_q["cont"]["clm_b"] = oldObject.content.column_b || { 1: "", 2: "" };
        new_q["cont"]["descr"] = oldObject.content.descr ? oldObject.content.descr : "";
    }
    return new_q;
}

export {
    quiz_data_stats_blueprint,
    qpool_stats_blueprint,
    getquestion_data_blueprint,
    getpractice_question_blueprint,
    getquestion_data_blueprint2,
    convertQuizQuestionFormat,
};

//RESOURCES
const resource = {
    metaData: { title: "", type: "" },
    localData: { local_id: "" },

    typeData: {},
};

function getrichtext_data_blueprint(user_id) {
    const richtext_data_blueprint = {
        richtext: "<p></p>",
        authors: [user_id],
        images: [],
    };
    return richtext_data_blueprint;
}

function getevent_data_blueprint(user_id) {
    const start = new Date();
    const end = new Date();
    const start_time = start.getHours() > 20 || start.getHours() < 6 ? 12 : start.getHours() + 1;
    const end_time = start.getHours() > 20 || start.getHours() < 6 ? 13 : start.getHours() + 2;

    start.setDate(start.getDate() + 1);
    start.setHours(start_time);
    start.setMinutes(0);
    end.setDate(end.getDate() + 1);
    end.setHours(end_time);
    end.setMinutes(0);
    const event_data_blueprint = {
        start: Timestamp.fromDate(start),
        end: Timestamp.fromDate(end),
        multiday: false,
        eventType: "inclass",
        location: "",
        room: "",
        remoteLink: "",
        video_call_provider: "",
        speakers: [user_id],
        recurrence: false,
        recurrence_type: "weekly",
    };
    return event_data_blueprint;
}
const video_data_blueprint = {
    link: "",
    host: "",
    title: "",
    videoLength: 0,
    videoDescription: "",
    channelName: "",
    datePublished: null,
};
const slide_data_blueprint = {
    url: "",
    file_name: "",
    file_type: "",
    path: "",
    slides_count: 0,
    isDownloadable: true,
};
const quiz_data_blueprint = {
    isOrderTypeRandom: true,
    isCheckTypeInstant: false,
    isAttemptsUnlimited: true,
    attemptsNumber: 1,
    isTimeUnlimited: true,
    timeLimitNumber: 0,
    passMarkTypeQuestions: true,
    passMarkPercent: 70,
    onlySinglePage: true,
    nQuestions: 1,
    q_obj: {},
    q_ids: [],
    description: "",
};
export { video_data_blueprint, slide_data_blueprint, quiz_data_blueprint, getevent_data_blueprint, getrichtext_data_blueprint };

//USERS
const curator_blueprint = {
    user: {
        name: "",
        prefix: "",
        image: "",
        description: "",
        social_media: {
            instagram: "",
            linked_in: "",
            youtube: "",
            twitter: "",
            github: "",
        },
    },
    metaData: {
        id: "",
        tags: [], // c_roles
        images: {
            cover_img: "",
        },
    },
    background: {
        profession: "",
        degree: "",
        location: {
            city: "",
            country: "",
        },
    },
    content: {
        curator_path_ids: [],
        top_paths: [],
        space: [],
    },
};

const users_private_blueprint = {
    metaData: { email: "", name: "", user_image: "" },
    config: {
        roles: {
            admin: false,
            curator: false,
        },
    },
    access: {
        paths_student_by_space: {},
        paths_curator_by_space: {},
        paths_teacher_by_space: {},
        user_space_ids: [],
        spaces_curated: [],
        teacherGroupsPerSpace: {},
        studentGroupsPerSpace: {},
    },
};
const user_data_blueprint = {
    metaData: { email: "", name: "" },
    config: { language: "" },
    access: { paths_curated: {}, paths_taught: {}, paths_enrolled: {} },
};

const user_progress_blueprint = {
    resources: { checked_space_resources: [] },
    tasks: { checked_space_resource_tasks: [] },
    stats: { time_worked: {} },
    tags: { checked_space_resources_by_tag_date: [] },
    voting: { downvotes: [], upvotes: [] },
    //    **Only space**
    config: { download_calendar: false, mark_as_read: [] },
};

export { memberData_blueprint, member_stats_blueprint, curator_blueprint, users_private_blueprint, user_data_blueprint, user_progress_blueprint };

//CHAT
const channel_blueprint = {
    // student_ids: [],
    stats: {
        message_count: 0,
        deleted_message_count: 0,
    },
    metaData: {
        date_created: serverTimestamp(),
        date_updated: serverTimestamp(),
        name: "",
        type: "",
        id: "",
        origin: "",
    },
};

const message_blueprint_old = {
    attachments: [],
    content: { text: "" },
    metaData: {
        id: "",
        space_id: "",
        path_id: "",
        channel_id: "",
        channel_type: "",
        created_at: serverTimestamp(),
        updated_at: serverTimestamp(),
        type: "",
    },
    user: { user_id: "", deleted_by: "", deleted_at: "", updated_at: "" },
    atResource: {}, // resource_id: "", timestamp: 0, tag: "", global_id: "", resource_type: ""
    stats: { lastReply: "", lastUserReply: {}, reactions: {}, reply_count: 0, reply_deleted_count: 0 },
    properties: { hasEmoji: false, isQuestion: false },
};
const message_blueprint = {
    files: [],
    cont: { text: "" },
    meta: {
        id: "",
        space_id: "",
        path_id: "",
        channel_id: "",
        channel_type: "",
        created_at: serverTimestamp(),
        updated_at: serverTimestamp(),
        type: "",
    },
    user: { user_id: "", deleted_by: "", deleted_at: "", updated_at: "" },
    res: {},
    stats: { lastReply: "", lastUserReply: {}, reactions: {}, reply_count: 0, reply_deleted_count: 0 },
    props: { hasEmoji: false, isQuestion: false },
};

export { channel_blueprint, message_blueprint };

//OTHER
export function gen_curator_data_blueprint(user_id, user_name, user_pic, paths_by_space, taught_paths_by_space, space_id, space_title) {
    return {
        //USER
        user: {
            name: user_name,
            prefix: "",
            image: user_pic,
            description: "",
        },
        metaData: {
            id: user_id,
            roles: {},
            images: {
                cover_img: "",
            },
        },
        stats: {},
        background: {
            profession: "",
            degree: "",
            location: {
                city: "",
                country: "",
            },
        },
        content: {
            paths_by_space,
            taught_paths_by_space,
            top_paths: {},
            spaces: [{ id: space_id, name: space_title }]
        },
        social_media: {
            instagram: "",
            linked_in: "",
            youtube: "",
            twitter: "",
            github: "",
        },
    };
}
