import { httpsCallable } from "firebase/functions";
import { functions } from "../../../app/firebase";

export async function translateTag(text, source_lang, lang) {
    console.log(text, source_lang, lang)
    const translateTag = httpsCallable(functions, "deepL-translateTag");
    console.log(translateTag)
    return translateTag({ text, source_lang, lang })
        .then((result) => {
            console.log(result);
            return result;
        }).catch((error) => {
            console.log(error);
            console.log(error.code);
            console.log(error.message);
            console.log(error.details);
            return error;
        });

}
