export default function setManifestFile(spaceThemeData, spaceData) {
    var dynamicManifest = {
        short_name: spaceData?.spc_title,
        name: spaceData?.spc_title,
        icons: [
            // {
            //     src: spaceThemeData?.spc_icon,
            //     type: "image/png",
            //     sizes: "64x64 32x32 24x24 16x16",
            // },
            // {
            //     src: spaceThemeData?.spc_icon,
            //     type: "image/png",
            //     sizes: "192x192",
            // },
            {
                src: "https://firebasestorage.googleapis.com/v0/b/radix-library.appspot.com/o/spaces%2Fbemedi%2F512x512_bemedi.png?alt=media&token=855357fb-d9a6-42f4-8a12-4d1cff27032d",
                type: "image/png",
                sizes: "512x512",
            },
        ],
        id: window.location.origin,
        start_url: window.location.origin,
        scope: window.location.origin,
        display: "standalone",
        theme_color: "#001733",
        background_color: "#fcf9f4",
    };

    const stringManifest = JSON.stringify(dynamicManifest);
    const blob = new Blob([stringManifest], { type: "application/json" });
    const manifestURL = URL.createObjectURL(blob);
    document.querySelector("#appManifest").setAttribute("href", manifestURL);
    return;
}
