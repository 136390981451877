import { ref } from "firebase/database";
import { useObjectValueOnce } from "react-firehooks";
import { rlt_db } from "../../../../app/firebase";
import { useSpaceContext } from "../../../../contexts/SpaceContext/SpaceContext";
import { useAuth } from "../../../../contexts/AuthContext";

export default function useMemberLastOnline(condition = true) {
    const { user_id} = useAuth();
    const { space_id } = useSpaceContext();
    //not listening
    let member_ref
    if (condition && space_id && user_id) {
        member_ref = ref(rlt_db, "user_presence/spaces/" + space_id + "/" + user_id + "/last_online")
    }

    const [user_last_online, loading_presence, error] = useObjectValueOnce(member_ref);

    const last_online = user_last_online ? user_last_online : null
    return {
        last_online, loading_presence
    };


}
