import classes from "./KeyTooltip.module.css";
import { KeyboardArrow, KeyboardEnter } from "../../../assets/icons/Icons";

export default function KeyTooltip({ type, children = null }) {
    let icon;
    let addClass = null;
    switch (type) {
        case "enter":
            icon = <KeyboardEnter size={"15px"} />;
            break;
        case "up":
            icon = <KeyboardArrow size={"15px"} />;
            break;
        case "down":
            icon = <KeyboardArrow size={"15px"} />;
            addClass = classes.Down;
            break;
        case "esc":
            icon = <span className={classes.KeyText}>esc</span>;
            break;
        default:
            icon = children;
    }
    return <div className={[classes.Key, addClass].join(" ")}>{icon}</div>;
}
