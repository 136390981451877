import { arrayRemove, arrayUnion, doc, updateDoc, writeBatch, deleteField, serverTimestamp } from "@firebase/firestore";
import { auth, firestore } from "../../app/firebase";


export function updateLanguage(language) {
    const uid = auth.currentUser?.uid;
    if (uid) {
        const usersRef = doc(firestore, "users", uid);
        updateDoc(usersRef, { ["config.language"]: language });
    }
}


export async function unenrollPath(space_id, path_id) {
    const uid = auth.currentUser?.uid;
    const batch = writeBatch(firestore);
    if (uid) {
        const userRef = doc(firestore, "users", uid);
        const checkedRef = doc(firestore, "progress_by_user", uid, "progress_spaces", space_id, "progress_paths", path_id); //Delete all progress
        batch.update(userRef, {
            [`access.paths_student.${path_id}`]: deleteField(),
        });
        batch.delete(checkedRef);
        await batch.commit();
    }
}
///VOTING
//PATH
//upvotes
export async function addUpvote(space_id, path_id, global_id, local_id) {
    const uid = auth.currentUser?.uid;
    const batch = writeBatch(firestore);
    const new_votes = { upvotes: arrayUnion(local_id) };
    const metaData = { path_id };
    const local_userUpvotesRef = doc(firestore, "progress_by_user", uid, "progress_spaces", space_id, "progress_paths", path_id);
    batch.set(local_userUpvotesRef, {
        voting: new_votes,
        metaData
    }, { merge: true });
    const new_votes_2 = { locally: { [global_id]: { upvotes: arrayUnion(local_id) } } };
    const user_space_ref = doc(firestore, "progress_by_user", uid, "progress_spaces", space_id);
    batch.set(user_space_ref, {
        voting: new_votes_2,
    }, { merge: true });
    await batch.commit();
}
export async function removeUpvote(space_id, path_id, global_id, local_id) {
    const uid = auth.currentUser?.uid;
    const user_space_ref = doc(firestore, "progress_by_user", uid, "progress_spaces", space_id);
    const local_userUpvotesRef = doc(firestore, "progress_by_user", uid, "progress_spaces", space_id, "progress_paths", path_id);
    const batch = writeBatch(firestore);
    batch.update(local_userUpvotesRef, { ["voting.upvotes"]: arrayRemove(local_id) });
    batch.update(user_space_ref, {
        ["voting.locally." + global_id + ".upvotes"]: arrayRemove(local_id),
    });
    await batch.commit();
}
//downvotes
export async function addDownvote(space_id, path_id, global_id, local_id) {
    const uid = auth.currentUser?.uid;
    const batch = writeBatch(firestore);
    const local_userUpvotesRef = doc(firestore, "progress_by_user", uid, "progress_spaces", space_id, "progress_paths", path_id);
    const new_votes = { downvotes: arrayUnion(local_id) };
    const metaData = { path_id };
    batch.set(local_userUpvotesRef, {
        voting: new_votes,
        metaData
    }, { merge: true });

    const user_space_ref = doc(firestore, "progress_by_user", uid, "progress_spaces", space_id);
    const new_votes_2 = { locally: { [global_id]: { downvotes: arrayUnion(local_id) } } };
    batch.set(user_space_ref, {
        voting: new_votes_2,
    }, { merge: true });
    await batch.commit();
}
export async function removeDownvote(space_id, path_id, global_id, local_id) {
    const uid = auth.currentUser?.uid;
    // const global_userUpvotesRef = doc(firestore, "progress_by_user", uid);
    const user_space_ref = doc(firestore, "progress_by_user", uid, "progress_spaces", space_id);
    const local_userUpvotesRef = doc(firestore, "progress_by_user", uid, "progress_spaces", space_id, "progress_paths", path_id);
    const batch = writeBatch(firestore);
    batch.update(user_space_ref, { ["voting.locally." + global_id + ".downvotes"]: arrayRemove(local_id) });
    batch.update(local_userUpvotesRef, { ["voting.downvotes"]: arrayRemove(local_id) });
    await batch.commit();
}
//both
export async function addUpvoteRemoveDownvote(space_id, path_id, global_id, local_id) {
    const uid = auth.currentUser?.uid;
    const local_userUpvotesRef = doc(firestore, "progress_by_user", uid, "progress_spaces", space_id, "progress_paths", path_id);
    const batch = writeBatch(firestore);
    batch.update(local_userUpvotesRef, {
        ["voting.downvotes"]: arrayRemove(local_id),
        ["voting.upvotes"]: arrayUnion(local_id),
    });
    const user_space_ref = doc(firestore, "progress_by_user", uid, "progress_spaces", space_id);
    batch.update(user_space_ref, {
        ["voting.locally." + global_id + ".downvotes"]: arrayRemove(local_id),
        ["voting.locally." + global_id + ".upvotes"]: arrayUnion(local_id),
    });
    await batch.commit();
}
export async function addDownvoteRemoveUpvote(space_id, path_id, global_id, local_id) {
    const uid = auth.currentUser?.uid;
    const batch = writeBatch(firestore);
    const local_userUpvotesRef = doc(firestore, "progress_by_user", uid, "progress_spaces", space_id, "progress_paths", path_id);
    batch.update(local_userUpvotesRef, {
        ["voting.upvotes"]: arrayRemove(local_id),
        ["voting.downvotes"]: arrayUnion(local_id),
    });
    const user_space_ref = doc(firestore, "progress_by_user", uid, "progress_spaces", space_id);
    batch.update(user_space_ref, {
        ["voting.locally." + global_id + ".upvotes"]: arrayRemove(local_id),
        ["voting.locally." + global_id + ".downvotes"]: arrayUnion(local_id),
    });
    await batch.commit();
}

//SPACE
//upvote
export async function addUpvoteSpaceRes(space_id, global_id) {
    const uid = auth.currentUser?.uid;
    const user_space_ref = doc(firestore, "progress_by_user", uid, "progress_spaces", space_id);
    const batch = writeBatch(firestore);
    batch.update(user_space_ref, {
        ["voting.upvotes"]: arrayUnion(global_id),
    });
    await batch.commit();
}
export async function removeUpvoteSpaceRes(space_id, global_id) {
    const uid = auth.currentUser?.uid;
    const user_space_ref = doc(firestore, "progress_by_user", uid, "progress_spaces", space_id);
    const batch = writeBatch(firestore);
    batch.update(user_space_ref, { ["voting.upvotes"]: arrayRemove(global_id) });
    await batch.commit();
}
//downvote
export async function addDownvoteSpaceRes(space_id, global_id) {
    const uid = auth.currentUser?.uid;
    const batch = writeBatch(firestore);
    const user_space_ref = doc(firestore, "progress_by_user", uid, "progress_spaces", space_id);
    batch.update(user_space_ref, {
        ["voting.downvotes"]: arrayUnion(global_id),
    });
    await batch.commit();
}
export async function removeDownvoteSpaceRes(space_id, global_id) {
    const uid = auth.currentUser?.uid;
    // const global_userUpvotesRef = doc(firestore, "progress_by_user", uid);
    const user_space_ref = doc(firestore, "progress_by_user", uid, "progress_spaces", space_id);
    const batch = writeBatch(firestore);
    batch.update(user_space_ref, { ["voting.downvotes"]: arrayRemove(global_id) });
    await batch.commit();
}
//both
export async function addUpvoteRemoveDownvoteSpaceRes(space_id, global_id) {
    const uid = auth.currentUser?.uid;
    const user_space_ref = doc(firestore, "progress_by_user", uid, "progress_spaces", space_id);
    const batch = writeBatch(firestore);
    batch.update(user_space_ref, {
        ["voting.downvotes"]: arrayRemove(global_id),
        ["voting.upvotes"]: arrayUnion(global_id),
    });
    await batch.commit();
}
export async function addDownvoteRemoveUpvoteSpaceRes(space_id, global_id) {
    const uid = auth.currentUser?.uid;
    const batch = writeBatch(firestore);
    const user_space_ref = doc(firestore, "progress_by_user", uid, "progress_spaces", space_id);
    batch.update(user_space_ref, {
        ["voting.upvotes"]: arrayRemove(global_id),
        ["voting.downvotes"]: arrayUnion(global_id),
    });
    await batch.commit();
}

// a list of exported variables
