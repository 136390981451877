import { doc, writeBatch, serverTimestamp, arrayUnion } from "@firebase/firestore";
import { firestore, auth } from "../../app/firebase";


export async function updateUserCuratedPaths(path_id) {
    const user_id = auth?.currentUser?.uid;
    const batch = writeBatch(firestore);
    const userRef = doc(firestore, 'users', user_id)
    batch.update(userRef, {
        [`access.paths_curator.${path_id}.date_updated`]: serverTimestamp()
    });
    await batch.commit();
}

export async function updateUserEnrolledPaths(path_id, current_module, role) {
    const user_id = auth.currentUser.uid;
    const batch = writeBatch(firestore);
    const userRef = doc(firestore, 'users', user_id)
    if (current_module) {
        batch.update(userRef, {
            ["access.paths_" + role + "." + path_id + ".date_updated"]: serverTimestamp(),
            ["access.paths_" + role + "." + path_id + ".currentModule"]: current_module
        })
    } else {
        batch.update(userRef, {
            ["access.paths_" + role + "." + path_id + ".date_updated"]: serverTimestamp()
        })
    }
    return batch.commit();
}


export async function setTutorialStepsAsRead(page_id) {
    const user_id = auth.currentUser?.uid;
    const batch = writeBatch(firestore);
    const userRef = doc(firestore, "users", user_id);
    batch.update(userRef, {
        ["config.tutorial_steps"]: arrayUnion(page_id)
    });
    await batch.commit();
}
export async function resetTutorialSteps() {
    const user_id = auth.currentUser?.uid;
    const batch = writeBatch(firestore);
    const userRef = doc(firestore, "users", user_id);
    batch.update(userRef, {
        ["config.tutorial_steps"]: []
    });
    await batch.commit();
}



export async function updateUserName(new_name) {
    const user_id = auth?.currentUser?.uid;
    const batch = writeBatch(firestore);
    const userRef = doc(firestore, 'users', user_id)
    batch.update(userRef, {
        ["user.name"]: new_name
    });
    await batch.commit();
}

export async function updateUserEmail(new_email) {
    const user_id = auth?.currentUser?.uid;
    const batch = writeBatch(firestore);
    const userRef = doc(firestore, 'users', user_id)
    batch.update(userRef, {
        ["user.email"]: new_email
    });
    await batch.commit();
}

export async function updateUserImage(url) {
    const user_id = auth?.currentUser?.uid;
    const batch = writeBatch(firestore);
    const userRef = doc(firestore, 'users', user_id)
    batch.update(userRef, {
        ["user.user_image"]: url
    });
    await batch.commit();
}