import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import { MenuTextMatch, useBasicTypeaheadTriggerMatch } from "@lexical/react/LexicalTypeaheadMenuPlugin";
import { $getSelection, $isRangeSelection, $isTextNode, TextNode, SELECTION_CHANGE_COMMAND, COMMAND_PRIORITY_LOW } from "lexical";
import { useCallback, useEffect, useRef, useState } from "react";
import { createPortal } from "react-dom";

import classes from "./MentionsPlugin.module.css";

import { $createMentionNode } from "../../nodes/MentionNode";
import ResourcePickerRT from "../../../../pages/Chat/components/MessageBar/components/ResourcePicker/ResourcePickerRT";
import { setFloatingElemPosition } from "../../utils/setFloatingElemPosition";
import { mergeRegister } from "@lexical/utils";

const PUNCTUATION = "\\.,\\+\\*\\?\\$\\@\\|#{}\\(\\)\\^\\-\\[\\]\\\\/!%'\"~=<>_:;";
const NAME = "\\b[A-Z][^\\s" + PUNCTUATION + "]";

const DocumentMentionsRegex = {
    NAME,
    PUNCTUATION,
};

const PUNC = DocumentMentionsRegex.PUNCTUATION;

const TRIGGERS = ["@"].join("");

// Chars we expect to see in a mention (non-space, non-punctuation).
const VALID_CHARS = "[^" + TRIGGERS + PUNC + "\\s]";

// Non-standard series of chars. Each series must be preceded and followed by
// a valid char.
const VALID_JOINS =
    "(?:" +
    "\\.[ |$]|" + // E.g. "r. " in "Mr. Smith"
    " |" + // E.g. " " in "Josh Duck"
    "[" +
    PUNC +
    "]|" + // E.g. "-' in "Salier-Hellendag"
    ")";

const LENGTH_LIMIT = 75;

const AtSignMentionsRegex = new RegExp(
    "(^|\\s|\\()(" + "[" + TRIGGERS + "]" + "((?:" + VALID_CHARS + VALID_JOINS + "){0," + LENGTH_LIMIT + "})" + ")$"
);

// 50 is the longest alias length limit.
const ALIAS_LENGTH_LIMIT = 50;

// Regex used to match alias.
const AtSignMentionsRegexAliasRegex = new RegExp(
    "(^|\\s|\\()(" + "[" + TRIGGERS + "]" + "((?:" + VALID_CHARS + "){0," + ALIAS_LENGTH_LIMIT + "})" + ")$"
);

function checkForAtSignMentions(text: string, minMatchLength: number, setFilter, setMatch): MenuTextMatch | null {
    let match = AtSignMentionsRegex.exec(text);

    if (match === null) {
        match = AtSignMentionsRegexAliasRegex.exec(text);
    }
    if (match !== null) {
        setMatch(match);
        // The strategy ignores leading whitespace but we need to know it's
        // length to add it to the leadOffset
        const maybeLeadingWhitespace = match[1];

        const matchingString = match[3];
        setFilter(matchingString);
        if (matchingString.length >= minMatchLength) {
            return {
                leadOffset: match.index + maybeLeadingWhitespace.length,
                matchingString,
                replaceableString: match[2],
            };
        }
    }
    return null;
}

function getQueryTextForSearch(editor: LexicalEditor): string | null {
    let text = null;
    editor.getEditorState().read(() => {
        const selection = $getSelection();
        if (!$isRangeSelection(selection)) {
            return;
        }
        text = getTextUpToAnchor(selection);
    });
    return text;
}

function getTextUpToAnchor(selection: RangeSelection): string | null {
    const anchor = selection.anchor;
    if (anchor.type !== "text") {
        return null;
    }
    const anchorNode = anchor.getNode();
    if (!anchorNode.isSimpleText()) {
        return null;
    }
    const anchorOffset = anchor.offset;
    return anchorNode.getTextContent().slice(0, anchorOffset);
}

function startTransition(callback: () => void) {
    if (React.startTransition) {
        React.startTransition(callback);
    } else {
        callback();
    }
}

function tryToPositionRange(leadOffset: number, range: Range, editorWindow: Window): boolean {
    const domSelection = editorWindow.getSelection();
    if (domSelection === null || !domSelection.isCollapsed) {
        return false;
    }
    const anchorNode = domSelection.anchorNode;
    const startOffset = leadOffset;
    const endOffset = domSelection.anchorOffset;

    if (anchorNode == null || endOffset == null) {
        return false;
    }

    try {
        range.setStart(anchorNode, startOffset);
        range.setEnd(anchorNode, endOffset);
    } catch (error) {
        return false;
    }

    return true;
}

function isSelectionOnEntityBoundary(editor: LexicalEditor, offset: number): boolean {
    if (offset !== 0) {
        return false;
    }
    return editor.getEditorState().read(() => {
        const selection = $getSelection();
        if ($isRangeSelection(selection)) {
            const anchor = selection.anchor;
            const anchorNode = anchor.getNode();
            const prevSibling = anchorNode.getPreviousSibling();
            return $isTextNode(prevSibling) && prevSibling.isTextEntity();
        }
        return false;
    });
}

export default function MentionsPlugin({ anchorElem, editorConfig }): JSX.Element | null {
    const [editor] = useLexicalComposerContext();
    const [filter, setFilter] = useState(null);
    const [match, setMatch] = useState(null);

    const onSelectResourceToTag = useCallback(
        (selectedResource) => {
            editor.update(() => {
                const mentionNode = $createMentionNode(selectedResource);
                const textNodeContainingQuery = match != null ? $splitNodeContainingQuery(match) : null;
                if (textNodeContainingQuery) {
                    textNodeContainingQuery.replace(mentionNode);
                }
                mentionNode.select();
            });
        },
        [editor, match]
    );

    const checkForSlashTriggerMatch = useBasicTypeaheadTriggerMatch("/", {
        minLength: 0,
    });

    const checkForMentionMatch = useCallback(
        (text: string) => {
            const slashMatch = checkForSlashTriggerMatch(text, editor);
            if (slashMatch !== null) {
                return null;
            }
            return checkForAtSignMentions(text, 0, setFilter, setMatch);
        },
        [checkForSlashTriggerMatch, editor, setFilter]
    );

    const [showPicker, setShowPicker] = useState(false);

    // useEffect(() => {
    //     return editor.registerCommand(
    //         SELECTION_CHANGE_COMMAND,
    //         (_payload, newEditor) => {
    //             updateToolbar();
    //             setActiveEditor(newEditor);
    //             return false;
    //         },
    //         COMMAND_PRIORITY_CRITICAL
    //     );
    // }, [editor, updateToolbar]);

    // const closeTypeahead = useCallback(() => {
    //     setResolution(null);
    //     if (onClose != null && resolution !== null) {
    //         onClose();
    //     }
    // }, [onClose, resolution]);

    // const openTypeahead = useCallback(
    //     (res: MenuResolution) => {
    //         setResolution(res);
    //         if (onOpen != null && resolution === null) {
    //             onOpen(res);
    //         }
    //     },
    //     [onOpen, resolution]
    // );

    const pickerRef = useRef(null);

    // useEffect(() => {
    //     if (showPicker) {
    //         const getPositionTextArea = document.querySelector(editorConfig?.editorContentID);
    //         getPositionTextArea?.addEventListener("input", (e) => showPositionMarker(e));
    //         getPositionTextArea?.addEventListener("click", (e) => showPositionMarker(e));
    //         if (anchorElem && pickerRef) {
    //             const pickerElem = pickerRef?.current;
    //             if (pickerElem) {
    //                 setFloatingElemPosition(rect, pickerElem, anchorElem);
    //             }
    //         }
    //     }
    //     return () => {
    //         const getPositionTextArea = document.querySelector(editorConfig?.editorContentID);
    //         getPositionTextArea?.removeEventListener("input", showPositionMarker);
    //         getPositionTextArea?.removeEventListener("click", showPositionMarker);
    //     };
    // }, [anchorElem, showPicker, pickerRef, editor]);

    // useEffect(() => {
    //     if (showPicker) {
    //         if (anchorElem && pickerRef) {
    //             const pickerElem = pickerRef?.current;
    //             console.log("SHOWPICKER setpos", pickerElem, pickerRef);
    //             if (pickerElem) {
    //                 setFloatingElemPosition(showPicker, pickerElem, anchorElem);
    //             }
    //         }
    //     }
    // }, [anchorElem, showPicker, pickerRef, editor, pickerRef?.current]);

    useEffect(() => {
        const updateListener = () => {
            editor.getEditorState().read(() => {
                const editorWindow = editor._window || window;
                const range = editorWindow.document.createRange();
                // const selection = $getSelection();
                const text = getQueryTextForSearch(editor);

                // if (!$isRangeSelection(selection) || !selection.isCollapsed() || text === null || range === null) {
                //     closeTypeahead();
                //     return;
                // }

                const match = checkForMentionMatch(text);

                if (match !== null && !isSelectionOnEntityBoundary(editor, match.leadOffset)) {
                    const isRangePositioned = tryToPositionRange(match.leadOffset, range, editorWindow);
                    if (isRangePositioned !== null) {
                        setShowPicker(true);
                        return;
                    }
                }
                // closeTypeahead();
            });
        };

        const removeUpdateListener = editor.registerUpdateListener(updateListener);

        return () => {
            removeUpdateListener();
        };
    }, [editor, checkForMentionMatch]);

    const updateLinkEditor = useCallback(() => {
        const selection = $getSelection();

        const editorElem = pickerRef.current;
        const nativeSelection = window.getSelection();
        const activeElement = document.activeElement;
        console.log("MentionsFloat -1", editorElem, nativeSelection, activeElement);
        if (editorElem === null) {
            return;
        }

        const rootElement = editor.getRootElement();
        console.log("MentionsFloat 0");

        if (
            selection !== null &&
            nativeSelection !== null &&
            rootElement !== null &&
            rootElement.contains(nativeSelection.anchorNode) &&
            editor.isEditable()
        ) {
            const domRange = nativeSelection.getRangeAt(0);
            let rect;
            if (nativeSelection.anchorNode === rootElement) {
                let inner = rootElement;
                while (inner.firstElementChild != null) {
                    inner = inner.firstElementChild as HTMLElement;
                }
                rect = inner.getBoundingClientRect();
            } else {
                rect = domRange.getBoundingClientRect();
            }
            console.log("MentionsFloat 1", rect, editorElem, anchorElem);
            setFloatingElemPosition(rect, editorElem, anchorElem);
        } else if (!activeElement) {
            if (rootElement !== null) {
                console.log("MentionsFloat 2");
                setFloatingElemPosition(null, editorElem, anchorElem);
            }
        }

        return true;
    }, [editor, pickerRef]); // closeTypeahead, openTypeahead

    useEffect(() => {
        return mergeRegister(
            editor.registerUpdateListener(({ editorState }) => {
                editorState.read(() => {
                    updateLinkEditor();
                });
            }),

            editor.registerCommand(
                SELECTION_CHANGE_COMMAND,
                () => {
                    updateLinkEditor();
                    return true;
                },
                COMMAND_PRIORITY_LOW
            )
        );
    }, [editor, updateLinkEditor]);

    return (
        showPicker &&
        createPortal(
            <div className={classes.PickerHolder} ref={pickerRef}>
                <ResourcePickerRT filter={filter} onSelection={onSelectResourceToTag} setShowAtPicker={setShowPicker} />
            </div>,
            anchorElem
        )
    );
}

/**
 * Split Lexical TextNode and return a new TextNode only containing matched text.
 * Common use cases include: removing the node, replacing with a new node.
 */
function $splitNodeContainingQuery(match: MenuTextMatch): TextNode | null {
    const selection = $getSelection();
    if (!$isRangeSelection(selection) || !selection.isCollapsed()) {
        return null;
    }
    const anchor = selection.anchor;
    if (anchor.type !== "text") {
        return null;
    }
    const anchorNode = anchor.getNode();
    if (!anchorNode.isSimpleText()) {
        return null;
    }
    const selectionOffset = anchor.offset;
    const textContent = anchorNode.getTextContent().slice(0, selectionOffset);
    const characterOffset = match.replaceableString.length;
    const queryOffset = getFullMatchOffset(textContent, match.matchingString, characterOffset);
    const startOffset = selectionOffset - queryOffset;
    if (startOffset < 0) {
        return null;
    }
    let newNode;
    if (startOffset === 0) {
        [newNode] = anchorNode.splitText(selectionOffset);
    } else {
        [, newNode] = anchorNode.splitText(startOffset, selectionOffset);
    }

    return newNode;
}

/**
 * Walk backwards along user input and forward through entity title to try
 * and replace more of the user's text with entity.
 */
function getFullMatchOffset(documentText: string, entryText: string, offset: number): number {
    let triggerOffset = offset;
    for (let i = triggerOffset; i <= entryText.length; i++) {
        if (documentText.substr(-i) === entryText.substr(0, i)) {
            triggerOffset = i;
        }
    }
    return triggerOffset;
}
