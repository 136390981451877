import { collection, limit, orderBy, query, where } from "firebase/firestore";
import { useCollectionData } from "react-firehooks";

import { useSpaceContext } from "../../../../../../contexts/SpaceContext/SpaceContext";
import { firestore } from "../../../../../../app/firebase";
import { useParams } from "react-router-dom";
import { useAuth } from "../../../../../../contexts/AuthContext";



export default function useYourTurnQuizDuels(condition = true) {
    const { space_id } = useSpaceContext();
    const { user_id } = useAuth();

    let qdRef, qdQuery;
    if (space_id  && condition && user_id) {
        qdRef = collection(firestore, "spaces", space_id, "quiz_duels")
        qdQuery = query(qdRef,
            where('duelData.rounds.next_player', '==', user_id),
            orderBy('metaData.date_updated', 'asc'),
            limit(5)
        );
    }
    const [your_turn_qds, loading_your_turn_qds, error_your_turn_qds] = useCollectionData(qdQuery);

    return { your_turn_qds, loading_your_turn_qds, error_your_turn_qds }
}
