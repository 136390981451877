import classes from "./ResourceIcon.module.css";
import {
    Youtube,
    Mic,
    GradLaptop,
    Book,
    Website,
    Papers,
    Cube,
    Slides,
    Event,
    Assignment,
    RichText,
    Cube3D,
    Quiz,
} from "../../../assets/icons/Icons";
import { ResourceColors } from "../../Helpers/AssetFunctions";

export default function ResourceIcon({ resource = null, size = 32, margin = null, type = null, onClick = null, host = null }) {
    const debug = false;
    let icon = null;
    let iconBgColor = "#ccc9bd";
    if (debug) console.log("resourceIcon,", resource);
    const rtype = type ? type : resource?.metaData?.type;
    switch (rtype) {
        case "video":
            icon = <Youtube color="white" size={Math.round(size * 0.46) + "px"} />;
            const vid_host = host ? host : resource?.typeData?.host;
            if (vid_host === "youtube") {
                iconBgColor = ResourceColors[vid_host];
            } else if (vid_host === "vimeo") {
                iconBgColor = ResourceColors[vid_host];
            }
            break;
        case "audio":
            icon = <Mic color="white" size={Math.round(size * 0.6) + "px"} style={{ marginLeft: "1px" }} />;
            iconBgColor = "#E69317";
            break;
        case "course":
            icon = <GradLaptop color="white" size={Math.round(size * 0.55) + "px"} style={{ marginBottom: "2px", marginLeft: "1px" }} />;
            iconBgColor = "#005120";
            break;
        case "book":
            icon = <Book color="white" size={Math.round(size * 0.42) + "px"} />;
            iconBgColor = "#8C0000";
            break;
        case "website":
            icon = <Website color="white" size={Math.round(size * 0.58) + "px"} />;
            iconBgColor = "#005B9D";
            break;
        // case "text":
        //     icon = <Bold color="white" size={Math.round(size * 0.42) + "px"} />;
        //     iconBgColor = "#000";
        //     break;
        case "pdf":
            icon = <Book color="white" size={Math.round(size * 0.42) + "px"} />;
            iconBgColor = "white";
            break;
        case "file":
            icon = <Papers color="white" size={Math.round(size * 0.65) + "px"} />;
            iconBgColor = "#ECB800";
            break;
        case "cube":
            icon = <Cube color="white" size={Math.round(size * 0.58) + "px"} />;
            iconBgColor = "#0052B2";
            break;
        case "slide":
            icon = <Slides color="white" size={Math.round(size * 0.45) + "px"} />;
            iconBgColor = "#8C0000";
            break;
        case "event":
            icon = (
                // <div
                //     className={classes.MonthDayContainer}
                // >
                //     {isDateInThisWeek(resource?.typeData?.start?.toDate()) ?
                //         <div className={classes.WeekDay}>
                //             {translate(days[resource?.typeData?.start.toDate().getDay()], lang)?.substr(0, 2)}
                //         </div>
                //         :
                //         <div className={classes.Month}>
                //             {translate(monthNames[resource?.typeData?.start.toDate().getMonth()], lang)?.substr(0, 3)}
                //         </div>}
                //     <div className={classes.Day}>
                //         {resource?.typeData?.start.toDate().getDate()}
                //     </div>
                // </div>
                <Event color="white" size={Math.round(size * 0.46) + "px"} />
            );
            iconBgColor = "#000";
            break;
        case "assignment":
            icon = <Assignment color="white" style={{ marginBottom: "3px" }} size={Math.round(size * 0.6) + "px"} />;
            iconBgColor = "#005B9D";
            break;
        case "richtext":
            icon = <RichText color="white" style={{ marginBottom: "1px" }} size={Math.round(size * 0.45) + "px"} />;
            iconBgColor = "#000000";
            break;
        case "3D":
            icon = <Cube3D color="white" style={{ marginBottom: "1px" }} size={Math.round(size * 0.6) + "px"} />;
            iconBgColor = "#00B0A6";
            break;
        case "quiz":
            icon = <Quiz color="white" style={{ marginBottom: "-1px" }} size={Math.round(size * 0.5) + "px"} />;
            iconBgColor = "#005B9D";
            break;
        default:
            <></>;
    }

    var IconDiskStyle = {
        backgroundColor: iconBgColor,
        width: size + "px",
        height: size + "px",
        borderRadius: size + "px",
    };
    var IconHolder = {
        width: size + "px",
        height: size + "px",
    };

    return (
        <div className={classes.ResourceIconWrapper} style={{ margin: margin }} onClick={onClick}>
            <div className={classes.IconDisk} style={IconDiskStyle}>
                <div className={classes.IconHolder} style={IconHolder}>
                    {icon}
                </div>
            </div>
        </div>
    );
}
