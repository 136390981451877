import { firestore } from "../../../app/firebase";
import { collection, query, where } from "firebase/firestore";
import { useCollectionData } from "react-firehooks";

import { useSpaceContext } from "../../../contexts/SpaceContext/SpaceContext";
import { useAuth } from "../../../contexts/AuthContext";

export default function usePathOverviews(condition = true) {
    const { space_id } = useSpaceContext();
    const { curatedTaughtAndEnrolled, isCuratorOfSpace } = useAuth();

    let pathOverviewRef, pathOverviewQuery;
    if (space_id && condition) {
        pathOverviewRef = collection(firestore, "spaces", space_id, "resources_overview_by_path_id");
        if (isCuratorOfSpace) {
            //ALL
            pathOverviewQuery = pathOverviewRef;
        } else if (curatedTaughtAndEnrolled?.length > 0) {
            pathOverviewQuery = query(
                pathOverviewRef,
                where("metaData.path_id", "in", curatedTaughtAndEnrolled.slice(0, 10)), //max of 10!
            );
        }
    }
    const [user_path_overviews, loading_path_overviews, error_path_overviews] = useCollectionData(pathOverviewQuery);


    return { user_path_overviews, loading_path_overviews, error_path_overviews }
}