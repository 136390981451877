import { ref } from "firebase/database";
import { useObjectValueOnce } from "react-firehooks";
import { rlt_db } from "../../../../app/firebase";

export default function useSpaceMap(condition = false) {
    let space_map_ref;
    if (condition) {
        space_map_ref = ref(rlt_db, "app_init")
    }
    const [space_map_loaded, loading_space_map, error] = useObjectValueOnce(space_map_ref);

    return { space_map_loaded, loading_space_map };
}
