import joinClasses from "../../../utils/joinClasses";
import classes from "./LabeledSection.module.css";

export default function LabeledSection({
    children,
    label = null,
    subLabel = null,
    active = false,
    disabled = false,
    hoverActive = false,
    labelClass = null,
    noMargin = false,
}) {
    return (
        <div
            className={joinClasses(
                classes.LabeledSection,
                disabled ? classes.Disabled : null,
                active ? classes.Active : null,
                hoverActive ? null : classes.NoHover,
                "NoSelect",
                labelClass && labelClass,
                noMargin && classes.NoMargin
            )}
        >
            {label ? <div className={classes.Label}>{label}</div> : null}
            {subLabel ? <div className={classes.SubLabel}>{subLabel}</div> : null}
            {children ? <div className={classes.Content}>{children}</div> : null}
        </div>
    );
}
