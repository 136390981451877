import { arrayUnion, doc, serverTimestamp, getDoc, writeBatch, increment, arrayRemove, deleteField, updateDoc, setDoc, deleteDoc } from "@firebase/firestore";
import { auth, firestore } from "../../../../../app/firebase";
import { getGlobalTags } from "../../../../../containers/CoursesBrowseArea/Functions/PathsFetchFunctions";
import { makeid } from "../../../../Helpers/HelperFunction";
import { translateTag } from "../../../../Helpers/HTTPRequests/DeepLRequests";
import { deepLLanguageCodeMapping } from "../../../../Helpers/LanguageHelperFunctions";
import { getQuestionDataNew } from "./QuizHelperFunctions";
import { getQuizData, getResourceData } from "../../functions/ResourceGetterFunctions";
import { getAnswerStats } from "./UserQuizHelperFunctions";

export async function sendQuizAttempt(space_id, path_id = null, attempt, quizAttempsID, duel_id = "") {
    const uid = auth.currentUser?.uid;
    attempt.date_created = new Date();
    attempt.attempt_id = makeid(5);
    //(1) ATTEMPT
    let quizAttempsPerUserPerPath;
    if (path_id) {
        if (duel_id) {
            console.log(duel_id);
            quizAttempsPerUserPerPath = doc(firestore, "progress_by_user", uid, "progress_spaces", space_id, "progress_paths", path_id, "quiz_duels", duel_id);
        } else {
            quizAttempsPerUserPerPath = doc(firestore, "progress_by_user", uid, "progress_spaces", space_id, "progress_paths", path_id, "quiz_attempts", quizAttempsID);
        }
    } else {
        if (duel_id) {
            console.log(duel_id);
            quizAttempsPerUserPerPath = doc(firestore, "progress_by_user", uid, "progress_spaces", space_id, "quiz_duels", duel_id);
        } else {
            quizAttempsPerUserPerPath = doc(firestore, "progress_by_user", uid, "progress_spaces", space_id, "quiz_attempts", quizAttempsID);
        }
    }
    const new_attempts = { attempts: arrayUnion(attempt) };
    await setDoc(quizAttempsPerUserPerPath, new_attempts, { merge: true });
}


//STATS
export async function updateUserQuizStats(
    space_id,
    path_id = null,
    number_of_questions,
    new_basket_1,
    new_basket_2,
    new_basket_3,
    new_learnt_basket
) {
    const uid = auth.currentUser?.uid;
    const batch = writeBatch(firestore);
    let quizPerformancePerUserPerPath;
    //(2) stats
    if (path_id) {
        quizPerformancePerUserPerPath = doc(firestore, "progress_by_user", uid, "progress_spaces", space_id, "progress_paths", path_id,
            "quiz_performance_stats",
            "quiz_performance_stats"
        );
    } else {
        quizPerformancePerUserPerPath = doc(firestore, "progress_by_user", uid, "progress_spaces", space_id, "quiz_performance_stats", "quiz_performance_stats");
    }

    const stats = {
        totals: {
            number_of_questions_taken: increment(number_of_questions),
            number_of_quizzes_taken: increment(1),
        },
        last_quiz_taken: serverTimestamp(),
    };
    const baskets = {
        basket_1: new_basket_1,
        basket_2: new_basket_2,
        basket_3: new_basket_3,
        basket_learnt: new_learnt_basket,
    };
    batch.set(
        quizPerformancePerUserPerPath,
        {
            baskets,
            stats,
        },
        { merge: true }
    );

    await batch.commit();
}

export async function updateQuestionsStat(space_id, questionsStat) {
    questionsStat.forEach((question) => {
        const q_type = question?.q_type;
        //different stats count per type

        const quizQuestionRef2 = doc(firestore, "quiz_questions", question.qid);
        if (question?.first) {
            console.log("first");
            //edge case
            updateDoc(quizQuestionRef2, {
                ["stats.totals.numberTaken"]: 1,
                ["props.maxPoints"]: question?.maxPoints,
                ["stats.avg_scr"]: question?.average_score,
                ["stats.avg_rl_scr"]: question?.average_relative_score,
            });
        } else {
            updateDoc(quizQuestionRef2, {
                "stats.totals.numberTaken": increment(1),
                ["stats.avg_scr"]: question?.average_score,
                ["stats.avg_rl_scr"]: question?.average_relative_score
            });
        }
        updateAnswerStatsPerQuestion(space_id, question.qid, q_type, question?.answers_selected)
    });

}

export async function updateAnswerStatsPerQuestion(space_id, qid, q_type, answers_selected = {}) {
    const answer_stats = getAnswerStats(q_type, answers_selected);
    const quizQuestionRef = doc(firestore, "quiz_questions", qid, "stats", "question_stats");
    setDoc(quizQuestionRef, answer_stats, { merge: true });
}


export async function createQuizQsStats(local_id, qids = []) {
    const batch = writeBatch(firestore);
    const quizQuestionRef = doc(firestore, "resources", local_id, "stats", "quiz_stats");

    let q_stat = {};
    qids.forEach((qid) => {
        q_stat = { ...q_stat, [qid]: [] };
    });
    const qs_stats = { qids: q_stat };
    console.log(qs_stats);
    batch.set(
        quizQuestionRef,
        {
            ["stats"]: qs_stats,
        },
        { merge: true }
    );
    await batch.commit();
}
export async function removeQFromQuizStats(local_id, qid) {
    const batch = writeBatch(firestore);
    const quizQuestionRef = doc(firestore, "resources", local_id, "stats", "quiz_stats");
    batch.update(quizQuestionRef, {
        ["stats.qids." + qid]: deleteField(),
    });
    await batch.commit();
}
export async function createQinQIDStats(space_id, path_id, qid) {
    const batch = writeBatch(firestore);
    const quizQuestionRef = doc(firestore, "spaces", space_id, "paths", path_id, "stats", "quiz_question_stats");
    const qs_stats = { [qid]: [] };
    batch.set(
        quizQuestionRef,
        {
            ["stats"]: qs_stats,
        },
        { merge: true }
    );
    await batch.commit();
}
export async function removeQsFromQIDStats(space_id, path_id, qids = []) {
    const batch = writeBatch(firestore);
    const quizQuestionRef = doc(firestore, "spaces", space_id, "paths", path_id, "stats", "quiz_question_stats");
    let q_stat = {};
    qids.forEach((qid) => {
        q_stat = { ...q_stat, [qid]: deleteField() };
    });
    batch.set(quizQuestionRef,
        {
            stats: q_stat,
        },
        { merge: true }
    );
    await batch.commit();
}

export async function updateAggregateStats(
    space_id,
    path_id,
    //(1) scores across questions
    numberOfNewlyTakenQuestions = 0,
    new_aggregated_average_score_questions = 0,
    new_sd_score_questions = 0,
    new_m2_score_questions = 0,
    new_aggregated_average_relative_score_questions = 0,
    new_sd__relative_score_questions = 0,
    new_m2_relative_score_questions = 0,
    //(2)scorces across persons
    new_person_in_stats = 0,
    new_aggregated_average_adjusted_relative_persons = 0,
    new_sd_adjusted_relative_persons = 0,
    new_m2_adjusted_relative_persons = 0,
    new_aggregated_average_relative_persons = 0,
    new_sd_relative_persons = 0,
    new_m2_relative_persons = 0,
    new_aggregated_average_persons = 0,
    new_sd_persons = 0,
    new_m2_persons = 0,

    //(3)AVERAGE NUMBER OF QUESTIONS PER PERSON
    new_average_number_of_questions_taken_person,
    new_sd_number_of_questions_taken_person,
    new_m2_number_of_questions_taken_person,
    //(4))AVERAGE NUMBER OF QUIZZES PER PERSON
    new_average_number_of_quizzes_taken_per_person = 0,
    new_sd_number_of_quizzes_taken_per_person = 0,
    new_m2_number_of_quizzes_taken_per_person = 0
) {
    const batch = writeBatch(firestore);
    const poolStatsRef = doc(firestore, "spaces", space_id, "paths", path_id, "stats", "quiz_data_stats");
    batch.update(poolStatsRef, {
        //TOTALS
        ["analytics.totals.number_of_people"]: increment(new_person_in_stats),
        ["analytics.totals.number_of_questions_taken"]: increment(numberOfNewlyTakenQuestions),
        //QUESTIONS
        ["analytics.question_scores.relative.average"]: new_aggregated_average_relative_score_questions,
        ["analytics.question_scores.relative.sd"]: new_sd__relative_score_questions,
        ["analytics.question_scores.relative.m2"]: new_m2_relative_score_questions,
        ["analytics.question_scores.score.average"]: new_aggregated_average_score_questions,
        ["analytics.question_scores.score.sd"]: new_sd_score_questions,
        ["analytics.question_scores.score.m2"]: new_m2_score_questions,
        //PERSONS
        ["analytics.person_scores.adjusted_relative.average"]: new_aggregated_average_adjusted_relative_persons,
        ["analytics.person_scores.adjusted_relative.sd"]: new_sd_adjusted_relative_persons,
        ["analytics.person_scores.adjusted_relative.m2"]: new_m2_adjusted_relative_persons,

        ["analytics.person_scores.relative.average"]: new_aggregated_average_relative_persons,
        ["analytics.person_scores.relative.sd"]: new_sd_relative_persons,
        ["analytics.person_scores.relative.m2"]: new_m2_relative_persons,

        ["analytics.person_scores.score.average"]: new_aggregated_average_persons,
        ["analytics.person_scores.score.sd"]: new_sd_persons,
        ["analytics.person_scores.score.m2"]: new_m2_persons,

        //AVERAGE NUMBER OF QUESTIONS PER PERSON
        ["analytics.questions_per_person.average"]: new_average_number_of_questions_taken_person,
        ["analytics.questions_per_person.sd"]: new_sd_number_of_questions_taken_person,
        ["analytics.questions_per_person.m2"]: new_m2_number_of_questions_taken_person,
        //AVERAGE NUMBER OF QUIZZES PER PERSON
        ["analytics.quizzes_per_person.average"]: new_average_number_of_quizzes_taken_per_person,
        ["analytics.quizzes_per_person.sd"]: new_sd_number_of_quizzes_taken_per_person,
        ["analytics.quizzes_per_person.m2"]: new_m2_number_of_quizzes_taken_per_person,
    });
    await batch.commit();
}

export async function updateStats(space_id, path_id, qids) {
    const poolStatsRef = doc(firestore, "spaces", space_id, "paths", path_id, "stats", "quiz_data_stats");
    await updateDoc(poolStatsRef, {
        //QUESTIONS
        question_ids: arrayUnion(...qids),
    });
    const stats_ref = doc(firestore, 'spaces', space_id, "stats", "quiz_question_stats");
    await updateDoc(stats_ref, {
        //QUESTIONS
        ["stats.question_ids"]: arrayUnion(...qids),
    });
}

export async function updateQuestionStat(path_id, local_id, qid, join) {
    const questionRef2 = doc(firestore, "quiz_questions", qid);
    if (join) {
        updateDoc(questionRef2, {
            ["meta.quiz_ids_per_course." + path_id]: arrayUnion(local_id),
        });
    } else {
        updateDoc(questionRef2, {
            ["meta.quiz_ids_per_course." + path_id]: arrayRemove(local_id),
        });
    }
}

export async function updateSpaceQuestionStat(space_id, local_id, qid, join) {
    const questionRef2 = doc(firestore, "quiz_questions", qid);
    if (join) {
        updateDoc(questionRef2, {
            ["meta.quiz_ids_per_course." + space_id]: arrayUnion(local_id),
        });
    } else {
        updateDoc(questionRef2, {
            ["meta.quiz_ids_per_course." + space_id]: arrayRemove(local_id),
        });
    }
}
//TODO: updateQuizQuestions not used
export async function updateQuizQuestions(space_id, path_id, local_id, new_quiz_questions) {
    const batch = writeBatch(firestore);
    const resourcesPathRef = doc(firestore, "resources", local_id);
    new_quiz_questions.forEach((q) => {
        batch.update(resourcesPathRef, {
            ["typeData.q_obj." + q.qid]: q,
        });
    });

    batch.commit();
}
export async function changePositionOfQuizQuestions(space_id, local_id, position_down, q_down, position_up, q_up, isSpaceResource) {
    const { q_id: qid_up } = getQuestionDataNew(q_up);
    const { q_id: qid_down } = getQuestionDataNew(q_down);
    const batch = writeBatch(firestore);
    let resourcesPathRef;
    if (isSpaceResource) {
        resourcesPathRef = doc(firestore, "spaces", space_id, "resources", local_id);
    } else {
        resourcesPathRef = doc(firestore, "resources", local_id);
    }
    batch.update(resourcesPathRef, {
        ["typeData.q_obj." + qid_down + ".position"]: position_down,
        ["typeData.q_obj." + qid_up + ".position"]: position_up,
    });

    batch.commit();
}
export async function putPositionOfQuizQuestionToTop(space_id, local_id, qid, isSpaceResource) {
    const batch = writeBatch(firestore);
    let resourcesPathRef;
    if (isSpaceResource) {
        resourcesPathRef = doc(firestore, "spaces", space_id, "resources", local_id);
    } else {
        resourcesPathRef = doc(firestore, "resources", local_id);
    }
    batch.update(resourcesPathRef, {
        ["typeData.q_obj." + qid + ".position"]: 1,
    });

    batch.commit();
}

export async function removeQuestionsFromQuiz(space_id, path_id, res_data, question_ids) {
    const { rsrc_local_id, rsrc_type_data } = getResourceData(res_data);
    const { quiz_questions_object } = getQuizData(rsrc_type_data);
    const new_quiz_questions_object = Object.fromEntries(
        Object.entries(quiz_questions_object).filter(([key]) => !question_ids.includes(Number(key)))
    );
    const resourcesPathRef = doc(firestore, "resources", rsrc_local_id);
    updateDoc(resourcesPathRef, {
        ["typeData.q_obj"]: new_quiz_questions_object,
        ["typeData.q_ids"]: arrayRemove(...question_ids),
    });
}
export async function removeQuestionFromQuiz(local_id, qid) {
    const resourcesPathRef = doc(firestore, "resources", local_id);
    updateDoc(resourcesPathRef, {
        ["typeData.q_obj." + qid]: deleteField(),
        ["typeData.q_ids"]: arrayRemove(qid)
    });
}
export async function removeQuestionFromSpaceQuiz(space_id, local_id, qid) {
    const resourcesPathRef = doc(firestore, "spaces", space_id, "resources", local_id);
    updateDoc(resourcesPathRef, {
        ["typeData.q_obj." + qid]: deleteField(),
        ["typeData.q_ids"]: arrayRemove(qid)
    });
}
export async function addQuestionsToQuizQuestion(local_id, qids, newQuestions) {
    const batch = writeBatch(firestore);
    const resourcesPathRef = doc(firestore, "resources", local_id);

    const question = {
        typeData: {
            q_obj: newQuestions,
            q_ids: arrayUnion(...qids),
        },
    };
    console.log(question);
    batch.set(resourcesPathRef, question, { merge: true });
    await batch.commit();
}
export async function addQuestionsToSpaceQuizQuestion(space_id, local_id, qids, newQuestions) {
    const batch = writeBatch(firestore);
    const resourcesPathRef = doc(firestore, "spaces", space_id, "resources", local_id);
    console.log(local_id);
    const question = {
        typeData: {
            q_obj: newQuestions,
            q_ids: arrayUnion(...qids),
        },
    };
    batch.set(resourcesPathRef, question, { merge: true });
    await batch.commit();
}

export async function deleteSingleQuestionFromPool(qid) {
    const questionRef2 = doc(firestore, "quiz_questions", qid);
    await deleteDoc(questionRef2);
}
export async function removeQuestionFromPath(space_id, path_id, qid) {
    const questionRef2 = doc(firestore, "quiz_questions", qid);
    await updateDoc(questionRef2, {
        "meta.path_ids": arrayRemove(path_id),
        ["meta.quiz_ids_per_course." + path_id]: deleteField()
    });
}



export async function deleteSingleQuestionFromPoolOld(space_id, qid) {
    const questionRef = doc(firestore, "quiz_questions", qid);
    deleteDoc(questionRef);
}
export async function updatePoolStats(space_id, path_id, qids, up) {
    //KEEP BOTH
    const number = qids?.length;
    const poolStatsRef = doc(firestore, "spaces", space_id, "paths", path_id, "stats", "quiz_data_stats");
    if (up) {
        updateDoc(poolStatsRef, {
            numberOfQuestions: increment(number),
            question_ids: arrayUnion(...qids),
        });
    } else {
        updateDoc(poolStatsRef, {
            numberOfQuestions: increment(-number),
            question_ids: arrayRemove(...qids),
        });
    }

    const stats_ref = doc(firestore, 'spaces', space_id, "stats", "quiz_question_stats");
    if (up) {
        updateDoc(stats_ref, {
            ["stats.numberOfQuestions"]: increment(number),
            ["stats.question_ids"]: arrayUnion(...qids),
        });
    } else {
        updateDoc(stats_ref, {
            ["stats.numberOfQuestions"]: increment(-number),
            ["stats.question_ids"]: arrayRemove(...qids),
        });
    }

}

export async function createQuestion(newQuestion) {
    // newQuestion["date_created"] = {value:serverTimestamp()}
    const questionRef2 = doc(firestore, "quiz_questions", newQuestion.meta.qid);
    await setDoc(questionRef2, newQuestion);
}

export async function updateBlankQuestion(space_id, qid, updated_q) {
    // newQuestion["date_created"] = {value:serverTimestamp()}
    const questionRef2 = doc(firestore, "quiz_questions", qid);
    await updateDoc(questionRef2, {
        ["cont.q"]: updated_q
    });
}

export async function addTagsToQPoolTags(space_id, path_id, new_tags) {
    const batch = writeBatch(firestore);
    const tags = new_tags.map((t) => t.key);
    const questionPoolStatsRef = doc(firestore, "spaces", space_id, "paths", path_id, "stats", "pool_tags");
    batch.update(questionPoolStatsRef, { tags: arrayUnion(...tags) });
    batch.commit();
}
export async function addTagToQPoolTags(space_id, path_id, new_tag) {
    const batch = writeBatch(firestore);
    const questionPoolStatsRef = doc(firestore, "spaces", space_id, "paths", path_id, "stats", "pool_tags");
    batch.update(questionPoolStatsRef, { tags: arrayUnion(new_tag) });
    batch.commit();
}

export async function deleteTagFromSpace(space_id, path_id, tag) {
    const langs = ["en", "de"];
    const batch = writeBatch(firestore);
    for (let index_1 = 0; index_1 < langs.length; index_1++) {
        const lang = langs[index_1];

        const space_tags_ref = doc(firestore, "spaces", space_id, "tags", lang);
        batch.update(space_tags_ref, {
            ["tags." + path_id + tag]: deleteField(),
        });
    }
    batch.commit();
}
export async function deleteTagFromQPool(space_id, path_id, tag) {
    const batch = writeBatch(firestore);
    const questionPoolStatsRef = doc(firestore, "spaces", space_id, "paths", path_id, "practice_question_tags", "2_q_ids_per_tags")
    console.log(tag);
    batch.update(questionPoolStatsRef, { ["tags." + tag]: deleteField() });
    batch.commit();
}

export async function updateSpaceAndGlobalTags(space_id, path_id = null, new_tags, spc_default_language, spc_avail_languages = []) {
    const batch = writeBatch(firestore);
    const langs = spc_avail_languages;
    for (let index_1 = 0; index_1 < langs.length; index_1++) {
        const lang = langs[index_1];

        const global_tags = await getGlobalTags(lang);
        const global_tags_ref = doc(firestore, "tags", lang);

        for (let index = 0; index < new_tags.length; index++) {
            const tag = new_tags[index];
            let tag_translated = tag.value;

            if (global_tags[tag.key]) {
                //already exists globally
                tag_translated = global_tags[tag.key];
            } else {
                if (lang !== spc_default_language) {
                    //not translate the typed in tag
                    const deepl_lang = deepLLanguageCodeMapping(lang);
                    console.log(deepl_lang);
                    const res = await translateTag(tag.value, spc_default_language, deepl_lang);
                    if (res?.data?.text) {
                        console.log("translation: " + res?.data?.text);
                        tag_translated = res?.data?.text;
                    }
                }
                console.log(tag_translated);
                batch.update(global_tags_ref, {
                    ["tags." + tag.key]: tag_translated,
                });
            }
            console.log(lang + " " + tag.key + " " + tag_translated);
            console.log(path_id);
            const space_tags_ref = doc(firestore, "spaces", space_id, "tags", lang);
            if (path_id) {
                batch.update(space_tags_ref, {
                    ["tags." + path_id + "." + tag.key]: tag_translated,
                });
            } else {
                batch.update(space_tags_ref, {
                    ["tags." + space_id + "." + tag.key]: tag_translated,
                });
            }
        }
    }
    await batch.commit();
}

export async function updateSingleQuestion(newQuestion) {
    const questionRef2 = doc(firestore, "quiz_questions", newQuestion.meta.qid);
    await updateDoc(questionRef2, newQuestion);
}
export async function updateTagsSingleQuestion(q_id, new_tags = []) {
    const questionRef2 = doc(firestore, "quiz_questions", q_id);
    await updateDoc(questionRef2, {
        ["meta.tags"]: arrayUnion(...new_tags)
    });
}
export async function removeTagsSingleQuestion(q_id, tags = []) {
    const questionRef2 = doc(firestore, "quiz_questions", q_id);
    await updateDoc(questionRef2, {
        ["meta.tags"]: arrayRemove(...tags)
    });
}
export async function updateQuestionInsideQuizzes(space_id, path_id, newQuestion) {
    const uid = auth.currentUser?.uid;
    //update each quiz where question appears
    const { q_id, q_type, q_tags, q_local_quiz_ids, q_prop_type } = getQuestionDataNew(newQuestion);
    q_local_quiz_ids?.forEach((local_id) => {
        const batch = writeBatch(firestore);
        const quizRef = doc(firestore, "resources", local_id);
        batch.update(quizRef, {
            ["typeData.q_obj." + q_id + ".qid"]: q_id,
            ["typeData.q_obj." + q_id + ".type"]: q_type,
            ["typeData.q_obj." + q_id + ".prop_type"]: q_prop_type,
            ["typeData.q_obj." + q_id + ".tags"]: q_tags,
        });
        batch.commit();
    });
}
export async function updateQTagsInQuiz(space_id, path_id, local_id, question_ids, new_tags = []) {
    const batch = writeBatch(firestore);
    const resourcesPathRef = doc(firestore, "resources", local_id);
    question_ids.forEach((q_id) => {
        batch.update(resourcesPathRef, {
            ["typeData.q_obj." + q_id + ".tags"]: arrayUnion(...new_tags),
        });
    });
    batch.commit();
}
export async function removeQTagsInQuiz(space_id, path_id, local_id, question_ids, removed_tags = []) {
    const batch = writeBatch(firestore);
    const resourcesPathRef = doc(firestore, "resources", local_id);
    question_ids.forEach((q_id) => {
        batch.update(resourcesPathRef, {
            ["typeData.q_obj." + q_id + ".tags"]: arrayRemove(...removed_tags),
        });
    });
    batch.commit();
}
