import "katex/dist/katex.css";
import classes from "./InsertEquationDialog.module.css";

import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import { $wrapNodeInElement } from "@lexical/utils";
import { $createParagraphNode, $insertNodes, $isRootOrShadowRoot, COMMAND_PRIORITY_EDITOR, createCommand } from "lexical";
import { useCallback, useEffect } from "react";

import { $createEquationNode, EquationNode } from "../../nodes/EquationNode";
import KatexEquationAlterer from "../KatexEquationAlterer/KatexEquationAlterer";
import LexicalModalHeader from "../LexicalModalHeader/LexicalModalHeader";
import { useLanguage } from "../../../../contexts/LanguageContext";
import Modal from "../../../UI/Modal/Modal";
import ReactPortal from "../../../UI/Modal/ReactPortal";

export const INSERT_EQUATION_COMMAND = createCommand("INSERT_EQUATION_COMMAND");

export function InsertEquationDialog({ activeEditor, setshowEquationDialog, editorConfig }) {
    const { translate } = useLanguage();
    const onEquationConfirm = useCallback(
        (equation, inline) => {
            activeEditor.dispatchCommand(INSERT_EQUATION_COMMAND, { equation, inline });
            setshowEquationDialog(false);
        },
        [activeEditor, setshowEquationDialog]
    );

    return (
        <ReactPortal wrapperId={editorConfig?.editorID}>
            <Modal setModalOpen={setshowEquationDialog}>
                <div className={classes.InsertEquationDialog}>
                    <LexicalModalHeader title={translate("Insert Equation")} onClose={() => setshowEquationDialog(false)} />
                    <KatexEquationAlterer onConfirm={onEquationConfirm} />
                </div>
            </Modal>
        </ReactPortal>
    );
}

export default function EquationsPlugin() {
    const [editor] = useLexicalComposerContext();

    useEffect(() => {
        if (!editor.hasNodes([EquationNode])) {
            throw new Error("EquationsPlugins: EquationsNode not registered on editor");
        }

        return editor.registerCommand(
            INSERT_EQUATION_COMMAND,
            (payload) => {
                const { equation, inline } = payload;
                const equationNode = $createEquationNode(equation, inline);

                $insertNodes([equationNode]);
                if ($isRootOrShadowRoot(equationNode.getParentOrThrow())) {
                    $wrapNodeInElement(equationNode, $createParagraphNode).selectEnd();
                }

                return true;
            },
            COMMAND_PRIORITY_EDITOR
        );
    }, [editor]);

    return null;
}
