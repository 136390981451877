import { useCallback, useState } from "react";

//ui
import classes from "./KatexEquationAlterer.module.css";

// import Button from "../ui/Button";
import KatexRenderer from "../KatexRenderer/KatexRenderer";
import Button from "../../../ComponentLibrary/Button/Button";

//contexts
import { useLanguage } from "../../../../contexts/LanguageContext";

type Props = {
    initialEquation?: string;
    onConfirm: (equation: string, inline: boolean) => void;
};

export default function KatexEquationAlterer({ onConfirm, initialEquation = "" }: Props): JSX.Element {
    const { translate } = useLanguage();
    const [equation, setEquation] = useState<string>(initialEquation);
    const [inline, setInline] = useState<boolean>(true);

    const onClick = useCallback(() => {
        onConfirm(equation, inline);
    }, [onConfirm, equation, inline]);

    const onCheckboxChange = useCallback(() => {
        setInline(!inline);
    }, [setInline, inline]);

    return (
        <>
            <div className={classes.KatexEquationAlterer_defaultRow}>
                {translate("Inline")}
                <input type="checkbox" checked={inline} onChange={onCheckboxChange} />
            </div>
            <div className={classes.KatexEquationAlterer_defaultRow}>{translate("Equation")}</div>
            <div className={classes.KatexEquationAlterer_centerRow}>
                {inline ? (
                    <input
                        onChange={(event) => {
                            setEquation(event.target.value);
                        }}
                        value={equation}
                        className={classes.KatexEquationAlterer_textArea}
                    />
                ) : (
                    <textarea
                        onChange={(event) => {
                            setEquation(event.target.value);
                        }}
                        value={equation}
                        className={classes.KatexEquationAlterer_textArea}
                    />
                )}
            </div>
            <div className={classes.KatexEquationAlterer_defaultRow}>{translate("Visualization")}</div>
            <div className={classes.KatexEquationAlterer_centerRow}>
                <KatexRenderer equation={equation} inline={false} onClick={() => null} />
            </div>
            <div className={classes.KatexEquationAlterer_dialogActions}>{<Button onClick={onClick}>{translate("CONFIRM")}</Button>}</div>
        </>
    );
}
