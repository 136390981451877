import { arrayRemove, arrayUnion, doc, increment, writeBatch, updateDoc} from "@firebase/firestore";
import { auth, firestore } from "../../app/firebase";
import { getUserPathProgressData } from "../../functions/DataGetterFunctions";



//MARKASDONE
//PATH
export async function markAsDoneResourcePath(space_id, path_id, resource_id_global, resource_id_local, checked_resource_by__tag_date, allTaskIDsNotChecked, allTaskTimesNotChecked) {
    const uid = auth.currentUser?.uid;
    const batch = writeBatch(firestore);
    const checkedRef = doc(firestore, "progress_by_user", uid, "progress_spaces", space_id, "progress_paths", path_id);
    const user_space_ref = doc(firestore, "progress_by_user", uid, "progress_spaces", space_id);
    const checkedGlobalRef = doc(firestore, "progress_by_user", uid);

    //SET+MERGE: if document does not exist yet!
    const marked_as_done_path = {
        resources: { checked_path_resources: arrayUnion(resource_id_local) },
        tasks: { checked_path_resource_tasks: { [resource_id_local]: arrayUnion(...allTaskIDsNotChecked) } },
        stats: {
            time_worked: increment(allTaskTimesNotChecked),
            checked_path_resources_count: increment(1),
        },
        tags: { checked_path_resources_by_date: arrayUnion(...checked_resource_by__tag_date) },
        metaData: { path_id }
    }
    batch.set(checkedRef, marked_as_done_path, { merge: true });
    const marked_as_done_space = {
        resources: { checked_path_resources: arrayUnion(resource_id_local) },
        stats: {
            time_worked: increment(allTaskTimesNotChecked),
            checked_path_resources_count: increment(1),
            checked_space_tasks_count: increment(allTaskIDsNotChecked?.length),
        }
    }
    batch.set(user_space_ref, marked_as_done_space, { merge: true });
    return batch.commit();
}
export async function markUnDoneResourcePath(space_id, path_id, resource_id_global, resource_id_local, checked_path_resources_by_date) {
    const uid = auth.currentUser?.uid;
    const batch = writeBatch(firestore);
    const checkedRef = doc(firestore, "progress_by_user", uid, "progress_spaces", space_id, "progress_paths", path_id);
    const user_space_ref = doc(firestore, "progress_by_user", uid, "progress_spaces", space_id);
    const checkedGlobalRef = doc(firestore, "progress_by_user", uid);

    batch.update(checkedRef, {
        ["resources.checked_path_resources"]: arrayRemove(resource_id_local),
        ["tags.checked_path_resources_by_date"]: checked_path_resources_by_date
    });
    batch.update(user_space_ref, {
        ["resources.checked_space_resources"]: arrayRemove(resource_id_local),
    });
    return batch.commit();
}
//SPACE
export async function markAsDoneResourceSpace(space_id, resource_id_local, allTaskIDsNotChecked=[], allTaskTimesNotChecked) {
    const uid = auth.currentUser?.uid;
    const user_space_ref = doc(firestore, "progress_by_user", uid, "progress_spaces", space_id);
    console.log(space_id, resource_id_local, allTaskIDsNotChecked, allTaskTimesNotChecked);
    await updateDoc(user_space_ref, {
        ["resources.checked_space_resources"]: arrayUnion(resource_id_local),
        [`tasks.checked_space_resource_tasks.${resource_id_local}`]: arrayUnion(...allTaskIDsNotChecked), //check all remaining tasks
        ["stats.checked_space_tasks_count"]: increment(allTaskIDsNotChecked?.length),
        ["stats.time_worked"]: increment(allTaskTimesNotChecked),
    });
}

export async function markUnDoneResourceSpace(space_id, resource_id_local) {
    const uid = auth.currentUser?.uid;
    const batch = writeBatch(firestore);
    const user_space_ref = doc(firestore, "progress_by_user", uid, "progress_spaces", space_id);
    console.log(resource_id_local);
    batch.update(user_space_ref, {
        ["resources.checked_space_resources"]: arrayRemove(resource_id_local),
    });
    return batch.commit();
}

//CHECK TASK
//PATH
export async function checkPathTask(space_id, path_id, resource_id, task_id, task_time) {
    const uid = auth.currentUser?.uid;
    const batch = writeBatch(firestore);
    //SET+MERGE: if document does not exist yet!
    const checkedRef = doc(firestore, "progress_by_user", uid, "progress_spaces", space_id, "progress_paths", path_id);
    const checked = {
        tasks: {
            checked_path_resource_tasks: {
                [resource_id]: arrayUnion(task_id)
            }
        },
        stats: {
            time_worked: increment(task_time),
            checked_path_tasks_count: increment(1)
        },
        metaData: { path_id }
    }
    batch.set(checkedRef, checked, { merge: true });
    const user_space_ref = doc(firestore, "progress_by_user", uid, "progress_spaces", space_id);

    const checked_space = {
        stats: {
            checked_space_tasks_count: increment(1),
            time_worked: increment(task_time),
        }
    }
    batch.set(user_space_ref, checked_space, { merge: true });
    await batch.commit();
}
export async function uncheckPathTask(space_id, resource_id, path_id, task_id, task_time) {
    const uid = auth.currentUser?.uid;
    const batch = writeBatch(firestore);
    const checkedRef = doc(firestore, "progress_by_user", uid, "progress_spaces", space_id, "progress_paths", path_id);
    const checked_path = {
        tasks: {
            checked_path_resource_tasks: {
                [resource_id]: arrayRemove(task_id)
            }
        },
        stats: {
            time_worked: increment(-task_time),
            checked_path_tasks_count: increment(-1)
        },
        metaData: { path_id }
    }
    batch.set(checkedRef, checked_path, { merge: true });
    const user_space_ref = doc(firestore, "progress_by_user", uid, "progress_spaces", space_id);
    const checked_space = {
        stats: {
            checked_space_tasks_count: increment(-1),
            time_worked: increment(-task_time),
        }
    }
    batch.set(user_space_ref, checked_space, { merge: true });
    await batch.commit();
}

//SPACE
export async function checkSpaceTask(space_id, resource_id, task_id, task_time) {
    const uid = auth.currentUser?.uid;
    const batch = writeBatch(firestore);
    const checkedRef = doc(firestore, "progress_by_user", uid, "progress_spaces", space_id);
    batch.update(checkedRef, {
        [`tasks.checked_space_resource_tasks.${resource_id}`]: arrayUnion(task_id),
        ["stats.time_worked"]: increment(task_time),
        ["stats.checked_space_tasks_count"]: increment(1)
    });
    await batch.commit();
}

export async function uncheckSpaceTask(space_id, resource_id, task_id, task_time) {
    const uid = auth.currentUser?.uid;
    const batch = writeBatch(firestore);
    const checkedRef = doc(firestore, "progress_by_user", uid, "progress_spaces", space_id);
    batch.update(checkedRef, {
        [`tasks.checked_space_resource_tasks.${resource_id}`]: arrayRemove(task_id),
        ["stats.time_worked"]: increment(-task_time),
        ["stats.checked_space_tasks_count"]: increment(-1)
    });
    await batch.commit();
}

export async function updateUsersStats(space_id, path_id, local_id, task_id, answer) {
    const uid = auth.currentUser?.uid;
    const batch = writeBatch(firestore);
    const pathRef = doc(firestore, "spaces", space_id, "paths", path_id, "stats", "study_behaviour");
    const userRef = doc(firestore, "users", uid, "stats", "study_behaviour");
    batch.set(pathRef, { study_times: arrayUnion({ local_id: local_id, task_id: task_id, user_id: uid, answer: answer }) }, { merge: true });
    batch.set(userRef, { study_times: arrayUnion({ local_id: local_id, task_id: task_id, path_id: path_id, answer: answer }) }, { merge: true });
    await batch.commit();
}

export async function markMessageAsReadOnUser(space_id, message_id) {
    const uid = auth.currentUser?.uid;
    const batch = writeBatch(firestore);
    const checkedRef = doc(firestore, "progress_by_user", uid, "progress_spaces", space_id);
    batch.update(checkedRef, {
        ["config.mark_as_read"]: arrayUnion(message_id)
    });
    await batch.commit();
}
export async function markMessageAsUnReadOnUser(space_id, message_id) {
    const uid = auth.currentUser?.uid;
    const batch = writeBatch(firestore);
    const checkedRef = doc(firestore, "progress_by_user", uid, "progress_spaces", space_id);
    batch.update(checkedRef, {
        ["config.mark_as_read"]: arrayRemove(message_id)
    });
    await batch.commit();
}

export async function toggleDownloadCalendar(space_id, boolean) {
    const uid = auth.currentUser?.uid;
    const batch = writeBatch(firestore);
    const checkedRef = doc(firestore, "progress_by_user", uid, "progress_spaces", space_id);
    batch.update(checkedRef, {
        ["config.download_calendar"]: boolean
    });
    await batch.commit();
}


export async function resetPathProgress(space_id, path_id, users_path_progress) {
    const uid = auth.currentUser?.uid;
    const batch = writeBatch(firestore);
    const checkedRef = doc(firestore, "progress_by_user", uid, "progress_spaces", space_id, "progress_paths", path_id);
    const user_space_ref = doc(firestore, "progress_by_user", uid, "progress_spaces", space_id);

    const marked_as_done_path = {
        resources: { checked_path_resources: [] },
        tasks: { checked_path_resource_tasks: {} },
        stats: {
            time_worked: 0,
            checked_path_resources_count: 0,
        },
        tags: { checked_path_resources_by_date: [] },
        metaData: { path_id }
    }
    const { users_checked_path_resources,
        time_worked, checked_path_resources_count, checked_path_tasks_count, checked_path_resources_by_tag_date,
        local_upvotes, local_downvotes } = getUserPathProgressData(users_path_progress);

    batch.set(checkedRef, marked_as_done_path, { merge: true });
    const marked_as_done_space = {
        resources: { checked_path_resources: arrayRemove(...users_checked_path_resources) },
        stats: {
            time_worked: increment(-time_worked),
            checked_path_resources_count: increment(-checked_path_resources_count),
            checked_space_tasks_count: increment(-checked_path_tasks_count),
        }
    }
    batch.set(user_space_ref, marked_as_done_space, { merge: true });
    return batch.commit();
}