import { lazy, Suspense } from "react";
import { RecoilRoot } from "recoil";

//UI
import "./AppInit.css";
import Loader from "../components/Shared/Loader/Loader";
//components
// import TooManyActiveSessions from "../components/TooManyActiveSessions/TooManyActiveSessions";
import SpaceLoader from "../components/Shared/Loader/SpaceLoader";

//contexts
import { AuthProvider } from "../contexts/AuthContext";
import { useSpaceContext } from "../contexts/SpaceContext/SpaceContext";

//APPS
const App = lazy(() => import("./App"));
const MobileApp = lazy(() => import("../mobile/MobileApp"));

export default function AppInit() {
    const { spc_lang, space_id, isMobile, loading_space_map, space_theme } = useSpaceContext();
    const space_is_ready = !loading_space_map && space_id && space_theme && spc_lang;

    // 1. thin loader -> JS loads
    // 2. Space Loader -> lazy loading (e.g. App or Mobile JS loads)
    if (space_is_ready) {
        return (
            <Suspense
                fallback={
                    <div className={"Fallback"}>
                        <SpaceLoader />
                    </div>
                }
            >
                <AuthProvider>
                    <RecoilRoot>
                        <link rel="stylesheet" type="text/css" href="css/style.css?version=#" />
                        {/* <header></header> */}
                        {isMobile ? <MobileApp /> : <App />}
                    </RecoilRoot>
                </AuthProvider>
            </Suspense>
        );
    } else {
        return (
            <div className={"FallbackAllOver"}>
                <Loader className={"LoaderBig"} />
            </div>
        );
    }
}
