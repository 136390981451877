import classes from "./LexicalFileInput.module.css";
import LabeledSection from "../../../ComponentLibrary/LabeledSection/LabeledSection";

export function LexicalFileInput({ accept, label, onChange, "data-test-id": dataTestId }) {
    return (
        <LabeledSection labelClass={classes.LabelClass} label={label}>
            <input type="file" accept={accept} className={classes.InputInput} onChange={(e) => onChange(e.target.files)} data-test-id={dataTestId} />
        </LabeledSection>
    );
}
