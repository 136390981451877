const debug = false;

//get viewport width and height
export function getViewport() {
    var viewPortWidth = null;
    var viewPortHeight = null;
    // the more standards compliant browsers (mozilla/netscape/opera/IE7) use window.innerWidth and window.innerHeight
    if (typeof window.innerWidth !== "undefined") {
        viewPortWidth = window.innerWidth;
        viewPortHeight = window.innerHeight;
        return [viewPortWidth, viewPortHeight];
    } else return [viewPortWidth, viewPortHeight];
}

//
export function checkIsMobile() {
    const [viewPortWidth, viewPortHeight] = getViewport();
    if (viewPortWidth !== null && viewPortHeight !== null) {
        if (debug) console.log("APP_INIT - DETERMINE WINDOW", viewPortWidth, viewPortHeight);
        return viewPortWidth < viewPortHeight;
    }
    return false;
}

export function determineSpaceID(space_map, not_inside_static_map, setnot_inside_static_map) {
    const host = window.location.host;
    let topLevel_domain, origin_domain;
    const host_splited = host.split(".");
    if (debug) console.log("APP_INIT - Determine SpaceID, host:", host, host_splited, space_map);
    if (host_splited[0] === "www") {
        origin_domain = host_splited[2];
    } else {
        origin_domain = host_splited[1];
        if (!origin_domain) origin_domain = host_splited[0];
    }
    if (origin_domain === "rdx" || origin_domain === "radix" || origin_domain.includes("localhost")) {
        topLevel_domain = host_splited[0];
        if (topLevel_domain === "www") {
            topLevel_domain = host_splited[1];
            if (origin_domain.includes("localhost:8000")) {
                topLevel_domain = "bemedi";
            }
        }
    } else {
        const host_length = host_splited?.length;
        topLevel_domain = host_splited[host_length - 2];
    }
    if (debug) console.log("APP_INIT - ORIGIN:", origin_domain, topLevel_domain);
    if (topLevel_domain) {
        if (debug) console.log("APP_INIT - Determine SpaceID, data:", topLevel_domain, space_map);
        try {
            if (debug) console.log(topLevel_domain);
            // console.log("APP INIT 1", top_domain, space_map);
            const new_space_id = space_map[topLevel_domain];
            if (debug) console.log("APP_INIT - Determined spaceID:", new_space_id);
            if (new_space_id) {
                return new_space_id;
            } else {
                if (not_inside_static_map) {
                    if (debug) console.log("APP_INIT - No spaceID going to default space: RDX");
                    return "BEMEDI"; //Default space -> TODO: make RADIX OR RDX? the default space
                } else {
                    return setnot_inside_static_map(true);
                }
            }
        } catch (error) {
            console.log("Something went wrong in determinSpaceID()", error);
        }
    }
}
