import classes from "./Switch.module.css";

const Switch = ({ disabled=false, dataId=null, onClick, checked }) => {
    return (
        <label className={classes.Switch}>
            <input
                data-id={dataId}
                disabled={disabled}
                type="checkbox"
                onChange={(e) => {
                    e.stopPropagation();
                    e.nativeEvent.stopImmediatePropagation();
                    onClick(e);
                }}
                defaultChecked={checked}
                checked={checked}
            />
            <span className={classes.Slider} style={{ cursor: disabled ? "default" : "pointer" }}></span>
        </label>
    );
};

export default Switch;
