import { useLayoutEffect } from "react";

export default function setSpaceTheme(spaceThemeData) {
    removeSpaceStyles();

    const {
        main_color,
        main_accent,
        main_font_color,
        //mainNav
        mainNav_backgroundColor,
        mainNav_middleBtnColor,
        mainNav_middleBtnColorActive,
        mainNav_middleBtnHover,
        //BIGCHAT
        chatRooms_background,
        chatRooms_roomActive,
        chatRooms_horizontalLine,
        //BROWSE BANNER
        browseBanner_accentColor,
        browseBanner_mainColor,
    } = spaceThemeData;

    // console.log("SPACE_THEME", mainNav_middleBtnHover, mainNav_middleBtnColor, mainNav_backgroundColor, mainNav_middleBtnColorActive);

    if (!spaceThemeData) return;
    var bodyStyles = document.body.style;
    // ** MAIN */
    if (main_color) bodyStyles.setProperty("--main_color", main_color);
    if (main_accent) bodyStyles.setProperty("--main_accent", main_accent);
    if (main_font_color) bodyStyles.setProperty("--main_font_color", main_font_color);
    //** MAIN NAV */
    if (mainNav_backgroundColor) bodyStyles.setProperty("--mainNav_backgroundColor", mainNav_backgroundColor);
    if (mainNav_backgroundColor) bodyStyles.setProperty("--spc_icon_backgroundColor", mainNav_backgroundColor);
    if (mainNav_middleBtnColor) bodyStyles.setProperty("--mainNav_middleBtnColor", mainNav_middleBtnColor);
    if (mainNav_middleBtnColorActive) bodyStyles.setProperty("--mainNav_middleBtnColorActive", mainNav_middleBtnColorActive);
    if (mainNav_middleBtnHover) bodyStyles.setProperty("--mainNav_middleBtnHover", mainNav_middleBtnHover);
    // ** BIGCHAT
    if (chatRooms_background) bodyStyles.setProperty("--chatRooms_background", chatRooms_background);
    if (chatRooms_roomActive) bodyStyles.setProperty("--chatRooms_roomActive", chatRooms_roomActive);
    if (chatRooms_horizontalLine) bodyStyles.setProperty("--chatRooms_horizontalLine", chatRooms_horizontalLine);
    //** SIDENAV */

    //BROWSEBANNER
    if (browseBanner_accentColor) {
        bodyStyles.setProperty("--browseBanner_accentColor", browseBanner_accentColor);
    }
    if (browseBanner_mainColor) bodyStyles.setProperty("--browseBanner_mainColor", browseBanner_mainColor);

    return true;
}

// function reloadCSSVariables() {
//     let links = document.getElementsByTagName("link");
//     console.log("SPACE RELOADING_CSS", links);
//     for (let i = 0; i < links.length; i++) {
//         if (links[i].getAttribute("rel") == "stylesheet") {
//             let href = links[i].getAttribute("href").split("?")[0];
//             let newHref = href + "?version=" + new Date().getMilliseconds();
//             console.log("SPACE RELOADING_CSS", newHref);
//             links[i].setAttribute("href", newHref);
//         }
//     }
// }

function removeSpaceStyles() {
    document.body.removeAttribute("style");
}
