import { useEffect, useLayoutEffect, useState } from "react";

import { $generateNodesFromDOM } from "@lexical/html";
import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import { $createParagraphNode, $getRoot, $isDecoratorNode, $isElementNode } from "lexical";

const OnMountPlugIn = ({ value, setEditable, setInFocus }) => {
    const [editor] = useLexicalComposerContext();
    const [initOnce, setinitOnce] = useState(false);

    useEffect(() => {
        // here we mount lexical with the current saved value
        // we only update when updatedAt changes

        editor.update(() => {
            // console.log("Lexical - ON_MOUNT", value, !value);
            if (!value || initOnce) return;
            const parser = new DOMParser();
            const dom = parser.parseFromString(value, "text/html");
            const nodes = $generateNodesFromDOM(editor, dom);
            const root = $getRoot();
            root.clear();
            root.select();
            nodes.forEach((node) => {
                try {
                    root.append(node);
                } catch (err) {
                    const paragraph = $createParagraphNode();
                    root.append(paragraph.append(node));
                }
            });
            setinitOnce(true);
            setEditable(true);
            setInFocus(true);
        });
    }, [editor, value]);

    return null;
};

export default OnMountPlugIn;

// export default OnFocusPlugIn;
// Here is my onMount plugin#
// import { useEffect } from 'react';

// import { $generateNodesFromDOM } from '@lexical/html';
// import { $convertToMarkdownString, TRANSFORMERS } from '@lexical/markdown';
// import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
// import { $createParagraphNode, $getRoot } from 'lexical';

// import { convertHTMLtoLexicalHTML } from '../helpers';

// const OnMountPlugIn = ({ baseValue, updatedAt, storedValue, setShowPH, setStoredValue }) => {
//   const [editor] = useLexicalComposerContext();
//   useEffect(() => {
//     // here we mount lexical with the current saved value
//     // we only update when updatedAt changes
//     editor.update(() => {
//       if (!baseValue && !storedValue) return;
//       const root = $getRoot();
//       const parser = new DOMParser();
//       const updatedBase = baseValue.replaceAll('<p><br /></p>', '<p></p>');
//       const html = convertHTMLtoLexicalHTML(updatedBase  '');
//       const dom = parser.parseFromString(html, 'text/html');
//       const nodes = $generateNodesFromDOM(editor, dom);
//       root.clear();
//       root.select();

//       nodes.forEach(node => {
//         try {
//           root.append(node);
//         } catch (err) {
//           const paragraph = $createParagraphNode();
//           root.append(paragraph.append(node));
//         }
//       });
//       const underline = { format: ['underline'], tag: '++', type: 'text-format' };
//       const NewTransformers = [...TRANSFORMERS, underline];
//       const markdown = $convertToMarkdownString(NewTransformers);
//       setStoredValue(markdown  '');
//       const checker = markdown.trim().replace(/[\n\r]/g, '');
//       setShowPH(checker.length > 0 ? false : true);
//     });
//   }, [editor, updatedAt, baseValue]);

//   return null;
// };
